import {Badge, HStack, Text, View} from 'native-base';
import React from 'react';
import {Colors} from '../../../../../styles';
import {testID, TestIdentifiers} from '../../../../../testUtils';
import {
  DISPLAY_CONVERSATION_SIDEBAR_ICON,
  getCountString,
} from './ConversationSidebarUtils';
import {useIntl} from 'react-intl';
import {Spin} from 'antd';
import {IConversationChannelList} from './Interfaces';
import {getConversationSidebarTabStyles} from './ConversationSidebarTabStyles';
import './ConversationSidebarTab.css';

export interface IConversationSidebarTabProps {
  tabItem: IConversationChannelList;
  allUnreadCount: number;
  selectedTabCode: string;
  isShowCountLoading: boolean | undefined;
  count: number;
  onTabPress: (tabItem: IConversationChannelList) => void;
}

const ConversationSidebarTab = ({
  tabItem,
  selectedTabCode,
  allUnreadCount,
  onTabPress,
  isShowCountLoading,
  count,
}: IConversationSidebarTabProps) => {
  const intl = useIntl();
  const testId = tabItem.code + '_TAB';
  const isTabSelected = selectedTabCode == tabItem.key;

  const styles = React.useMemo(() => {
    return getConversationSidebarTabStyles({isTabSelected});
  }, [isTabSelected]);

  const handleOnTabPress = () => {
    onTabPress(tabItem);
  };
  return (
    <div
      key={tabItem.key + '_ConvSidebar_'}
      onClick={handleOnTabPress}
      className={`pressable tab-container${
        isTabSelected ? ' tab-container-selected' : ''
      }`}
      {...testID(TestIdentifiers.conversationTabClick)}
    >
      <HStack flex={1}>
        <View
          justifyItems={'center'}
          alignItems={'center'}
          alignSelf={'center'}
          justifySelf={'center'}
          height={'16px'}
          width={'16px'}
          {...testID('image')}
        >
          {DISPLAY_CONVERSATION_SIDEBAR_ICON(
            tabItem.key,
            isTabSelected ? Colors.Custom.Primary300 : Colors.FoldPixel.GRAY300
          )}
        </View>
        <Text
          flex={0.9}
          style={styles.tabText}
          size="mdNormal"
          fontSize={14}
          fontWeight={500}
          {...testID(testId)}
        >
          {intl.formatMessage({id: tabItem.intlId})}
        </Text>
        {isShowCountLoading && <Spin />}
        {tabItem.code &&
        tabItem.code != 'ALL_CHAT' &&
        !isShowCountLoading &&
        count > 0 ? (
          <Badge
            size={'exsMedium'}
            fontSize={10}
            fontWeight={500}
            backgroundColor={Colors.Custom.primary300}
            _text={{
              color: 'white',
            }}
            alignSelf="center"
            rounded="6px"
            justifyContent={'center'}
            paddingX={'4px'}
            paddingY={'2px'}
            {...testID(`${testId}_COUNT`)}
          >
            {getCountString({
              count: count,
            })}
          </Badge>
        ) : null}
        {tabItem.code == 'ALL_CHAT' &&
        !isShowCountLoading &&
        allUnreadCount > 0 ? (
          <Badge
            fontSize={10}
            fontWeight={500}
            backgroundColor={Colors.Custom.primary300}
            _text={{
              color: 'white',
            }}
            alignSelf="center"
            rounded="6px"
            justifyContent={'center'}
            paddingX={'4px'}
            paddingY={'2px'}
            {...testID(`${testId}_COUNT`)}
          >
            {getCountString({
              count: allUnreadCount,
            })}
          </Badge>
        ) : null}
      </HStack>
    </div>
  );
};

export default ConversationSidebarTab;
