import React, {useContext, useState} from 'react';
import {View, Pressable, Dimensions} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles/Colors';
import {IconButton, Text, useMediaQuery} from 'native-base';
import DetailedBill from './DetailedBill';
import {
  IBilledActivityRecord,
  IBillingData,
  IHandleBillingHistoryTableActions,
  IHandleTableActions,
  IHookState,
} from '../BillingHooks/BillingHookInterfaces';
import {COMMON_ACTION_CODES} from '../../../../constants/ActionConst';
import {useIntl} from 'react-intl';
import {Drawer} from 'antd';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  SMALL_WINDOW_1700,
} from '../../../../constants/StringConst';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import CareProgramBilling from '../CareProgramBilling';
import {
  getMonthAndYearFromDate,
  getMonthStartAndEndDateTime,
} from '../../../../utils/DateUtils';

interface IBillingHistoryActions {
  handleBillingHistoryTableActions: (
    params: IHandleBillingHistoryTableActions
  ) => void;
  record: IBilledActivityRecord;
  timezone: string;
}

const BillingHistoryActions = (props: IBillingHistoryActions) => {
  const {handleBillingHistoryTableActions, record} = props;
  const intl = useIntl();
  const [openDetailedBilledHistory, setOpenDetailedBilledHistory] =
    useState(false);
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const screenDimensions = Dimensions.get('window');
  const smallWindow = screenDimensions?.width < SMALL_WINDOW_1700;

  const onCloseDrawer = () => {
    setOpenDetailedBilledHistory(false);
    handleBillingHistoryTableActions({
      actionCode: COMMON_ACTION_CODES.CLOSE,
      actionData: undefined,
    });
  };

  return (
    <>
      <Pressable
        onPress={() => {
          setOpenDetailedBilledHistory(true);
          handleBillingHistoryTableActions({
            actionCode: COMMON_ACTION_CODES.DETAIL_VIEW,
            actionData: record,
          });
        }}
      >
        <View
          style={{
            justifyContent: 'flex-start',
            backgroundColor: Colors.FoldPixel.PRIMARY100,
            borderColor: Colors.Custom.PrimaryColor200,
            width: 'max-content',
            paddingVertical: 10.5,
            paddingHorizontal: 8,
          }}
        >
          <Text size={'smRegular'} style={{color: Colors.FoldPixel.PRIMARY300, fontWeight: '500'}}>
            {intl.formatMessage({id: 'viewBillingReport'})}
          </Text>
        </View>
      </Pressable>
      {openDetailedBilledHistory && (
        <Drawer
          destroyOnClose
          placement="right"
          open={true}
          onClose={onCloseDrawer}
          closable
          width={
            isIPadScreen || isIPadMiniScreen
              ? '80%'
              : smallWindow
              ? '70%'
              : '75%'
          }
          title={
            <ModalActionTitle
              text={`Billing Report - ${getMonthAndYearFromDate(
                props.record.month,
                props.timezone
              )}`}
              buttonList={[
                {
                  id: 1,
                  btnText: 'close',
                  textColor: Colors.Custom.mainPrimaryPurple,
                  variant: BUTTON_TYPE.PRIMARY,
                  isTransBtn: false,
                  onClick: onCloseDrawer,
                },
              ]}
            />
          }
        >
          <CareProgramBilling
            isBilledView={true}
            serviceStartDateTime={
              getMonthStartAndEndDateTime(props.record.month, props?.timezone)
                .monthStartDateTime
            }
            serviceEndDateTime={
              getMonthStartAndEndDateTime(props.record.month, props?.timezone)
                .monthEndDateTime
            }
          />
        </Drawer>
      )}
    </>
  );
};

export default BillingHistoryActions;
