import {View,Pressable} from "react-native";
import {Colors} from "../../../../styles";
import {IAttributes, ICustomAttribute} from "../../../RightSideContainer/Contacts/CustomField/interface";
import {Tooltip,Popover} from 'antd'
import {useState, useMemo} from 'react'
import { BOOLEAN_OPTION, CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR} from "../../../RightSideContainer/Contacts/CustomField/CustomFieldConst";
import {filterNullAttributes} from "../../../RightSideContainer/Contacts/CustomField/CustomFieldUtils";
import {getDateStrFromFormat} from "../../../../utils/DateUtils";
import {DATE_FORMATS} from "../../../../constants";
import { StyleSheet } from 'react-native';
import {Text} from 'native-base'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tagContainer: {
    marginRight: 8,
    marginBottom: 4,
    maxWidth: 234,
  },
  tag: {
    backgroundColor: Colors.FoldPixel.GRAY50,
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 16,
    color: Colors.FoldPixel.GRAY300
  },
  moreText: {
    fontWeight: '500',
    marginTop: 1,
    color: Colors.Custom.PurpleColor,
    paddingVertical: 3,
    marginLeft: 1,
  },
  container2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 240,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  popover: {
    padding: 0,
    borderRadius: 12,
  },
  popoverOverlay: {
    padding: 0,
  },
};

export const AttributesView = (props: {attributes: IAttributes[]}) => {
  const [stateData, setStateData] = useState({
    showMore: false,
    maxTagToShow: 2,
  })

  const getValue = (attribute: IAttributes) => {
    if (attribute?.dateValue) {
      return getDateStrFromFormat(attribute?.value, DATE_FORMATS.CONVERSATION_DATE_PICKER)
    } else if (attribute?.booleanValue !== null) {
      return attribute?.booleanValue ? BOOLEAN_OPTION?.[0]?.label :  BOOLEAN_OPTION?.[1]?.label;
    }
    return attribute?.value;
  }

  const renderTags = (attribute: IAttributes) => {
    return (
      <View style={styles.tagContainer}>
        <Text size={'smRegular'} style={styles.tag}>
          {attribute?.label}
          <Tooltip
            title={attribute?.value?.length>CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? attribute?.value : ''}
            placement={'top'}
            destroyTooltipOnHide={true}
          >
            {": "} {attribute?.value?.length > CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR ? `${attribute?.value.slice(0,CUSTOM_ATTRIBUTE_VALUE_MAX_DISPLAY_CHAR)}...` : getValue(attribute)}
          </Tooltip>
        </Text>
      </View>
    )
  }

  const popoverContent = useMemo(() => (
    <View style={styles.container2}>
      {filterNullAttributes(props?.attributes)?.map?.((attribute: IAttributes) => {
        return (renderTags(attribute))
      })}
    </View>
  ), [props.attributes]);

  return <>
    <View style={styles.container}>
      {filterNullAttributes(props?.attributes)?.map?.((attribute: IAttributes, index: number) => {
        if (index < stateData?.maxTagToShow && attribute?.value) {
          return (renderTags(attribute))
        }
      })}
      {(filterNullAttributes(props?.attributes)?.length - stateData?.maxTagToShow) > 0 && <Popover
        overlayInnerStyle={antdStyles.popover}
        overlayClassName='patient-search-select-styles'
        className='patient-search-select-styles'
        overlayStyle={antdStyles.popoverOverlay}
        overlay={antdStyles.popoverOverlay}
        style={antdStyles.popover}
        placement={'bottomLeft'}
        trigger={'click'}
        visible={stateData?.showMore}
        onVisibleChange={() => {
          setStateData((prev) => {
            return {
              ...prev,
              showMore: false
            }
          })
        }}
        content={popoverContent}
      >
        <Pressable
          onPress={() => {
            setStateData((prev) => {
              return {
                ...prev,
                showMore: true
              }
            })
          }}
        >
          <Text style={styles.moreText}>
            {`+${filterNullAttributes(props?.attributes)?.length - stateData?.maxTagToShow} more`}
          </Text>
        </Pressable>
    </Popover>}
    </View>
  </>
}