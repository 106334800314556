import {DurationInputArg2} from "moment";
import { IVitalData } from "../../../../../../Interfaces";
import { DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT } from "../../../../../../constants";
import { getDateStrFromFormat, getFormattedDate, getMomentObj, getMomentObjFromDateStrAndFormat } from "../../../../../../utils/DateUtils";
import { Vital, getDisplay, getVitalUnitDisplay } from "../../../../../../utils/VitalUtils";
import { IVitals } from "../../interfaces";
import { getVitalDataArray } from "../DetailTableUtils";
import { GoalOperator } from "../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Goals/AddOrUpdateGoals/interface";
import { GraphsNodeTypes, GraphsTypes, IGraph, IHighLightValues, VitalGraphData } from "../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/interface";
import { getOldRecordsData } from "../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper";
import { feetToFeetAndInches, inchesToFeetAndInches } from "../../../../../../utils/vitalUnitConversions";
import { Colors } from "../../../../../../styles";

export interface IGraphDataSet {
  date: string;
  label: string;
  [index: string]: string | number;
}

export interface IGraphDataSetInCoords {
  x: string | number,
  y: string | number,
  dateStr?: string,
  tooltipText?: string,
  graphNodeType?: GraphsNodeTypes
}
export interface IVitalsConst {
  name: string;
  code: string;
  label: string;
  titleText: string;
}

export enum CustomPlotName {
  CCM = 'CCM',
  GOAL = 'Goal',
  UG = 'Upper Goal',
  LG = 'Lower Goal'
}

export enum VitalTypes {
  weight = 'weight',
  bmi = 'bmi',
  systolicBloodPressure = 'systolicBloodPressure',
  diastolicBloodPressure = 'diastolicBloodPressure',
  heartRate = 'heartRate',
  respiratoryRate = 'respiratoryRate',
}

export const getLabelDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    year: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getVitalGraphData = (vital: Vital, vitalData: IVitals[], vitalConfigs: IVitalData[]) => {
  const matchedData = getVitalDataArray(vital, vitalData) || [];
  const unit = getVitalUnitDisplay(vital, vitalConfigs);
  const vitalLabel = getDisplay(vital, vitalConfigs);
  const vitalYAxisLabel = unit ? `${vitalLabel} (${unit})` : vitalLabel;

  const data = {
    data: (matchedData || []).map((item) => {
      return {
        date: getLabelDate(item.date),
        value: item.value,
        label: vitalLabel,
      };
    }),
    xField: 'date',
    yField: 'value',
    yAxis: {
      title: {
        text: vitalYAxisLabel,
      },
    },
    seriesField: 'label',
    smooth: true,
    maintainAspectRatio: true,
    style: {maxHeight: 200},
  };
  return {
    isEmpty: !matchedData.length,
    graphData: data,
    title: vitalLabel,
    loinc: vital
  }
}

export const getBloodPressureGraphData = (vitalData: IVitals[], vitalConfigs: IVitalData[]) => {
  const systolicArray = getVitalDataArray(Vital.systolicBloodPressure, vitalData) || [];
  const diastolicArray = getVitalDataArray(Vital.diastolicBloodPressure, vitalData) || [];
  const unit = getVitalUnitDisplay(Vital.bloodPressure, vitalConfigs);
  const vitalLabel = getDisplay(Vital.bloodPressure, vitalConfigs);
  const vitalYAxisLabel = unit ? `${vitalLabel} (${unit})` : vitalLabel;

  const bloodPressureDatasets: Array<IGraphDataSet> = [];
  (systolicArray || []).forEach((systolicData) => {
    bloodPressureDatasets.push({
      date: getLabelDate(systolicData.date),
      value: parseFloat(systolicData.value),
      label: getDisplay(Vital.systolicBloodPressure, vitalConfigs),
    });
  });

  (diastolicArray || []).forEach((diastolicData) => {
    bloodPressureDatasets.push({
      date: getLabelDate(diastolicData.date),
      value: parseFloat(diastolicData.value),
      label: getDisplay(Vital.diastolicBloodPressure, vitalConfigs),
    });
  });

  const data = {
    data: bloodPressureDatasets,
    xField: 'date',
    yField: 'value',
    yAxis: {
      title: {
        text: vitalYAxisLabel,
      },
    },
    seriesField: 'label',
    smooth: true,
    maintainAspectRatio: true,
    style: {maxHeight: 200},
  };
  return {
    isEmpty: !systolicArray.length && !diastolicArray.length,
    graphData: data,
    title: vitalLabel,
    loinc: Vital.bloodPressure
  }
}

export const getWeightAndBMIGraphData = (vitalData: IVitals[], vitalConfigs: IVitalData[]) => {
  const weightGraphData: Array<IGraphDataSet> = [];
  const bmiGraphData: Array<IGraphDataSet> = [];
  const weightsArray = getVitalDataArray(Vital.weight, vitalData) || [];
  const bmiArray = getVitalDataArray(Vital.bmi, vitalData) || [];

  const weightUnit = getVitalUnitDisplay(Vital.weight, vitalConfigs);
  const weightLabel = getDisplay(Vital.weight, vitalConfigs);
  const weightYAxisLabel = weightUnit ? `${weightLabel} (${weightUnit})` : weightLabel;
  (weightsArray || []).forEach((weightData) => {
    const data: any = {
      label: getDisplay(Vital.weight, vitalConfigs),
      date: getLabelDate(weightData.date),
    }
    data[weightLabel] = parseFloat(weightData?.value);
    weightGraphData.push(data);
  });

  const bmiUnit = getVitalUnitDisplay(Vital.bmi, vitalConfigs);
  const bmiLabel = getDisplay(Vital.bmi, vitalConfigs);
  const bmiYAxisLabel = bmiUnit ? `${bmiLabel} (${bmiUnit})` : bmiLabel;
  (bmiArray || []).forEach((bmiData) => {
    const data: any = {
      label: getDisplay(Vital.bmi, vitalConfigs),
      date: getLabelDate(bmiData.date),
    }
    data[bmiLabel] = parseFloat(`${bmiData?.value || 0}`);
    bmiGraphData.push(data);
  });

  const data: any = {
    data: [weightGraphData, bmiGraphData],
    xField: 'date',
    yField: [weightLabel, bmiLabel],
    yAxis: {},
    seriesField: 'label',
    smooth: true,
    maintainAspectRatio: true,
    style: {maxHeight: 200},
    geometryOptions: [{smooth: true}, {smooth: true}],
  };
  data.yAxis[bmiLabel] = {
    title: {
      text: bmiYAxisLabel,
      unit: bmiUnit || ''
    },
    min: 0,
  };
  data.yAxis[weightLabel] = {
    title: {
      text: weightYAxisLabel,
      unit: weightUnit || ''
    },
    min: 0,
  }
  return {
    isEmpty: !weightsArray.length && !bmiArray.length,
    graphData: data,
    title: `${weightLabel} and ${bmiLabel}`,
    loinc: Vital.bmi
  }
};

export const getHeartRateAndRespiratoryRateGraphData = (vitalData: IVitals[], vitalConfigs: IVitalData[]) => {
  const heartRateGraphData: Array<IGraphDataSet> = [];
  const respiratoryRateGraphData: Array<IGraphDataSet> = [];

  const heartRateArray = getVitalDataArray(Vital.heartRate, vitalData) || [];
  const respiratoryRateArray = getVitalDataArray(Vital.respirationRate, vitalData) || [];

  const heartRateUnit = getVitalUnitDisplay(Vital.heartRate, vitalConfigs);
  const heartRateLabel = getDisplay(Vital.heartRate, vitalConfigs);
  const heartRateYAxisLabel = heartRateUnit ? `${heartRateLabel} (${heartRateUnit})` : heartRateLabel;
  (heartRateArray || []).forEach((heartRateData: any) => {
    const data: IGraphDataSet = {
      label: heartRateLabel,
      date: getLabelDate(heartRateData?.date),
    }
    data[heartRateLabel] = parseFloat(heartRateData?.value || 0);
    heartRateGraphData.push(data);
  });

  const respRateUnit = getVitalUnitDisplay(Vital.respirationRate, vitalConfigs);
  const respRateLabel = getDisplay(Vital.respirationRate, vitalConfigs);
  const respRateYAxisLabel = respRateUnit ? `${respRateLabel} (${respRateUnit})` : respRateLabel;
  (respiratoryRateArray || []).forEach((respiratoryRateData: any) => {
    const data: IGraphDataSet = {
      label: respRateLabel,
      date: getLabelDate(respiratoryRateData?.date),
    }
    data[respRateLabel] = parseFloat(respiratoryRateData?.value || 0);
    respiratoryRateGraphData.push(data);
  });

  const data: any = {
    data: [heartRateGraphData, respiratoryRateGraphData],
    xField: 'date',
    yField: [heartRateLabel, respRateLabel],
    yAxis: {},
    seriesField: 'label',
    smooth: true,
    maintainAspectRatio: true,
    style: {maxHeight: 200},
    geometryOptions: [{smooth: true}, {smooth: true}],
  };

  data.yAxis[heartRateLabel] = {
    title: {
      text: heartRateYAxisLabel,
      unit: heartRateUnit || ''
    },
    min: 0,
  };
  data.yAxis[respRateLabel] = {
    title: {
      text: respRateYAxisLabel,
      unit: respRateUnit || ''
    },
    min: 0,
  }
  return {
    isEmpty: !heartRateArray.length && !respiratoryRateArray.length,
    graphData: data,
    title: `${heartRateLabel} and ${respRateLabel}`,
    loinc: Vital.respirationRate
  }
};


export const getVitalGraphDataInCoords = (vital: Vital, vitalData: IVitals[], vitalConfigs: IVitalData[], ccmDate?: string) => {
  const matchedData = getVitalDataArray(vital, vitalData) || [];
  const unit = getVitalUnitDisplay(vital, vitalConfigs, true);
  const vitalLabel = getDisplay(vital, vitalConfigs);
  const vitalYAxisLabel = unit ? `${vitalLabel} (${unit})` : vitalLabel;

  const data = (matchedData || []).map((item) => {
    const date = getDateStrFromFormat(item.date, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
    const y = parseInt(item.value);
    return {
      x: date,
      y: y,
      dateStr: item?.date,
      tooltipText: getGraphTooltipText(y, date, unit),
    };
  });

  const uniqueDates = new Set(data.map((item) => item.x));

  const dataWithUniqueDates = Array.from(uniqueDates).map((date) => {
    const matchedData = data.filter((item) => item.x === date);
    return {
      x: date,
      y: matchedData?.[0]?.y,
      dateStr: matchedData?.[0]?.dateStr,
      tooltipText: getGraphTooltipText(matchedData?.[0]?.y, date, unit),
    };
  });

  return {
    isEmpty: !matchedData.length,
    graphData: data,
    title: vitalLabel,
    loinc: vital,
    annontationIndex: data.length && ccmDate ? getAnonationIndex(ccmDate, dataWithUniqueDates) : undefined,
    vitalYAxisLabel,
    code: vital,
    matchedData,
    unit,
  }
}

const getGraphTooltipText = (value: string | number, dateStr: string, unit?: string,) => {
  let tooltipText = `${value} ${unit ? unit : ''}`
  tooltipText += `\n ${dateStr}`
  return tooltipText;
}

export const getAnonationIndex = (date:string, data:{
  x: string;
  y: string | number;
  dateStr: string;
}[],
  customDuration?: DurationInputArg2,
  fixError?: boolean
) => {
  let low = 0;
  let high = data.length - 1;
  const momentObj = getMomentObj(date);
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const midDate = getMomentObj(data[mid].dateStr);

    if (midDate.isSame(momentObj, customDuration || 'day')) {
      //EDGE-CASE: This handling is done when only one data point is present and that is on ccm date
      // so we keep annonation index at the only data point
      if (mid === 0 && data.length === 1) {
        return mid + 1;
      }
      return !!fixError ? mid + 1 : mid;
    } else if (midDate.isBefore(momentObj,customDuration || 'day')) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }

  if (low >= data.length) {
    return data.length + 0.5; // Date is after all dates in the array
  }

  if (high < 0) {
    return 0.5; // Date is before all dates in the array
  }

  const startDate = getMomentObj(data[high]?.dateStr);
  const endDate = getMomentObj(data[high + 1]?.dateStr);

  const percentage = momentObj.diff(startDate, customDuration || 'days') / endDate.diff(startDate, customDuration ||'days');

  return low+percentage;
}

export const getDomain = (data: any[], annontationIndex?: number) => {
  let domain = undefined;
  if (annontationIndex !== undefined){
    if (annontationIndex > data.length){
      domain = {
        x: [1, annontationIndex],
      }
    } else if (annontationIndex < data?.length){
      domain = {
        x: [0, data.length+1],
      }
    }
  }

  domain = getYDomain(data, domain);
  return domain;
}

const getYDomainValues = (y: number) => {
  return [y - 2, y + 2]
}

export const getYDomain = (data: any[], domain?: any) => {
  const isSameYValue = data?.every((val, i, arr) => val?.y === arr?.[0]?.y);

  if(isSameYValue && typeof data?.[0]?.y === 'number'){
    if(domain) {
      domain.y = getYDomainValues(data[0].y);
      return domain;
    }
    return {
      y: getYDomainValues(data[0].y)
    }
  }
  return domain;
}



export const getHeartRateAndRespiratoryRateGraphDataInCoords = (vitalData: IVitals[], vitalConfigs: IVitalData[], ccmDate: string) => {
  const heartRateGraphData: Array<IGraphDataSetInCoords> = [];
  const respiratoryRateGraphData: Array<IGraphDataSetInCoords> = [];

  const heartRateArray = getVitalDataArray(Vital.heartRate, vitalData) || [];
  const respiratoryRateArray = getVitalDataArray(Vital.respirationRate, vitalData) || [];

  const heartRateUnit = getVitalUnitDisplay(Vital.heartRate, vitalConfigs);
  const heartRateLabel = getDisplay(Vital.heartRate, vitalConfigs);
  const heartRateYAxisLabel = heartRateUnit ? `${heartRateLabel} (${heartRateUnit})` : heartRateLabel;
  (heartRateArray || []).forEach((heartRateData: any) => {
    const data: IGraphDataSetInCoords = {
      x: getLabelDate(heartRateData?.date),
      y: heartRateData?.value || 0,
      dateStr: heartRateData?.date
    }
    heartRateGraphData.push(data);
  });

  const respRateUnit = getVitalUnitDisplay(Vital.respirationRate, vitalConfigs);
  const respRateLabel = getDisplay(Vital.respirationRate, vitalConfigs);
  const respRateYAxisLabel = respRateUnit ? `${respRateLabel} (${respRateUnit})` : respRateLabel;
  (respiratoryRateArray || []).forEach((respiratoryRateData: any) => {
    const data: IGraphDataSetInCoords = {
      y: respiratoryRateData?.value || 0,
      x: getLabelDate(respiratoryRateData?.date),
      dateStr: respiratoryRateData?.date,
    }
    respiratoryRateGraphData.push(data);
  });

  const data: any = {
    data: [heartRateGraphData, respiratoryRateGraphData],
  };

  return {
    isEmpty: !heartRateArray.length && !respiratoryRateArray.length,
    graphData: data,
    title: `${heartRateLabel} and ${respRateLabel}`,
    loinc: Vital.respirationRate,
    annontationIndex: data.length && ccmDate ? getAnonationIndex(ccmDate, heartRateGraphData as any) : undefined,
    code: Vital.respirationRate,
  }
};

export const getWeightAndBMIGraphDataWithCoords = (vitalData: IVitals[], vitalConfigs: IVitalData[], ccmDate:string) => {
  const weightGraphData: Array<IGraphDataSetInCoords> = [];
  const bmiGraphData: Array<IGraphDataSetInCoords> = [];
  const weightsArray = getVitalDataArray(Vital.weight, vitalData) || [];
  const bmiArray = getVitalDataArray(Vital.bmi, vitalData) || [];

  const weightUnit = getVitalUnitDisplay(Vital.weight, vitalConfigs);
  const weightLabel = getDisplay(Vital.weight, vitalConfigs);
  const weightYAxisLabel = weightUnit ? `${weightLabel} (${weightUnit})` : weightLabel;
  (weightsArray || []).forEach((weightData) => {
    const data: IGraphDataSetInCoords = {
      y: weightData?.value,
      x: getLabelDate(weightData.date),
      dateStr:weightData.date
    }
    weightGraphData.push(data);
  });

  const bmiUnit = getVitalUnitDisplay(Vital.bmi, vitalConfigs);
  const bmiLabel = getDisplay(Vital.bmi, vitalConfigs);
  const bmiYAxisLabel = bmiUnit ? `${bmiLabel} (${bmiUnit})` : bmiLabel;
  (bmiArray || []).forEach((bmiData) => {
    const data: IGraphDataSetInCoords = {
      y: bmiData?.value,
      x: getLabelDate(bmiData.date),
      dateStr: bmiData.date
    }
    bmiGraphData.push(data);
  });

  const data: any = {
    data: [weightGraphData],
  };

  return {
    isEmpty: !weightsArray.length && !bmiArray.length,
    graphData: data,
    title: `${weightLabel} and ${bmiLabel}`,
    loinc: Vital.bmi,
    annontationIndex: (weightGraphData || bmiGraphData).length && ccmDate ? getAnonationIndex(ccmDate, (weightGraphData || bmiGraphData) as any) : undefined,
  }
};


export const getBloodPressureGraphDataWithCoords = (vitalData: IVitals[], vitalConfigs: IVitalData[], ccmDate?:string, isTrim?: boolean) => {
  const systolicArray = getVitalDataArray(Vital.systolicBloodPressure, vitalData) || [];
  const diastolicArray = getVitalDataArray(Vital.diastolicBloodPressure, vitalData) || [];
  const unit = getVitalUnitDisplay(Vital.bloodPressure, vitalConfigs);
  const vitalLabel = getDisplay(Vital.bloodPressure, vitalConfigs);
  const vitalYAxisLabel = unit ? `${vitalLabel} (${unit})` : vitalLabel;

  let systolicbloodPressureDatasets: Array<IGraphDataSetInCoords> = [];
  (systolicArray || []).forEach((systolicData) => {
    const formattedDate = getFormattedDate(systolicData.date, DISPLAY_SLASH_DATE_FORMAT);
    const y = parseFloat(systolicData.value);
    systolicbloodPressureDatasets.push({
      x: formattedDate,
      y: y,
      dateStr: systolicData.date,
      tooltipText: getGraphTooltipText(y, formattedDate, unit),
      graphNodeType: GraphsNodeTypes.SYSTOLIC
    });
  });

  if (isTrim) {
    systolicbloodPressureDatasets = getTrimmedDataForTrendView(
      systolicbloodPressureDatasets
    );
  }

  const uniqueDatesSystolic = new Set(systolicbloodPressureDatasets.map((item) => item.x));
  const systolicDataWithUniqueDates = Array.from(uniqueDatesSystolic).map((date) => {
    const matchedData = systolicbloodPressureDatasets
      .filter((item) => item.x === date)
      ?.sort((valueA: any, valueB: any) => {
        return valueA.y - valueB.y;
      });
    return {
      x: date,
      y: matchedData?.[matchedData.length - 1]?.y,
      y0: matchedData?.[0]?.y,
      dateStr: matchedData?.[0]?.dateStr,
      graphNodeType: GraphsNodeTypes.SYSTOLIC
    };
  });

  let diastolicbloodPressureDatasets: Array<IGraphDataSetInCoords> = [];
  (diastolicArray || []).forEach((diastolicData) => {
    const formattedDate = getFormattedDate(diastolicData.date, DISPLAY_SLASH_DATE_FORMAT);
    const y = parseFloat(diastolicData.value);
    diastolicbloodPressureDatasets.push({
      x: formattedDate,
      y: y,
      dateStr: diastolicData.date,
      tooltipText: getGraphTooltipText(y, formattedDate, unit),
      graphNodeType: GraphsNodeTypes.DYSTOLIC
    });
  });

  if (isTrim) {
    diastolicbloodPressureDatasets = getTrimmedDataForTrendView(
      diastolicbloodPressureDatasets
    );
  }

  const uniqueDatesDiastolic = new Set(diastolicbloodPressureDatasets.map((item) => item.x));
  const diastolicDataWithUniqueDates = Array.from(uniqueDatesDiastolic).map((date) => {
    const matchedData = diastolicbloodPressureDatasets
      .filter((item) => item.x === date)
      ?.sort((valueA: any, valueB: any) => {
        return valueA.y - valueB.y;
      });
    return {
      x: date,
      y: matchedData?.[matchedData.length - 1]?.y,
      y0: matchedData?.[0]?.y,
      dateStr: matchedData?.[0]?.dateStr,
      graphNodeType: GraphsNodeTypes.DYSTOLIC
    };
  });

  return {
    isEmpty: !systolicArray.length && !diastolicArray.length,
    graphData: [systolicbloodPressureDatasets, diastolicbloodPressureDatasets],
    barData: [systolicDataWithUniqueDates, diastolicDataWithUniqueDates],
    title: vitalLabel,
    loinc: Vital.bloodPressure,
    annontationIndex: (systolicDataWithUniqueDates || systolicDataWithUniqueDates).length && ccmDate ? getAnonationIndex(ccmDate, (systolicDataWithUniqueDates || systolicDataWithUniqueDates) as any) : undefined,
    vitalYAxisLabel,
    code: Vital.bloodPressure,
    unit,
  }
}

export const getGoalLines = (measure: { operator: any; targetValue: { high: any; low: any; value: any; }; }) => {
  if(measure) {
    const operator = measure?.operator;
    if(operator === GoalOperator.between) {
      const higherGoal = {
        value: measure.targetValue.high,
        name: CustomPlotName.UG,
        operator: operator
      }
      const lowerGoal = {
        value: measure.targetValue.low,
        name: CustomPlotName.LG,
        operator: operator
      }
      return [higherGoal, lowerGoal];
    } else {
      const singleGoal = {
        value: measure.targetValue.value,
        name: CustomPlotName.GOAL,
        operator: operator
      }
      return [singleGoal];
    }
  }
  return [];
}

export const getTootlTipName = (
  tooltipName: string,
  targetValue: {
    low: any; high: any; value: any;
  }
) => {
  switch (tooltipName) {
    case CustomPlotName.UG:
      return `Should be less than ${targetValue?.high}`
    case CustomPlotName.LG:
      return `Should be greater than ${targetValue?.low}`
    case CustomPlotName.GOAL:
      return `Should be equal to ${targetValue?.value}`
    default:
      return '';
  }
}

export const getMeasure = (loinc: any, goals: any[]) => {
  let goal;
  switch(loinc) {
    case Vital.steps:
      goal = goals.find(_goal => [Vital.steps, 'STEPS'].includes(_goal?.goalTargets?.[0]?.entityCode || ''));
      break;
    case  Vital.calories:
      goal = goals.find(_goal => [Vital.calories, 'CALORIES'].includes(_goal?.goalTargets?.[0]?.entityCode || ''));
      break;
    case  Vital.duration:
      goal = goals.find(_goal => [Vital.duration, 'DURATION'].includes(_goal?.goalTargets?.[0]?.entityCode || ''));
      break;
    default:
      goal = goals.find(_goal => _goal?.goalTargets?.[0]?.entityCode === loinc);
  }
  return goal?.goalTargets?.[0]?.measure;
}

export const getTrendViewGraphData = (vital: Vital, formattedVitalData: IVitals[], vitalList: IVitalData[]) => {

  if(!formattedVitalData || formattedVitalData?.length === 0){
    return;
  }

  if(vital){
    if(vital === Vital.bloodPressure){
      const graphData = getBloodPressureGraphDataWithCoords(
        formattedVitalData || [],
        vitalList,
        undefined,
        true
      )

      return converGraphDataToVitalGraphData(graphData, GraphsTypes.VERTICAL_SCATTER);
    }else {
      const graphData = getVitalGraphDataInCoords(
        vital,
        formattedVitalData || [],
        vitalList,
      )

      graphData.graphData = getTrimmedDataForTrendView(graphData.graphData);

      return converGraphDataToVitalGraphData(graphData, GraphsTypes.LINE);
    }
  }
}

export const getTrimmedDataForTrendView = (graphData: any[]) => {
  const maxTrendCount = 7;
  if (graphData?.length > maxTrendCount) {
    graphData = graphData?.slice(
      graphData?.length - maxTrendCount,
      graphData?.length
    );
  }
  return graphData;
}

export const converGraphDataToVitalGraphData = (
  graphData: any,
  graphType: GraphsTypes,
  highLightValues?: IHighLightValues[]
) => {
  return {
    ...graphData,
    highLightValues: highLightValues || [],
    graphType: graphType,
  } as VitalGraphData;
};

const getInchesToFeetRange = (minNumber: number) => {
  if(minNumber === 0){
    return '0'
  }
  const minInchesAndFeet = inchesToFeetAndInches(minNumber);

  return `${minInchesAndFeet.feet} ft ${minInchesAndFeet.inches} in`
};

export const getRangeTextFromGraph = (graphData: any[]) => {

  if(graphData?.length === 1){
    return '-';
  }

  const maxValue = Math.max(
    ...graphData?.map((data: any) =>
      typeof data.y === 'number' ? data.y : parseInt(data.y)
    )
  );

  const minValue = Math.min(
    ...graphData?.map((data: any) =>
      typeof data.y === 'number' ? data.y : parseInt(data.y)
    )
  );

  if(maxValue && minValue){
    return `${minValue} - ${maxValue}`
  }

  return '-'
}

export const getGraphNodeColor = (graph: IGraph[]) => {
  return graph?.[0]?.graphNodeType === GraphsNodeTypes.DYSTOLIC ? '#8DE3D4' : '#80A4D5';
}

export const getGraphNodeShape = (graph: IGraph[]) => {
  return graph?.[0]?.graphNodeType === GraphsNodeTypes.DYSTOLIC ? 'diamond' : 'circle';
}


export const getOperatorSymbol = (operator: GoalOperator) => {
  switch(operator) {
    case GoalOperator.eq:
      return "="
    case GoalOperator.between:
      return "between"
    case GoalOperator.gt:
      return ">"
    case GoalOperator.gte:
      return ">="
    case GoalOperator.lt:
      return "<"
    case GoalOperator.lte:
      return "<="
    default:
      return "="
  }
}

export const formatHeightToFeetAndInches = (
  feet: number,
  inches: number
): string => {
  return `${feet} ft ${inches} in`;
};

export const getTooltipContentForHeight = (
  operator: GoalOperator,
  valueinFeet: number | number[],
  valueinInches: number | number[],
) => {
  let goalValue = '';

  if (Array.isArray(valueinFeet) || Array.isArray(valueinInches)) {
    switch (operator) {
      case GoalOperator.between:
        const lowerFeet = Array.isArray(valueinFeet)
          ? valueinFeet[0]
          : valueinFeet;
        const lowerInches = Array.isArray(valueinInches)
          ? valueinInches[0]
          : valueinInches;
        const upperFeet = Array.isArray(valueinFeet)
          ? valueinFeet[1]
          : valueinFeet;
        const upperInches = Array.isArray(valueinInches)
          ? valueinInches[1]
          : valueinInches;
        goalValue = `${formatHeightToFeetAndInches(
          lowerFeet,
          lowerInches
        )} - ${formatHeightToFeetAndInches(upperFeet, upperInches)}`;
        break;
    }
  } else {
    const formattedValue = formatHeightToFeetAndInches(
      valueinFeet as number,
      valueinInches as number
    );
    switch (operator) {
      case GoalOperator.eq:
        goalValue = formattedValue;
        break;
      case GoalOperator.gt:
        goalValue = `> ${formattedValue}`;
        break;
      case GoalOperator.gte:
        goalValue = `>= ${formattedValue}`;
        break;
      case GoalOperator.lt:
        goalValue = `< ${formattedValue}`;
        break;
      case GoalOperator.lte:
        goalValue = `<= ${formattedValue}`;
        break;
    }
  }
  return goalValue ? `Goal: ${goalValue}` : '';
};

export const getTooltipContent = (
  operator: GoalOperator,
  value: number[] | number,
  unit: string
) => {
  let goalValue = '';

  if (unit === 'ft') {
    let feetValue, inchesValue;
    if (Array.isArray(value)) {
      const lowerBoundFeet = value[0];
      const upperBoundFeet = value[1];
      const {feet: lowerBoundFeetValue, inches: lowerBoundInchesValue} =
        feetToFeetAndInches(lowerBoundFeet);
      const {feet: upperBoundFeetValue, inches: upperBoundInchesValue} =
        feetToFeetAndInches(upperBoundFeet);
      feetValue = [lowerBoundFeetValue, upperBoundFeetValue];
      inchesValue = [lowerBoundInchesValue, upperBoundInchesValue]; 
    } else {
      const {feet, inches} = feetToFeetAndInches(value);
      feetValue = feet; 
      inchesValue = inches; 
    }
    goalValue = getTooltipContentForHeight(
      operator,
      feetValue,
      inchesValue,
    );
    return goalValue;
  } else {
    switch (operator) {
      case GoalOperator.eq:
        goalValue = `${value} ${unit}`;
        break;
      case GoalOperator.between:
        if (Array.isArray(value)) {
          goalValue = `${value[0]}-${value[1]} ${unit}`;
        }
        break;
      case GoalOperator.gt:
        goalValue = `> ${value} ${unit}`;
        break;
      case GoalOperator.gte:
        goalValue = `>= ${value} ${unit}`;
        break;
      case GoalOperator.lt:
        goalValue = `< ${value} ${unit}`;
        break;
      case GoalOperator.lte:
        goalValue = `<= ${value} ${unit}`;
        break;
    }
  }
  return goalValue ? `Goal: ${goalValue}` : '';
};
