import { Button, Typography, Radio } from 'antd'; // Added Radio import
import { HStack, Pressable, View, VStack } from 'native-base';
const { Text, Title } = Typography;
import { DatePicker, Menu, Popover } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import FilterNewIconSvg from '../Svg/FilterNewIconSvg';
import FilterDataSvg from '../../../assets/svg/FilterSvg';
import { Colors } from '../../../styles/Colors';
import { addTimeToDate, getCurrentDateInISOString, getDateDifference } from '../../../utils/DateUtils';

const { RangePicker } = DatePicker;

interface DateRange {
  startDate: Moment;
  endDate: Moment;
}
interface CustomDateRangePickerProps {
  onDateRangeChange: (
    range: { startDate: string | undefined; endDate: string | undefined; appliedFilter: string | undefined }
  ) => void;
}

export const getUpdatedTimeRangeByCurrentTime = (startDate: string, endDate: string) => {
  const currentDateTime = getCurrentDateInISOString();
  const diffFromEnd = getDateDifference(endDate, currentDateTime, 'milliseconds', true);
  const updatedStartDate = addTimeToDate(startDate, diffFromEnd, 'milliseconds').toISOString();
  return {
    startDate: updatedStartDate,
    endDate: currentDateTime,
  };
};


export enum DATE_RANGE_OPTIONS {
  last24hours,
  lastWeek,
  lastMonth,
  custom
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  onDateRangeChange,
}) => {
  const [selectedRange, setSelectedRange] = useState<DateRange | null>(null);
  const [customRangeVisible, setCustomRangeVisible] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleRadioChange = (e: any) => {
    let startDate: Moment | undefined;
    let endDate: Moment | undefined;
    const now = moment();

    switch (e.target.value) {
      case DATE_RANGE_OPTIONS.last24hours:
        startDate = now.clone().subtract(24, 'hours');
        endDate = now;
        setCustomRangeVisible(false);
        setIsOpen(false);
        break;
      case DATE_RANGE_OPTIONS.lastWeek:
        startDate = now.clone().subtract(7, 'days');
        endDate = now.clone();
        setCustomRangeVisible(false);
        setIsOpen(false);
        break;
      case DATE_RANGE_OPTIONS.lastMonth:
        startDate = now.clone().subtract(1, 'month');
        endDate = now;
        setCustomRangeVisible(false);
        setIsOpen(false);
        break;
      case DATE_RANGE_OPTIONS.custom:
        setCustomRangeVisible(true);
        return;
      default:
        break;
    }

    if (startDate && endDate) {
      setSelectedRange({ startDate, endDate });
      setSelectedOption(e.target.value);
    }
  };

  useEffect(() => {
    onDateRangeChange({
      startDate: selectedRange?.startDate?.toISOString(),
      endDate: selectedRange?.endDate?.toISOString(),
      appliedFilter: selectedOption || undefined,
    });
  }, [selectedRange]);

  const handleCustomRangeChange = (dates: [Moment, Moment] | null) => {
    if (dates) {
      setSelectedRange({ startDate: dates[0], endDate: dates[1] });
      setSelectedOption('custom');
      setIsOpen(false);
    }
    setCustomRangeVisible(false);
  };

  const menu = (
    <VStack flex={1} width={'full'} justifyContent={'center'} alignItems={'center'}>
      {!customRangeVisible && (
        <Radio.Group onChange={handleRadioChange} value={selectedOption} style={{ display: 'flex', flexDirection: 'column' }}>
          <Radio value={DATE_RANGE_OPTIONS.last24hours} style={{ marginBottom: '10px' }}>Last 24 hrs</Radio>
          <Radio value={DATE_RANGE_OPTIONS.lastWeek} style={{ marginBottom: '10px' }}>Last Week</Radio>
          <Radio value={DATE_RANGE_OPTIONS.lastMonth} style={{ marginBottom: '10px' }}>Last Month</Radio>
          <Radio value={DATE_RANGE_OPTIONS.custom} style={{ marginBottom: '10px' }}>Custom Date Range</Radio>
        </Radio.Group>
      )}
      {customRangeVisible && (
        <HStack flex={1} width={'full'} marginBottom={2}>
          <RangePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(dates: any) => {
              handleCustomRangeChange(dates);
            }}
          />
        </HStack>
      )}
    </VStack>
  );


  return (
    <div>
      <Popover open={isOpen} content={menu} title="Select Date Range"
        trigger="click"
        onOpenChange={(openStatus: boolean) => {
          setIsOpen(openStatus);
        }}>
        <Pressable
          onPress={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div style={{ marginTop: '4px' }}>
            <FilterDataSvg strokeColor={selectedOption ? Colors.Custom.primary300 : "#6F7A90"} />
          </div>
        </Pressable>
      </Popover>
    </div>
  );
};

export default CustomDateRangePicker;
