import React, {useCallback, useContext, useState} from 'react';
import AlertsView from '../../../SideCar/AlertsView';
import {ITaskAlert} from '../../../SideCar/SideBarCustomHooks/useFetchAlerts';
import {Pressable, ScrollView, View} from 'react-native';
import {ActionableTab} from '../../../common/PatientListInternalTask/InternalTaskDrawer';
import {useIntl} from 'react-intl';
import {ClinicalSectionFilterTabs} from '../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import {Badge, HStack, Stack, Text} from 'native-base';
import {Colors} from '../../../../styles';
import FilterIconSvgForAlerts from '../../../common/Svg/SideCarSvg/FilterIconSvgForAlerts';
import {DisplayText} from '../../../common/DisplayText/DisplayText';
import NotificationSvg from '../../../common/Svg/NotificationSvg';
import SearchIconSvg from '../../../common/Svg/TimelineSvg/SearchIconSvg';
import {SearchBar} from '../../../common/SearchBar';
import {debounce} from 'lodash';
import Feather from 'react-native-vector-icons/Feather';
import CareGapDetailView from '../../../SideCar/CareGapDetailView';
import DiagnosisGapDetailView from '../../../SideCar/DiagnosisGapDetailView';
import { IDiagnosisGap } from '../../../SideCar/interfaces';
import { IPersonData } from '../interfaces';
import {ITask} from '../../../common/CareDashboard/CareDashboardInterfaces';
import AddOrUpdateAlert from '../../../SideCar/AddOrUpdateAlert';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GET_TASK_ALERT_DETAIL} from '../../../../services/Task/TaskQueries';
import {TASK_TYPES} from '../../../../constants/StringConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {getBooleanFeatureFlag} from '../../../../utils/commonUtils';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

const PatientAlerts = (props: {contactId: string, personData: IPersonData}) => {
  const {contactId, personData} = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const showManualAddGaps = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.SHOW_MANUAL_ADD_GAPS);
  const [componentState, setComponentState] = useState<{
    selectedCareGapDetail?: ITaskAlert;
    showFiltersTabInAlerts?: boolean;
    alertSearchText?: string;
    formattedContactData?: any;
    pendingAlertsCount?: number;
    selectedTab?: string;
    alertFilterCount?: number;
    filterCount?: number | null;
    showSearchBar?: boolean;
    selectedDiagnosisGapDetail?: IDiagnosisGap;
    showAddOrUpdateAlert?: boolean;
    alertType?: string;
    task?: ITask;
  }>({
    selectedCareGapDetail: {} as ITaskAlert,
    showFiltersTabInAlerts: false,
    alertSearchText: '',
    formattedContactData: {
      ...props.personData.contactData,
      contactUUID: props.personData.contactData?.uuid,
      name: props.personData.name,
      contactData: props.personData.contactData
    },
    pendingAlertsCount: 0,
    selectedTab: ActionableTab.ALERTS,
    alertFilterCount: 0,
    filterCount: 0,
    showSearchBar: false,
    selectedDiagnosisGapDetail: {} as IDiagnosisGap,
    showAddOrUpdateAlert: false,
    alertType: '',
    task: {} as ITask,
  });
  const {
    selectedCareGapDetail,
    showFiltersTabInAlerts,
    alertSearchText,
    formattedContactData,
    selectedTab,
    alertFilterCount,
    filterCount,
    showSearchBar,
    selectedDiagnosisGapDetail,
    showAddOrUpdateAlert,
    alertType
  } = componentState;

  const [getAlertsDetail, {loading: alertDetailLoading}] = useLazyQuery(
    GET_TASK_ALERT_DETAIL,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (response) => {
        const task = response?.getTasks?.tasks?.[0];
        setComponentState((prev) => {
          return {
            ...prev,
            showAddOrUpdateAlert: true,
            task: task
          };
        });
      },
    }
  );
  const setState = (newState: Partial<typeof componentState>) => {
    setComponentState((prev) => ({...prev, ...newState}));
  };
  const onAlertAdd = (type: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        showAddOrUpdateAlert: true,
        alertType: type
      };
    });
  }
  const editCareGapAlert = async (alert: any, taskType?: string) => {
    await getAlertsDetail({
      variables: {
        params: {
          ids: [alert?.id],
          includeAlerts: true
        }
      }
    })
    setComponentState((prev) => {
      return {
        ...prev,
        alertType: taskType === TASK_TYPES.CARE_GAPS ? TASK_TYPES.CARE_GAPS : TASK_TYPES.DIAGNOSIS_GAPS
      };
    });
  }

  const getFilterCountBadge = useCallback(() => {
    const currentFilterCount =
      selectedTab === ActionableTab.ALERTS
        ? alertFilterCount
        : filterCount || 0;

    return currentFilterCount && currentFilterCount > 0 ? (
      <Badge
        mb={-2}
        position={'absolute'}
        top={-6}
        right={-12}
        paddingLeft={1}
        paddingRight={1}
        paddingTop={0}
        paddingBottom={0}
        mt={currentFilterCount > 0 ? -2 : 0}
        zIndex={100}
        variant="solid"
        backgroundColor={Colors.Custom?.badgeColorOrange}
        alignSelf="flex-end"
        size={'smMedium'}
        _text={{
          fontSize: 9,
          fontWeight: 'bold',
        }}
      >
        {currentFilterCount}
      </Badge>
    ) : (
      ''
    );
  }, [alertFilterCount, filterCount, selectedTab]);
  
  const openCareGapDetail = (alert: ITaskAlert) => {
    if (alert?.referenceData?.executionId || !showManualAddGaps) {
      setState({selectedCareGapDetail: alert})
    } else {
      editCareGapAlert(alert, TASK_TYPES.CARE_GAPS)
    }
  };
  
  const addOrUpdateAlert = () => {
    return <AddOrUpdateAlert 
    alertType={componentState.alertType} 
    formattedContactData={componentState.formattedContactData}
    task={componentState.task}
    onBackClick={() => {
      setComponentState((prev) => {
        return {
          ...prev,
          showAddOrUpdateAlert: false,
          task: {} as ITask
        };
      });
    }} />
  }

  const careGapDetailView = () => {
    return (
      <CareGapDetailView
      editCareGapAlert={(alert: any) => editCareGapAlert(alert, TASK_TYPES.CARE_GAPS)}
        contactUuid={contactId}
        style={{
          paddingHorizontal: 0,
          paddingVertical: 0,
        }}
        onBackClick={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedCareGapDetail: {} as ITaskAlert,
            };
          });
        }}
        careGapDetail={
          componentState.selectedCareGapDetail || ({} as ITaskAlert)
        }
      />
    );
  };
  const diagnosisGapDetailView = () => {
    return (
      <DiagnosisGapDetailView
        contactData={formattedContactData}
        onClose={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedDiagnosisGapDetail: {} as IDiagnosisGap,
            };
          });
        }}
        diagnosisGapDetail={selectedDiagnosisGapDetail || {} as IDiagnosisGap}
      />
    );
  };
  return (
    <Stack direction="column" style={{flex: 1}}>
      <View
        style={{
          paddingVertical: 8,
          paddingLeft: 8,
          paddingRight: 16,
          backgroundColor: '#fff',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 50,
          flexDirection: 'row',
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.FoldPixel.GRAY150,
          display: selectedCareGapDetail?.id ? 'none' : 'flex',
        }}
      >
        <View style={{flexDirection: 'row', alignItems: 'center', flex: 2}}>
          <NotificationSvg />
          <View style={{marginLeft: 8}}>
            <DisplayText
              textLocalId={'alerts'}
              size={'smMedium'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY400
              }}
            />
          </View>
        </View>
        <HStack
          style={{alignItems: 'center', flex: 7, justifyContent: 'flex-end'}}
        >
          {showSearchBar ? (
            <Stack
              direction={'row'}
              style={{
                alignItems: 'center',
                marginVertical: 4,
                marginHorizontal: 12,
                flex: 8,
              }}
            >
              <SearchBar
                width={'100%'}
                nativeId="tableTopSearchBarStyle"
                minHeightWrapper={32}
                height={32}
                onChange={debounce(
                  (value: string) => setState({alertSearchText: value}),
                  500
                )}
                searchBarInputStyles={{
                  height: 32,
                }}
                rightElement={
                  <Feather
                    onPress={() =>
                      setState({showSearchBar: false, alertSearchText: ''})
                    }
                    style={{
                      marginRight: 16,
                    }}
                    name="x"
                    size={15}
                    color={Colors.Custom.Gray600}
                  />
                }
              />
            </Stack>
          ) : (
            <Pressable onPress={() => setState({showSearchBar: true})}>
              <SearchIconSvg
                customStrokeColor={Colors.FoldPixel.GRAY300}
                strokeWidth={1}
              />
            </Pressable>
          )}

          <View
            style={{
              width: 1,
              height: 20,
              backgroundColor: Colors.Custom.Gray200,
              marginHorizontal: 12,
            }}
          />
          <Pressable
            onPress={() =>
              setState({showFiltersTabInAlerts: !showFiltersTabInAlerts})
            }
          >
            <Stack direction={'column'} style={{position: 'relative'}}>
              {getFilterCountBadge()}
              <FilterIconSvgForAlerts />
            </Stack>
          </Pressable>
        </HStack>
      </View>
      <ScrollView
        style={{padding: 12, marginTop: selectedCareGapDetail?.id || selectedDiagnosisGapDetail?.id ? 0 : 12}}
      >
        <Stack direction="column">
          {selectedCareGapDetail?.id && !showAddOrUpdateAlert && careGapDetailView()}
          {selectedDiagnosisGapDetail?.id && !showAddOrUpdateAlert && diagnosisGapDetailView()}
          {showAddOrUpdateAlert && addOrUpdateAlert()}
          {!selectedCareGapDetail?.id && !selectedDiagnosisGapDetail?.id && !showAddOrUpdateAlert && (
            <AlertsView
              onAlertAdd={onAlertAdd}
              openCareGapDetail={openCareGapDetail}
              openDiagnosisGapDetail={(diagnosisGap) =>
                setState({selectedDiagnosisGapDetail: diagnosisGap})
              }
              contactId={contactId}
              filterStyle={{
                marginLeft: 1,
                paddingVertical: 8,
                paddingHorizontal: 12,
              }}
              showFiltersTabInAlerts={showFiltersTabInAlerts || false}
              onFilterApplied={(filterCount) =>
                setState({alertFilterCount: filterCount})
              }
              searchText={alertSearchText || ''}
              onPendingCountChange={(count) =>
                setState({pendingAlertsCount: count})
              }
              isActionableDrawer={true}
              formattedContactData={formattedContactData}
              personData={personData}
              contactData={personData.contactData}
            />
          )}
        </Stack>
      </ScrollView>
    </Stack>
  );
};

export default PatientAlerts;
