import {gql} from '@apollo/client';
import BaseService from '../CommonService/BaseService';
import {contractBaseUrl} from './contractServiceConst';

const axios = BaseService.getSharedInstance().axios;

export const getContracts = (body: {
  searchString: string;
  pageSize: number;
  pageNo: number;
}) => {
  const {searchString, pageNo, pageSize} = body;
  const url = `${contractBaseUrl}?page_size=${pageSize}&page_number=${pageNo}&name=${searchString}`;
  return axios.get(url);
};

export const getContractQuery = gql`
  query getContractQuery(
    $searchString: String!
    $accountUuid: uuid!
    $limit: Int
    $offset: Int
  ) {
    selectQmContract(
      where: {
      tenantId: {_eq: $accountUuid},
      name: {_ilike: $searchString},
      isDeleted: {_eq: false}
      }
      limit: $limit
      offset: $offset
      order_by: {name: asc}
    ) {
      id
      name
      isDeleted
      endDate
      goal
      startDate
      projectedRevenue
      contractType {
        code
        value
      }
      contractPayer{
        name
      }
      contractPayerCarePrograms(where: {is_deleted: {_eq: false}}) {
        payerCareProgram {
          isDeleted
          careProgramType {
            code
            value
          }
        }
      }
      riskLevel
      contractContacts_aggregate {
        aggregate {
          count
        }
      }
    }
    aggregateQmContracts(
      where: {tenantId: {_eq: $accountUuid}, name: {_ilike: $searchString}, isDeleted: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getContractCountQuery = gql`
  query getContractQuery(
    $searchString: String!
    $accountUuid: uuid!
  ) {
    aggregateQmContracts(
      where: {tenantId: {_eq: $accountUuid}, name: {_ilike: $searchString}, isDeleted: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

export default {getContractQuery}
