import { CARE_PROGRAM_STEP_TYPE, CONTACT_TABLE_COLUMN_CODE } from "../../../../../../common/MemebersView/constant";

export const TCM_COLUMN_CODES = [
CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
CONTACT_TABLE_COLUMN_CODE.RAF,
CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO,
CONTACT_TABLE_COLUMN_CODE.LOCATION,
CONTACT_TABLE_COLUMN_CODE.TAGS,
CONTACT_TABLE_COLUMN_CODE.CONDITION,
CONTACT_TABLE_COLUMN_CODE.PCP,
CONTACT_TABLE_COLUMN_CODE.TCM_ASSIGNEE,
CONTACT_TABLE_COLUMN_CODE.TCM_STATUS,
CONTACT_TABLE_COLUMN_CODE.COMPLEXITY,
CONTACT_TABLE_COLUMN_CODE.ADMITTED_DATE,
CONTACT_TABLE_COLUMN_CODE.DISCHARGED_DATE,
CONTACT_TABLE_COLUMN_CODE.DISCHARGED_TO,
CONTACT_TABLE_COLUMN_CODE.FACILITY,
CONTACT_TABLE_COLUMN_CODE.FACILITY_TYPE,
CONTACT_TABLE_COLUMN_CODE.PCP_APPOINTMENT_DATE,
CONTACT_TABLE_COLUMN_CODE.PRIMARY_DIAGNOSIS_CODE,
CONTACT_TABLE_COLUMN_CODE.TCM_ATTENDING_PHYSICIAN,
CONTACT_TABLE_COLUMN_CODE.PENDING_ACTIONS,
CONTACT_TABLE_COLUMN_CODE.ACTION,
]

export const CCM_COLUMN_CODES = [
    CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
    CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
    CONTACT_TABLE_COLUMN_CODE.RAF,
    CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO,
    CONTACT_TABLE_COLUMN_CODE.LOCATION,
    CONTACT_TABLE_COLUMN_CODE.TAGS,
    CONTACT_TABLE_COLUMN_CODE.CONDITION,
    CONTACT_TABLE_COLUMN_CODE.PCP,
    CONTACT_TABLE_COLUMN_CODE.CCM_ASSIGNEE,
    CONTACT_TABLE_COLUMN_CODE.CCM_STATUS,
    CONTACT_TABLE_COLUMN_CODE.COMPLEXITY,
    CONTACT_TABLE_COLUMN_CODE.TIME_SPENT,
    CONTACT_TABLE_COLUMN_CODE.NUMBER_OF_DIAGNOSIS_GAPS,
    CONTACT_TABLE_COLUMN_CODE.FOLD_ID,
    CONTACT_TABLE_COLUMN_CODE.ACTIVE_CARE_PROGRAMS,
    CONTACT_TABLE_COLUMN_CODE.ACTION,
]

export const AWV_COLUMN_CODES = [
  CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
  CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
  CONTACT_TABLE_COLUMN_CODE.RAF,
  CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO,
  CONTACT_TABLE_COLUMN_CODE.LOCATION,
  CONTACT_TABLE_COLUMN_CODE.TAGS,
  CONTACT_TABLE_COLUMN_CODE.PCP,
  CONTACT_TABLE_COLUMN_CODE.AWV_ASSIGNEE,
  CONTACT_TABLE_COLUMN_CODE.PENDING_ACTIONS,
  CONTACT_TABLE_COLUMN_CODE.AWV_DATE,
  CONTACT_TABLE_COLUMN_CODE.LAST_VISIT,
  CONTACT_TABLE_COLUMN_CODE.ACTIVE_CARE_PROGRAMS,
  CONTACT_TABLE_COLUMN_CODE.GOAL_PROGRESS,
  CONTACT_TABLE_COLUMN_CODE.ACTION
]

export const MY_PATIENT_COLUMN_CODES = [
    CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
    CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
    CONTACT_TABLE_COLUMN_CODE.RAF,
    CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO,
    CONTACT_TABLE_COLUMN_CODE.LOCATION,
    CONTACT_TABLE_COLUMN_CODE.TAGS,
    CONTACT_TABLE_COLUMN_CODE.CONDITION,
    CONTACT_TABLE_COLUMN_CODE.PCP,
    CONTACT_TABLE_COLUMN_CODE.PRIMARY_CARE_MANAGER,
    CONTACT_TABLE_COLUMN_CODE.PENDING_ACTIONS,
    CONTACT_TABLE_COLUMN_CODE.AWV_DATE,
    CONTACT_TABLE_COLUMN_CODE.LAST_VISIT,
    CONTACT_TABLE_COLUMN_CODE.NUMBER_OF_DIAGNOSIS_GAPS,
    CONTACT_TABLE_COLUMN_CODE.FOLD_ID,
    CONTACT_TABLE_COLUMN_CODE.ACTIVE_CARE_PROGRAMS,
    CONTACT_TABLE_COLUMN_CODE.GOAL_PROGRESS,
    CONTACT_TABLE_COLUMN_CODE.ACTION,
]

export const ALL_PATIENTS_COLUMN_CODES = [
    CONTACT_TABLE_COLUMN_CODE.MEMBER_NAME,
    CONTACT_TABLE_COLUMN_CODE.FOLD_SCORE,
    CONTACT_TABLE_COLUMN_CODE.RAF,
    CONTACT_TABLE_COLUMN_CODE.CONTACT_INFO,
    CONTACT_TABLE_COLUMN_CODE.LOCATION,
    CONTACT_TABLE_COLUMN_CODE.TAGS,
    CONTACT_TABLE_COLUMN_CODE.CONDITION,
    CONTACT_TABLE_COLUMN_CODE.PCP,
    CONTACT_TABLE_COLUMN_CODE.PRIMARY_CARE_MANAGER,
    CONTACT_TABLE_COLUMN_CODE.PENDING_ACTIONS,
    CONTACT_TABLE_COLUMN_CODE.AWV_DATE,
    CONTACT_TABLE_COLUMN_CODE.LAST_VISIT,
    CONTACT_TABLE_COLUMN_CODE.FOLD_ID,
    CONTACT_TABLE_COLUMN_CODE.ACTIVE_CARE_PROGRAMS,
    CONTACT_TABLE_COLUMN_CODE.GOAL_PROGRESS,
    CONTACT_TABLE_COLUMN_CODE.ACTION,
]

export const CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE = {
    CONTACT_CARE_PROGRAM: 'CONTACT_CARE_PROGRAM',
    CONTACT: 'CONTACT',
};

export const CARE_PROGRAM_EVENT_CODES = {
  BILLING_SELECTION_CHANGED: 'BILLING_SELECTION_CHANGED',
  COMPLEXITY_CHANGED: 'COMPLEXITY_CHANGED',
  AWV_TYPE_CHANGED: 'AWV_TYPE_CHANGED',
  BILLING_DATA_CHANGED: 'BILLING_DATA_CHANGED',
  STOP_TIMER: 'STOP_TIMER',
  SAVE_RECORDED_TIME: 'SAVE_RECORDED_TIME',
  LOGGED_TIME: 'LOGGED_TIME',
  OPEN_TIME_LOGGING_DRAWER: 'OPEN_TIME_LOGGING_DRAWER',
  CARE_PROGRAM_ACTION_TAKEN: 'CARE_PROGRAM_ACTION_TAKEN',
  GENERATE_BILLING_DATA: 'GENERATE_BILLING_DATA',
  SEND_CLAIM: 'SEND_CLAIM',
  RECORD_AS_OUTREACH: 'RECORD_AS_OUTREACH',
  UPDATE_RECORDED_TIME: 'UPDATE_RECORDED_TIME',
  RESET_TIMER: 'RESET_TIMER',
  FORM_RESPONSE_ACCEPTED_DECLINED: 'FORM_RESPONSE_ACCEPTED_DECLINED',
  ALLOW_TO_SUBMIT_FORM: 'ALLOW_TO_SUBMIT_FORM',
  REVIEW_APPOINTMENT : 'REVIEW_APPOINTMENT'
} as const;

export const BILLING_STATUS = {
  BILLED: 'Billed',
  PENDING: 'Pending'
} as const;

export const OUTREACH_STEP_TYPES = [
  CARE_PROGRAM_STEP_TYPE.ECM_OUTREACH,
  CARE_PROGRAM_STEP_TYPE.TCM_OUTREACH_2DAY,
  CARE_PROGRAM_STEP_TYPE.OUTREACH,
] as const;

export const CARE_PROGRAM_STEP_WISE_SUBTITLE = {
  OUTREACH: 'Outreach',
} as const;
