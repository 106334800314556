import moment from 'moment';
import {DATE_FORMATS, DISPLAY_SLASH_DATE_FORMAT, PERSON_TYPES, PERSON_TYPES_TEXT, QUALITY_MEASURES_PATIENT_TYPE} from '../../../../constants';
import {getAgeValue, getDateStrFromFormat} from '../../../../utils/DateUtils';
import {
  IContactData,
  IContractResp,
  IMeasurePopulation,
  IMeasureReport,
  IPatientList,
  IqmContractQualityMeasuresResp,
  IQualityMeasuresList,
} from './interface';
import {CRITERIA_STRING, EVIDENCE_CODES, MEASURE_CRITERIA_STATUS, MEASURE_RESOURCE_TYPE, QM_IMPROVEMENT_NOTATION, RESOURCE_TYPE_CODE_MAP, SearchCode, evidenceCode} from './const';

export const getFormattedContracts = (contractsResp: IContractResp) => {
  const contracts = contractsResp?.selectQmContract;
  const formattedContracts = contracts.map((contract) => {
    return {
      ...contract,
      livesCovered: contract?.contractContacts_aggregate?.aggregate?.count || 0,
      startDate: getDateStrFromFormat(
        contract.startDate,
        DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
      ),
      endDate: getDateStrFromFormat(
        contract.endDate,
        DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
      ),
      projectedRevenue: formatProjectedRevenue(contract.projectedRevenue),
    };
  });
  return formattedContracts;
};

export function formatProjectedRevenue(value: string) {
  if (!value) {
    return '';
  }
  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return '';
  }
  return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const getFormattedQualityMeasuresList = (
  qualityMeasuresList: IqmContractQualityMeasuresResp
) => {
  const formattedQualityMeasures: IQualityMeasuresList[] =
    qualityMeasuresList.qmContractQualityMeasures.map((qmItem) => {
      const showExpand =  qmItem?.qualityMeasure?.hasSinglePerformance ? qmItem?.qualityMeasure?.qmMeasureStratification?.length > 1 : qmItem?.qualityMeasure?.qmMeasureGroup?.length > 1
      return {
        careGap: 0,
        id: qmItem.id,
        goal: qmItem.goal,
        name: qmItem?.qualityMeasure?.name,
        inverse: qmItem?.qualityMeasure?.improvementNotation === QM_IMPROVEMENT_NOTATION.DECREASE,
        fhirResourceId: qmItem?.qualityMeasure?.fhirResourceId,
        qmMeasureGroup : qmItem?.qualityMeasure?.qmMeasureGroup,
        qmMeasureStratification: qmItem?.qualityMeasure?.qmMeasureStratification,
        showExpand: showExpand,
        contractName: qmItem?.contract?.name,
        totalPatients: 0,
        performanceScore: '0%',
        measureId: qmItem?.qualityMeasure?.id,
        hasSinglePerformance: qmItem?.qualityMeasure?.hasSinglePerformance
      };
    });
  return formattedQualityMeasures;
};

export const getPopulationCriteriaOrTitle = (criteriaType: string, isTitle?: boolean,)=> {
  switch(criteriaType) {
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED:
      if (isTitle) {
        return 'criteriaMatch'
      }
      return PopulationCriteriaCode.numerator
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED:
      if (isTitle) {
        return 'criteriaNotMatch'
      }
      return PopulationCriteriaCode.numerator
    case QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION:
      if (isTitle) {
        return 'denominatorExclusion'
      }
      return PopulationCriteriaCode.denominatorExclusion
    case QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION:
      return PopulationCriteriaCode.numeratorExclusion
    case QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION:
      return PopulationCriteriaCode.denominatorException
    case QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION:
      return PopulationCriteriaCode.numeratorException
    default:
      return ''
  }
}

export const getContactAndMeasureFormattedData = (args:{
  qmMeasureReports: IMeasureReport[],
  contactData: IContactData[],
  patientType: string
}
): IPatientList[] => {
  const {
    qmMeasureReports,
    contactData,
    patientType
  } = args;
  const contactMap = new Map(contactData.map((contact) => [contact.uuid, contact]));
  return qmMeasureReports.map((report) => {
    const contact = contactMap.get(report.contactId);
    const contactType = contact?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER
      ? PERSON_TYPES_TEXT.PATIENT
      : PERSON_TYPES_TEXT.PROSPECT;
    const gender = contact?.person?.sexAtBirth?.value || contact?.person?.gender?.value || '';
    const birthDate = contact?.person?.birthDate ? moment(contact.person.birthDate).format(DISPLAY_SLASH_DATE_FORMAT) : '';
    const ageValue = contact?.person?.birthDate ? getAgeValue(contact.person.birthDate, '') : '';
    const inverse = report?.qmQualityMeasure?.improvementNotation === QM_IMPROVEMENT_NOTATION.DECREASE;
    const fhirResourceId = report?.qmQualityMeasure?.fhirResourceId;
    const formattedQmMeasureEvidence = getFormattedCriteriaListData(report?.qmMeasureEvidence, patientType, inverse);
    let reason = ''
    if (patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED) {
      if (formattedQmMeasureEvidence?.length > 1) {
        reason = inverse ? `${formattedQmMeasureEvidence?.length} ${CRITERIA_STRING.CRITERIA_INCOMPLETE}` : CRITERIA_STRING.ALL_CRITERIA_MATCHED;
      } else if (formattedQmMeasureEvidence?.length === 1) {
        reason = inverse ? formattedQmMeasureEvidence[0].criteriaTitle : CRITERIA_STRING.ALL_CRITERIA_MATCHED;
      }
    } else if (patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED) {
      if (formattedQmMeasureEvidence?.length > 1) {
        reason = inverse ? CRITERIA_STRING.ALL_CRITERIA_MATCHED : `${formattedQmMeasureEvidence?.length} ${CRITERIA_STRING.CRITERIA_INCOMPLETE}`;
      } else if (formattedQmMeasureEvidence?.length === 1) {
        reason = inverse ? CRITERIA_STRING.ALL_CRITERIA_MATCHED : formattedQmMeasureEvidence[0].criteriaTitle;
      }
    } else {
      reason = 'Evidence'
    }
    return {
      id: report.id,
      reason: reason,
      payerId: report?.qmContract?.payerId,
      contractPayer: report?.qmContract?.contractPayer,
      contactData: {
        uuid: contact?.uuid || '',
        memberType: contactType,
        name: contact?.name || '',
        gender: gender,
        age: ageValue,
        dob: birthDate,
        id: contact?.id
      },
      inverse: inverse,
      fhirResourceId: fhirResourceId,
      qmMeasureGroup: report?.qmQualityMeasure?.qmMeasureGroup,
      qmMeasureStratification: report?.qmQualityMeasure?.qmMeasureStratification,
      qmMeasureEvidence: formattedQmMeasureEvidence,
      showExpand: report?.qmQualityMeasure?.qmMeasureGroup?.length > 1,
    };
  });
};
export function extractNumber(input: string): number | null {
  const match = input.match(/\d+(\.\d+)?/);
  if (match) {
    return parseFloat(match[0]);
  }
  return null;
}

const filterByCriteriaMatch = (formattedCriteriaListData: any, criteriaType: string)=> {
  switch(criteriaType) {
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED:
      return formattedCriteriaListData?.filter((criteria: any) => {
        return criteria?.actualResult === criteria?.expectedResult
      })||[];
    default:
      return formattedCriteriaListData?.filter((criteria: any) => {
        return criteria?.actualResult !== criteria?.expectedResult
      })||[];
  }
}

export const getMeasureCriteriaStatus = (criteria: any, criteriaType: string, isInverse?: boolean)=> {
  switch(criteriaType) {
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED:
    case QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED:
      if (isInverse) {
        return criteria?.actualResult === criteria?.expectedResult ? MEASURE_CRITERIA_STATUS.INCOMPLETE : MEASURE_CRITERIA_STATUS.COMPLETE;
      }
      return criteria?.actualResult === criteria?.expectedResult ? MEASURE_CRITERIA_STATUS.COMPLETE : MEASURE_CRITERIA_STATUS.INCOMPLETE;
    default:
      return MEASURE_CRITERIA_STATUS.COMPLETE
  }
}

export const getFormattedCriteriaListData = (criteriaListData: any, criteriaType: string, isInverse?: boolean)=> {
  const formattedCriteriaListData: any[] =
  criteriaListData?.map((criteria: any) => {
    const qualifyingResources = criteria?.qualifyingResources?.filter((resource: any)=> resource?.id);
    const data = {} as any;
    data.criteriaTitle = criteria?.expressionName;
    data.id = criteria?.id;
    data.status = getMeasureCriteriaStatus(criteria,criteriaType, isInverse);
    data.actualResult = criteria?.actualResult;
    data.expectedResult = criteria?.expectedResult;
    data.showViewDetails = qualifyingResources?.length ? true : false;
    data.qualifyingResources = qualifyingResources;
    return data;
  });
  return filterByCriteriaMatch(formattedCriteriaListData, criteriaType);
}
export const isCriteriaMet = (value: number, goal: number, inverse: boolean) => {
  if (inverse) {
    if (value >= goal) {
      return false;
    }
    return true;
  } else {
    if (value <= 30 && value <= goal) {
      return false;
    } else if (value >= goal) {
      return true;
    }
    return false;
  }
};

export const getLeadingOffTrackValue = (value: number, goal: number, inverse: boolean) => {
  if (inverse) {
    const difference = Math.abs(goal - value);
    return Number.isInteger(difference) ? difference : difference.toFixed(2);
  } else {
    const difference = Math.abs(goal - value);
    return Number.isInteger(difference) ? difference : difference.toFixed(2);
  }
};

export const isCriteriaExceed = (value: number, goal: number, inverse: boolean) => {
  return (value >= goal);
};

export const getResourceTypeForDate = (resourceType: string)=> {
  switch (resourceType) {
    case MEASURE_RESOURCE_TYPE.ENCOUNTER:
      return 'Visit Date';
    case MEASURE_RESOURCE_TYPE.OBSERVATION:
      return 'Evaluation Date';
    case MEASURE_RESOURCE_TYPE.MEDICATION_REQUEST:
      return 'Evaluation Date';
    case MEASURE_RESOURCE_TYPE.IMMUNIZATION:
      return 'Evaluation Date';
    case MEASURE_RESOURCE_TYPE.SERVICE_REQUEST:
      return 'Evaluation Date';
    case MEASURE_RESOURCE_TYPE.CONDITION:
      return 'Onset Date';
    case MEASURE_RESOURCE_TYPE.DIAGNOSTIC_REPORT:
      return 'Evaluation Date';
    case MEASURE_RESOURCE_TYPE.PROCEDURE:
      return 'Evaluation Date'
    default:
      return '--';
  }
}

export const getCodeSystem = (system?: string) => {
  switch (system) {
    case SearchCode.CVX: return 'CVX';
    case SearchCode.FOLD: return 'FOLD';
    case SearchCode.NPI: return 'NPI';
    case SearchCode.RXNORM: return 'RXNORM';
    case SearchCode.SNOMED: return 'SNOMED';
    case SearchCode.LOINC: return 'LOINC';
    case SearchCode.CPT: return 'CPT';
    case SearchCode.ICD10: case SearchCode.ICD10D: return 'ICD10'
    case evidenceCode.LOINC: return 'LOINC';
    case evidenceCode.SNOMED: return 'SNOMED';
    case evidenceCode.RXNORM: return 'RXNORM';
    case evidenceCode.CVX: return 'CVX';
    case evidenceCode.ICD10: return 'ICD10';
    case evidenceCode.CPT: return 'CPT';
    default: return;
  }
};

export const getCriteriaTitle = (criteria?: { code?: { coding?: { display?: string }[] } })=> {
  if (!criteria?.code?.coding) return "-";
  for (const coding of criteria?.code?.coding) {
    if (coding?.display) {
      return coding?.display;
    }
  }
  return '-';
}

export const getCodeSystemFromCriteria = (criteria?: { code?: { coding?: any[] } }) => {
  if (!criteria?.code?.coding) return;
  for (const coding of criteria.code.coding) {
    const matchedSystem = getCodeSystem(coding?.system);
    if (matchedSystem) {
      return {
        ...coding,
        matchedSystem
      };
    }
  }
  return criteria?.code?.coding[0];
};

export const checkForCustom = (codeSystem: string)=> {
  return !EVIDENCE_CODES?.includes(codeSystem);
}

export const getCodeSystemByResourceType = (resourceType: string, system: SearchCode | evidenceCode)=> {
  const allowedCodes = RESOURCE_TYPE_CODE_MAP[resourceType];
  return allowedCodes ? allowedCodes.includes(system) : false;
}

export const getCodeFromCriteria = (criteria?: any) => {
  for (const coding of criteria.code.coding) {
    const matchedSystem = getCodeSystemByResourceType(criteria?.resourceType, coding?.system || '');
    if (matchedSystem && coding?.code) {
      return coding?.code
    }
  }
  return criteria?.code?.coding[0]?.code || "-";
};

export enum PopulationCriteriaCode {
  numerator = 'numerator',
  denominatorExclusion = 'denominatorExclusion',
  numeratorExclusion = 'numeratorExclusion',
  denominatorException = 'denominatorException',
  numeratorException = 'numeratorException'
}

export const getReasonText = (memberType: string, reason: string)=> {
  return reason;
}

export const formatExecutionDate = (dateInput: any)=> {
  const now = moment();
  const date = moment(dateInput);

  if (now.isSame(date, 'day')) {
      return date.fromNow().replace('minutes', 'mins');
  }
  return date.format(DATE_FORMATS.EXECUTION_DATE_FORMAT);
}

export const getPopulationCodes = (populationArray: IMeasurePopulation[])=> {
  const populationCodes: string[] = [];
  if (populationArray?.length) {
    populationArray?.forEach((population: any) => {
      if (population?.code?.coding?.[0]?.code) {
        populationCodes.push(population?.code?.coding?.[0]?.code)
      }
    })
  }
  return populationCodes;
}
