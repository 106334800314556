import {View} from 'native-base';
import React from 'react';
import PatientCareJourneyListView from '../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyListView';
import { CareTimeline } from './CareTimeline/CareTimeline';
import { Documents } from './Documents/Documents';
import {FormResponseTableView} from './FormResponseTableView';
import {IMiddleContainerProps} from './interfaces';
import LatestConversation from './Messages/LatestConversation';
import {MIDDLE_CONTAINER_TABS} from './MiddleContainerConst';
import {getDataByTabType} from './MiddleContainerUtils';
import OrdersTimelineView from './OrdersTimeline/OrdersTimelineView';
import {TimelineView} from './TimelineView';
import CarePlan from './CarePlan/CarePlan';
import PatientAlerts from './Alerts/PatientAlerts';
import CareManagement from '../../RightSideContainer/CareManagementView/CareManagement';
import InsightsReferralOrdersView from '../../SideCar/components/InsightsReferralOrdersView';
import { useWindowDimensions } from 'react-native';


export const MiddleContainerMainView = (props: IMiddleContainerProps) => {
  const getElementByTabCode = (tabCode: string): JSX.Element => {
    let tabElement = <></>;
    switch (tabCode) {
      case MIDDLE_CONTAINER_TABS.TIMELINE:
        tabElement = (
          <TimelineView
            mainTimelineTitle=""
            timelineData={[]}
            personData={props.personData}
            unFormattedContactData={props.unFormattedContactData}
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.ALERTS:
        tabElement = (
          <PatientAlerts
            contactId={
              props.unFormattedContactData?.uuid ||
              props.personData.contactUUID ||
              props.personData?.uuid
            }
            personData={props.personData}
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.DETAILS:
      //   <PatientNotes
      //   mainTimelineTitle=""
      //   timelineData={[]}
      //   personData={props.personData}
      //   unFormattedContactData={props.unFormattedContactData}
      // />
        tabElement = (
          <CareTimeline
            personData={props.personData}
            unFormattedContactData={props.unFormattedContactData}
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.MESSAGES:
        tabElement = (
          <LatestConversation
            mainTimelineTitle="February 2022"
            timelineData={getDataByTabType('MESSENGER')}
            personData={props.personData}
            conversation={props.conversation}
            conversationInbox={props?.conversationInbox}
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.JOURNEYS:
        tabElement = (
          <PatientCareJourneyListView personData={props.personData} />
        );
        break;
      case MIDDLE_CONTAINER_TABS.TASKS:
        tabElement = <></>//<Tasks personData={props.personData} />;
        break;
      case MIDDLE_CONTAINER_TABS.NOTES:

      //   <PatientNotes
      //   mainTimelineTitle=""
      //   timelineData={[]}
      //   personData={props.personData}
      //   unFormattedContactData={props.unFormattedContactData}
      // />
        tabElement = (
          <CareTimeline
            personData={props.personData}
            unFormattedContactData={props.unFormattedContactData}
          />
        );
        break;

      case MIDDLE_CONTAINER_TABS.FORM_RESPONSES:
        tabElement = (
          <FormResponseTableView
            personData={props.personData}
            personType={props.personType}
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.ORDERS:
        tabElement = (
          <OrdersTimelineView
            personData={props.personData}
          unFormattedContactData={props.unFormattedContactData}
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.DOCUMENTS:
        tabElement = (
          <Documents
            contactUuid={props.unFormattedContactData?.uuid}
            contactName={props?.personData?.name}
            contactId={props?.personData?.id}
            personData={props.personData}
            unFormattedContactData={props.unFormattedContactData}
          />
        );
      break;
      case MIDDLE_CONTAINER_TABS.CARE_PLAN:
        tabElement = (
          <CarePlan
            accountLocationUuid={props.personData?.accountLocationUuid || ''}
            contactUuid={props.unFormattedContactData?.uuid}
            contactName={props?.personData?.name}
            contactId={props?.personData?.id}
            personData={props.personData}
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.CARE_MANAGEMENT:
        tabElement = (
          <CareManagement
            accountLocationUuid={props.personData?.accountLocationUuid || ''}
            contactName={props?.personData?.name}
            contactId={props?.personData?.id}
            personData={props.personData}
            contactUuid={
              props.unFormattedContactData?.uuid ||
              props.personData.contactUUID ||
              props.personData?.uuid
            }
          />
        );
        break;
      case MIDDLE_CONTAINER_TABS.REFERRAL:
        tabElement = (
          <View height={useWindowDimensions()?.height - 115} backgroundColor={'white'} width={ props.enableCareProgram ? '80%' : '100%'} alignSelf={'center'}>
          <InsightsReferralOrdersView
            formattedContactData={props.personData}
            contactData={props.unFormattedContactData}
            onBack={() => {}}
            heightOffsetForContainer={200}
          />
          </View>
        );
        break;
    }

    return tabElement;
  };

  return (
    <View flex={1} flexDirection={'row'}>
      <View
        style={{
          marginRight: -3,
          flex: 1,
          ...(props.middleContainerSelectedTabCode !==
            MIDDLE_CONTAINER_TABS.DETAILS && {overflow: 'hidden'}),
        }}
      >
        <>{getElementByTabCode(props.middleContainerSelectedTabCode)}</>
      </View>
    </View>
  );
};
