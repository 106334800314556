import React, {useEffect, useState} from 'react';
import {
  FetchContactsBy,
  IAssignJourneyListViewComponentState,
  IAssignJourneyListViewProps,
} from './interface';
import {Drawer, Skeleton, notification, Switch, Table} from 'antd';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {BUTTON_TYPE, GROUP_MEMBER_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {getContactsWithinGroup, getPopGroupLocationBasedContacts} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {isLoginUserBusinessOwner, splitArrayIntoChunks} from '../../../../../utils/commonUtils';
import {useLazyQuery} from '@apollo/client';
import ContactsQueries from '../../../../../services/Contacts/ContactsQueries';
import { HStack, Text, VStack, View, Spacer, Pressable, Center} from 'native-base';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import {capitalizeText} from '../Helper';
import { useIntl } from 'react-intl';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import { Dimensions } from 'react-native';
import { GET_JOURNEY_ASSIGNED_PATIENTS } from '../../../../../services/CareJourney/CareJourneyQueries'
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import Feather from 'react-native-vector-icons/Feather';
import EmptyMembersStateSvg from '../../../../common/Svg/EmptyMembersStateSvg';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { getLocationIntersectionList } from '../../../Workflow/workflowUtils';

const AssignJourneyListView = (props: IAssignJourneyListViewProps) => {
  const {visible, selectedContactIds, fetchContactsBy, onAssign, onClose} =
    props;
  const isFetchByContactIds = fetchContactsBy === FetchContactsBy.CONTACT_IDS;
  const intl = useIntl();
  const [componentState, setComponentState] =
    useState<IAssignJourneyListViewComponentState>({
      contactIdsList: [],
      contactList: [],
      loading: false,
      selectAllLoading: false,
      error: false,
      showAllMembers: false,
    });
  const { height } = Dimensions.get('window');
  const tableHeight = height - 300;
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isBusinessOwner = isLoginUserBusinessOwner();
  const toggleBooleanStates = (
    key: keyof Pick<
      IAssignJourneyListViewComponentState,
      'loading' | 'selectAllLoading' | 'error'
    >,
    value: boolean
  ) => {
    setComponentState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [getContactsByIds, contactDetailsQuery] = useLazyQuery(
    isFetchByContactIds
      ? ContactsQueries.GET_ACTIVE_CONTACTS_BY_CONTACTIDS
      : ContactsQueries.GET_ACTIVE_CONTACTS_BY_CONTACTUUIDS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => {

      },
    }
  );

  const [getAlreadyJourneyAssignedPatients] = useLazyQuery(
    GET_JOURNEY_ASSIGNED_PATIENTS,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
      fetchPolicy: 'no-cache'
    }
  );
  const isUnassignedMembersEmptyCheck = !componentState.showAllMembers && !componentState?.unassignedJourneyContacts?.length;

  const buttonList = [
    {
      show: true,
      id: 1,
      btnText: 'cancel',
      textColor: Colors.Custom.mainSecondaryBrown,
      variant: BUTTON_TYPE.SECONDARY,
      isTransBtn: false,
      onClick: () => {
        onClose?.();
      }
    },
    {
      id: 3,
      btnText: `Assign`,
      textColor: Colors.Custom.mainPrimaryPurple,
      btnStype: BUTTON_TYPE.PRIMARY,
      variant: BUTTON_TYPE.PRIMARY,
      isTransBtn: false,
      isDisabled: isUnassignedMembersEmptyCheck || componentState.showAllMembers,
      onClick: () => {
        onAssign(props?.ruleData || {}, componentState?.unassignedContactsUuids || [])
      }
    }
  ];
  const getActiveContactsByChunk = async (contactIds: string[]) => {
    const chunkForGetContacts = splitArrayIntoChunks(contactIds, 100);
    const fetchPromises = chunkForGetContacts.map(async (chunk) => {
      const contactsData = await getContactsByIds({
        variables: {
          contactIdList: chunk,
        },
      });
      return contactsData?.data?.contacts || [];
    });

    const contactsArrays = await Promise.all(fetchPromises);
    const contacts = contactsArrays.flat();

    return contacts;
  };

  const getJourneyAlreadyAssignedAndNotAssignedContacts = async (contactList: any[]) => {
    const patientUuidToContactMap: Record<string, any> = {};
    const patientsDataVariable: { patientId: string, contactId: string }[] = [];
    const unassignedJourneyContacts: any[] = [];
    contactList.forEach((contact) => {
      const patientUuid = contact?.patient?.patientUuid;
      if (!(patientUuid in patientUuidToContactMap)) {
        patientUuidToContactMap[patientUuid] = contact;
        patientsDataVariable.push(
          {
            patientId: patientUuid,
            contactId: contact?.uuid
          }
        )
      }
    });

    const patientUuidsOfAlreadyAssignedJourney = await getAlreadyJourneyAssignedPatients({
      variables: {
        careJourneyId: props?.selectedJourney?.id,
        assignee: patientsDataVariable
      },
    });

    const setOfPatientUuidsOfAlreadyAssignedJourney: Set<string> = new Set(patientUuidsOfAlreadyAssignedJourney?.data?.validateBulkJourneyAssignee?.journeyAssignee)
    const unassignedContactsUuids: string[] = []
    contactList?.forEach((contact: any) => {
        if (setOfPatientUuidsOfAlreadyAssignedJourney?.has(contact?.patient?.patientUuid)) {
          contact.alreadyAssignedJourney = true;
        } else {
          unassignedJourneyContacts.push(contact);
          unassignedContactsUuids.push(contact?.uuid)
        }
    });
    setComponentState((prev) => ({
      ...prev,
      patientUuidsOfAlreadyAssignedJourney: setOfPatientUuidsOfAlreadyAssignedJourney,
      unassignedJourneyContacts: unassignedJourneyContacts,
      unassignedContactsUuids: unassignedContactsUuids
    }))
    return contactList
  };


  const fetchContactIdsByRule = async () => {
    if (!props?.ruleData?.id) {
      toggleBooleanStates('loading', false);
      return;
    }
    let contactUuids: string[] = [];
    if (isMsoEnabled && props?.locationAndRuleIdBasedContactIdsFilter) {
      const journeyLocations = props?.selectedJourney?.careJourneyLocations?.map((loc: any) => loc?.locationId) || [];
      const popGroupLocations = props?.ruleData?.locationIds?.map((loc: any) => loc?.locationId) || [];
      const intersectedLocations = getLocationIntersectionList(journeyLocations, popGroupLocations, isBusinessOwner);

      try {
        const filteredContactIds = await getPopGroupLocationBasedContacts({
          ruleIds: [props?.ruleData?.id],
          accountLocationIds: intersectedLocations,
        });
        contactUuids = filteredContactIds?.data[props.ruleData.id] || [];
      } catch (error) {
        notification.error({
          message: 'Error occurred while fetching contact IDs',
        });
        toggleBooleanStates('loading', false);
        toggleBooleanStates('error', true);
        console.error('Error fetching contact IDs:', error);
      }
    } else {
      const data = await getContactsWithinGroup(props?.ruleData?.id, true).catch(() => {
        notification.error({
          message: 'Error occurred while fetching employee details',
        });
        toggleBooleanStates('loading', false);
        toggleBooleanStates('error', true);
      });
      contactUuids = data?.data?.contactUuids || [];
    }
    if (!contactUuids || !contactUuids?.length) {
      setComponentState((prev) => ({...prev, loading: false, contactList: []}));
    } else {
      const contacts = await getActiveContactsByChunk(contactUuids);
      const updatedContactList = await getJourneyAlreadyAssignedAndNotAssignedContacts(contacts)
      setComponentState((prev) => ({...prev, contactList: updatedContactList}));
      toggleBooleanStates('loading', false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        contactList: updatedContactList,
      }));
    }
  };

  const fetchContactDetailsByIds = async () => {
    if (selectedContactIds && selectedContactIds.length) {
      let careJourneyLocations=[]
      if(props?.selectedJourney?.careJourneyLocations?.length>0){
         careJourneyLocations = props?.selectedJourney?.careJourneyLocations.map((loc:any)=>loc?.locationId)
      }
      const contacts = await getActiveContactsByChunk(selectedContactIds);
      const findMatchingContacts = (contacts: any[], careJourneyLocations: string[]) => {
        return contacts.filter((contact: any) => {
          return contact?.contactPracticeLocations?.some((contactPracticeLocation: any) =>
            careJourneyLocations?.includes(contactPracticeLocation?.accountLocation?.uuid)
          );
        });
      };
      
      let matchingContact = [];
      if (careJourneyLocations?.length > 0) {
        matchingContact = findMatchingContacts(contacts, careJourneyLocations);
      } else{
        matchingContact = contacts;
      }
      if (matchingContact.length > 0) {
        const updatedContactList = await getJourneyAlreadyAssignedAndNotAssignedContacts(matchingContact);
        setComponentState((prev) => ({...prev, contactList: updatedContactList}));
        toggleBooleanStates('loading', false);
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          contactList: updatedContactList,
        }));
      } else {
        props?.showNoPatientMappedToJourneyAlert?.();
      }
    }
    toggleBooleanStates('loading', false);
  };

  useEffect(() => {
    if (!isFetchByContactIds && props?.ruleData?.id?.length) {
      toggleBooleanStates('loading', true);
      fetchContactIdsByRule();
    }
  }, [props?.ruleData?.id]);

  useEffect(() => {
    if (
      isFetchByContactIds &&
      selectedContactIds &&
      selectedContactIds?.length > 0
    ) {
      toggleBooleanStates('loading', true);
      fetchContactDetailsByIds();
    }
  }, [props.selectedContactIds]);

  const renderContactItem = ({item: contact, index}: any) => {
    const {name, email, id, profileImgSrc, person} = contact;
    return (
      <HStack alignItems={'center'} flex={1} marginTop={5}>
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '12',
          }}
          isLetterAvatarShow={true}
          userData={{
            userId: id,
            userType: GROUP_MEMBER_TYPE.CONTACT,
            imgSrc: profileImgSrc || '',
            genderCode: person?.gender?.code || 'other',
            userName: name,
          }}
        />
        <VStack style={{marginLeft: 12}}>
          <Text size={'smMedium'} color={Colors.Custom.Gray900}>
            {name}
          </Text>
          <Text size={'smNormal'} color={Colors.Custom.Gray500}>
            {email}
          </Text>
        </VStack>
      </HStack>
    );
  };

  const renderMainView = () => {
    if (componentState.loading) {
      return <Skeleton active />;
    }
    if (componentState.contactList.length > 0) {
      return (
        <VStack>
          <Text
            style={{marginBottom: 5}}
            size={'mdBold'}
            color={Colors.primary[500]}
          >
            {capitalizeText('Group')}
          </Text>
          <Text
            style={{marginBottom: 5}}
            size={'smMedium'}
            color={Colors.primary[500]}
          >
            {props?.ruleData?.name || ''}
          </Text>
          <HStack
          space={2}
          alignItems='center'
          minHeight={'30px'}
          marginBottom={2}
          >
            <Switch
              style={{
                backgroundColor: componentState.showAllMembers ? Colors.Custom.Primary300 : Colors.Custom.Gray200,
                width: 25,
                borderRadius: 3,
              }}
              onChange={() => {
                setComponentState((prev) => ({
                  ...prev,
                  showAllMembers: !prev.showAllMembers
                }));
              }
              }
              loading={false}
              checked={componentState.showAllMembers}
            />
            <Text color={Colors.Custom.Gray400}>
              {intl.formatMessage({ id: 'showAllMembers' })}
            </Text>
            <Spacer />
            {!componentState?.showAllMembers && (
              <Pressable
                disabled={isUnassignedMembersEmptyCheck}
                onPress={() =>
                  onAssign(props?.ruleData ? props.ruleData : {}, componentState?.unassignedContactsUuids || [])
                }
              >
                <View
                  justifyContent='flex-end'
                  backgroundColor={isUnassignedMembersEmptyCheck ? Colors?.Custom?.Gray100 : Colors?.Custom?.Primary300}
                  padding={'6px'}
                  borderRadius={'4px'}
                >
                  <Text
                    fontWeight={'500'}
                    color={isUnassignedMembersEmptyCheck ? Colors?.Custom?.Gray300 : Colors?.Custom?.FontColorWhite}
                    fontSize={'14px'}
                  >
                    Assign All
                  </Text>
                </View>
              </Pressable>
            )}
          </HStack>
          {isUnassignedMembersEmptyCheck ? (
            <Center>
              <EmptyMembersStateSvg />
              <Text margin={2} color={Colors.Custom.Gray400}>
                {intl.formatMessage({id: 'noUnassignedMembers'})}
              </Text>
            </Center>
          )
            :
            <Table
              scroll={{ y: tableHeight }}
              dataSource={componentState.showAllMembers ? componentState?.contactList: componentState?.unassignedJourneyContacts}
              showHeader={true}
              rowKey={(record, index) => record?.contact?.uuid + index}
              pagination={false}
              columns={[
                {
                  title: (
                    <DisplayText
                      size={'xsMedium'}
                      extraStyles={{ color: Colors.Custom.Gray500 }}
                      textLocalId="nameCapital"
                    />
                  ),
                  dataIndex: 'contact',
                  width: '60%',
                  key: 'contact',
                  render: (value: any, record, index) => {
                    return renderContactItem({ item: record })
                  },
                },
                {
                  title: (
                    <View>
                      <DisplayText
                        size={'xsMedium'}
                        extraStyles={{ color: Colors.Custom.Gray500, alignItems: 'center', textAlign: 'center' }}
                        textLocalId="careJourneyAssigned"
                      />
                    </View>
                  ),
                  dataIndex: 'status',
                  key: 'status',
                  render: (value: any, record, index) => {
                    return <View style={{ alignItems: 'center' }}>
                      {!!record?.alreadyAssignedJourney ?
                        <Feather
                          name="check"
                          size={20}
                          color={Colors.Custom.SuccessColor}
                        />
                        :
                        <Feather
                          name="x"
                          size={20}
                          color={Colors.Custom.crossIconColor}
                        />
                      }
                    </View>
                  },
                }
              ]}
            />
          }
        </VStack>
      );
    }
    return <></>;
  };

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      width="40%"
      title={
        <ModalActionTitle title={'Assign Journey'} buttonList={buttonList} />
      }
    >
      {renderMainView()}
    </Drawer>
  );
};

export default AssignJourneyListView;
