import {Skeleton} from 'antd';
import moment from 'moment';
import {Pressable, Text, Tooltip} from 'native-base';
import React, {useEffect, useState} from 'react';
import {ANALYTICS_RESOURCE, DATE_FORMATS, EMPLOYER_REPORT_FILTER_CODES} from '../../../../../constants';
import {getSectionDataByResourceCode} from '../../../../../services/Employer/EmployerServices/EmployerServices';
import {Colors} from '../../../../../styles';
import RestSvgIcons from '../../../../common/Svg/RestSvgIcons';
import EyeHideSvg from '../../../../common/Svg/WidgetsSvg/EyeHideSvg';
import InfoIconView from '../CommonView/InfoIconView';
import {DashboardDataContext} from '../EmployerContext/DashboardContext';
import {WIDGET_ACTION_CODE} from '../EmployerUtils/EmployerConst';
import {getFormattedDataForParetoCurves} from '../EmployerUtils/FormateDataUtils';
import {convertObjectToArray, isWidgetDataEmpty} from '../EmployerUtils/WidgetUtils';
import {IAppliedFilter, IResource, IWidgetCardView} from '../interface';
import {DPC_UTILIZATION, EXPENSE_PARETO_CURVES, getTenantForDemo} from '../tempDataFile';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import NodataViewTypeOne from './NoDataView/NodataViewTypeOne';
import WidgetActionView from './WidgetActionView';
import { SM_CONFIG, XS_CONFIG } from '../../../../../theme/FontConfig/TextFontSizeConfig';


const TrendWidget = (props: IWidgetCardView) => {
  const {
    sectionData,
    // resourceData,
    employerId,
    appliedFilter,
    memberType,
    isAccountDashboard,
    hintMembershipDisabled,
  } = props;

  const isParetoCurves =
    sectionData.resourceCode == ANALYTICS_RESOURCE.EXPENSE_PARETO_CURVES_TREND;
  const isDpcUtilization =
    sectionData.resourceCode == ANALYTICS_RESOURCE.DPC_STRATIFICATION;
    const [stateData, setStateData] = useState({
      resourceData: [] as IResource[] | any,
      loading: false,
      appliedFilter: props.appliedFilter,
      filterValueName: [] as string[],
      filterValueString: '',
      selectedAppliedFilter: [] as any[]
    });
  const expenseParetoCurveData = getFormattedDataForParetoCurves(sectionData.resourceCode,
    stateData.resourceData?.contactExpData
  );
  const formattedInArray = isParetoCurves || isDpcUtilization
    ? expenseParetoCurveData.tempArray || []
    : [];
  const totalCount = expenseParetoCurveData.totalCount;
  const onActionClick = (code: string, data?: any) => {
    switch (code) {
      case WIDGET_ACTION_CODE.RESET_LOCATION_FILTER:
        const filteredWidgetFilter = stateData.selectedAppliedFilter.filter(item => {
          return item.filterCode != 'LOCATION'
        })
        setStateData(prev => {
          return {
            ...prev,
            selectedAppliedFilter: filteredWidgetFilter,
            filterValueString: '',
            filterValueName: []
          }
        })
        getSectionByResourceCode();
        break;
      default:
        props.onActionClick?.(code, data)
        break;
    }
  }
  const onDrillDownClick = (item: any) => {
    let filterData: any = [{
      filterCode: 'EXPENSE_PARETO_CURVE_PERCENTAGE',
      filterValue: item?.label,
      count : item?.value
    }];
    const dateRangeFilter = {
      filterCode: 'DATE_RANGE',
      filterValue: {
        fromDate: moment(props.dateRange?.uiFromDate).format(
          `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
        ),
        toDate: moment(props.dateRange?.uiToDate).format(
          `${DATE_FORMATS.AVAILABILITY_DATE_FORMAT}T${DATE_FORMATS.API_TIME_FORMAT}`
        ),
      },
    };
    filterData.push(dateRangeFilter);
    filterData = [...filterData, ...stateData.selectedAppliedFilter]
    props.onActionClick?.(sectionData.resourceCode || '', {filterData});
  };
  const getSectionByResourceCode = async (appliedFilter?: IAppliedFilter[]) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const filterList: any = appliedFilter?.length ?  appliedFilter : stateData.appliedFilter
    if (hintMembershipDisabled) {
      filterList.push({
        filterCode: EMPLOYER_REPORT_FILTER_CODES.MEMBERSHIP, 
        filterValue: hintMembershipDisabled
      })
    }
    const response = await getSectionDataByResourceCode(
      employerId,
      sectionData.resourceCode || '',
      filterList,
      '',
      isAccountDashboard,
      memberType,
    ).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          resourceData: [],
          employeeTotalCount: 0,
        };
      });
    })

    if (response?.data && isWidgetDataEmpty(response?.data)) {

      const resourceData = response.data?.totalCount ? response.data : response.data?.result || response.data;
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: resourceData,
          employeeTotalCount: response.data?.totalCount || null,
          loading: false,
        };
      });
    } else {
      props.onWidgetLoaded?.(WIDGET_ACTION_CODE.WIDGET_LOADED,{resourceCode: sectionData.resourceCode})
      setStateData((prev) => {
        return {
          ...prev,
          resourceData: [] as IResource[],
          employeeTotalCount: 0,
          loading: false,
        };
      });
    }
  };
  const controller = new AbortController();
  useEffect(() => {
    if (getTenantForDemo()) {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true
        }
      })
      let resourceData = {};
       if (getTenantForDemo()) {
         resourceData = isParetoCurves? EXPENSE_PARETO_CURVES :
          isDpcUtilization ? DPC_UTILIZATION : {}
       } else {
        resourceData = stateData.resourceData || ({} as any)
      };

      setTimeout(()=> {
        setStateData((prev) => {
          return {
            ...prev,
            resourceData: resourceData,
            loading: false
          }
        })
        },3000)
      return
    }
    getSectionByResourceCode();
    return () => controller?.abort();
  }, [sectionData.id])
  useEffect(() => {
    if (props.filterData?.resourceCode == sectionData.resourceCode) {
      const appliedFilter = stateData.appliedFilter?.length ? [...stateData.appliedFilter] : [];
      const selectedFilter = {
        filterCode: props.filterData?.filterCode,
        filterValue:  props.filterData?.filterValue
      }
      let filterValueString = '';
      props.filterData?.filterValueName?.forEach((item: string, index: number) => {
        if (index < 2) {
          return
        }
        filterValueString = filterValueString + ( filterValueString.length ? " | " : '') + item
      })
      setStateData(prev => {
        return {
          ...prev,
          filterValueName: props.filterData?.filterValueName,
          filterValueString: filterValueString,
          selectedAppliedFilter: [selectedFilter]
        }
      })
      appliedFilter.push(selectedFilter);
      getSectionByResourceCode(appliedFilter);
    }
    return () => controller?.abort();
  },[props.filterData?.filterValue])

  const getRefreshButtonOnLocalEnv = () => {
    if (window.location.host.includes('localhost')) {
      return (
        <Pressable
          style={{marginHorizontal: 3}}
          onPress={() => {
            setStateData(prev => {
              return {
                ...prev,
                filterValueName: [],
                filterValueString: ''
              }
            })
            getSectionByResourceCode();
          }}
        >
          <Tooltip label="Refresh">
            <RestSvgIcons />
          </Tooltip>
        </Pressable>
      )
    }
    return <></>;
  }
  return (
    <div className={'widgetBoxShadow'}>
      <WidgetHeader isPrintPreview ={props.isPrintPreview} isPrintDownload={props.isPrintDownload} refreshButtonOnLocalEnv={getRefreshButtonOnLocalEnv()} sectionData={sectionData} onActionClick={onActionClick} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: Colors.Custom.Gray50,
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 2,
            paddingTop: 13,
            paddingBottom: 13,
            paddingLeft: 24,
          }}
        >
          <div
            style={{
              color: Colors.Custom.Gray500,
              ...XS_CONFIG.xsSemibold
            }}
          >
            {'Status'}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 3,
            paddingTop: 13,
            paddingBottom: 13,
            paddingLeft: 24,
          }}
        >
          <div
            style={{
              color: Colors.Custom.Gray500,
              ...XS_CONFIG.xsSemibold
            }}
          >
            {'Number of Members'}
          </div>
        </div>
      </div>
      { stateData.loading ? (
        <Skeleton style={{padding: 40}} active={true} />
      ) : (
        <div style={{height: formattedInArray.length ? 'auto' : '80%'}}>
          {formattedInArray.length ? (
            formattedInArray.map((item, ind) => {
              return (
                <div
                  key={ind}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: 2,
                    paddingBottom: 2,
                    borderBottomStyle: 'solid',
                    borderBottomWidth:
                      formattedInArray.length == ind + 1 ? 0 : 1,
                    borderBottomColor: '#E4E7EC',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 2,
                      paddingTop: 11,
                      paddingBottom: 11,
                      paddingLeft: 24,
                    }}
                  >
                    <div
                      style={{
                        color: Colors.Custom.Gray900,
                        ...SM_CONFIG.smBold,
                        lineHeight: 1.2,
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flex: 3,
                      paddingTop: 13,
                      paddingBottom: 13,
                      paddingLeft: 24,
                    }}
                  >
                    <div
                      style={{
                        height: 8,
                        borderRadius: 25,
                        backgroundColor: '#F9F5FF',
                        width: 80,
                      }}
                      onClick={() => {
                        onDrillDownClick(item);
                      }}
                    >
                      <div
                        style={{
                          height: 8,
                          borderRadius: 25,
                          backgroundColor: '#80A4D5',
                          width: (80 * ((item.value / totalCount) * 100)) / 100,
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        color: Colors.Custom.Gray700,
                        ...SM_CONFIG.smBold,
                        lineHeight: 1.2,
                        marginLeft: 10,
                      }}
                    >
                      {item.value}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '60%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NodataViewTypeOne viewCode={'TEXT_ICON'} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default React.memo(TrendWidget);
