import { useLazyQuery } from '@apollo/client';
import { DatePicker, Divider, Select } from 'antd';
import { debounce } from 'lodash';
import { Checkbox, HStack, Input, Pressable, Spinner, Text, VStack, View, useMediaQuery } from 'native-base';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IMlov } from '../../../../Interfaces';
import {
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import { LABEL_TYPE_CODES, MLOV_CATEGORY, TASK_PRIORITY_CODES, TASK_STATUS, TASK_STATUS_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { LeadQueries, TaskQueries } from '../../../../services';
import { GetLabelsByLabelTitleAndTypeId } from '../../../../services/Labels/LabelsQueries';
import { Colors } from '../../../../styles';
import { getDateStrFromFormat, getMomentObj } from '../../../../utils/DateUtils';
import {
  getAccountId,
  getBooleanFeatureFlag,
  getUserEmployerId,
  getUserUUID,
  isEmployerRole
} from '../../../../utils/commonUtils';
import { getCareStudioMlovListByCategoryCode, getContactTypeId, getMlovId, getMlovListFromCategory, getMlovObjectFromCode, getMlovValue } from '../../../../utils/mlovUtils';
import { isEmployerOwnerShowFilterByContact } from '../../../RightSideContainer/UserAccess/UserPermissionConst';
import MemberInfoListItem from '../../MemberInfoListItem/MemberInfoListItem';
import { ILabelTask } from '../CareDashboardInterfaces';
import { ITopBarData } from './CareDashboardTopBar';
import { FilterWrapperComponent } from './FilterWrapperComponent';
import { BoardType, TaskFilterType } from './interfaces';
import moment from 'moment';
import {IAllUserPoolSelect, IUserPool, IUserSearch} from '../CareDashboardWidget/UserAutoComplete';
import { TASK_TABS } from '../CareDashboardConstants';
import './FilterRow.css'
import Stack from '../../LayoutComponents/Stack';
import FoldButtonV2, { ButtonType } from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import Feather from 'react-native-vector-icons/Feather';
import CrossIconSvg from '../../Svg/CrossIconSvg';
import CrossIcon from '../../Svg/CrossIcon';
import UserAutoCompleteMultiSelect from '../../CalendarWidget/UserAutoComplete/UserAutoCompleteMultiSelect';
import { getLabelForAssigneeField } from '../../../TaskModule/TaskModuleHelper';
import BoardTypeSelector from './BoardTypeSelector';
import { styles } from './CustomTabsViewStyles';
import { TaskContext } from '../../../../context/TaskContext';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { usePermissions } from '../../../CustomHooks/usePermissions';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { USER_ACCESS_PERMISSION } from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { testID, TestIdentifiers } from '../../../../testUtils';

const { Option } = Select;

interface IFilterByTaskStatus {
  statusType: string;
  statusTypeUuid: string;
  id: number;
  label: string;
}


export interface IFilterByDueDate {
  dueDateType: string;
  dueDateTypeUuid: string;
  id: number;
  label: string;
}

export const dateRangeStatusUuid = 'date_range';
export const dateRangeStatusValue = 'Date Range';

const FilterRowView = (props: {
  selectedStatusList?: Array<IMlov>;
  selectedPriorityList?: Array<IMlov>;
  selectedDueDateType: string[];
  selectedDueDateUuid: string[];
  onActionPerformed: (
    tabCode: any,
    rawData: any,
  ) => void;
  topBarData: ITopBarData;
  onTopBarDataChange: (topBarData: ITopBarData) => void;
  onCloseFilterView: () => void;
  isPatientView?: boolean;
  selectedContactUuid?: {
    value: string | undefined;
    label: string;
    key: string;
    contactData: any;
  }[];
  selectedPatientName?: string[];
  selectedPatientLabel?: any[];
  setContactsData?: any;
  contactsData?: any;
  userPools?: any;
  children?: JSX.Element;
}) => {
  const mlovData = useContext(CommonDataContext);
  const { taskPools, taskAccessUsers, manageAccessUsersTaskPools, taskContextDataLoading, taskMasterUsers, taskPoolsLoading, loginUserLocations } = useContext(TaskContext);
  const isSidecarContext = !!mlovData.sidecarContext?.isSidecar;
  const { accountLocationListWithEHR } = mlovData;
  const { topBarData } = props;
  const intl = useIntl();
  const { setContactsData, contactsData } = props;
  const [contactsDataState, setContactsDataState] = useState(contactsData)

  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS);
  const acceptedStatusMlov = getMlovObjectFromCode(TASK_STATUS.ACCEPTED, taskStatusMlovs);
  const completedStatusMlov = getMlovObjectFromCode(TASK_STATUS.COMPLETED, taskStatusMlovs);
  const missedStatusMlov = getMlovObjectFromCode(TASK_STATUS_CODES.MISSED, taskStatusMlovs);
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_PRIORITY);
  const highPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.HIGH, taskPriorityMlovs) as IMlov;
  const mediumPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.MEDIUM, taskPriorityMlovs) as IMlov;
  const lowPriorityMlov = getMlovObjectFromCode(TASK_PRIORITY_CODES.LOW, taskPriorityMlovs) as IMlov;

  //For Due Date
  const allStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const allStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );
  const overdueStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const overdueStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'overdue'
  );
  const todayStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const todayStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'today'
  );
  const tomorrowStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const tomorrowStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'tomorrow'
  );
  const laterStatusUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );
  const laterStatusValue = getMlovValue(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'later'
  );

  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const { check } = usePermissions();
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);

  const allowedAccountLocationIds = accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => location?.uuid) || [];

  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const labelMlov = filteredResult[0];

  const [searchText, setSearchText] = useState("");

  const [stateData, setStateData] = useState({
    statusTypeList: [] as IMlov[],
    priorityTypeList: [] as IMlov[],
    dueDateTypeList: [] as IFilterByDueDate[],
    selectedDueDateCodes: (props.selectedDueDateUuid || []),
    selectedStatusCodes: (props.selectedStatusList?.map?.((mlov) => mlov.code) || []),
    selectedPriorityCodes: (props.selectedPriorityList?.map?.((mlov) => mlov.code) || []),
    formData: {
      selectedContactUuid: props.selectedContactUuid
        ? props.selectedContactUuid
        : [],
      selectedPatientLabel: props.selectedPatientLabel
        ? props.selectedPatientLabel
        : [],
      selectedPatientName: props.selectedPatientName
        ? props.selectedPatientName
        : [],
      selectedStatusList: props.selectedStatusList
        ? props.selectedStatusList
        : [],
      selectedPriorityList: props.selectedPriorityList
        ? props.selectedPriorityList
        : [],
      selectedDueDate: props.selectedDueDateType
        ? props.selectedDueDateType
        : allStatusValue,
      selectedDueDateUuid: props.selectedDueDateUuid
        ? props.selectedDueDateUuid
        : allStatusUuid,
      start: getDateStrFromFormat(
        props.topBarData.start ? props.topBarData.start : new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      end: getDateStrFromFormat(
        props.topBarData.end ? props.topBarData.end : new Date(),
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      ),
      selectedPools: props?.topBarData?.selectedPools
        ? Array.from(props.topBarData.selectedPools)
        : [],
      selectedUsers: props.topBarData?.selectedUsers || [],
      createdByIds: props.topBarData?.createdByIds || [],
      mentionUserIds: props.topBarData?.mentionUserIds || [],
      tempUserPool: [] as IUserPool[],
      tempAllUserPool: [] as IUserPool[],
      tempAllUserPoolSelect: [] as IAllUserPoolSelect[],
    },
  });

  useEffect(() => {
    if (props?.selectedContactUuid?.length) {
      setStateData((prev) => {
        return ({
          ...prev,
          formData: {
            ...prev.formData,
            selectedContactUuid: props.selectedContactUuid || []
          }
        })

      })
    }
  }, [props.selectedContactUuid])

  const [taskLabelData, setTaskLabelData] = useState<{
    taskLabelsList?: ILabelTask[];
    limit: number;
    offset: number;
    loading: boolean;
    searchString?: string;
    selectedLabels?: string[];
  }>({
    taskLabelsList: [],
    limit: 10,
    offset: 0,
    searchString: '',
    loading: false,
    selectedLabels: props?.topBarData?.labels
      ? props.topBarData.labels?.map(item => item?.uuid || '') || []
      : [],
  });

  const [customerData, setCustomerData] = useState<
    {
      value: string | undefined;
      label: string;
      key: string;
      contactData: any;
    }[]
  >([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [getTaskLabels] = useLazyQuery(GetLabelsByLabelTitleAndTypeId, {
    fetchPolicy: 'no-cache'
  });

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  async function fetchLabelsForBatch(batchIds: string[]) {
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds: batchIds,
        labelTypeId: labelMlov.id,
      },
    });
    return getLabelsRes?.data?.labels || [] as ILabelTask[];
  }

  const onSubmit = async () => {
    setSearchText("");
    setCustomerData(stateData.formData.selectedContactUuid)
    setTaskLabelData((prev) => {
      return { ...prev, searchString: "" };
    });
    let getLabelsDataRes = [] as ILabelTask[];
    if (taskLabelData.selectedLabels?.length) {
      getLabelsDataRes = await fetchLabelsForBatch(taskLabelData.selectedLabels);
    }
    props.onActionPerformed(
      COMMON_ACTION_CODES.SAVE,
      { ...stateData.formData, selectedLabels: getLabelsDataRes },
    );
  }

  useEffect(() => {
    if (props.selectedContactUuid) {
      setCustomerData(props.selectedContactUuid)
    }
  }, [props.selectedContactUuid])

  const [searchContacts] = useLazyQuery(
    LeadQueries.searchContacts,
    {
      onCompleted: (data: any) => {
        let contactsData = [];
        if (data?.searchContacts?.contacts?.length) {
          contactsData = data.searchContacts.contacts
            .filter((contactItem: any) => contactItem?.uuid)
            .map((contact: any) => {
              return {
                label: contact.name,
                value: contact?.uuid,
                key: contact?.uuid,
                contactData: contact,
              };
            });
        }
        setCustomerData(contactsData);
        setCustomerLoading(false);
      },
      onError: (error) => {
        setCustomerData([]);
        setCustomerLoading(false);
      },
    }
  );

  const onChangeCustomer = (searchText: string) => {
    if (searchText) onSearchCustomer(searchText);
  };

  const onSearchCustomer = async (searchText: string) => {
    try {
      setCustomerLoading(true);
      await searchContacts({
        variables: {
          params : {
            limit: 10,
            offset: 0,
            isActive: true,
            searchString: searchText,
            orderBy: {
              updated_at: 'desc',
            },
            ...(isMultiTenancyEnabled && { accountLocationIds: allowedAccountLocationIds }),
          }
        },
      });
    } catch (e) { }
  };

  useEffect(() => {
    const initialStatusType = [
      {
        ...acceptedStatusMlov,
        value: 'Pending'
      } as IMlov,
      {
        ...missedStatusMlov,
      } as IMlov,
      {
        ...completedStatusMlov,
      } as IMlov,
    ];
    const initialPriorityType = [
      highPriorityMlov,
      mediumPriorityMlov,
      lowPriorityMlov,
    ];
    const initialDueDateType = [
      {
        id: 1,
        dueDateType: overdueStatusValue,
        dueDateTypeUuid: overdueStatusUuid,
        label: overdueStatusValue,
      },
      {
        id: 2,
        dueDateType: todayStatusValue,
        dueDateTypeUuid: todayStatusUuid,
        label: todayStatusValue,
      },
      {
        id: 3,
        dueDateType: tomorrowStatusValue,
        dueDateTypeUuid: tomorrowStatusUuid,
        label: tomorrowStatusValue,
      },
      {
        id: 4,
        dueDateType: laterStatusValue,
        dueDateTypeUuid: laterStatusUuid,
        label: laterStatusValue,
      },
      {
        id: 5,
        dueDateType: dateRangeStatusValue,
        dueDateTypeUuid: dateRangeStatusUuid,
        label: dateRangeStatusValue,
      },
    ];

    setStateData((prev) => {
      return {
        ...prev,
        statusTypeList: initialStatusType,
        priorityTypeList: initialPriorityType,
        dueDateTypeList: initialDueDateType,
        selectedPriorityCodes: (props.selectedPriorityList?.map?.((mlov) => mlov.code) || []),
        selectedDueDateCodes: (props.selectedDueDateUuid || []),
        selectedStatusCodes: (props.selectedStatusList?.map?.((mlov) => mlov.code) || []),
        formData: {
          ...prev.formData,
          selectedStatusList: props.selectedStatusList
            ? props.selectedStatusList
            : [],
          selectedPriorityList: props.selectedPriorityList
            ? props.selectedPriorityList
            : [],
          selectedDueDate: props.selectedDueDateType
            ? props.selectedDueDateType
            : allStatusValue,
          selectedDueDateUuid: props.selectedDueDateUuid
            ? props.selectedDueDateUuid
            : allStatusUuid,
          selectedContactUuid: props.selectedContactUuid
            ? props.selectedContactUuid
            : [],
          selectedPatientLabel: props.selectedPatientLabel
            ? props.selectedPatientLabel
            : [],
          selectedPatientName: props.selectedPatientName
            ? props.selectedPatientName
            : [],
          selectedPools: props?.topBarData?.selectedPools
            ? Array.from(props.topBarData.selectedPools)
            : [],
        },
      };
    });
    setTaskLabelData(prev => {
      return {
        ...prev,
        taskLabelsList: props?.topBarData?.labels
          ? props.topBarData.labels
          : [],
        selectedLabels: props?.topBarData?.labels
          ? props.topBarData.labels?.map(item => item?.uuid || '') || []
          : [],
      }
    });
  }, [
    props.selectedStatusList,
    props.selectedPriorityList,
    props.selectedDueDateType,
    props.selectedPatientName,
    props.topBarData?.labels,
  ]);


  const allDueDateUuid = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDueDateCategory',
    'all'
  );

  const onSearchTaskLabel = async (searchString: string) => {
    try {
      const taskLabelParams = {
        limit: 10,
        offset: 0,
        labelTypeId: labelMlov.id,
        search: `%${searchString?.trim()}%`,
      };
      const apiResponse = await getTaskLabels({ variables: taskLabelParams });
      const taskLabels = apiResponse?.data?.labels || [];
      setTaskLabelData(prev => {
        return {
          ...prev,
          loading: false,
          taskLabelsList: taskLabels,
        }
      });
    } catch (error) {
      setTaskLabelData(prev => { return { ...prev, loading: false } });
    }
  };

  const onCreatedOrMentionChange = useCallback((selectedUser: IUserSearch[]) => {
    const isCreatedTabSelected = topBarData.selectedTab === TASK_TABS.CREATED;
    const isMentionTabSelected = topBarData.selectedTab === TASK_TABS.MENTIONED;
    const updatedTopBarData = {
      ...( isCreatedTabSelected  && { createdByIds: selectedUser } ),
      ...( isMentionTabSelected  && { mentionUserIds: selectedUser } ),
    }
    setStateData((prev) => {
      return {
        ...prev,
        formData: {
          ...prev.formData,
          ...updatedTopBarData,
        },
      };
    });
  }, [topBarData.selectedTab]);

  const onBoardTypeChange = useCallback((type: BoardType) => {
    props?.onActionPerformed && props?.onActionPerformed(COMMON_ACTION_CODES.SELECTED_BOARD_TYPE, {
      selectedBoardType: type,
    })
  }, [props?.onActionPerformed]);

  const renderAssigneeFields = () => {
    if (!isSidecarContext) {
      return props.children
    }
    const canShowBoardTypeSelector = topBarData.selectedTab &&
      ![TASK_TABS.TASK_POOL, TASK_TABS.CREATED, TASK_TABS.MENTIONED].includes(topBarData.selectedTab);
    return (
      <Stack space={16} direction={isSidecarContext ? 'column' : 'row'} style={styles.fullWidth}>
        {(taskContextDataLoading || taskPoolsLoading) && <Spinner size={'sm'} />}
        {(!taskContextDataLoading && !taskPoolsLoading && !!taskMasterUsers?.length) && (topBarData.selectedTab === TASK_TABS.CREATED || topBarData.selectedTab === TASK_TABS.MENTIONED) &&
          <UserAutoCompleteMultiSelect
            labelText={getLabelForAssigneeField(topBarData.selectedTab)}
            isMentionOrCreatedSelect={true}
            filterType={topBarData.selectedTab === TASK_TABS.CREATED ? TaskFilterType.CreatedBy : TaskFilterType.Mention}
            selectedValue={topBarData.selectedTab === TASK_TABS.CREATED ? topBarData.createdByIds : topBarData.mentionUserIds}
            onChange={onCreatedOrMentionChange}
            selectedTab={topBarData?.selectedTab}
            accountUsers={taskMasterUsers}
            taskAccessUsers={taskAccessUsers}
            manageAccessUsersTaskPools={manageAccessUsersTaskPools}
          />
        }
        {(!taskContextDataLoading && !taskPoolsLoading && !!taskMasterUsers?.length) && (
          <UserAutoCompleteMultiSelect
            labelText={'Assigned to'}
            selectedValue={stateData.formData.selectedUsers}
            onChange={(selectedUsers) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    selectedUsers: selectedUsers,
                  },
                };
              });
            }}
            onPoolChange={(newUserPool: IUserPool[]) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    tempUserPool: newUserPool,
                  },
                };
              });
            }}
            setAllUserPoolList={(allUsersPools: IUserPool[])=>{
              setStateData((prev) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    tempAllUserPool: allUsersPools,
                  },
                };
              });
            }}
            onAllUserPoolChange={(allUserPool: IAllUserPoolSelect[]) => {
              setStateData((prev) => {
                return {
                  ...prev,
                  formData: {
                    ...prev.formData,
                    tempAllUserPoolSelect: allUserPool,
                  },
                };
              });
            }}
            accountUsers={taskMasterUsers}
            taskAccessUsers={taskAccessUsers}
            manageAccessUsersTaskPools={manageAccessUsersTaskPools}
          />
        )}
        {canShowBoardTypeSelector && (
            <View marginBottom={isSidecarContext ? 2 : 0}>
            <BoardTypeSelector
              selectedType={topBarData.selectedBoardType || BoardType.Status}
              handleTypeChange={onBoardTypeChange}
            />
          </View>
        )}
      </Stack>
    );
  }

  const renderPool = ()=> {
    if (props.isPatientView) {
      return null;
    }
    return (
      <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        noJustify={isSidecarContext}
        maxNumberOfAppliedFiltersToshow={2}
        maxWidth={'80%'}
        marginBottom={isSidecarContext ? 8 : 0}
        popoverMaxHeight={300}
        onClose={() => {
          !isSidecarContext && onSubmit();
        }}
        label={"Task Pool"}
        value={stateData.formData.selectedPools?.map((item) => item.label)}
      >
        <View style={styles.container}>
          <View style={styles.searchContainer}>
            <Text fontWeight={500} style={styles.searchTitle}>
              Search Pool
            </Text>
            <Input
              placeholder={'Select Task Pool'}
              value={searchText}
              onChangeText={(text) => {
                setSearchText(text.toLowerCase());
              }}
              width={'100%'}
              {...testID(TestIdentifiers.selectTaskPool)}
            />
          </View>
          <View>
            {props.userPools?.length ?
              props.userPools?.filter((item: any) => item?.name?.toLowerCase()?.includes(searchText) ? true : false)?.map((item: any, index: number) => {
                const itemToAdd = {
                  "label": item.name,
                  "value": item.id,
                  "key": item.id,
                };
                return (
                  <Checkbox
                    key={`Filter_Task_Pool_${item.id}`}
                    value={item.id}
                    style={styles.checkbox}
                    alignItems={'center'}
                    accessibilityLabel={`checkbox-${item.name}`}
                    isChecked={stateData.formData?.selectedPools?.find(item => item.key == itemToAdd.key) ? true : false}
                    onChange={(isChecked) => {
                      const selectedPoolsRaw = stateData.formData.selectedPools;

                      if (isChecked) {
                        selectedPoolsRaw.push(itemToAdd);
                      } else {
                        const index = selectedPoolsRaw.findIndex(item => item.key == itemToAdd.key);
                        if (index > -1) {
                          selectedPoolsRaw.splice(index, 1);
                        }
                      }
                      setStateData((prev) => {
                        return {
                          ...prev,
                          formData: {
                            ...prev.formData,
                            selectedPools: selectedPoolsRaw
                          },
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smRegular'}
                      color={Colors.FoldPixel.GRAY400}
                      style={styles.checkboxText}
                    >
                      {item.name}
                    </Text>
                  </Checkbox>
                )
              }) :
              <Text style={styles.noDataText} size={'smMedium'}
                color={Colors.Custom.Gray700}>
                {intl.formatMessage({
                  id: 'noDataFound',
                })}
              </Text>
            }
          </View>
        </View>
        {/*
          <ModalActionAntSelect
            mode={'multiple'}
            allowClear={true}
            leftMargin={'0'}
            showSearch={true}
            onSearch={debounce(onChangeCustomer, 500)}
            labelInValue={true}
            filterOption={false}
            value={stateData.formData?.selectedPools}
            isRequired={false}
            notFoundContent={intl.formatMessage({
              id: 'NoDataSelect',
            })}
            loading={customerLoading}
            placeholder={'Select Task Pool'}
            // accessibilityLabel="Select Employer"
            onChange={(value: any) => {
              if (value) {
                setStateData((prev) => {
                  return {
                    ...prev,
                    formData: {
                      ...prev.formData,
                      selectedPools: value,
                    },
                  };
                });
              }
            }}
            data={props.userPools}
            optionProps={{ key: 'id', value: 'id', label: 'name' }}
            extraStyle={{ width: 300 }}
          /> */}
      </FilterWrapperComponent>
    )
  }

  const renderTaskLabel = () => {
    const selectedLabelsTitle = taskLabelData.selectedLabels?.map?.(item => {
      const title = taskLabelData.taskLabelsList?.find(subitem => subitem.uuid == item);
      return title?.title;
    })
    return (
      <FilterWrapperComponent
      isMultiSelect
      showFilterAsDropDown={isSidecarContext}
      dropdownMarginLeft={isSidecarContext ? 0 : undefined}
      maxWidth={'80%'}
      maxNumberOfAppliedFiltersToshow={4}
      noJustify={isSidecarContext}
      onClose={() => {
        !isSidecarContext && onSubmit();
      }} popoverMaxHeight={300} label={"Labels"} value={selectedLabelsTitle} marginBottom={isSidecarContext ? 8 : 0}>
        <View style={styles.container}>
          <View style={[
            styles.searchContainer,
            {
            borderBottomWidth: (taskLabelData?.taskLabelsList?.length && !taskLabelData.loading) || (taskLabelData.searchString && !taskLabelData.loading) || taskLabelData.loading ? 1 : 0,
            }
          ]}>
            <Text fontWeight={500} style={styles.searchTitle}>
              Search Label
            </Text>
            <Input
              placeholder={'Search Labels'}
              value={taskLabelData.searchString}
              onChangeText={(text) => {
                setTaskLabelData((prev) => {
                  return { ...prev, searchString: text?.trim(),  loading: text?.trim() ? true : false };
                });
                debounce((value) => {
                  onSearchTaskLabel(value);
                }, 500)(text)
              }}
              width={'100%'}
              {...testID(TestIdentifiers.searchLabels)}
            />
          </View>
          <View>
            {taskLabelData.loading && <Spinner marginTop={4}></Spinner>}
            {taskLabelData?.taskLabelsList?.length && !taskLabelData.loading?
              taskLabelData?.taskLabelsList?.map((item: ILabelTask, index: number) => {
                if (!item?.uuid) {
                  return null;
                }
                return (
                  <Checkbox
                    key={item.id}
                    value={item.id?.toString()}
                    style={styles.checkbox}
                    alignItems={'center'}
                    accessibilityLabel={`checkbox-${item.title}`}
                    isChecked={taskLabelData?.selectedLabels?.includes(item.uuid) ? true : false}
                    onChange={(isChecked) => {
                      if (!item?.uuid) {
                        return null;
                      }
                      const selectedLabelsRaw = taskLabelData?.selectedLabels || [];

                      if (isChecked) {
                        selectedLabelsRaw.push(item.uuid);
                      } else {
                        const index = selectedLabelsRaw.indexOf(item.uuid);
                        if (index > -1) {
                          selectedLabelsRaw.splice(index, 1);
                        }
                      }
                      setTaskLabelData((prev) => {
                        return {
                          ...prev,
                          selectedLabels: selectedLabelsRaw,
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smRegular'}
                      color={Colors.FoldPixel.GRAY400}
                      style={styles.checkboxText}
                    >
                      {item.title}
                    </Text>
                  </Checkbox>
                )
              }) : taskLabelData.searchString && !taskLabelData.loading ?
              <Text style={styles.noDataText} size={'smMedium'}
                color={Colors.Custom.Gray700}>
                {intl.formatMessage({
                  id: 'noDataFound',
                })}
              </Text> : null
            }
          </View>
        </View>

        {/* <Select
          mode="multiple"
          allowClear
          placeholder="Search Labels"
          showSearch={true}
          value={
            taskLabelData.selectedLabels?.length
              ? taskLabelData.selectedLabels
              : []
          }
          filterOption={false}
          notFoundContent={intl.formatMessage({
            id: 'NoDataSelect',
          })}
          onChange={(selectedLabels) => {
            const tempData = selectedLabels?.filter((item: string) => {
              return item != 'noData';
            });
            setTaskLabelData((prev) => {
              return {
                ...prev,
                selectedLabels: tempData,
              };
            });
          }}
          onSearch={debounce((value) => {
            setTaskLabelData((prev) => {
              return { ...prev, searchString: value?.trim() };
            });
            onSearchTaskLabel(value);
          }, 500)}
          loading={taskLabelData.loading}
          style={{ width: 200, height: 40 }}
        >
          {getLabelOptions()}
        </Select> */}
      </FilterWrapperComponent>
    );
  };

  const getDueDateValue = () => {
    if (stateData.formData?.selectedDueDate === 'All') {
      return [];
    }
    if (stateData.formData.selectedDueDate.includes(dateRangeStatusValue)) {
      const startDate = stateData.formData?.start || '';
      const endDate = stateData.formData?.end || '';
      const value = dateRangeStatusValue + ': ' + startDate + ' - ' + endDate;
      return [value];
    }
    return stateData.formData?.selectedDueDate || [];
  }

  const renderFilterView = (
    <Stack direction={isSidecarContext ? 'column' : 'row'} space={isSidecarContext ? 8 : undefined} style={isSidecarContext ? styles.filterView : {}}>
      {topBarData.selectedTab !== TASK_TABS.TASK_POOL && renderAssigneeFields()}
      {topBarData.selectedTab === TASK_TABS.TASK_POOL && renderPool()}
      {topBarData.selectedTab === TASK_TABS.TASK_POOL && renderAssigneeFields()}
      {!props.isPatientView &&
        (isEmployerRole() ? isEmployerOwnerShowFilterByContact() : true) && (
        <FilterWrapperComponent
          isMultiSelect
          showFilterAsDropDown={isSidecarContext}
          dropdownMarginLeft={isSidecarContext ? 0 : undefined}
          maxWidth={'80%'}
          maxNumberOfAppliedFiltersToshow={2}
          noJustify={isSidecarContext}
          marginBottom={isSidecarContext ? 8 : 0} onClose={() => {
            !isSidecarContext && onSubmit();
          }} label={"Members"} value={stateData?.formData?.selectedContactUuid?.map((item) => item.label)} >
            <View style={styles.memberFilterWrapper}>
              <View style={styles.memberFilterHeader}>
                <Text fontWeight={500} style={styles.memberFilterTitle}>
                  Select Member
                </Text>
                <Input
                  placeholder={'Select Members'}
                  onChangeText={(searchText) => {
                    if(searchText){
                      setCustomerLoading(true)
                    }
                    setSearchText(searchText);
                    debounce((searchText) => onChangeCustomer(searchText), 500)(searchText)
                  }}
                  width={'100%'}
                  value={searchText}
                  {...testID(TestIdentifiers.selectMembers)}
                />
              </View>
              {customerLoading && <Spinner marginTop={4}></Spinner>}
              {!customerLoading && <View style={styles.memberList}>
                {customerData?.length ?
                  customerData?.map((item, index: number) => {
                    return (
                      <Checkbox
                        key={item.key}
                        value={item.value || ""}
                        style={styles.checkboxItem}
                        accessibilityLabel={`checkbox-${item.label}`}
                        alignItems={'center'}
                        isChecked={stateData?.formData?.selectedContactUuid?.find((subitem: any) => subitem.key == item.key) ? true : false}
                        onChange={(isChecked) => {
                          const value = stateData.formData.selectedContactUuid;
                          if (isChecked) {
                            value.push(item as any);
                          } else {
                            const index = value.findIndex((subitem: any) => subitem.key == item.key);
                            if (index > -1) {
                              value.splice(index, 1);
                            }
                          }

                          if (value) {
                            const labelArray: string[] = [];
                            value.map((valueItem: any) => {
                              (valueItem?.label?.props?.contactData?.name ||
                                valueItem?.label) &&
                                labelArray.push(
                                  valueItem?.label?.props?.contactData?.name ||
                                  valueItem?.label
                                );
                            });
                            const newContactDataArray = value.reduce(
                              (acc: any, item: any) => {
                                const id = item.key;
                                const foundContact = contactsDataState.find(
                                  (contact: any) => contact.key === id
                                );
                                if (foundContact) {
                                  acc.push(foundContact);
                                } else {
                                  const customerInfo = customerData.find(
                                    (customer) => customer.key === id
                                  );
                                  if (customerInfo) {
                                    acc.push({
                                      key: customerInfo.key,
                                      label: customerInfo.label,
                                      value: customerInfo.value,
                                      contactData: customerInfo.contactData,
                                    });
                                  }
                                }
                                return acc;
                              },
                              []
                            );
                            setStateData((prev) => {
                              return {
                                ...prev,
                                formData: {
                                  ...prev.formData,
                                  selectedContactUuid: value,
                                  selectedPatientName: labelArray,
                                },
                              };
                            });
                            setContactsData(newContactDataArray);
                            setContactsDataState(newContactDataArray);
                          }
                        }}

                      >
                        <View style={styles.checkboxItemContent}>
                          <MemberInfoListItem
                            contactData={item.contactData}
                            showDateOfBirth={true}
                            isPatientSearchCard={true}
                          />
                        </View>
                      </Checkbox>
                    )
                  }) :
                  searchText ?
                    <Text style={styles.noDataText} size={'smMedium'}
                      color={Colors.Custom.Gray700}>
                      {intl.formatMessage({
                        id: 'noDataFound',
                      })}
                    </Text> : <Text style={styles.noDataText} size={'smMedium'}
                      color={Colors.FoldPixel.GRAY400}>
                      {intl.formatMessage({
                        id: 'NoDataSelect',
                      })}
                    </Text>
                }
              </View>}
            </View>
          </FilterWrapperComponent>
        )}
      {!props.isPatientView && (
        <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        maxNumberOfAppliedFiltersToshow={3}
        maxWidth={'80%'}
        noJustify={isSidecarContext}
        marginBottom={isSidecarContext ? 8 : 0} onClose={() => {
          !isSidecarContext && onSubmit();
        }} label={"Status"} value={stateData.selectedStatusCodes.map(statusCode => stateData.statusTypeList.find(status => status.code === statusCode)?.value)} >
          {stateData.statusTypeList.length ? (
            <View style={styles.statusList}>
              {stateData.statusTypeList.map((mlov) => {
                return (
                  <Checkbox
                    key={mlov.id}
                    value={mlov.code}
                    style={styles.statusItem}
                    alignItems={'center'}
                    accessibilityLabel={`checkbox-${mlov.value}`}
                    isChecked={stateData.selectedStatusCodes?.includes(
                      mlov.code
                    )}
                    onChange={(isChecked) => {
                      setStateData((stateData) => {
                        const selectedStatusCodes =
                          stateData.selectedStatusCodes || [];
                        if (isChecked) {
                          if (!selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.push(mlov.code);
                          }
                        } else {
                          if (selectedStatusCodes.includes(mlov.code)) {
                            selectedStatusCodes.splice(
                              selectedStatusCodes.indexOf(mlov.code),
                              1
                            );
                          }
                        }

                        const selectedStatusList: IMlov[] = [];
                        selectedStatusCodes?.length &&
                          stateData.statusTypeList?.forEach?.((status) => {
                            if (
                              selectedStatusCodes.indexOf?.(status.code) !==
                              -1
                            ) {
                              selectedStatusList.push(status);
                            }
                          });

                        return {
                          ...stateData,
                          selectedStatusCodes: [...selectedStatusCodes],
                          formData: {
                            ...stateData.formData,
                            selectedStatusList: [...selectedStatusList],
                          },
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smRegular'}
                      color={Colors.FoldPixel.GRAY400}
                      style={styles.statusItemText}
                    >
                      {mlov.value}
                    </Text>
                  </Checkbox>
                );
              })}
            </View>
          ) : undefined}
        </FilterWrapperComponent>
      )}

      {!props.isPatientView && (
        <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        maxNumberOfAppliedFiltersToshow={3}
        maxWidth={'80%'}
        noJustify={isSidecarContext}
        marginBottom={isSidecarContext ? 8 : 0} onClose={() => {
          !isSidecarContext && onSubmit();
        }} label={"Priority"} value={stateData.formData.selectedPriorityList?.map(item => item.value)} >
          {stateData.priorityTypeList.length ? (
            <View style={styles.priorityList}>
              {stateData.priorityTypeList.map((mlov) => {
                return (
                  <Checkbox
                    key={mlov.id}
                    value={mlov.code}
                    style={styles.checkboxItem}
                    alignItems={'center'}
                    isChecked={stateData.selectedPriorityCodes?.includes(mlov.code)}
                    accessibilityLabel={`checkbox-${mlov.value}`}
                    onChange={(isChecked) => {
                      setStateData((stateData) => {
                        const selectedPriorityCodes =
                          stateData.selectedPriorityCodes || [];
                        if (isChecked) {
                          if (!selectedPriorityCodes.includes(mlov.code)) {
                            selectedPriorityCodes.push(mlov.code);
                          }
                        } else {
                          if (selectedPriorityCodes.includes(mlov.code)) {
                            selectedPriorityCodes.splice(
                              selectedPriorityCodes.indexOf(mlov.code),
                              1
                            );
                          }
                        }

                        const selectedPriorityList: IMlov[] = [];
                        selectedPriorityCodes?.length &&
                          stateData.priorityTypeList?.forEach?.(
                            (status) => {
                              if (
                                selectedPriorityCodes.indexOf?.(
                                  status.code
                                ) !== -1
                              ) {
                                selectedPriorityList.push(status);
                              }
                            }
                          );

                        return {
                          ...stateData,
                          selectedPriorityCodes: [...selectedPriorityCodes],
                          formData: {
                            ...stateData.formData,
                            selectedPriorityList: [...selectedPriorityList],
                          },
                        };
                      });
                    }}
                  >
                    <Text
                      size={'smRegular'}
                      color={Colors.FoldPixel.GRAY400}
                      style={styles.checkboxText}
                    >
                      {mlov.value}
                    </Text>
                  </Checkbox>
                );
              })}
            </View>
          ) : undefined}
        </FilterWrapperComponent>
      )}

      {!props.isPatientView && (
        <FilterWrapperComponent
        isMultiSelect
        showFilterAsDropDown={isSidecarContext}
        dropdownMarginLeft={isSidecarContext ? 0 : undefined}
        maxNumberOfAppliedFiltersToshow={4}
        maxWidth={'80%'}
        noJustify={isSidecarContext}
        marginBottom={isSidecarContext ? 8 : 0}
        onClose={() => {
          !isSidecarContext && onSubmit();
        }}
        label={"Due Date"}
        popoverMaxHeight={300}
        value={getDueDateValue()} >
          {stateData.dueDateTypeList.length && (
            <View style={styles.dueDateListContainer}>
              {stateData.dueDateTypeList.map((type) => {
                return (
                  <Checkbox
                    key={type.dueDateTypeUuid}
                    value={type.label}
                    style={styles.dueDateCheckbox}
                    alignItems={'center'}
                    accessibilityLabel={`checkbox-${type.dueDateType}`}
                    isChecked={stateData.selectedDueDateCodes?.includes(
                      type.dueDateTypeUuid
                    )}
                    onChange={(isChecked) => {
                      if (type.dueDateTypeUuid === dateRangeStatusUuid) {
                        if (isChecked) {
                          setStateData((stateData) => {
                            return {
                              ...stateData,
                              selectedDueDateCodes: [type.dueDateTypeUuid],
                              formData: {
                                ...stateData.formData,
                                selectedDueDate: [type.label],
                                selectedDueDateUuid: [type.dueDateTypeUuid],
                              },
                            };
                          });
                        } else {
                          setStateData((stateData) => {
                            return {
                              ...stateData,
                              selectedDueDateCodes: [allDueDateUuid],
                              formData: {
                                ...stateData.formData,
                                selectedDueDate: [],
                                selectedDueDateUuid: [allDueDateUuid],
                              },
                            };
                          });
                        }
                      } else {
                        setStateData((stateData) => {
                          let selectedDueDateCodes =
                            stateData.selectedDueDateCodes || [];
                          if (isChecked) {
                            if (
                              !selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              if (
                                selectedDueDateCodes.includes(
                                  dateRangeStatusUuid
                                )
                              ) {
                                selectedDueDateCodes = [type.dueDateTypeUuid];
                              } else {
                                if (Array.isArray(selectedDueDateCodes)) {
                                  selectedDueDateCodes.push(
                                    type.dueDateTypeUuid
                                  );
                                } else {
                                  selectedDueDateCodes = [
                                    type.dueDateTypeUuid,
                                  ];
                                }
                              }
                            }
                          } else {
                            if (
                              selectedDueDateCodes.includes(
                                type.dueDateTypeUuid
                              )
                            ) {
                              selectedDueDateCodes.splice(
                                selectedDueDateCodes.indexOf(
                                  type.dueDateTypeUuid
                                ),
                                1
                              );
                            }
                          }

                          const selectedDueDateType: string[] = [];
                          selectedDueDateCodes?.length &&
                            stateData.dueDateTypeList?.forEach?.(
                              (dueDateType) => {
                                if (
                                  selectedDueDateCodes.indexOf?.(
                                    dueDateType.dueDateTypeUuid
                                  ) !== -1
                                ) {
                                  selectedDueDateType.push(dueDateType.label);
                                }
                              }
                            );
                          if (
                            selectedDueDateCodes.length > 1 &&
                            selectedDueDateCodes.includes(allDueDateUuid)
                          ) {
                            selectedDueDateCodes =
                              selectedDueDateCodes.filter(
                                (code) => code !== allDueDateUuid
                              );
                          }
                          if (selectedDueDateCodes.length === 0) {
                            selectedDueDateCodes.push(allDueDateUuid);
                          }

                          return {
                            ...stateData,
                            selectedDueDateCodes: [...selectedDueDateCodes],
                            formData: {
                              ...stateData.formData,
                              selectedDueDate: [...selectedDueDateType],
                              selectedDueDateUuid: [...selectedDueDateCodes],
                            },
                          };
                        });
                      }
                    }}
                  >
                    <Text
                      size={'smRegular'}
                      color={Colors.FoldPixel.GRAY400}
                      style={styles.checkboxText}
                    >
                      {type.dueDateType}
                    </Text>
                  </Checkbox>
                );
              })}
            </View>
          )}

          {(stateData.formData.selectedDueDateUuid.includes('date_range') ||
            props.isPatientView) && (
              <HStack>
                <VStack space={1}>
                  <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                    From Date
                  </Text>
                  <DatePicker
                    style={{
                      height: '44px',
                      width: 125,
                      borderColor: Colors.Custom.Gray300,
                      borderRadius: 8,
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                    disabledDate={(date) => {
                      const currentDate = getMomentObj(new Date()).add(
                        14,
                        'days'
                      );
                      return date && date.valueOf() > currentDate.valueOf();
                    }}
                    value={
                      stateData.formData.start
                        ? moment(
                          stateData.formData.start,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                        : undefined
                    }
                    // disabledDate={(current: moment.Moment) => {
                    //   return current && isPastDay(current);
                    // }}
                    format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                    onChange={(dates: any, formatStrings: string) => {
                      if (formatStrings && formatStrings.length > 1) {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              start: formatStrings,
                              end: stateData.formData.end,
                            },
                          };
                        });
                      }
                    }}
                  />
                </VStack>
                <VStack space={1} style={styles.toDateContainer}>
                  <Text fontSize={14} color={'gray.700'} fontWeight={500} ml={3}>
                    To Date
                  </Text>
                  <DatePicker
                    style={{
                      height: '44px',
                      width: 125,
                      borderColor: Colors.Custom.Gray300,
                      borderRadius: 8,
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                    disabledDate={(date) => {
                      const currentDate = getMomentObj(new Date()).add(
                        14,
                        'days'
                      );
                      return date && date.valueOf() > currentDate.valueOf();
                    }}
                    value={
                      stateData.formData.end
                        ? moment(
                          stateData.formData.end,
                          DATE_FORMATS.DISPLAY_DATE_FORMAT
                        )
                        : undefined
                    }
                    format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                    onChange={(dates: any, formatStrings: string) => {
                      if (formatStrings && formatStrings.length > 1) {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            formData: {
                              ...prev.formData,
                              end: formatStrings,
                              start: stateData.formData.start,
                            },
                          };
                        });
                      }
                    }}
                  />
                </VStack>
              </HStack>
            )}
        </ FilterWrapperComponent>
      )}

      {topBarData.selectedTab !== TASK_TABS.TASK_POOL && renderPool()}

      {renderTaskLabel()}

    </Stack>
  )

  return (
    isSidecarContext ?
      <Stack direction={'column'} style={styles.container100}>
        <Stack direction={'column'} style={styles.columnStack}>
          <Stack direction={'row'} style={styles.rowStack}>
            <Text style={styles.filterText}>
              {intl.formatMessage({
                id: 'filters',
              })}
            </Text>
            <View style={styles.spacer}></View>
            <FoldButtonV2
              key={'clearAll'}
              label={'Clear All'}
              buttonType={ButtonType.secondary}
              onPress={() => {
                props?.onActionPerformed(COMMON_ACTION_CODES.CLEAR_ALL, {})
              }}
            />
            <FoldButtonV2
              key={'apply'}
              label={intl.formatMessage({ id: 'apply' })}
              buttonType={ButtonType.secondary}
              onPress={onSubmit}
            />
            <View style={styles.divider} backgroundColor={Colors.Custom.Gray200} />
            <Pressable
              onPress={props.onCloseFilterView}
            >
              <CrossIcon />
            </Pressable>
          </Stack>
          <Divider style={{ marginTop: 6, marginBottom: 12}} />
        </Stack>
          {renderFilterView}
      </Stack>
      :
      <div className={'task-filters'}>
        {renderFilterView}
      </div>
  );
};

export default FilterRowView;
