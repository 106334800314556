import { DatePicker } from 'antd';
import { Button, Center, Spinner, View } from 'native-base';
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState, useRef } from 'react';
import "./powerbi.css";
import { IPowerBIDataSelection, IPowerBIProps } from './interface';
import { IPowerBITokenResp } from '../../../../../services/PowerBI/interface';
import { getPowerBITokenInfoForReport, getPowerBIWorkspaces} from '../../../../../services/PowerBI/PowerBIService';
import { NoDataFound } from '../../../../common/NoDataFound';
import { Dimensions } from 'react-native';
import { PowerBiReportMode } from '../../../BrazilCodeMR/pages/analytics/AnalyticsController';
import {applyDateRangeFilter, getVisualFilterTableName} from './PowerBiUtils';
import {POWER_BI_CUSTOM_TABLE_COLUMN, POWER_BI_ENTITY_TYPE_CODE, POWER_BI_FORMS_TABLE_LIST} from './powerBiConst';
import {useLazyQuery} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {LeadQueries, TaskQueries} from '../../../../../services';
import {ITask} from '../../../../common/CareDashboard/CareDashboardInterfaces';
import AddOrUpdateTask from '../../../../common/AddTask/AddOrUpdateTask';
import {IContact} from '../../../TeamInbox/Conversations/interfaces';
import MessagingContactDetailsDrawer from '../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';



const PowerBiView = (props: IPowerBIProps) => {
  const { height } = Dimensions.get('window');
  const [powerBiViewState, setPowerBiViewState] = useState({
    tokenInfo: {} as IPowerBITokenResp,
    isLoading: true,
    isDrawerLoading: false,
    isError: false,
  });
  const [selectedTaskDetail, setSelectedTaskDetail] = useState<{
    showTaskDrawer: boolean;
    task?: ITask;
  }>({
    showTaskDrawer: false,
  });
  const [selectedContactDetail, setSelectedContactDetail] = useState<{
    showContactDetailDrawer: boolean;
    contactDetailData?: IContact;
  }>({
    showContactDetailDrawer: false,
  });
  const lastEventRef = useRef<{ eventCode: string; eventId: string | null }>({ eventCode: '', eventId: null });
  const [getTasks] = useLazyQuery(TaskQueries.GET_TASKS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const [getContactsByUuid] = useLazyQuery(LeadQueries.getContactsByUuid, {
    fetchPolicy: 'no-cache',
  });
  const getPowerBiEmbedTokenInfoFromAPI = async () => {
    setPowerBiViewState((prev) => {
      return {
        ...prev,
        isLoading: true,
        isError: false,
        tokenInfo: {} as any
      }
    });
    const powerBiReport = props?.selectedPowerBiWorkspaceReport;
    let embedTokenInfo: any;
    if (powerBiReport?.tokenData?.reportId) {
      embedTokenInfo = powerBiReport?.tokenData;
    } else {
      const embedTokenInfoResp = await getPowerBITokenInfoForReport({
        reportId: powerBiReport?.powerBiReportId,
        workspaceId: powerBiReport?.powerBiWorkspaceId
      }).catch(() => {
        setPowerBiViewState((prev) => {
          return {
            ...prev,
            isError: true,
            isLoading: false
          }
        });
      });
      embedTokenInfo = embedTokenInfoResp?.data;
    }
    if (embedTokenInfo?.accessToken && embedTokenInfo?.embedUrl) {
      setPowerBiViewState((prev) => {
        return {
          ...prev,
          isLoading: false,
          tokenInfo: embedTokenInfo
        }
      });
    }
  };

  const updatePowerBIPageFilter = async () => {
    if(props?.selectedPowerBiWorkspaceReport?.entityType === POWER_BI_ENTITY_TYPE_CODE.TASKS) {
      return;
    }
    try {
      const pages = await (window as unknown as { report: any }).report.getPages();
      const activePage = pages.filter(function (page: { isActive: boolean }) {
        return page.isActive
      })[0];

      const visuals = await activePage.getVisuals();
      for (const visual of visuals) {
          const filters = await visual.getFilters();
          if (filters && filters?.length) {
            const tableName = getVisualFilterTableName(filters);
            let formId = '';
            const employerId = props?.filters?.selectedEmployerData?.id;
            if (POWER_BI_FORMS_TABLE_LIST.includes(tableName)) {
              formId = props?.filters?.selectedFormData?.id;
            }
            if (tableName) {
              await applyDateRangeFilter(props?.filters?.dateRange?.fromDate, props?.filters?.dateRange?.toDate, visual, tableName, formId, employerId);
            }
          }

      }
    }
    catch (errors) {
    }
  }

  const getTaskDetailsDrawer = () => {
    if (selectedTaskDetail?.showTaskDrawer) {
      return (
        <AddOrUpdateTask
          task={selectedTaskDetail?.task}
          isVisible={selectedTaskDetail.showTaskDrawer}
          onCancel={() => {
            setSelectedTaskDetail((prev) => {
              return {
                showTaskDrawer: false,
                task: undefined,
              };
            });
          }}
          onComplete={(
            data: any,
            category?: string,
            existCategory?: string
          ) => {
            setSelectedTaskDetail((prev) => {
              return {
                showTaskDrawer: false,
                task: undefined,
              };
            });
          }}
        />
      );
    }
    return <></>;
  };

  const getContactDetailsDrawer = () => {
    if (selectedContactDetail?.showContactDetailDrawer && selectedContactDetail?.contactDetailData) {
      return (
        <MessagingContactDetailsDrawer
          contactData={selectedContactDetail?.contactDetailData}
          isDrawerVisible={selectedContactDetail?.showContactDetailDrawer ? true : false}
          contactType={selectedContactDetail?.contactDetailData?.contactType}
          onSideBarActionPerformed={(action?: any) => {
            setSelectedContactDetail((prev) => {
              return {
                showContactDetailDrawer: false,
                contactDetailData: undefined,
              };
            });
          }}
        />
      );
    }
    return <></>;
  };


  React.useEffect(() => {
    getPowerBiEmbedTokenInfoFromAPI();
  }, [props?.selectedPowerBiWorkspaceReport?.id])

  React.useEffect(() => {
    updatePowerBIPageFilter();
  }, [props?.filters?.dateRange?.fromDateStr, props?.filters?.dateRange?.toDateStr,  props?.filters?.selectedFormData?.id, props?.filters?.selectedEmployerData?.id])




  React.useEffect(() => {
    if (props?.powerBiReportMode === PowerBiReportMode.EDIT) {
      (window as unknown as { report: any }).report?.switchMode("edit");
    } else if (props?.powerBiReportMode === PowerBiReportMode.VIEW) {
      (window as unknown as { report: any }).report?.switchMode("view");
    }
  }, [props?.powerBiReportMode])

  const messageEventListener = (eventData: any) => {
    try {
      if (typeof eventData?.data === 'string') {
        const data: any = JSON.parse(eventData?.data);
        if (data.eventId !== lastEventRef.current.eventId) {
          lastEventRef.current = { eventCode: data.eventCode, eventId: data.eventId };
          if (data.eventCode === 'SELECTED_CONTACTS') {
            if (data?.eventData?.selectedContactIds?.length) {
              const selectedContactIds = [...new Set(data?.eventData?.selectedContactIds)] as string[];
              props?.onDrillThrowSelection({
                  contactUuids: selectedContactIds
              })
            } else if (data?.eventData?.selectedContactIds?.length === 0) {
              props?.onDrillThrowSelection({
                contactUuids: []
            })
            }
          } else if (data.eventCode === 'POWER_BI_CUSTOM_TABLE_CELL_CLICKED') {
            openDetailsDrawerFromSelectedColumnKey(data);
          }
        }
      }
    } catch (error) {
        console.error('Error parsing message data:', error);
    }
  };

  const openDetailsDrawerFromSelectedColumnKey = async (data: any) => {
    if (data?.eventData?.columnKey?.toLowerCase() === POWER_BI_CUSTOM_TABLE_COLUMN.TaskDetails) {
      try {
        const columnData = JSON.parse(data.eventData.columnData);
        if (columnData.task_id) {
          const taskId = columnData.task_id;
          setPowerBiViewState((prev) => {
            return {
              ...prev,
              isDrawerLoading: true,
            }
          });
          const getTasksData = await getTasks({
            variables: {
              params: {
                ids: [taskId],
                orderBy: {
                  createdOn: 'desc',
                }
              },
            },
          });
          setPowerBiViewState((prev) => {
            return {
              ...prev,
              isDrawerLoading: false,
            }
          });
          if (getTasksData?.data) {
            const singleTask = getTasksData?.data?.getTasks?.tasks[0];
            setSelectedTaskDetail((prev) => {
              return {
                task: singleTask,
                showTaskDrawer: true
              };
            });
          }
        }
      } catch (error) {
        console.error('Error processing task details:', error);
      }
    } else if (data?.eventData?.columnKey?.toLowerCase() === POWER_BI_CUSTOM_TABLE_COLUMN.ContactDetails) {
      try {
        const columnData = JSON.parse(data.eventData.columnData);
        if (columnData.contact_id) {
          const contactId = columnData.contact_id;
          setPowerBiViewState((prev) => {
            return {
              ...prev,
              isDrawerLoading: true,
            }
          });
          const response = await getContactsByUuid({
            variables: {
              contactUuidList: [contactId],
              limit: 1,
              offset: 0,
            }
          });
          const contactList = response.data.contacts || [];
          setPowerBiViewState((prev) => {
            return {
              ...prev,
              isDrawerLoading: false,
            }
          });
          if (contactList[0]?.id) {
            const singleContactData = contactList[0];
            setSelectedContactDetail((prev) => {
              return {
                contactDetailData: singleContactData,
                showContactDetailDrawer: true
              };
            });
          }
        }
      } catch (error) {
        console.error('Error processing contact details:', error);
      }
    }
  };



  React.useEffect(() => {
    if (window?.addEventListener && typeof window?.addEventListener === 'function') {
      window.addEventListener('message', messageEventListener);
    }

    return ()=> {
      if (window?.addEventListener && typeof window?.addEventListener === 'function') {
        window.removeEventListener('message', messageEventListener);
      }
    }
  }, [])

  const getPowerBIReportSettings = () => {
    if(props?.selectedPowerBiWorkspaceReport?.additionalAttributes?.zoomLevel) {
      return {
        zoomLevel: props?.selectedPowerBiWorkspaceReport?.additionalAttributes?.zoomLevel,
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
      }
    } else {
      return {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
      }
    }
  }

  return (
    <View nativeID="empMainSection">
      {powerBiViewState.isLoading && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      {powerBiViewState.isDrawerLoading && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      {!powerBiViewState.isLoading && powerBiViewState.isError && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <NoDataFound />
          </Center>
        </View>
      )}

      <View style={{
        height: height - 140
      }}>
        {!powerBiViewState.isLoading && powerBiViewState?.tokenInfo?.embedUrl && powerBiViewState?.tokenInfo?.accessToken && (
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, and qna.
              id: powerBiViewState?.tokenInfo?.reportId,
              embedUrl: powerBiViewState?.tokenInfo?.embedUrl,
              accessToken: powerBiViewState?.tokenInfo?.accessToken,
              tokenType: models?.TokenType?.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
              viewMode: props?.powerBiReportMode === PowerBiReportMode?.EDIT ? 1 : 0,
              settings: getPowerBIReportSettings(),
              permissions: models?.Permissions?.ReadWrite
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  async function () {
                    updatePowerBIPageFilter();
                  },
                ],
                [
                  "rendered",
                  function (test, test2) {
                  },
                ],
                [
                  "dataSelected",
                  function (event) {
                    const eventData: IPowerBIDataSelection = event?.detail;
                    if(eventData?.dataPoints?.length) {
                      let selectedContactUuids = [] as any;
                      eventData?.dataPoints.forEach((singleData) => {
                        if (singleData?.identity.length) {
                          singleData?.identity?.forEach((singleData) => {
                            if (singleData?.target?.table === 'obt_contacts' && singleData?.target?.column === 'uuid' && singleData?.equals) {
                              selectedContactUuids.push(singleData?.equals);
                            } else if (singleData?.target?.column === 'contact_uuid' && singleData?.equals) {
                              selectedContactUuids.push(singleData?.equals);
                            }
                          });
                        }
                      });
                      if (selectedContactUuids?.length) {
                        selectedContactUuids = [...new Set(selectedContactUuids)];
                        props?.onDrillThrowSelection({
                          contactUuids: selectedContactUuids
                        })
                      }
                    }

                  },
                ],
                [
                  "visualClicked",
                  function (test) {
                  },
                ],
                [
                  "visualRendered",
                  function (test) {
                  },
                ],
                [
                  "error",
                  function (event) {
                  },
                ],
                [
                  "buttonClicked",
                  function (event) {
                    console.log(event);
                    console.log('buttonClicked');
                    if (event?.detail?.type?.toLowerCase() === 'back') {
                      props?.onDrillThrowSelection({
                        contactUuids: []
                      })
                    }
                  },
                ],
              ])
            }
            cssClassName={"embed-container"}
            getEmbeddedComponent={(embeddedReport) => {
              (window as unknown as { report: any }).report = embeddedReport;
            }}
          />
        )}
      </View>
      {getTaskDetailsDrawer()}
      {getContactDetailsDrawer()}
    </View>

  )
}
export default PowerBiView;
