import React, {useCallback, useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {Text} from 'native-base';
import {useQuery} from '@apollo/client';
import {TaskQueries} from '../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {ITasksData} from '../CareDashboard/CareDashboardInterfaces';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {getMlovListFromCategory} from '../../../utils/mlovUtils';
import {EVENT_NAMES, MLOV_CATEGORY} from '../../../constants';
import {getCompletedTaskStatusId} from '../Tasks/TasksUtils/TasksUtils';
import {HStack, Skeleton} from 'native-base';
import InternalTaskDrawer, { ActionableTab } from './InternalTaskDrawer';
import {Colors} from '../../../styles';
import TaskIconV2 from '../../../assets/Icons/TaskIconV2';
import {EventBus} from '../../../utils/EventBus';
import { GET_AGGREGATE_TASK_COUNT } from '../../../services/Task/TaskQueries';
import AlertSeverityIcon from '../../../assets/Icons/AlertSeverityIcon';
import { TASK_PRIORITY_CODES } from '../../../constants/MlovConst';
import { getUserUUID } from '../../../utils/commonUtils';
import BellSvg from '../../../assets/Icons/BellSvg';

interface IPatientListInternalTaskProps {
  contactId: string;
  onActionableClick: (actionableTab: ActionableTab) => void;
  alertDisplayCategoryId: string;
  dayOptimizerView?: boolean
}

const PatientListInternalTask = (props: IPatientListInternalTaskProps) => {
  const contextData = useContext(CommonDataContext);
  const userUuid = getUserUUID();
  const taskStatusMlov =
    getMlovListFromCategory(
      contextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];

  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);

  const contactId = props.contactId;

  const getQueryObject = useCallback(
    () => ({
      assigneeIds: [],
      contactIds: [contactId],
      assigneeIdsNotIn: [contactId],
      statusIdsNotIn: [completedStatusId],
      orderBy: {
        endDateTime: 'asc',
      },
    }),
    [contactId]
  );

  const onEventReceived = () => {
    // Do something
    taskApiRes.refetch();
  }

  useEffect(() => {
     let mounted = true;
     const eventBus = EventBus.getEventBusInstance();
     const code = `${EVENT_NAMES.REFRESH_TASK}_${contactId}`;
     eventBus.addEventListener(
       code,
       onEventReceived
     );
     return () => {
       eventBus.removeEventListener(onEventReceived);
       const clear = async () => {
         mounted = false;
       };
       clear();
     };
  },[])

  const taskApiRes = useQuery<{
    getTasks: ITasksData;
  }>(TaskQueries.GET_CARE_DASHBOARD_TASKS, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
    variables: getQueryObject(),
  });

  const alertApiRes = useQuery(
    GET_AGGREGATE_TASK_COUNT,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT,},
      variables: {
        params: {
          assigneeIds: [userUuid],
          includeOnlyAlerts: true,
          contactIds: [contactId],
          taskAlertDisplayCategoryIds: [props?.alertDisplayCategoryId],
        },
      },
    }
  );

  if (taskApiRes.loading || alertApiRes.loading) return <Skeleton.Text lines={1} />;
  

  const totalTask = taskApiRes.data?.getTasks?.aggregate?.total ?? 0;
  const totalAlerts = alertApiRes.data?.getTasks?.aggregate?.total ?? 0;
  const tasks = taskApiRes.data?.getTasks?.tasks;

  if (((totalTask === 0 && totalAlerts === 0) || (taskApiRes.error && alertApiRes.error)) && !props.dayOptimizerView) {
    return <Text>-</Text>;
  }

  const getTaskCountView = () => {
    if (!taskApiRes.error && tasks?.length) {
      return (
        <Text
            size={'smRegular'}
            onPress={() => props.onActionableClick(ActionableTab.TASKS)}
            style={{
              color: Colors.Custom.mainPrimaryPurple,
              paddingVertical: 10
            }}
          >
            <TaskIconV2 size={18} color={Colors.Custom.mainPrimaryPurple} />
            {` ${tasks?.length} ${tasks?.length === 1 ? 'Task' : 'Tasks'}`}
        </Text>
      );
    }
  }

  const getAlertCountView = () => {
    if(!alertApiRes.error && props.dayOptimizerView){
      return (
        <HStack justifyContent={'center'} alignItems={'center'} space={0.5}> 
          <BellSvg />  
          <Text style={{color : Colors.FoldPixel.GRAY300}}>{totalAlerts || 0}</Text>
        </HStack>
      )
    }else if (!alertApiRes.error && totalAlerts) {
      return (
        <Text
          size={'smRegular'}
          onPress={() => props.onActionableClick(ActionableTab.ALERTS)}
          style={{
            color: Colors.Custom.mainPrimaryPurple,
            paddingVertical: 10,
            marginRight: 10,
          }}
        >
          <AlertSeverityIcon priorityCode={TASK_PRIORITY_CODES.MEDIUM}/>
          {` ${totalAlerts} ${totalAlerts === 1 ? 'Alert' : 'Alerts'}`}
        </Text>
      );
    }
  }

  return (
    <>
      {props.dayOptimizerView ? (
        <>
          {getAlertCountView()}
        </>
      ) : (
    <HStack>
      {getAlertCountView()}
      {getTaskCountView()}
    </HStack>
   )}
    </>
  );
};

export default PatientListInternalTask;
