import React from 'react';
import {Dimensions, View} from 'react-native';
import {Text} from 'native-base';
import {TableWrapper} from '../../common/TableWrapper';
import {Skeleton, Table, Tooltip} from 'antd';
import {getCareProgramBillingHistoryColumns} from './CareProgramBillingHelper';
import useBillingHistoryTab from './BillingHooks/UseBillingHistoryTab';
import Stack from '../../common/LayoutComponents/Stack';
import {Colors} from '../../../styles/Colors';
import {useIntl} from 'react-intl';
import { BILLING_FILTER_ACTION_CODES } from './BillingHooks/BillingHooksConstants';

export const CareProgramBillingHistory = () => {
  const {
    billingHistoryHookState,
    handleBillingHistoryTableActions,
    ehrList,
    monthlyDataLoading,
    handleActions,
    paginationState
  } = useBillingHistoryTab({});
  const screenHeight = Dimensions.get('window').height;
  const intl = useIntl();

  const renderBilledAmount = (
    <View
      style={{
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.Custom.Gray200,
        backgroundColor: Colors.Custom.ContainerBGColor,
        padding: 12,
        width: '30%',
        margin: 12,
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Stack direction={'row'} style={{alignItems: 'center'}}>
        <Text
          size={'mdNormal'}
          style={{
            marginBottom: 4,
            color: Colors.FoldPixel.GRAY300,
            marginRight: 4,
          }}
        >
          {intl.formatMessage({id: 'totalBilledAmount'})}
        </Text>
      </Stack>
      <Text
        size={'xlSemibold'}
        style={{
          marginBottom: 4,
          color: Colors.FoldPixel.GRAY400
        }}
      >
        $ {billingHistoryHookState.totalAmountSum}
      </Text>
    </View>
  );

  return monthlyDataLoading ? (
    <Skeleton />
  ) : (
    <View style={{flex: 1}}>
      {renderBilledAmount}
      <TableWrapper
        pagination={{
          pageSize: paginationState.limit,
          current: paginationState.pageNo,
          total: paginationState.total,
          onChange(page, pageSize) {
            handleActions(BILLING_FILTER_ACTION_CODES.PAGINATION, {
              page: page,
              pageSize: pageSize,
            })
          },
        }}
      >
        <Table
          style={{
            backgroundColor: '#fff',
          }}
          scroll={{x: 700, y: screenHeight - 260}}
          columns={getCareProgramBillingHistoryColumns(
            handleBillingHistoryTableActions,
            ehrList,
            billingHistoryHookState.timezone
          )}
          dataSource={billingHistoryHookState.billedRecords}
          pagination={false}
          rowKey={(record: any) => record?.id}
        />
      </TableWrapper>
    </View>
  );
};
