import {uniqBy} from 'lodash';
import {
  IInfoOnMessageWindowHeader,
  ILoginUserData,
  IUserRoleCode,
} from '../../../../../Interfaces/CommonInterfaces';
import {IGroupMessageCreatedData} from '../../../../../Interfaces/GroupMessageInterface';
import {
  IMessageCreated,
  IWSSender,
} from '../../../../../Interfaces/WebSocketEventInterfaces';
import {
  CONVERSATION_CONST,
  COPY_MESSAGE_CODE,
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  GROUP_TYPES,
  MESSAGE_STATUS,
  PARENT_CODE,
  PERSON_TYPES,
  RESPONSE_CATEGORY_CODE,
  SENDER_TYPE,
  SMS_DELIVERY_STATUS,
} from '../../../../../constants';
import {CRM_PUBLIC_BASE_URL} from '../../../../../constants/Configs';
import {
  GLOBAL_ROLE_CODES,
  GROUP_TYPE_CODES,
  USER_ROLE_CODES,
} from '../../../../../constants/MlovConst';
import BaseService from '../../../../../services/CommonService/BaseService';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {
  IGroupContact,
  IGroupMember,
  IGroupUser,
} from '../../../../../services/Conversations/interfaces';
import {IMessageParams} from '../../../../../services/Message/interface';
import {StrCaseConverter} from '../../../../../utils/CaseConverter';
import {
  convertUnixDate,
  getAgeValue,
  getDateStrFromFormat,
  getDateToMomentISOString,
  getDiffInMinutes,
  getMomentObj,
} from '../../../../../utils/DateUtils';
import {
  getGenderFullForm,
  getGenderTextByGenderCode,
  getMsgEchoId,
  getSecondaryValue,
  isChannelEmail,
  isChannelTwillioSms,
  isChannelTypeWebWidget,
  isEFaxChannelInboxType,
  isInternalChannelInboxType,
  parseHtmlToPlainText,
  replaceHashValueToString,
} from '../../../../../utils/commonUtils';
import {IMentionObject, IUserType} from '../../../../../utils/interfaces';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {IConversationInboxs, IInboxMembers, IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import {IUserList} from '../../../../common/MeetingView/interface';
import {getCommunicationTypesByMessageUuids} from '../CommunicationTypes/CommunicationTypesUtils';
import {ICommunicationType, ICommunicationTypeByMessageUuidData} from '../CommunicationTypes/interfaces';
import {getGroupMembersListForAssignee} from '../ConversationChannelNew/ConversationSidebarUtils';
import {getAvatarFullName} from '../ConversationContainer/ConversationList/ConversationListItemUtils';
import {
  IContact,
  IContactType,
  IConversationData,
  IConversationMessage,
  IConversationMessageData,
  IMessageBoxData,
  IMessagingWindowHeaderActionProps,
  IMsgAttachment,
  IMsgBoxDataPosition,
  IMsgWithAttachments,
  MESSAGE_TYPE,
} from '../interfaces';
import {
  isAllInboxType,
  isSMSChannelInboxType,
} from './../../../../../utils/commonUtils';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE, CONVERSATION_TAB_CODES, MESSAGE_TYPE_CODE} from './../ConversationConst';
import {
  MESSAGE_CUSTOM_CARD_KEY_NAME,
  MESSAGE_CUSTOM_CARD_TYPE,
} from './MessagingCustomCard/CustomCardConst';
import {DEVICES_MODAL, DEVICES_NAME, MEMBER_SUBTITLE_DATA_CODE, MEMBER_SUBTITLE_DATA_CODE_LIST_CHAT, MEMBER_SUBTITLE_DATA_CODE_LIST_INTERNAL, MSG_SEND_OPTIONS, MSG_FOOTER_TAB_NAME, mentionRegexForMessaging} from './MsgConst';
import {IContactInfo, IContactPCPReqData, ICopiedMessageItem,  IDeletedMsgSender, IGetMessageAttachmentData, IGetMessageBodyVariables, IGetMessageAttachmentWithResourceData, IGroupMemberInfo, IInternalUser, IMessageSubtitleDeatilData, IMessageSubtitleHeaderInfo, IMessageSubtitleWithMemberHeaderInfo, IMessageTopHeaderInfo, ITaskCount, IUserPreferences, IPCPUserData} from './interface';
import {isBirthSexPrimary } from '../../../../../utils/capabilityUtils';
import { IUser } from '../../../Contacts/TeamMembers/interfaces';
import { FeatureKey } from '../../../../common/CalendarWidget/BookingWorkflows/ScheduleSuggestor/interface';
import { IntlShape } from 'react-intl';
import { getCurrentPatientDeceasedData, getDeceasedDate } from '../../../../../services/CommonService/AidBoxService';
import { IAvailableEhr } from '../../../../PersonOmniView/MiddleContainer/interfaces';
import DeviceInfo from 'react-native-device-info';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../../constants/WebSocketConst';
import moment from 'moment';
import {MessageStorage} from './MessageStorage/MessageStorage';
import {IAllDraftBody, IConversationDraftData, IConversationReplyDraft, IMessageDraftTypes} from '../../../../common/EmailDrawerCommonV2/interfaces';
import CommonService from '../../../../../services/CommonService/CommonService';
import { getUniqueMemberListByUuid } from '../ConversationContainer/ConversationList/ConversationListUtils';
import { MessageBus } from '../../../../../utils/MessageBus';
import { IInternalUsers } from '../../../../common/CustomUserSearch/interface';
import { IInboxMember } from '../../../../../services/Inbox/interfaces';
import { getLastTenDigit } from '../../../../common/PhoneCalling/utils';

export const showPracticeMessageOnRightSide = (
  userData: ILoginUserData,
  messageData: IConversationMessage | any,
  showPracticeMessageTogether = false,
) => {
  const senderType = messageData.senderType || messageData.sender_type || '';
  if (showPracticeMessageTogether) {
    if (senderType === SENDER_TYPE.CONTACT) {
      return false;
    } else {
      return true;
    }
  }

  const senderId = messageData?.senderId || messageData?.sender_id;
  if (
    userData?.id === senderId &&
    senderType === SENDER_TYPE.USER
  ) {
    return true;
  }
  return false;
};
export const getFormattedMessagingList = (
  messageList: IConversationMessage[],
  msgAttachments: IMsgWithAttachments[],
  userData: ILoginUserData,
  showPracticeMessageTogether = false,
): IMessageBoxData[] => {
  const messageBoxDataList: IMessageBoxData[] = [];
  if (messageList) {
    messageList.forEach((messageData) => {
      let position: IMsgBoxDataPosition = 'left';

      const parentMessageDataStr = messageData?.parentMessage?.content || '';
      const parentMessagePlainStr = parseHtmlToPlainText(parentMessageDataStr);

      const messageDataStr = messageData.content || '';
      const attachmentWithMsgId = getAttachmentsFromMsgId(msgAttachments, messageData.id || 0)
      const attachments =
      attachmentWithMsgId?.length ? attachmentWithMsgId : messageData?.messageAttachments || [];
      const parentAttachments =
        getAttachmentsFromMsgId(
          msgAttachments,
          messageData?.parentMessage?.id || 0
        ) || [];
      // const messagePlainStr = parseHtmlToPlainText(messageDataStr);
      const messagePlainStr = messageDataStr;
      let senderFullName = '';

      let createdDateStr = messageData.createdAt;
      let parentDateStr =
        messageData?.parentMessage?.updatedAt ||
        messageData?.parentMessage?.createdAt;
      if (
        createdDateStr &&
        (createdDateStr.includes('Z') || createdDateStr.includes('z'))
      ) {
        createdDateStr = messageData.createdAt;
        parentDateStr = messageData?.parentMessage?.createdAt;
      } else if (createdDateStr) {
        createdDateStr = createdDateStr + 'Z';
        parentDateStr = parentDateStr + 'Z';
      }

      let updatedDateStr = messageData.updatedAt;
      if (
        updatedDateStr &&
        (updatedDateStr.includes('Z') || updatedDateStr.includes('z'))
      ) {
        updatedDateStr = messageData.updatedAt;
      } else {
        updatedDateStr = updatedDateStr + 'Z';
      }

      let parentMessageData = null;
      if (messageData?.parentMessage) {
        parentMessageData = {
          id: messageData?.parentMessage?.id || -1,
          uuid: messageData?.parentMessage?.uuid || '',
          parentMessageUuid:
            messageData?.parentMessage?.parentMessageUuid || '',
          displayContent: parentMessagePlainStr,
          date: parentDateStr,
          dateStr: parentDateStr || '',
          position: position,
          senderFullName: '',
          messageType: messageData?.parentMessage?.messageType || -1,
          // msgAttachment: attachments,
          senderType: messageData?.parentMessage?.senderType || '',
          senderContact: messageData?.parentMessage?.senderContact,
          senderUser: messageData?.parentMessage?.senderUser,
          private: messageData?.parentMessage?.private || false,
          messageData: messageData?.parentMessage,
          msgAttachment:
            parentAttachments.length > 0
              ? parentAttachments
              : (messageData.parentMessage?.messageAttachments as any),
        };
        let paremtMsgSenderFullName = '';
        if (messageData?.parentMessage?.senderType === SENDER_TYPE.CONTACT) {
          paremtMsgSenderFullName =
            messageData?.parentMessage?.senderContact?.name || '';
        } else if (
          messageData?.parentMessage?.senderType === SENDER_TYPE.USER
        ) {
          paremtMsgSenderFullName =
            messageData?.parentMessage?.senderUser?.name || '';
        }
        parentMessageData.senderFullName = paremtMsgSenderFullName;
      }
      const messageDisplayData: IMessageBoxData = {
        id: messageData.id || -1,
        uuid: messageData.uuid || '',
        echoId: messageData?.contentAttributes?.echoId || "NA",
        parentMessageUuid: messageData.parentMessageUuid || '',
        displayContent: messagePlainStr,
        dateStr: createdDateStr || '',
        date: createdDateStr,
        dateInNumber: moment(createdDateStr).valueOf(),
        updatedDateStr: updatedDateStr,
        position: 'left',
        senderFullName: '',
        messageType: messageData.messageType || -1,
        msgAttachment: attachments,
        senderType: messageData.senderType || '',
        senderContact: messageData.senderContact,
        senderUser: messageData.senderUser,
        private: messageData.private,
        messageData,
        parentMessage: parentMessageData,
        messageMentions: messageData?.messageMentions || [],
        status: messageData?.status,
      };
      if (showPracticeMessageOnRightSide(userData, messageData, showPracticeMessageTogether)) {
        position = 'right';
      }
      if (messageData.senderType === SENDER_TYPE.CONTACT) {
        senderFullName = messageData?.senderContact?.name || '';
      } else if (messageData.senderType === SENDER_TYPE.USER) {
        senderFullName = messageData?.senderUser?.name || '';
      }
      messageDisplayData.senderFullName = senderFullName;
      messageDisplayData.position = position;
      messageBoxDataList.push(messageDisplayData);
    });
  }
  return messageBoxDataList;
};

export const getAttachmentsFromMsgId = (
  msgAttachment: IMsgWithAttachments[],
  msgId: number
): IMsgAttachment[] => {
  let attachments: IMsgAttachment[] = [] as any;
  const selectedMsg = msgAttachment?.find((msgObj) => {
    return msgObj.id === msgId;
  });
  if (
    selectedMsg &&
    selectedMsg?.attachments &&
    selectedMsg?.attachments?.length
  ) {
    attachments = getAttachmentsList(selectedMsg.attachments);
  }
  return attachments;
};

export const getMessageBoxDataObj = (
  messageDataStr: string,
  position: IMsgBoxDataPosition,
  messageId: number,
  messageData: IMessageCreated,
  msgAttachments?: IMsgAttachment[],
  dateStr?: string
): IMessageBoxData => {
  const senderFullName = messageData?.sender?.name || '';
  let currentTime = getDateToMomentISOString(new Date());
  if (dateStr) {
    currentTime = dateStr;
  }

  const messageDataCreatedAt = messageData.message_created_at || messageData.createdAt || messageData.created_at;
  if (messageDataCreatedAt) {
    const createdAtISO =
      typeof messageDataCreatedAt === 'number' ?
        convertUnixDate(messageDataCreatedAt).toISOString()
        : getMomentObj(messageDataCreatedAt)?.toISOString();

    if (createdAtISO) {
      currentTime = createdAtISO;
      dateStr = createdAtISO;
    }
  }

  const parentMessage = {
    ...messageData?.parent_message,
       msgAttachment: messageData?.parent_message_attachments || [],
  }

  const msgData: IConversationMessage = {
    id: messageId,
    uuid: messageData?.uuid || '',
    parentMessageUuid: messageData?.parent_message_uuid || '',
    content: messageDataStr,
    senderId: messageData?.sender?.id,
    senderType: messageData?.sender?.type,
    contentType: messageData?.content_type || messageData?.contentType || 0,
    createdAt: getDateStrFromFormat(dateStr || new Date()),
    updatedAt: getDateStrFromFormat(dateStr || new Date()),
    messageType: messageData.message_type || messageData.messageType,
    private: messageData?.private,
    // messageAttachments: msgAttachments || [],
    parentMessage: parentMessage,
    contentAttributes: messageData?.content_attributes || messageData?.contentAttributes || {},
  };
  return {
    id: messageId,
    echoId: messageData.echo_id,
    uuid: messageData?.uuid || '',
    parentMessageUuid: messageData?.parent_message_uuid || messageData?.parentMessageUuid || '',
    displayContent: messageDataStr,
    date: dateStr,
    dateStr: currentTime,
    dateInNumber: moment(currentTime).valueOf(),
    position: position,
    senderFullName: senderFullName,
    messageType: messageData.message_type || messageData.messageType,
    msgAttachment: msgAttachments || [],
    senderType: messageData?.sender_type || messageData?.sender?.type || messageData?.senderType || '',
    private: messageData?.private,
    messageData: msgData,
    senderContact: {
      ...messageData?.sender,
      name: messageData?.sender?.name,
    },
    senderUser: {
      ...messageData?.sender,
      name: messageData?.sender?.name,
      id: messageData?.sender?.id,
    },
    parentMessage: {
      ...messageData?.parent_message,
      displayContent: messageData?.parent_message?.content,
      parentMessageUuid: messageData?.parent_message?.parent_message_uuid,
      senderId: messageData?.parent_message?.sender_id,
      senderType: messageData?.parent_message?.sender_type,
      contentType: messageData?.parent_message?.content_type,
      contentAttributes: messageData?.parent_message?.content_attributes,
      createdAt: messageData?.parent_message?.created_at,
      updatedAt: messageData?.parent_message?.updated_at,
      dateStr: getDateStrFromFormat(
        messageData?.parent_message?.created_at || new Date()
      ),
      messageType: messageData?.parent_message?.message_type,
      sourceId: messageData?.parent_message?.source_id,
      senderFullName: messageData?.parent_message?.sender?.name,
      senderContact: {
        ...messageData?.parent_message?.sender,
        name: messageData?.parent_message?.sender?.name,
      },
      msgAttachment: messageData?.parent_message_attachments || [],
    },
  };
};

export const getMessageBoxDataObjForGroup = (
  messageDataStr: string,
  position: IMsgBoxDataPosition,
  messageId = -1,
  messageData: IGroupMessageCreatedData,
  msgAttachments?: IMsgAttachment[],
  dateStr?: string,
  uuid?: string,
  parentMessageUuid?: string
): IMessageBoxData => {
  const senderFullName = messageData?.sender?.name || '';
  return {
    id: messageId,
    uuid: uuid || '',
    parentMessageUuid: parentMessageUuid || '',
    displayContent: messageDataStr,
    date: dateStr,
    dateInNumber: moment(dateStr || new Date()).valueOf(),
    dateStr: getDateStrFromFormat(dateStr || new Date()),
    position: position,
    senderFullName: senderFullName,
    messageType: messageData.messageType,
    msgAttachment: msgAttachments || [],
    senderType: '',
    private: messageData?.private,
  };
};

export const getUniqueMessageList = (
  messageList: IConversationMessage[]
): IConversationMessage[] => {
  return uniqBy(messageList, (msgData) => {
    return msgData.id;
  });
};

export const getUniqueDisplayMessageList = (
  messageList: IMessageBoxData[]
): IMessageBoxData[] => {
  return uniqBy(messageList, (msgData) => {
    return msgData.id;
  });
};

export const getUniqDisplayMessageList = (
  messageList: IMessageBoxData[]
): IMessageBoxData[] => {
  const messageMap = new Map<number, IMessageBoxData>();
  messageList?.forEach((message) => {
    const existingMessage = messageMap.get(message.id);
    if (!existingMessage || message?.msgAttachment) {
      messageMap.set(message.id, message);
    }
  });
  return Array.from(messageMap.values());
};

export const isUserAlreadyTyping = (
  typingUserList: IWSSender[],
  currentTypingUser: IWSSender
): boolean => {
  return typingUserList.some((user) => {
    return (
      currentTypingUser &&
      user &&
      user.id === currentTypingUser.id &&
      user.type === currentTypingUser.type
    );
  });
};

export const getUniqueTypingUsersList = (
  typingUserList: IWSSender[]
): IWSSender[] => {
  return uniqBy(typingUserList, (userData) => {
    return [userData.id, userData.type].join();
  });
};

export const getMessageIds = (
  msgDisplayDataList: IConversationMessage[]
): number[] => {
  const messageIds: number[] = [];
  msgDisplayDataList.forEach((msgData) => {
    if (
      msgData.id &&
      msgData.messageAttachments &&
      msgData.messageAttachments.length
    ) {
      messageIds.push(msgData.id);
    }
  });
  return messageIds;
};

export const getContactDataAndType = (
  selectedConversation: IConversationData
): {
  contactData: IContact;
  contactType: IContactType;
} => {
  let contactData: any =
    selectedConversation?.conversationContact || ({} as any);
  let contactType = contactData?.contactType?.contactType || {};
  if (!selectedConversation?.contactId && selectedConversation?.groupConversation?.groupMembers?.length) {
    const contactInfo = getPatientDataFromGroupMemberList(
      selectedConversation?.groupConversation?.groupMembers
    );
    contactData = contactInfo.contactData;
    contactType = contactInfo.contactType;
  } else {
    contactData = selectedConversation?.conversationContact || {};
    contactType = contactData?.contactType?.contactType;
  }
  return {
    contactData,
    contactType,
  };
};

export const getPatientDataFromGroupMemberList = (
  groupMembers: IGroupMember[]
) => {
  let contactData = {} as IGroupContact;
  let contactType = {} as IContactType;
  for (let index = 0; index < groupMembers?.length; index++) {
    const singleGroupMember = groupMembers[index];
    if (
      (singleGroupMember?.contact?.patient?.id || singleGroupMember?.contact?.id) &&
      singleGroupMember.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT
    ) {
      contactData = singleGroupMember.contact;
      contactType = singleGroupMember?.contact?.contactType?.contactType;
    }
  }
  return {
    contactData,
    contactType,
  };
};

export const getGroupMemberList = (
  groupMembers: IGroupMember[]
): {
  patientData?: IGroupMemberInfo;
  groupMembersInfoList: IGroupMemberInfo[];
} => {
  const groupMembersInfoList: IGroupMemberInfo[] = [];
  let patientData: any = {};
  for (let index = 0; index < groupMembers?.length; index++) {
    const singleGroupMember = groupMembers[index];
    if (singleGroupMember?.contact?.patient?.id) {
      patientData = {
        id: singleGroupMember.contact?.id,
        uuid: singleGroupMember.contact?.uuid,
        memberType: GROUP_MEMBER_TYPE.PATIENT,
        email: singleGroupMember?.contact?.email,
        name: singleGroupMember?.contact?.name,
      };
      groupMembersInfoList.push(patientData);
    } else if (singleGroupMember?.contact?.id) {
      groupMembersInfoList.push({
        id: singleGroupMember.contact?.id,
        uuid: singleGroupMember.contact?.uuid,
        memberType: GROUP_MEMBER_TYPE.CONTACT,
        email: singleGroupMember?.contact?.email,
        name: singleGroupMember?.contact?.name,
      });
    } else if (singleGroupMember.user?.id) {
      groupMembersInfoList.push({
        id: singleGroupMember.user?.id,
        uuid: singleGroupMember.user?.uuid,
        memberType: GROUP_MEMBER_TYPE.USER,
        email: singleGroupMember.user.email,
        name: singleGroupMember.user.name,
      });
    }
  }
  return {
    patientData,
    groupMembersInfoList,
  };
};

const inUserInAccountUsers = (singleGroupMember: string, internalUsers?: IUserList[]) => {
  return internalUsers?.some((user) => {
    const userUuid = user?.user?.uuid || user?.uuid
    return userUuid === singleGroupMember
  })
}

export const getGroupMemberUserOrContactIdListByTypeCode = (
  groupMembers: IGroupMember[],
  groupMemberType: string,
  internalUsers?: IUserList[]
): string[] => {
  const groupMembersInfoList: string[] = [];
  for (let index = 0; index < groupMembers?.length; index++) {
    const singleGroupMember = groupMembers[index];
    if (
      groupMemberType === singleGroupMember?.groupUserType?.code &&
      singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT
    ) {
      groupMembersInfoList.push(singleGroupMember?.contact?.uuid || '');
    } else if (
      groupMemberType === singleGroupMember?.groupUserType?.code &&
      singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE.USER
    ) {
      groupMembersInfoList.push(singleGroupMember?.user?.uuid || '');
    }
  }
  return groupMembersInfoList;
};

export const getPatientDataFromGroupMember = (
  groupMembers: IGroupMember[]
): IGroupContact => {
  let groupContactData: IGroupContact = {} as any;
  if (groupMembers?.length) {
    for (let index = 0; index < groupMembers?.length; index++) {
      const singleGroupMember = groupMembers[index];
      if (singleGroupMember?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT) {
        groupContactData = singleGroupMember?.contact || ({} as any);
        break;
      }
    }
  }
  return groupContactData;
};

export const getMembersIdsAndContactsFromGroupMember = (
  groupMembers: IGroupMember[]
): {groupMembersIdsList: string[], patientLists: any[]} => {
  const groupMembersIdsList: string[] = [];
  const patientLists = [];
  for (let index = 0; index < groupMembers?.length; index++) {
    const singleGroupMember = groupMembers[index];
    if (
      singleGroupMember.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT ||
      singleGroupMember.groupUserType.code === GROUP_MEMBER_TYPE.CONTACT
    ) {
      groupMembersIdsList.push(singleGroupMember.contact?.uuid || '');
      patientLists.push(singleGroupMember.contact);
    }
  }
  return {groupMembersIdsList, patientLists};
};

const getUserDataFromGroupMember = (
  groupMembers: IGroupMember[]
): IGroupUser => {
  let groupContactData: IGroupUser = {} as IGroupUser;

  for (let index = 0; index < groupMembers?.length; index++) {
    const singleGroupMember = groupMembers[index];
    if (singleGroupMember.user?.name?.length) {
      groupContactData = singleGroupMember.user;
      break;
    }
  }
  return groupContactData;
};

export const getAddedAndRemovedUserIDs = (
  prevGroupMembers: IGroupMember[],
  updatedUserIds: string[],
  loginUserUuid: string
): {
  addUserIds: string[];
  removeUserIds: string[];
} => {
  const addUserIds: string[] = [];
  const removeUserIds: string[] = [];
  const prevGroupMembersUserIds: string[] = [];
  for (let index = 0; index < prevGroupMembers.length; index++) {
    const singleGroupMember = prevGroupMembers[index];
    if (
      singleGroupMember.groupUserType.code === GROUP_MEMBER_TYPE.USER &&
      singleGroupMember.groupUserId
    ) {
      prevGroupMembersUserIds.push(singleGroupMember.groupUserId);
      if (updatedUserIds.indexOf(singleGroupMember.groupUserId) === -1) {
        removeUserIds.push(singleGroupMember.groupUserId);
      }
    }
  }
  updatedUserIds.forEach((latestUserId) => {
    if (!prevGroupMembersUserIds.includes(latestUserId)) {
      addUserIds.push(latestUserId);
    }
  });
  if (removeUserIds.indexOf(loginUserUuid) !== -1) {
    const loginUserUuidIndex = removeUserIds.indexOf(loginUserUuid);
    removeUserIds.splice(loginUserUuidIndex, 1);
  }
  return {
    addUserIds,
    removeUserIds,
  };
};

export const getAddedAndRemovedContactIDs = (
  prevGroupMembers: IGroupMember[],
  updatedContactIds: string[]
): {
  addFamilyMemberIds: string[];
  removeFamilyMemberIds: string[];
} => {
  const addFamilyMemberIds: string[] = [];
  const removeFamilyMemberIds: string[] = [];
  const prevGroupMembersContactIds: string[] = [];
  for (let index = 0; index < prevGroupMembers.length; index++) {
    const singleGroupMember = prevGroupMembers[index];
    if (
      singleGroupMember.groupUserType.code === GROUP_MEMBER_TYPE.CONTACT &&
      singleGroupMember.groupUserId
    ) {
      prevGroupMembersContactIds.push(singleGroupMember.groupUserId);
      if (updatedContactIds.indexOf(singleGroupMember.groupUserId) === -1) {
        removeFamilyMemberIds.push(singleGroupMember.groupUserId);
      }
    }
  }
  updatedContactIds.forEach((latestUserId) => {
    if (!prevGroupMembersContactIds.includes(latestUserId)) {
      addFamilyMemberIds.push(latestUserId);
    }
  });
  return {
    addFamilyMemberIds,
    removeFamilyMemberIds,
  };
};

export const getGroupMemberEmailList = (
  groupMembers: IGroupMember[]
): string[] => {
  const emailList: string[] = [];
  groupMembers?.forEach((member) => {
    if (
      member.groupUserType.code === GROUP_MEMBER_TYPE.CONTACT ||
      member.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT
    ) {
      if (member?.contact?.email) {
        emailList.push(member?.contact?.email);
      }
    }
  });
  return emailList;
};

export const getPatientGroupMemberData = (
  groupMembers: IGroupMember[]
): IGroupMember => {
  let groupContactData: IGroupMember = {} as any;
  for (let index = 0; index < groupMembers?.length; index++) {
    const singleGroupMember = groupMembers[index];
    if (singleGroupMember.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT) {
      groupContactData = singleGroupMember || ({} as any);
      break;
    }
  }
  return groupContactData;
};

export const isSendMessageAllowed = (
  selectedConversation: IConversationData,
  currentUserUuid: string,
  isLoggedInUserGroupMember: boolean,
  conversationInbox: IConversationInboxs,
  accessToViewPatientProfile = true
) => {
  if (!accessToViewPatientProfile && selectedConversation?.inboxId === -1) {
    return false;
  }
  const isAllowed = true;
  if (
    (selectedConversation?.groupConversation?.groupType?.code ===
      GROUP_TYPE_CODES.PATIENT_BROADCAST ||
      selectedConversation?.groupConversation?.groupType?.code ===
      GROUP_TYPE_CODES.USER_BROADCAST) &&
    selectedConversation.groupConversation?.ownerId !== currentUserUuid
  ) {
    return false;
  } else if (selectedConversation?.groupConversation?.groupMembers?.length && !isLoggedInUserGroupMember) {
    return false;
  } else if (conversationInbox?.inboxMembers  && !isLoggedInUserGroupMember) {
    return false;
  } else if (conversationInbox?.isDeleted) {
    return false;
  } else if (!isLoggedInUserGroupMember) {
    return false;
  }
  return isAllowed;
};

export const isBroadCastAndPrivateGroup = (
  selectedConversation: IConversationData
) => {
  return (
    selectedConversation?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.PATIENT_BROADCAST ||
    selectedConversation?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.USER_BROADCAST ||
    selectedConversation?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.INTERNAL
  );
};


export const isUserGroupsGroup = (selectedConversation: IConversationData) => {
  return (
    selectedConversation?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.USER_BROADCAST ||
    selectedConversation?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.INTERNAL
  );
};

export const isPrivateGroupConversation = (
  conversationData: IConversationData
): boolean => {
  if (
    conversationData?.groupConversation?.id &&
    conversationData?.groupConversation?.groupType?.code === GROUP_TYPES.PRIVATE
  ) {
    return true;
  }
  return false;
};
export const isGroupAvatar = (singleConversationData: IConversationData) => {
  return (
    singleConversationData?.groupConversation?.groupMembers?.length > 2 ||
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES?.PATIENT_BROADCAST ||
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES?.USER_BROADCAST
  );
};

export const isPrivateGroup = (singleConversationData: IConversationData) => {
  return (
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES?.PRIVATE
  );
};

export const isMentionTab = (selectedInboxTypeCode: string,) => {
  return (
    selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
  );
};

export const isGroupMembersAvailable = (singleConversationData: IConversationData, userUuid: string) => {
  if (isPrivateGroup(singleConversationData as any)) {
    const assigneeMembers = getGroupMembersListForAssignee(singleConversationData?.groupConversation?.groupMembers || [], userUuid)
    return assigneeMembers.length > 0 ? true : false;
  }
  return true;
}

export const canShowAssignment = (singleConversationData: IConversationData) => {
  const channelType = singleConversationData?.conversationInbox?.channelType || '';
  return (
    isChannelEmail(channelType) ||
    isChannelTwillioSms(channelType) ||
    isChannelTypeWebWidget(channelType) ||
    isEFaxChannelInboxType(channelType) ||
    isPrivateGroup(singleConversationData as any)
  )
};

export const isInternalChat = (singleConversationData: IConversationData) => {
  return (
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES?.INTERNAL
  );
};

export const isPrivateMessage = (selectedMessage: IConversationMessage) => {
  return (
    selectedMessage?.private
  );
};

export const isGroupConversation = (
  selectedConversation: IConversationData
): boolean => {
  if (selectedConversation?.groupConversation?.id) {
    return true;
  }
  return false;
};

export const isCurrentUserBusinessOwner = (
  currentUserRoles: IUserRoleCode[]
) => {
  return currentUserRoles.some((role) => {
    return (USER_ROLE_CODES.BUSINESS_OWNER === role?.code || GLOBAL_ROLE_CODES.GLOBAL_ADMIN === role?.code );
  });
};

export const isCurrentUserGuardian = (currentUserRoles: IUserRoleCode[]) => {
  return currentUserRoles.some((role) => {
    return USER_ROLE_CODES.GUARDIAN === role?.code;
  });
};

export const isLoginUserOwnerOfCurrentGroup = (
  currentUserRoles: IUserRoleCode[]
): boolean => {
  if (
    isCurrentUserBusinessOwner(currentUserRoles) ||
    isCurrentUserGuardian(currentUserRoles)
  ) {
    return true;
  }
  return false;
};

export const GroupTypeIs = (conversationData: any) => {
  if (
    conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.USER_BROADCAST ||
    conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.PATIENT_BROADCAST ||
    conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.INTERNAL
  ) {
    return false;
  } else {
    return true;
  }
};

export const getContactTypeValue = (contactType: IContactType): string => {
  if (contactType?.code === PERSON_TYPES.CUSTOMER) {
    return 'Patient';
  } else if (contactType?.code === PERSON_TYPES.LEAD) {
    return 'Prospect';
  } else if (contactType?.value && contactType.value.length > 0) {
    return capitalizeText(contactType.value)
  }
  return 'Group';
};

export const getPatientOrProspectStr = (
  singleConversationData: IConversationData,
  parentCode: string,
  userUuid: string,
  showInfoOnHeaderData?: IInfoOnMessageWindowHeader,
) => {
  const contactInfo: any = getContactDataAndType(singleConversationData);
  const contactData: IGroupContact = contactInfo.contactData;
  const choosenName =
    !isBroadCastGroup(singleConversationData as IConversationData) &&
    showInfoOnHeaderData?.SHOW_CHOSEN_NAME &&
    contactData?.person?.chosenName?.length
      ? ' (' + contactData?.person?.chosenName + ')'
      : '';
  let finalStr = '';
  if (isGroupConversation(singleConversationData)) {
    const groupData = singleConversationData?.groupConversation;
    if (groupData?.name) {
      finalStr = groupData?.name;
    } else if (singleConversationData?.conversationContact?.name) {
      finalStr = singleConversationData?.conversationContact?.name || '';
    } else if (groupData?.groupMembers) {
      const userData = getUserDataFromGroupMember(groupData?.groupMembers);
      const filterByLoggedInUser = groupData?.groupMembers?.filter((member) => {
        return member?.groupUserId !== userUuid;
      });
      if (
        singleConversationData.groupConversation?.groupType?.code ===
        GROUP_TYPE_CODES.INTERNAL
      ) {
        const sortUserByAlphabeticalOrder = sortUserMembersByName(filterByLoggedInUser)
        let updatedName = sortUserByAlphabeticalOrder[0]?.user?.name || '';
        const groupMemberCount =
          singleConversationData?.groupConversation?.groupMembers_aggregate
            ?.aggregate?.count ||
          singleConversationData?.groupConversation?.groupMembers?.length;
        if (groupMemberCount > 2) {
          updatedName = `${
            sortUserByAlphabeticalOrder[0]?.user?.name.split(' ')[0]
          }, ${
            sortUserByAlphabeticalOrder[1]?.user?.name.split(' ')[0]
          } + ${groupMemberCount - 2} ${
            groupMemberCount > 3 ? 'members' : 'member'
          }`;
        }
        finalStr = singleConversationData.groupConversation?.name ? singleConversationData.groupConversation?.name :
          sortUserByAlphabeticalOrder?.length && sortUserByAlphabeticalOrder[0]?.user?.name
            ? updatedName
            : userData?.name || '';
      } else if (
        singleConversationData.groupConversation?.groupType?.code ===
        GROUP_TYPE_CODES.PATIENT_BROADCAST
      ) {
        finalStr = singleConversationData.groupConversation.name;
      }
      else if (singleConversationData.groupConversation?.groupType?.code ===
        GROUP_TYPE_CODES.PRIVATE) {
        finalStr = contactData?.name
      }
      else {
        finalStr = userData?.name || '';
      }

      const groupMemberCount =
        singleConversationData?.groupConversation?.groupMembers?.length - 2; //count excludes logged in user too
      let str = '';
      if (groupMemberCount) {
        str = `${groupMemberCount}`;
        finalStr += `${groupMemberCount > 2 ? ` + ${str} members` : groupMemberCount === 1 ? ` + ${str} member` : ''}`;
      }
    }
  } else {
    finalStr = contactData.name;
  }
  if (finalStr === '') {
    const groupData = singleConversationData?.groupConversation;
    if (groupData?.groupMembers) {
      const contactData = getPatientDataFromGroupMember(
        groupData?.groupMembers
      );
      finalStr = contactData?.name || '';
    }
  }

  return (finalStr || CONVERSATION_CONST.GROUP_CHAT) + choosenName;
};
export const getPatientAgeAndGenderStr = (
  singleConversationData: IConversationData,
  ehrConfig: IAvailableEhr,
  pcpUserName?: string,
  showPcp?: boolean,
  additionalData?: {
    deceasedDate?: string;
  }
) => {
  const detail: string[] = [];
  const contactInfo: any = getContactDataAndType(singleConversationData);

  const contactData: IGroupContact = contactInfo.contactData;
  const contactType = contactInfo.contactType;

  if (
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.INTERNAL
  ) {
    const groupName = singleConversationData?.groupConversation?.name;
    detail.push(groupName);
  } else {
    const contactTypeStr = getContactTypeValue(contactType);
    detail.push(contactTypeStr);
    const ageAndGenderText = getAgeAndGenderText(contactData, showPcp, additionalData);
    if (ageAndGenderText.length) {
      detail.push(ageAndGenderText);
    }
  }
  if (pcpUserName?.length) {
    detail.push(pcpUserName + '(PCP)');
  }
  const groupMemberCount =
    singleConversationData?.groupConversation?.groupMembers_aggregate?.aggregate?.count ||
    singleConversationData?.groupConversation?.groupMembers?.length;
  let str = '';
  if (groupMemberCount) {
    str = `${groupMemberCount}`;
    detail.push(str + `${groupMemberCount > 1 ? ' members' : 'member'}`);
  }

  if (singleConversationData?.conversationInbox?.name?.length) {
    detail.push(singleConversationData?.conversationInbox?.name);
  }

  return detail.join(' | ');
};

export const getDeatiledData = (data: IMessageSubtitleDeatilData, isInstantChatView?: boolean) => {
  const detail: IMessageSubtitleWithMemberHeaderInfo[] = [];
  const contactInfo: any = getContactDataAndType(data?.singleConversationData);
  const contactData: IGroupContact = contactInfo.contactData;
  const contactType = contactInfo.contactType;

  data?.actionList.forEach((code) => {
    switch (code) {
      case MEMBER_SUBTITLE_DATA_CODE.GROUP_NAME:
        const groupName = data?.singleConversationData?.groupConversation?.name;
        detail.push({
          code: MEMBER_SUBTITLE_DATA_CODE.GROUP_NAME,
          value: groupName,
        });
        break;

      case MEMBER_SUBTITLE_DATA_CODE.CONTACT_TYPE:
        const contactTypeStr = getContactTypeValue(contactType);
        detail.push({
          code: MEMBER_SUBTITLE_DATA_CODE.CONTACT_TYPE,
          value: contactTypeStr,
        });
        break;

      case MEMBER_SUBTITLE_DATA_CODE.AGE:
        const ageText = getAgeForMemberData(contactData);
        if (ageText) {
          detail.push({code: MEMBER_SUBTITLE_DATA_CODE.AGE, value: ageText});
        }
        break;

      case MEMBER_SUBTITLE_DATA_CODE.GENDER:
        const genderText = getGenderForMemberData(contactData, data?.showPcp);
        if (genderText) {
          detail.push({
            code: MEMBER_SUBTITLE_DATA_CODE.GENDER,
            value: genderText,
          });
        }
        break;

      case MEMBER_SUBTITLE_DATA_CODE.LOACTION:
        const locationText = getLocationForMemberData(contactData);
        if (locationText && !isInstantChatView) {
          detail.push({
            code: MEMBER_SUBTITLE_DATA_CODE.LOACTION,
            value: locationText,
          });
        }
        break;

      case MEMBER_SUBTITLE_DATA_CODE.PCP_USER_NAME:
        if (data?.pcpUserName?.length && !isInstantChatView) {
          detail.push({
            code: MEMBER_SUBTITLE_DATA_CODE.PCP_USER_NAME,
            value: data?.pcpUserName + '(PCP)',
          });
        }
        break;

      case MEMBER_SUBTITLE_DATA_CODE.MEMBER_COUNT:
        const groupMemberCount =
          data?.singleConversationData?.groupConversation
            ?.groupMembers_aggregate?.aggregate?.count ||
          data?.singleConversationData?.groupConversation?.groupMembers?.length;
        let str = '';
        if (groupMemberCount) {
          str = `${groupMemberCount}`;
          detail.push({
            code: MEMBER_SUBTITLE_DATA_CODE.MEMBER_COUNT,
            value: str + `${groupMemberCount > 1 ? ' members' : 'member'}`,
          });
        }
        break;

      case MEMBER_SUBTITLE_DATA_CODE.CONVERSATION_INBOX_NAME:
        if (data?.singleConversationData?.conversationInbox?.name?.length) {
          detail.push({
            code: MEMBER_SUBTITLE_DATA_CODE.CONVERSATION_INBOX_NAME,
            value: data?.singleConversationData?.conversationInbox?.name,
          });
        }
    }
  });
  return detail;
};

export const getPatientAgeAndGenderObject = (
  singleConversationData: IConversationData,
  isInstantChatView?: boolean,
  pcpUserName?: string,
  showPcp?: boolean
) => {
  const actionList =
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.INTERNAL
      ? MEMBER_SUBTITLE_DATA_CODE_LIST_INTERNAL
      : MEMBER_SUBTITLE_DATA_CODE_LIST_CHAT;

  const data: IMessageSubtitleDeatilData = {
    singleConversationData,
    actionList,
    pcpUserName,
    showPcp,
  };
  const detail: IMessageSubtitleWithMemberHeaderInfo[] = getDeatiledData(data, isInstantChatView);
  return detail;
};

export const getAgeForMemberData = (contactData: IGroupContact) => {
  const ageInYears = contactData?.person?.birthDate
    ? getDateStrFromFormat(contactData?.person?.birthDate,DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT)
    : '';
  return ageInYears;
};

export const getGenderForMemberData = (
  contactData: IGroupContact,
  showPcp?: boolean
) => {
  const genderValue = showPcp
    ? getGenderAndSexAtBirthByGenderCodeStr(contactData)
    : getGenderTextByGenderCode(contactData);
  return genderValue;
};

export const getLocationForMemberData = (contactData: IGroupContact) => {
  const location =
    contactData?.contactPracticeLocations?.[0]?.practiceLocation?.name;
  return location;
};

export const getAgeAndGenderText = (
  contactData: IGroupContact,
  showPcp?: boolean,
  additionalData?: {
    deceasedDate?: string;
}) => {
  const ageAndGenderDetail: string[] = [];
  const ageInYears = contactData?.person?.birthDate
  ? (getDateStrFromFormat(contactData?.person?.birthDate,DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT) + '(' + getAgeValue(contactData?.person?.birthDate, additionalData?.deceasedDate || '') + ')')
  : '';
  if (ageInYears?.length) {
    ageAndGenderDetail.push(ageInYears);
  }
  const genderValue = showPcp ? getGenderAndSexAtBirthByGenderCodeStr(contactData): getGenderTextByGenderCode(contactData);
  if (genderValue.length) {
    ageAndGenderDetail.push(genderValue);
  }
  const location = contactData?.contactPracticeLocations?.[0]?.practiceLocation?.name;
  if (location?.length) {
    ageAndGenderDetail.push(location);
  }
  return ageAndGenderDetail.join(' | ');
};

export const getMembersId = (
  selectedConversation?: IConversationData,
  contactData?: any,
  userUuid?: string
) => {
  if (selectedConversation?.groupConversation?.groupMembers?.length) {
    const membersId = selectedConversation?.groupConversation?.groupMembers
      .filter((member) => {
        if (member?.groupUserId !== userUuid) {
          return member;
        }
      })
      .map((member) => {
        return member?.groupUserId;
      });
    return membersId;
  } else {
    const contactUuid = contactData?.uuid;

    return [contactUuid];
  }
};

export const getUserGroupUserId = (
  selectedConversation?: IConversationData,
  contactData?: any,
  userUuid?: string
) => {
  if (selectedConversation?.groupConversation?.groupMembers?.length) {
    const userGroupUserId =
      selectedConversation?.groupConversation?.groupMembers
        .filter((member) => {
          if (
            member?.groupUserType.code === GROUP_MEMBER_TYPE.USER &&
            member?.groupUserId !== userUuid
          ) {
            return member;
          }
        })
        .map((member) => {
          return member?.groupUserId;
        });
    return userGroupUserId;
  } else {
    const contactUuid = contactData?.uuid;
    return [contactUuid];
  }
};

export const getContactGroupUserId = (
  selectedConversation?: IConversationData,
  contactData?: any
): any => {
  if (selectedConversation?.groupConversation?.groupMembers?.length) {
    const contactGroupUserId =
      selectedConversation?.groupConversation?.groupMembers
        .filter((member) => {
          if (member?.groupUserType.code !== GROUP_MEMBER_TYPE.USER) {
            return member;
          }
        })
        .map((member) => {
          return member?.groupUserId;
        });
    return contactGroupUserId;
  } else {
    const contactUuid = contactData?.uuid;
    return [contactUuid];
  }
};

export const isSeen = (
  lastSeenBy: any,
  groupMember: any,
  date: string,
  text: string,
  returnValue?: boolean,
  conversation?: IConversationData,
  isSinglePatientReadReceiptEnable?: boolean,
  privateMessage?: boolean,
) => {
  let count = 0;
  let isSeenByPatient = false;
  let updatedGroupMember = groupMember;
  if (privateMessage) {
    const internalUserIds = conversation?.groupConversation?.groupMembers?.map(
      (item) => {
        if (item?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
          return item?.groupUserId;
        }
      }
    );
    updatedGroupMember = updatedGroupMember?.filter((memberId: any) =>
      internalUserIds?.includes(memberId)
    );
  }
  const isSeenByMember = (memberId: any) => {
    const messageSeen = lastSeenBy.filter((lastSeenMember: any) => {
      if (lastSeenMember.lastseenById === memberId) {
        return lastSeenMember;
      }
    });
    return messageSeen;
  };
  const MsgReadByUser: any = [];

  const chkLastSeen = (lastSeenAt: string, memberId: string) => {
    if (date && (date.includes('Z') || date.includes('z'))) {
      date = date;
    } else if (date) {
      date = date + 'Z';
    }
    if (lastSeenAt && (lastSeenAt.includes('Z') || lastSeenAt.includes('z'))) {
      lastSeenAt = lastSeenAt;
    } else if (lastSeenAt) {
      lastSeenAt = lastSeenAt + 'Z';
    }

    if (getDiffInMinutes(date, lastSeenAt) > 0) {
      MsgReadByUser.push({isSeen: true, memberId, lastSeenAt});
      if (isSinglePatientReadReceiptEnable) {
        const isPatientSeen = conversation?.groupConversation?.groupMembers?.find((item) => {
          return item.groupUserId === memberId
        })
        if (
          isPatientSeen?.groupUserType?.code === GROUP_MEMBER_TYPE.CONTACT ||
          isPatientSeen?.groupUserType?.code === GROUP_MEMBER_TYPE.CUSTOMER ||
          isPatientSeen?.groupUserType?.code === GROUP_MEMBER_TYPE.PATIENT
        ) {
          isSeenByPatient = true;
        }
      }
      count++;
    } else {
      MsgReadByUser.push({isSeen: false, memberId, lastSeenAt});
    }
  };

  updatedGroupMember.map((memberId: any) => {
    const isSeenBy = isSeenByMember(memberId);
    if (isSeenBy.length) {
      chkLastSeen(isSeenBy[0]?.updatedAt, memberId);
    }
  });

  if (returnValue) {
    return MsgReadByUser;
  } else {
    if (isSeenByPatient) {
      return true;
    } else if (count === updatedGroupMember.length) {
      return true;
    } else {
      return false;
    }
  }
};

const ALL_TYPE_SEND_OPTIONS = [
  MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS,
  MSG_SEND_OPTIONS.BROWSE_FORMS,
  MSG_SEND_OPTIONS.BROWSE_ARTICLES,
  MSG_SEND_OPTIONS.SEND_APPOINTMENT_LINK,
];

const SMS_TYPE_SEND_OPTIONS = [
  MSG_SEND_OPTIONS.BROWSE_FORMS,
  MSG_SEND_OPTIONS.BROWSE_ARTICLES,
  MSG_SEND_OPTIONS.SEND_APPOINTMENT_LINK,
];

export const getSendMsgOptionList = (
  selectedInboxTypeCode: string,
  conversationData: IConversationData,
  conversationInbox: any
): string[] => {
  let sendMsgOptionList: string[] = [...ALL_TYPE_SEND_OPTIONS];
  if (
    conversationInbox &&
    conversationInbox?.channelType === 'Channel::TwilioSms'
  ) {
    sendMsgOptionList = [...SMS_TYPE_SEND_OPTIONS];
  } else if (isAllInboxType(selectedInboxTypeCode || '')) {
    if (
      conversationData?.conversationInbox?.channelType ===
      CHANNEL_TYPE.CHANNEL_TWILIO_SMS
    ) {
      sendMsgOptionList = [...SMS_TYPE_SEND_OPTIONS];
    } else if (conversationData?.conversationInbox?.channelType === CHANNEL_TYPE.CHANNEL_EMAIL) {
      sendMsgOptionList = [
        MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS,
      ];
    } else if (
      conversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPES.USER_BROADCAST ||
      conversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPES.INTERNAL
    ) {
      sendMsgOptionList = [MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS];
    } else if (
      conversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPES.PATIENT_BROADCAST
    ) {
      sendMsgOptionList = [
        MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS,
        MSG_SEND_OPTIONS.BROWSE_ARTICLES,
      ];
    }
  } else if (isSMSChannelInboxType(selectedInboxTypeCode || '')) {
    sendMsgOptionList = [...SMS_TYPE_SEND_OPTIONS];
  } else if (
    conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.USER_BROADCAST ||
    conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.INTERNAL
  ) {
    sendMsgOptionList = [
      MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS,
      MSG_SEND_OPTIONS.BROWSE_CONTACT,
    ];
  } else if (
    conversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPES.PATIENT_BROADCAST
  ) {
    sendMsgOptionList = [
      MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS,
      MSG_SEND_OPTIONS.BROWSE_ARTICLES,
    ];
  } else if (isChannelEmail(conversationData?.conversationInbox?.channelType)){
    sendMsgOptionList = [
      MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS,
      // MSG_SEND_OPTIONS.BROWSE_CONTACT,
    ];
  }
  return [...sendMsgOptionList, MSG_SEND_OPTIONS.MEDIA_ATTACHMENTS, MSG_SEND_OPTIONS.SCHEDULE_MESSAGE];
};

export const convertMsgObjectToReplyMsgObject = (
  msgObject: IMessageBoxData
): IReplyMessageObject => {
  return {
    id: msgObject.id,
    messageType: msgObject.messageType,
    position: msgObject.position,
    text: msgObject.displayContent,
    dateStr: msgObject.dateStr,
    conversationMessage: msgObject.messageData,
    senderFullName: msgObject.senderFullName,
    attachments: msgObject?.msgAttachment || [],
    // attachmentId: attachmentId || undefined,
    private: msgObject.private,
    uuid: msgObject.uuid || '',
  };
};

export const getCategoryCode = (
  selectedInboxTypeCode: string | undefined,
  inboxTypeFromConversation: string
) => {
  if (
    selectedInboxTypeCode == 'CHANNEL_EMAIL' ||
    inboxTypeFromConversation == CHANNEL_TYPE.CHANNEL_EMAIL
  ) {
    return RESPONSE_CATEGORY_CODE.EMAIL;
  } else if (
    selectedInboxTypeCode == 'CHANNEL_TWILIO_SMS' ||
    inboxTypeFromConversation == CHANNEL_TYPE.CHANNEL_TWILIO_SMS
  ) {
    return RESPONSE_CATEGORY_CODE.SMS;
  } else {
    return RESPONSE_CATEGORY_CODE.CHAT;
  }
};

export const getAttachmentsList = (msgWithAttachmentList: any[]) => {
  return msgWithAttachmentList?.filter((attachmentObj) => {
    return attachmentObj && attachmentObj?.id;
  });
};

export const getMessagingHeaderInfo = (
  conversation: any,
  ehrConfig: IAvailableEhr,
  additionalData?: {
    deceasedDate: string;
  }
): {
  headerString?: string;
  subtitleString?: string;
} => {
  const groupMembers = conversation?.groupConversation?.groupMembers || [];
  const groupMemberData = getGroupMemberList(groupMembers);
  const memberList = groupMemberData?.groupMembersInfoList;

  const displayName = groupMemberData?.patientData?.name || memberList[0]?.name;

  if (isPrivateGroup(conversation as any)) {
    let contactTypeStr;
    if (conversation) {
      contactTypeStr = getPatientAgeAndGenderStr(conversation, ehrConfig, undefined, undefined, additionalData);
    }
    if (conversation?.groupConversation?.name) {
      return {
        headerString: setHeaderTitle(conversation),
        subtitleString: contactTypeStr,
      };
    } else {
      return {
        headerString: getAvatarFullName(conversation as any, true),
        subtitleString: contactTypeStr,
      };
    }
  } else {
    return {
      headerString:
        conversation?.groupConversation?.name &&
        conversation?.groupConversation?.name,
      subtitleString: `${groupMemberData?.groupMembersInfoList?.length} ${groupMemberData?.groupMembersInfoList?.length > 1 ? 'Members' : 'Member'
        } `,
    };
  }
};

export const setHeaderTitle = (conversation: any) => {
  return conversation?.groupConversation?.name || CONVERSATION_CONST.GROUP_CHAT;
};

export const isLoginUserDeletedFromInbox = (
  selectedConversation: IConversationData,
  userData: ILoginUserData
) => {
  let isUserDeleted = false;
  if (
    selectedConversation?.inboxId &&
    selectedConversation?.inboxId > 0 &&
    selectedConversation?.conversationInbox?.inboxMembers?.length
  ) {
    const inboxUsers = selectedConversation?.conversationInbox?.inboxMembers;
    for (let userIndex = 0; userIndex < inboxUsers.length; userIndex++) {
      const currentUser = inboxUsers[userIndex];
      if (currentUser.userId === userData.id && currentUser.isDeleted) {
        isUserDeleted = currentUser.isDeleted;
        break;
      }
    }
  }
  return isUserDeleted;
};

export const getShortLink = async (link: string) => {
  const axios = BaseService.getSharedInstance().axios;
  const body = {longLink: link};
  const result = await axios.post(CRM_PUBLIC_BASE_URL + `/shortLink`, body);
  return result?.data?.shortLink || link;
};

export const getSendContactVariables = (data: IGetMessageBodyVariables) => {
  const contactCardJson = {
    resourceCode: data.messageCustomCardKey,
    resourceData: {
      resourceId: data.contactUUID,
    },
  };
  return {
    echo_id: getMsgEchoId(),
    private: data.privateNote,
    cc_emails: '',
    bcc_emails: '',
    content: JSON.stringify(contactCardJson),
    content_type: MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD,
  };
};

export const getMessageBodyWithAllParams = (
  messageCustomCardKey: MESSAGE_CUSTOM_CARD_KEY_NAME,
  accountId: number,
  conversationId: number,
  browseContactUUID: string,
  privateNote: any
) => {
  const getMessageBodyVariables: IGetMessageBodyVariables = {
    contactUUID: browseContactUUID,
    privateNote: privateNote,
    messageCustomCardKey: messageCustomCardKey as MESSAGE_CUSTOM_CARD_KEY_NAME,
  };

  const messageBody = getSendContactVariables(getMessageBodyVariables);
  const messageBodyWithAllParams: IMessageParams = {
    messageBody: messageBody,
    accountId: accountId,
    conversationId: conversationId,
  };
  return messageBodyWithAllParams;
};


export const messageDataKeysConverter = (messageData: any) => {
  if (messageData && Object.keys(messageData) && Object.keys(messageData)?.length) {
    const messageDataKeys = Object.keys(messageData);
    messageDataKeys.forEach((singleKey) => {
      if (singleKey) {
        const conKey = StrCaseConverter.toSnakeCase(singleKey);
        messageData[conKey] = messageData[singleKey];
      }
    })
  }
  return messageData;
};

export const getButtonActionObject = (status: any) => {
  let buttonObj: any = {};
  if (status == 0) {
    buttonObj = {
      title: 'Archive',
      nextStatusCode: 1,
      currentStatusCode: status,
      code: 'OPEN',
    };
  } else if (status == 1) {
    buttonObj = {
      title: 'Unarchive',
      nextStatusCode: 0,
      currentStatusCode: status,
      code: 'RESOLVED',
    };
  } else if (status == 2) {
    // buttonObj = {
    //   title: 'Open',
    //   nextStatusCode: 0,
    //   currentStatusCode: status,
    //   code: 'PENDING',
    // };
    //if pending then show same as status 0
    buttonObj = {
      title: 'Archive',
      nextStatusCode: 1, //0
      currentStatusCode: 0,//2
      code: 'OPEN',
    };
  } else {
    //if -1
    buttonObj = {
      title: 'Archive',
      nextStatusCode: 1,
      currentStatusCode: 0,//-1
      code: 'OPEN',
    };
  }
  return buttonObj;
};

export const isJsonString = (str: any) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

export const canShowArchive = (selectedInboxTypeCode: string, conversation?: IConversationData) => {
  const groupTypeCode = conversation?.groupConversation?.groupType?.code;
  const isBroadCast =
    groupTypeCode === GROUP_TYPE_CODES.PATIENT_BROADCAST ||
    groupTypeCode === GROUP_TYPE_CODES.USER_BROADCAST;
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    return false
  }
  if (conversation) {
    return !isInternalChat(conversation) && !isBroadCast;
  }
  return false;
};

export const isBroadCastGroup = (conversation?: IConversationData) => {
  const groupTypeCode = conversation?.groupConversation?.groupType?.code;
  const isBroadCast =
    groupTypeCode === GROUP_TYPE_CODES.PATIENT_BROADCAST ||
    groupTypeCode === GROUP_TYPE_CODES.USER_BROADCAST;
  return isBroadCast;
};

export const isUserMentionedInSelectedConversation = (args: {
  selectedConversation: IConversationData;
}) => {
  const {selectedConversation} = args;
  if (!areUnreadMessagesPresentInSelectedConversation({selectedConversation})) {
    return false;
  }
  const unreadMessages = selectedConversation.unreadMessages;
  const result = unreadMessages?.find((item) => item.messageMentions && item?.messageMentions?.length > 0);
  return result?.id;
};

export const isMissedCallMessageInUnreadMessages = (args: {
  selectedConversation: IConversationData;
}) => {
  const {selectedConversation} = args;
  if (!areUnreadMessagesPresentInSelectedConversation({selectedConversation})) {
    return false;
  }
  const unreadMessages = selectedConversation?.unreadMessages;
  const result = unreadMessages?.find(
    (item) =>
      item?.messageType == MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD
  );
  return result?.id;
};

export const areUnreadMessagesPresentInSelectedConversation = (args: {
  selectedConversation: IConversationData;
}) => {
  const {selectedConversation} = args;
  return selectedConversation.unreadMsgCount > 0;
};

export const isInternalChatConversation = (args: {
  selectedConversation: IConversationData;
}) => {
  const {selectedConversation} = args;
  const groupCode =
    selectedConversation?.groupConversation?.groupType?.code || '';
  return groupCode === GROUP_TYPE_CODES.INTERNAL;
}

export const setSearchParamsForSelectedConversation = (args: {
  selectedConversation: IConversationData;
  searchParams: URLSearchParams
  setSearchParams: (params: URLSearchParams) => void
}) => {
  const {selectedConversation, searchParams, setSearchParams} = args;
  const selectedConversationId = selectedConversation.displayId;
  const areUnreadMessagesPresent =
    selectedConversation?.unreadMsgCount > 0;
  const conversationChannelType =
    selectedConversation?.conversationInbox?.channelType || '';
  const isMissedCallMessage = isMissedCallMessageInUnreadMessages({
    selectedConversation,
  });
  const isUserMentioned = isUserMentionedInSelectedConversation({
    selectedConversation,
  });
  const isInternalConversation = isInternalChatConversation({
    selectedConversation,
  });
  const inboxId = selectedConversation.inboxId;
  const status = selectedConversation.status;
  const assigneeId = selectedConversation.assigneeId;
  searchParams.set(
    'selectedConversationId',
    selectedConversationId?.toString()
  );
  searchParams.set('assigneeId', assigneeId?.toString());
  searchParams.set('status', status?.toString());
  searchParams.set('inboxId', inboxId?.toString());
  searchParams.set('channelType', conversationChannelType);
  if (areUnreadMessagesPresent) {
    searchParams.set('updateUnreadCount', 'true');
  } else {
    searchParams.set('updateUnreadCount', 'false');
  }
  if (isUserMentioned) {
    searchParams.set('isUserMentioned', 'true');
  } else {
    searchParams.set('isUserMentioned', 'false');
  }
  if (isMissedCallMessage) {
    searchParams.set('isMissedCallMessage', 'true');
  } else {
    searchParams.set('isMissedCallMessage', 'false');
  }
  if (isInternalConversation) {
    searchParams.set('isInternalConversation', 'true');
  } else {
    searchParams.set('isInternalConversation', 'false');
  }
  setSearchParams(searchParams);
}
export const isGroupMember = (memberID: string, groupMembers: IGroupMember[]) => {
  return groupMembers?.some(member => memberID === member?.groupUserId);
}

export const isInboxMember = (memberID: string, InboxMembers: IInboxMembers[]) => {
  return InboxMembers?.some(member => memberID === member?.user?.uuid);
}

export const getConversationMentionTabData = (selectedInboxTypeCode: string, item: IConversationData) => {
  if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    return {
      conversationMentionId: item?.conversationMentionId || '',
      isRead: !!item?.isRead,
    };
  }
  const messageMentions =
    item?.conversationMessages?.[0]?.messageMentions &&
    item?.conversationMessages?.[0]?.messageMentions[0];
  if (messageMentions?.id) {
    item.conversationMentionId = messageMentions.id;
  }
  return {
    conversationMentionId: messageMentions?.id || '',
    isRead: !!messageMentions?.isRead || true,
  };
}


export const getMentionObjects = (args: {
  conversationId: number;
  messageBoxData: IMessageBoxData[];
}): IMentionObject[] => {
  const {conversationId, messageBoxData} = args;
  const result: IMentionObject[] = [];
  messageBoxData.forEach((item) => {
    const messageMentions = item?.messageMentions || [];
    if (messageMentions && messageMentions.length > 0 && !messageMentions[0].isRead) {
      result.push({
        conversationId: conversationId,
        mentionUuid: messageMentions[0].id,
        messageId: item.id,
      });
    }
  });
  return result;
};

export const getAvatarNameAndType = (userUuid: string, conversation?: IConversationData) => {
  if (conversation?.groupConversation?.groupType?.code === GROUP_TYPE_CODES.INTERNAL) {
    if (conversation?.groupConversation?.groupMembers?.length < 2) {
      const filteredNotLoggedInUser = conversation.groupConversation?.groupMembers?.filter(user => {
        return user.user?.uuid !== userUuid
      })
      const sortUserByAlphabeticalOrder = sortUserMembersByName(filteredNotLoggedInUser)
      return {
        userType: GROUP_MEMBER_TYPE.USER,
        name: sortUserByAlphabeticalOrder[0]?.user?.name || ''
      }
    }
    if (conversation.groupConversation?.groupMembers?.length === 2) {
      const filteredNotLoggedInUser = conversation.groupConversation?.groupMembers?.filter(user => {
        return user.user?.uuid !== userUuid
      })
      return {
        userType: GROUP_MEMBER_TYPE.USER,
        name: filteredNotLoggedInUser[0].user?.name || ''
      }
    } else if (conversation.groupConversation?.groupMembers?.length > 2) {
      const filteredNotLoggedInUser = conversation.groupConversation?.groupMembers?.filter(user => {
        return user.user?.uuid !== userUuid
      })
      const sortUserByAlphabeticalOrder = sortUserMembersByName(filteredNotLoggedInUser)
      const groupMemberCount =
        conversation?.groupConversation?.groupMembers_aggregate?.aggregate
          ?.count || conversation.groupConversation?.groupMembers?.length;
      const updatedName = `${
        sortUserByAlphabeticalOrder[0].user?.name.split(' ')[0]
      }, ${sortUserByAlphabeticalOrder[1].user?.name.split(' ')[0]} + ${
        groupMemberCount - 2
      } ${groupMemberCount > 3 ? 'members' : 'member'}`;
      return {
        userType: GROUP_MEMBER_TYPE.USER,
        name: updatedName || ''
      }
    }
  } else if (conversation) {
    const contactInfo: IContactInfo = getContactDataAndType(conversation);
    const contactName = contactInfo?.contactData?.name;
    if (contactName) {
      return {
        userType: GROUP_MEMBER_TYPE.CONTACT,
        name: contactName
      }
    }
  }
  return {
    userType: GROUP_MEMBER_TYPE.CONTACT,
  }
}

export const sortUserMembersByName = (userMemberList: IGroupMember[]) => {
  return userMemberList?.sort((userMemberOne, userMemberTwo): any => {
    return userMemberOne?.user?.name.localeCompare(userMemberTwo?.user?.name || '')
  });
}

export const getCreateChannelTabCode = (selectedTabCode: string) => {
  if (selectedTabCode === CHANNEL_TYPE_CODE.CHANNEL_INTERNAL) {
    return CONVERSATION_TAB_CODES.INTERNAL;
  } else {
    return CONVERSATION_TAB_CODES.PRIVATE;
  }
};

export const getMessageUuidsFromApiResponse = (
  apiResponse: IConversationMessageData
) => {
  const {conversationMessages} = apiResponse;
  const messageUuids: string[] = [];
  conversationMessages.forEach((item) => {
    if (item.uuid) {
      messageUuids.push(item.uuid);
    }
  });
  return messageUuids;
};

export const addCommunicationTypesByMessageApiResponse = async (
  apiResponse: IConversationMessageData,
  displayMsgList: IMessageBoxData[]
) => {
  const messageUuids = getMessageUuidsFromApiResponse(apiResponse);
  const response = await getCommunicationTypesByMessageUuids(messageUuids);
  const messageCommunicationTypeMap = getMessageCommunicationTypeMap(
    response.data
  );
  const data = [...displayMsgList];
  data.forEach((item) => {
    const uuid = item.uuid;
    item.communicationTypes = messageCommunicationTypeMap[uuid] || [];
  });
  return data;
};

export const addCommunicationTypesByMessageUuids = async (data: {
  messageUuids: string[]
  displayMsgList: IMessageBoxData[]
}) => {
  const {displayMsgList, messageUuids} = data;
  const response = await getCommunicationTypesByMessageUuids(messageUuids);
  const messageCommunicationTypeMap = getMessageCommunicationTypeMap(
    response.data
  );
  const messageList = [...displayMsgList];
  messageList.forEach((item) => {
    const uuid = item.uuid;
    item.communicationTypes =
      messageCommunicationTypeMap[uuid] || item.communicationTypes;
  });
  return messageList;
}

export const addMessageStatusToMessageList = async (data: {
  apiResponse: IConversationMessageData,
  displayMsgList: IMessageBoxData[]
}) => {
  const {apiResponse, displayMsgList} = data;
  const messageIds = getMessageIdsFromApiResponse(apiResponse);
  const messageData = [...displayMsgList];
  if (messageIds?.length) {
    const smsStatus = (await getSMSStatusByMessageIds({messageIds})) || [];
    messageData.forEach((item) => {
      const statusObject = smsStatus?.find((s: any) => s.messageId === item.id);
      if (statusObject?.errorCode){
        const errorData = {
          errorCode: statusObject?.errorCode,
          message: statusObject?.twilioErrorCodes?.customMessage || statusObject?.twilioErrorCodes?.message,
        }
        item.errorData = errorData;
      }
    });
  }
  return messageData;
}

export const getMessageIdsFromApiResponse = (apiResponse: IConversationMessageData) => {
  const {conversationMessages} = apiResponse;
  const messageIds: number[] = [];
  conversationMessages.forEach((item) => {
    if (item.id && (item.status === MESSAGE_STATUS.failed) ) {
      messageIds.push(item.id);
    }
  });
  return messageIds;
}

export const getSMSStatusByMessageIds = async (data: {messageIds: number[]}) => {
  const {messageIds} = data;
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/message-sms-status`;
  try {
    const response = await baseService.post(url, {messageIds});
    return response.data;
  } catch (error) {
    return [];
  }
}

export const getMessageCommunicationTypeMap = (
  data: ICommunicationTypeByMessageUuidData[]
) => {
  const result: {[index in string]: ICommunicationType[]} = {};
  data.forEach((item) => {
    const messageUuid = item.messageUuid;
    if (!result[messageUuid]) {
      result[messageUuid] = [];
    }
    result[messageUuid].push(item.communicationType);
  });
  return result;
}
export const getUpdatedConversationResponse = (
  apiMessageData: IConversationMessageData,
  parentMessages: IConversationMessage[]
) => {
  const messageData: IConversationMessageData = Object.assign(apiMessageData);
  if (parentMessages.length) {
    parentMessages.forEach((parentMessageObj) => {
      messageData.conversationMessages?.forEach((item) => {
        if (parentMessageObj?.id === item?.parentMessage?.id) {
          item.parentMessage = parentMessageObj;
        }
      });
    });
  }
  return messageData;
};

export const isSMSNotSent = (status: number) => {
  return (
    status !== undefined &&
    (status === MESSAGE_STATUS.failed)
  );
};

export const getMessageUuidsFromCopyMessageList = (data: {
  copyMessageList: ICopiedMessageItem[];
}) => {
  const {copyMessageList} = data;
  const result: string[] = [];
  copyMessageList.forEach((item) => {
    if (item.uuid) {
      result.push(item.uuid);
    }
  });
  return result;
};

export const isCommunicationTypeAssignedToSelectedMessages = (data: {
  uuids: string[],
  displayData: IMessageBoxData[]
}) => {
  const {displayData, uuids} = data;
  const selectedMessages = displayData.filter((item) => {
    return uuids.includes(item.uuid);
  });
  return !!selectedMessages?.find(
    (item) => item.communicationTypes && item.communicationTypes.length > 0
  );
}

export const handleMarkMentionAsUnread = async (ids: string[], status: boolean) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = '/crm-nest/platform';
  const mutationPostObject = {
    operationName: 'ReadMentionMessage',
    query: ConversationsQueries.ReadMentionMessage,
    variables: {
      ids: ids,
      status: status
    },
  };
  const response = await baseService.post(url, mutationPostObject);
  return response.data;
};

export const formateMessageWithMention = (message: string) => {
  const regex = /\[@([^]+?)\]\(mention:\/\/[^)]+\)/g;

  const matches = [...message.matchAll(regex)];

  const mentionData = matches.map(match => ({
    mention: match[0], // The entire mention
    name: match[1] // The extracted name
  }));

  let formattedText = message;
  mentionData.forEach(({mention, name}) => {
    formattedText = formattedText.replace(mention, `${name}`);
  });
  return formattedText
}

export const getMessagingHeaderInfoInJSON = (
  pcpUserData: IUser | any,
  showInfoOnHeaderData: IInfoOnMessageWindowHeader,
  contactData: IContact,
  userUuid: string,
  ehrConfig: IAvailableEhr,
  selectedInboxTypeCode?: string,
  conversation?: IConversationData,
  isInstantChatView?: boolean,
  additionalData?: {
    deceasedDate?: string;
  }
) => {
  const channelType = conversation?.conversationInbox?.channelType || '';
  const avatarNameAndType = getAvatarNameAndType(userUuid, conversation);
  const groupMembers = conversation?.groupConversation?.groupMembers || [];
  const groupMemberData = getGroupMemberList(groupMembers);
  const memberList = groupMemberData.groupMembersInfoList;
  let contactTypeStr: IMessageSubtitleHeaderInfo[] = [];
  if (!!conversation) {
    contactTypeStr = getPatientAgeAndGenderJson(
      conversation,
      ehrConfig,
      pcpUserData?.name,
      isInstantChatView,
      additionalData
    );
  }
  const choosenName =
  !isBroadCastGroup(conversation as IConversationData) &&
   showInfoOnHeaderData?.SHOW_CHOSEN_NAME &&
    contactData?.person?.chosenName?.length
      ? ' (' + contactData?.person?.chosenName + ')'
      : '';

  if (isPrivateGroup(conversation as any)) {
    if (conversation?.groupConversation?.name) {
      return {
        headerString: setHeaderTitle(conversation) + choosenName,
        subtitleData: contactTypeStr,
      };
    } else {
      return {
        headerString: getAvatarFullName(
          conversation as any,
          true,
          '',
          contactData
        ),
        subtitleData: contactTypeStr,
      };
    }
  } else if (isInternalChat(conversation as any)) {
    let headerStringName = '';
    if (memberList.length === 2 && !conversation?.groupConversation?.name) {
      memberList.map((userData: any) => {
        if (userData?.uuid !== userUuid) {
          headerStringName = userData?.name;
        }
      });
    }
    const memberCount =
      conversation?.groupConversation?.groupMembers_aggregate?.aggregate
        ?.count || groupMemberData?.groupMembersInfoList?.length;
    return {
      headerString: headerStringName
        ? headerStringName
        : conversation?.groupConversation?.name
        ? conversation?.groupConversation?.name
        : avatarNameAndType?.name,
      subtitleData: [
        {
          memberCountStr: `${memberCount} ${
            memberCount > 1
              ? 'Members'
              : 'Member'
          } `,
        },
      ],
    };
  } else if (isChannelTwillioSms(channelType)) {
    return {
      headerString:
        conversation?.groupConversation?.name &&
        conversation?.groupConversation?.name + choosenName,
      subtitleData: contactTypeStr,
    };
  } else if (isChannelEmail(selectedInboxTypeCode || '')) {
    return {
      headerString:
        (conversation?.groupConversation?.name ||
          CONVERSATION_CONST.GROUP_CHAT) + choosenName,
      subtitleData: contactTypeStr,
    };
  } else {
    return {
      headerString:
        (conversation?.groupConversation?.name ||
          CONVERSATION_CONST.GROUP_CHAT) + choosenName,
      subtitleData: [
        {
          memberCountStr: `${groupMemberData?.groupMembersInfoList?.length} ${
            groupMemberData.groupMembersInfoList.length > 1
              ? 'Members'
              : 'Member'
          } `,
        },
      ],
    };
  }
};

export const getPatientAgeAndGenderJson = (
  singleConversationData: IConversationData,
  ehrConfig: IAvailableEhr,
  pcpUserName?: string,
  isInstantChatView?: boolean,
  additionalData?: {
    deceasedDate?: string;
  }
) => {
  const detail: IMessageSubtitleHeaderInfo[] = [];
  const contactInfo: any = getContactDataAndType(singleConversationData);

  const contactData: IGroupContact = contactInfo.contactData;
  const contactType = contactInfo.contactType;

  if (
    singleConversationData?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.INTERNAL
  ) {
    const groupName = singleConversationData?.groupConversation?.name;
    detail.push({name: groupName});
  } else {
    const contactTypeStr = getContactTypeValue(contactType);
    detail.push({type: contactTypeStr});
    const ageAndGenderText = getAgeAndGenderJson(contactData, isInstantChatView, additionalData);
    // const ageAndGenderText = getAgeAndGenderJson(contactData,);
    if (ageAndGenderText.length) {
      detail.push(...ageAndGenderText);
    }
  }
  const groupMemberCount =
    singleConversationData?.groupConversation?.groupMembers_aggregate?.aggregate
      ?.count ||
    singleConversationData?.groupConversation?.groupMembers?.length;
  let str = '';
  if (groupMemberCount) {
    str = `${groupMemberCount}`;
    detail.push({
      memberCountStr: str + `${groupMemberCount > 1 ? ' members' : 'member'}`,
    });
  }
  if (pcpUserName?.length && !isInstantChatView) {
    detail.push({pcpUserName: pcpUserName + '(PCP)'});
  }
  const location =
    contactData?.contactPracticeLocations?.[0]?.practiceLocation?.name;
  if (location?.length && !isInstantChatView) {
    detail.push({location: location});
  }

  if (singleConversationData?.conversationInbox?.name?.length) {
    detail.push({inboxName: singleConversationData?.conversationInbox?.name});
  }
  const mergedData: any = {};
  detail.forEach((item: any) => {
    for (const key in item) {
      if (item?.hasOwnProperty(key)) {
        mergedData[key] = item?.[key];
      }
    }
  });

  return [mergedData];
};

export const getAgeAndGenderJson = (
  contactData: IGroupContact ,
  isInstantChatView?: boolean,
  additionalData?: {
    deceasedDate?: string;
  }
) => {
  const ageAndGenderDetail: IMessageSubtitleHeaderInfo[] = [];
  let ageInYears;
  if (!isWeb()) {
    ageInYears = contactData?.person?.birthDate
      ? getDateStrFromFormat(
          contactData?.person?.birthDate,
          DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
        ) +
        '(' +
        getAgeValue(contactData?.person?.birthDate, additionalData?.deceasedDate || '') +
        ')'
      : '';
  } else {
    ageInYears = contactData?.person?.birthDate
      ? getDateStrFromFormat(
          contactData?.person?.birthDate,
          DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
        )
      : '';
  }
  if (ageInYears?.length) {
    ageAndGenderDetail.push({
      dateOfBirth: {
        ...getDOBHoverText(contactData, additionalData),
      },
    });
  }
  const genderValue = getGenderAndSexAtBirthByGenderCode(contactData);
  ageAndGenderDetail.push({
    birthSex: genderValue?.birthSex,
    gender: {
      ...genderValue?.gender,
      ...getGenderAndSexAtBirthHoverText(genderValue)
    },
  });
  return ageAndGenderDetail;
};

export const getDOBHoverText = (contactData: IGroupContact, additionalData?: {
  deceasedDate?: string;
}) => {
  let hoverText = '';
  let labelText = '';
  if (contactData?.person?.birthDate) {
    labelText = getDateStrFromFormat(
      contactData?.person?.birthDate,
      DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
    );
    hoverText = getAgeValue(contactData?.person?.birthDate, additionalData?.deceasedDate || '');
  } else {
    hoverText = '';
    labelText = '';
  }
  return {
    dobHoverText: hoverText,
    dobLabelText: labelText,
  };
};

export const getGenderAndSexAtBirthHoverText = (
  genderValue: IMessageSubtitleHeaderInfo
) => {
  let hoverText = '';
  let labelText = '';
  if (
    genderValue?.gender?.code?.length &&
    genderValue?.gender?.code !== 'Unknown'
  ) {
    hoverText =
      genderValue?.birthSex?.length &&
      (genderValue?.gender?.code == 'Male' ||
        genderValue?.gender?.code == 'Female')
        ? capitalizeText(genderValue?.birthSex + ' At Birth')
        : genderValue?.gender?.genderFullValue +
          ' (' +
          capitalizeText(genderValue?.birthSex + ' At Birth') +
          ')';

    labelText = genderValue?.gender?.code;
  } else if (
    genderValue?.birthSex?.length &&
    !genderValue?.gender?.code?.length
  ) {
    hoverText = 'Gender is not set';
    labelText = genderValue?.birthSex;
  } else if (
    genderValue?.birthSex?.length && genderValue?.birthSex !== 'Unknown' &&
    genderValue?.gender?.code === 'Unknown'
  ) {
    hoverText = `Gender 'Unknown'`;
    labelText = genderValue?.birthSex;
  } else if (
    genderValue?.gender?.code === 'Unknown' &&
    genderValue?.birthSex === 'Unknown'
  ) {
    hoverText = 'Unknown At Birth';
    labelText = genderValue?.gender?.code;
  }
  return {
    genderHoverText: hoverText,
    genderLabelText: labelText,
  };
};

export const getGenderAndSexAtBirthByGenderCode = (contactData: any) => {
  const genderData: IMessageSubtitleHeaderInfo = {
    birthSex: getGenderFullForm(contactData?.person?.sexAtBirth?.value),
    gender: {
      code: getGenderFullFormByCode(contactData?.person?.gender?.code),
      genderFullValue: contactData?.person?.gender?.value,
    },
  };
  return genderData;
};

export const getGenderAndSexAtBirthByGenderCodeStr = (contactData: any) => {
  if (contactData?.person?.gender?.code?.length && contactData?.person?.gender?.code !== 'unknown') {
    return getGenderFullFormByCode(contactData?.person?.gender?.code);
  } else if (contactData?.person?.sexAtBirth?.value?.length) {
    return getGenderFullForm(contactData?.person?.sexAtBirth?.value);
  }
  return '';
};

export const getGenderFullFormByCode = (genderValue: string) => {
  if (genderValue) {
    if (genderValue.toLowerCase().includes('female')) {
      return 'Female';
    } else if (genderValue.toLowerCase().includes('male')) {
      return 'Male';
    } else if (genderValue.toLowerCase().includes('unknown')) {
      return 'Unknown';
    } else {
      return 'Other';
    }
  } else {
    return '';
  }
}

export const filterMembersByUserType = (
  memberList: IGroupMember[],
  userTypes: IUserType[]
) => {
  return memberList.filter((member) => {
    return !userTypes.includes(member.groupUserType.code as IUserType);
  });
};

export const isArchiveOnSend = (preferenceData?: IUserPreferences) => {
  const pref = preferenceData?.preferencesJson
  ? JSON.parse(preferenceData?.preferencesJson)
  : {};
  return pref?.userMessages?.[FeatureKey.isArchiveConversationOnMessageSend];
}

export const getPlaceholder = (
  intl: IntlShape,
  privateNote: string,
  selectedInboxTypeCode?: string,
  isEnterForNewLine?: boolean,
  isSideCarContext?: boolean,
  isInDrawerView?: boolean,
) => {
  if (
    isInternalChannelInboxType(selectedInboxTypeCode || '') ||
    privateNote === 'true'
  ) {
    if (isWeb()) {
      if (isEnterForNewLine) {
        return intl.formatMessage({
          id: (isSideCarContext || isInDrawerView ) ? 'shiftEnterToSendTheMessage' : 'visibleOnlyToYourTeamShiftEnterToSendTheMessage',
        });
      } else {
        return intl.formatMessage({
          id: (isSideCarContext || isInDrawerView) ? 'shiftEnterToStartANewLine' :  'visibleOnlyToYourTeamShiftEnterToStartANewLine',
        });
      }
    }
    return intl.formatMessage({id: 'visibleOnlyToYourTeam'});
  } else {
    if (isWeb()) {
      if (isEnterForNewLine) {
        return intl.formatMessage({
          id: (isSideCarContext || isInDrawerView) ? 'shiftEnterToSendTheMessage' : 'visibleToEveryoneShiftEnterToSendTheMessage',
        });
      } else {
        return intl.formatMessage({
          id: (isSideCarContext || isInDrawerView) ? 'shiftEnterToStartANewLine' : 'visibleToEveryoneShiftEnterToStartANewLine',
        });
      }
    }
  }
  return intl.formatMessage({id: 'visibleToEveryone'});
};

export function sortMessages(messages: IMessageBoxData[]) {
  const uniqueMessageList = getUniqDisplayMessageList(messages);
  return uniqueMessageList?.sort((m1, m2) => {
    if (!m2.dateStr || !m1.dateStr) {
      return 1;
    }
    return m2.dateInNumber - m1.dateInNumber;
  }) || [];
}

export const sortConversationMessages = (messages: IConversationMessage[]) => {
  const uniqueMessageList = uniqBy(messages, (msgData) => {
    return msgData.id;
  });
  return (
    uniqueMessageList?.sort((m1, m2) => {
      if (!m2.createdAt || !m1.createdAt) {
        return 1;
      }
      return (
        new Date(m2.createdAt).getTime() - new Date(m1.createdAt).getTime()
      );
    }) || []
  );
};

export function getMessageDataFromId(messages: IMessageBoxData[], id: number) {
  return messages?.find((singleMessage) => {
    return (singleMessage?.id === id);
  });
}

 export const getMessageUuid = (msgDisplayDataList: IConversationMessage[]) => {
   const messageUuids: string[] = [];
   msgDisplayDataList.forEach((msgData) => {
     if (msgData?.uuid) {
       messageUuids.push(msgData.uuid);
     }
   });
   return messageUuids;
 };

 export const isMessageCopied = (
   copyMessageList: ICopiedMessageItem[],
   copyCode: string
 ) => {
   if (
     copyMessageList?.length > 0 &&
     (copyCode === COPY_MESSAGE_CODE.COPY_MODE_ON ||
       copyCode === COPY_MESSAGE_CODE.COPY_MODE_COUNT)
   ) {
     return true;
   }
   return false;
 };

 export const getBlockNumberActionMessage = (
   patientName: string,
   phoneNumber: string,
   intl: IntlShape
 ): string => {
   const blockMessage = intl.formatMessage({id: 'blockNumberActionMessage'});
   const BlockNumberActionKeyValue = {
     PATIENT_NAME: patientName,
     PHONE_NUMBER: phoneNumber,
   };
   const updatedMessage = replaceHashValueToString(
     BlockNumberActionKeyValue,
     blockMessage,
   );
   return updatedMessage;
 };

 export const getSenderName = (
  messageData: IConversationMessage | IMessageBoxData,
) => {
  if (
    messageData?.senderType?.toLowerCase() === SENDER_TYPE?.USER.toLowerCase()
  ) {
    return messageData?.senderUser?.name || '';
  } else if (
    messageData?.senderType?.toLowerCase() === SENDER_TYPE?.CONTACT.toLowerCase()
  ) {
    return messageData?.senderContact?.name || '';
  }
  return '';
};

 export const checkAnyMessageSenderDeleted = (
   msgDisplayDataList: IConversationMessage[]
 ) => {
  if (msgDisplayDataList?.length) { 
    for (const msgData of msgDisplayDataList) {
      if (checkMsgSenderDeleted(msgData)) {
        return true;
     }
   }
  }
   return false;
 };

 export const getUpdatedSenderUserData = (
   msgData: IConversationMessage[],
   deletedSenderDetails: IDeletedMsgSender[],
 ) => {
   if (deletedSenderDetails && deletedSenderDetails?.length > 0 && msgData) {
     msgData?.forEach((data) => {
       if (checkMsgSenderDeleted(data)) {
         const senderDetails = deletedSenderDetails?.find(
           (item: any) =>
             item?.id === data?.senderId &&
             item?.senderType === data?.senderType
         );
         if (data?.senderType === SENDER_TYPE.CONTACT) {
           data.senderContact = senderDetails;
         } else {
           data.senderUser = senderDetails;
         }
       }
     });
   }
   return msgData;
 };

 export const getDeletedSenderDetails = async (
   conversationData: IConversationData,
   abortSignal: AbortSignal
 ) => {
   try {
     const baseService = BaseService.getSharedInstance().axios;
     const createGroupUrl = `crm-nest/conversation/deleted-sender-details`;
     const senderInfo = await baseService.get(createGroupUrl, {
       params: {
         groupConversationsId: conversationData?.groupConversation?.id,
         inboxId: conversationData?.conversationInbox?.id,
         conversationContactId: conversationData?.contactId,
       },
       signal: abortSignal
     });
     if (senderInfo?.data) {
       return senderInfo?.data;
     }
     return [];
   } catch (e) {
     return [];
   }
 };

 export const handleDeletedContact = async (
   msgData: IConversationMessage[],
   conversationData: IConversationData,
   isAnySenderDeleted: boolean,
   abortSignal: AbortSignal
 ) => {
   if (msgData) {
     if (isAnySenderDeleted) {
       const response = await getDeletedSenderDetails(conversationData, abortSignal);
       return response;
     }
     return [];
   }
 };

export const getUpdatedTaskCountData = (
  displayData: IMessageBoxData[],
  index: number
) => {
  const updateMsgData = displayData[index];
  if (updateMsgData) {
    if (updateMsgData?.taskCount) {
      updateMsgData.taskCount = updateMsgData?.taskCount + 1;
    } else {
      updateMsgData.taskCount = 1;
    }
    return updateMsgData;
  }
  return displayData[index];
};

export const isGooglePixelDevice = async () => {
  let isGooglePixel = false;
  const deviceManufacturer = await DeviceInfo.getManufacturer();
  const deviceModel = DeviceInfo.getModel();

  if (
    deviceManufacturer?.toLowerCase() === DEVICES_NAME.GOOGLE &&
    deviceModel?.toLowerCase().includes(DEVICES_MODAL.PIXEL)
  ) {
    isGooglePixel = true;
  }

  return isGooglePixel;
};

export const isIncomingOrOutgoingNonActivityMessage = (messageType: number) => {
  return (
    messageType === 1 ||
    messageType === -1 ||
    messageType === 0 ||
    messageType === 8
  );
};

export const getLastNonInternalMessageInList = (
  displayData: IMessageBoxData[]
) => {
  const lastNonInternalMessageData = displayData?.find(
    (item) =>
      !item.private && isIncomingOrOutgoingNonActivityMessage(item.messageType)
  );
  return lastNonInternalMessageData;
};

export const getPhoneNumber = (rowData?: any) => {
  const phoneNumber =
    rowData?.phoneNumber ||
    getSecondaryValue(rowData?.personContact, 'phone') ||
    '';
  return phoneNumber;
};

export const getEmail = (rowData?: any) => {
  const email =
    rowData?.email ||
    getSecondaryValue(rowData?.personContact, 'email') ||
    '';
  return email;
};
export const getEventCodeFromParentCode = (parentCode?: string) => {
  if (parentCode === PARENT_CODE.MESSAGING_DETAIL_PANEL) {
    return CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_MORE_ACTIONS_FROM_DRAWER
  }
  return CONVERSATION_LOCAL_EVENT_CODES.CONVERSATION_MORE_ACTIONS
}

export const getMessagingFooterTabLabel = (
  conversationInboxType: string,
  tabCode?: string
) => {
  if (tabCode === CONVERSATION_TAB_CODES.PRIVATE) {
    return MSG_FOOTER_TAB_NAME.INTERNAL;
  }
  if (conversationInboxType === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
    return MSG_FOOTER_TAB_NAME.SMS;
  }
  if (conversationInboxType === CHANNEL_TYPE.CHANNEL_EMAIL) {
    return MSG_FOOTER_TAB_NAME.EMAIL;
  }
  return MSG_FOOTER_TAB_NAME.CHAT;
};


export const  getInitialMessageValueIfSaved = async (conversationUuid: string, draftType: IMessageDraftTypes, abortSignal: AbortSignal) => {
  const messageStorageInst = MessageStorage.getMessageStorageInstance();
  return await messageStorageInst.getSaveConversationMessage(conversationUuid, draftType, abortSignal);

}


export const saveConversationMessage = async (conversationUuid: string, draftData: Partial<IAllDraftBody>, draftType: IMessageDraftTypes) => {
  const messageStorage = MessageStorage.getMessageStorageInstance();
  if (conversationUuid) {
    messageStorage.saveConversationMessage(conversationUuid, draftData, draftType);
  }
}



export const removeConversationMessage = async (conversationUuid: string, draftType: IMessageDraftTypes) => {
  const messageStorage = MessageStorage.getMessageStorageInstance();
  if (conversationUuid) {
    return messageStorage.deleteDraftConversationMessage(conversationUuid, draftType);
  }
}
// For Local Data for conversation draft start
export const saveConversationListDraftMessagesInMessageInMessageStorage = (conversationsDraftData: IConversationDraftData) => {
  const messageStorage = MessageStorage.getMessageStorageInstance();
  if (conversationsDraftData && Object.keys(conversationsDraftData)?.length) {
    messageStorage.saveConversationsDraftData(conversationsDraftData);
  }
}

export const getSingleConversationDraftMessagesFromMessageInMessageStorage = (conversationUuid: string) => {
  const messageStorage = MessageStorage.getMessageStorageInstance();
  if (conversationUuid) {
    return messageStorage.getSingleConversationDraftData(conversationUuid);
  }
  return {} as IConversationReplyDraft;
}


export const deleteAllConversationsDraftDataFromMessageInMessageStorage = () => {
  const messageStorage = MessageStorage.getMessageStorageInstance();
  return messageStorage.deleteAllConversationsDraftData();
}

// For Local Data for conversation draft end

export const checkMsgSenderDeleted = (msgData: IConversationMessage) => {
  const isMsgSenderDeleted =
    msgData?.senderId &&
    (msgData?.messageType === MESSAGE_TYPE.incoming ||
      msgData?.messageType === MESSAGE_TYPE.outgoing) &&
    !msgData?.senderContact &&
    !msgData?.senderUser;
  return isMsgSenderDeleted;
};


export const getFilteredMentionMessage = (plainString: string) => {
  let filteredString = plainString;
    const isMentionPresent = mentionRegexForMessaging.test(plainString);
    if (isMentionPresent) {
      filteredString = plainString?.replace(mentionRegexForMessaging, (match, userName, type, uuid) => {
      return `@${userName}`;
      });
    }
    return filteredString
}

export const getFilterMemberListForMention = (
  memberList: IGroupMember[],
  privateNote: boolean,
  userId: number | string,
  inboxId?: number
) => {
  const filteredMemberList: IGroupMember[] = [
    ...getUniqueMemberListByUuid(memberList),
  ]?.filter(
    (member) =>
      member?.user?.accountUsers?.some((account) => account.isActive) ||
      [
        GROUP_MEMBER_TYPE.CONTACT,
        GROUP_MEMBER_TYPE.PATIENT
      ].includes(member?.groupUserType?.code as IUserType)
  );
  return filteredMemberList.filter((singleMember) => {
    const groupMemberName =
      singleMember?.user?.name ?? singleMember?.contact?.name;
    // user should not be able to mention him/her self and if there is no valid user/member
    if (!groupMemberName || singleMember?.user?.id === userId) {
      return false;
    }
    const lowercaseUserName = groupMemberName?.toLowerCase();
    if (privateNote) {
      if (singleMember?.groupUserType?.code === GROUP_MEMBER_TYPE.USER) {
        return lowercaseUserName;
      } else if (inboxId && inboxId !== -1) {
        return lowercaseUserName;
      }
    } else {
      return lowercaseUserName;
    }
  });
};

export const getMessageAttachmentData = async (data: IGetMessageAttachmentData) => {
  const {accountId, conversationDisplayId, messageIds} = data
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;
  const url = `/accounts/${accountId}/conversations/${conversationDisplayId}/messages_attachments`
  if (messageIds && messageIds?.length) {
    const msgAttachment = await axiosService.post(url, {
      message_ids: messageIds,
    })
    return msgAttachment?.data;
  }
  return [];
}

export const getMessageAttachmentWithResourceData = async (params: IGetMessageAttachmentWithResourceData, abortSignal: AbortSignal) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `/crm-nest/account/conversation/${params?.conversationUuid}/message/attachments-with-resource-data`;
  const msgAttachment = await baseService.post(url, {
    attachmentMessageIds: params?.attachmentMessageIds,
    smsStatusMessageIds: params?.smsStatusMessageIds,
    messageUuids: params?.messageUuids,
    conversationDisplayId: params?.conversationDisplayId
  }, {
    signal: abortSignal
  })
  return msgAttachment?.data;
}

export const getContactPCPData = async (params: IContactPCPReqData, abortSignal: AbortSignal) => {
  const baseService = BaseService.getSharedInstance().axios;
  const url = `crm-nest/api/contact/${params?.contactUuid}/pcp`;
  const pcpRespData = await baseService.post<IPCPUserData>(url, {
    careTeamTypeId: params?.careTeamTypeId,
  }, {
    signal: abortSignal
  })
  return pcpRespData?.data;
}

export const checkIsLoggedInUserConversationMember = (
  selectedConversation: IConversationData,
  userUuid: string
) => {
  let isLoggedInUserGroupMember = false;
  if (selectedConversation?.groupConversation?.id) {
    isLoggedInUserGroupMember = isGroupMember(userUuid, selectedConversation?.groupConversation?.groupMembers);
  } else if (selectedConversation?.conversationInbox?.id) {
    isLoggedInUserGroupMember = isInboxMember(
      userUuid,
      selectedConversation?.conversationInbox?.inboxMembers
    );
  }
  return isLoggedInUserGroupMember;
};
export const getSearchStringForCannedResponseForInput = (
  searchString: string
) => {
  if (!searchString) {
    return '';
  }
  const markdown = searchString;
  if (markdown.indexOf('\n') !== -1) {
    return markdown.substring(0, markdown.indexOf('\n'));
  }
  return markdown;
};

export const updateMentionWhenOnMentionTab = (
  selectedConversation: any,
  selectedInboxTypeCode: string
) => {
  const messageBus = MessageBus.getMessageBusInstance();
  if (selectedConversation?.isRead) {
    return;
  }
  if (!selectedConversation?.conversationMentionId) {
    return;
  }
  if (selectedInboxTypeCode != CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
    return;
  }
  if (!selectedConversation.conversationMessages?.length) {
    return;
  }
  const mentionObject: IMentionObject = {
    conversationId: selectedConversation.id,
    messageId: selectedConversation.conversationMessages?.[0]?.id || 0,
    mentionUuid: selectedConversation.conversationMentionId || '',
    updateOtherCounts: true,
  };
  messageBus.updateMentionStatus({mentionObjects: [mentionObject]});
};

export const getFormattedInboxMembers = (
  inboxMembers: IInboxMember[]
): IInternalUsers[] => {
  if (inboxMembers?.length) {
    const formattedData = inboxMembers.map((user: any) => {
      return {
        userId: user?.userId,
        user: user?.user,
      };
    });
    return formattedData;
  }
  return [];
};

export const getUserIdsFromUserData = (users: IInternalUser[]) => {
  if (users?.length) {
    const userIds = users.map((item: IInternalUser) => {
      return item?.id;
    });
    return userIds;
  }
  return [];
};

export const getSideCarSubStringObject = (
  contactData: IContact,
  deceasedDate: string,
  sideCarDashboardEnabled?: boolean
) => {
  const birthDate = getDateStrFromFormat(
    contactData?.person?.birthDate,
    DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
  );
  const age = getAgeValue(
    contactData?.person?.birthDate || '',
    deceasedDate || '',
    true
  );

  const genderData = getGenderAndSexAtBirthByGenderCode(contactData);
  let genderValue = genderData?.birthSex || genderData?.gender?.code;
  let data = `${genderValue} • ${age} (${birthDate})`;

  if(sideCarDashboardEnabled) {
    genderValue = genderValue === 'Female' ? 'F' : genderValue === 'Male' ? 'M' : 'O'
    data = `${genderValue} • ${age} (${birthDate})`
  }
  return data;
};

export const checkIsPatientNumberChanged = (sourceId: string, phoneNumber: string) => {
  const oldPatientNumber =
    sourceId
      ? getLastTenDigit(sourceId)
      : '';
  const newPatientNumber =
    phoneNumber
      ? getLastTenDigit(phoneNumber)
      : '';
  return oldPatientNumber !== newPatientNumber;
}

export const getFormatedChatConversationData = (
  selectedConversation: IConversationData,
  selectedConversationResponse: IConversationData
) => {
  let conversationData = selectedConversation;
  if (
    selectedConversation?.groupConversation?.groupType?.code ===
    GROUP_TYPE_CODES.INTERNAL
  ) {
    const groupConversation = selectedConversationResponse?.groupConversation;
    const groupMembers = [
      ...groupConversation?.groupMembers,
      ...(selectedConversation?.groupConversation?.groupMembers || []),
    ];
    const uniqueGroupMember = uniqBy(groupMembers, (item) => {
      return item?.groupUserId;
    });
    const updatedGroupConversation = {
      ...groupConversation,
      groupMembers: uniqueGroupMember,
    };
    conversationData = {
      ...selectedConversation,
      conversationContact: selectedConversationResponse?.conversationContact,
      groupConversation: updatedGroupConversation,
      unreadMessages: selectedConversationResponse?.unreadMessages,
    };
  } else {
    conversationData = {
      ...selectedConversation,
      conversationContact: selectedConversationResponse?.conversationContact,
      groupConversation: selectedConversationResponse?.groupConversation,
      unreadMessages: selectedConversationResponse?.unreadMessages,
    };
  }
  return conversationData;
};

export const getPatientLocationUuidFromGroupMembers = (groupMembers: IGroupMember[]) => {
  const data = groupMembers?.find((item) => {
    return item.groupUserType.code === GROUP_MEMBER_TYPE.PATIENT;
  });
  return data?.contact?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
};
export const isChatOrSmsConversation = (
  selectedConversation: IConversationData
): boolean => {
  const channelType =
    selectedConversation?.conversationInbox?.channelType || '';
  return (
    isGroupConversation(selectedConversation) ||
    isChannelTwillioSms(channelType)
  );
};

export const shouldSetMessageData = (data: IMessageCreated, userId: number) => {
  if (data?.content_attributes?.isScheduleMessage) {
    return true;
  }
  return (data?.sender_id !== userId)
}

export const getLastEmailMessageUuid = (
  messageList: IMessageBoxData[]
): string => {
  const lastEmailMessageIndex = messageList?.findIndex((item) =>
    isIncomingOrOutgoingNonActivityMessage(item?.messageType)
  );
  if (lastEmailMessageIndex == -1) {
    return '';
  }
  const lastEmailMessageUuid = messageList?.[lastEmailMessageIndex]?.uuid;
  return lastEmailMessageUuid || '';
};

export const extractPersonContact = (headerActionData: IMessagingWindowHeaderActionProps) => {
  return headerActionData?.formattedContactData?.personContact ?? 
         headerActionData?.selectedRowData?.personContact ?? 
         headerActionData?.selectedConversation?.conversationContact?.personContact;
};

export const extractContactConsents = (headerActionData: IMessagingWindowHeaderActionProps) => {
  return headerActionData?.formattedContactData?.contactConsents ?? 
         headerActionData?.selectedRowData?.contactConsents ?? 
         headerActionData?.selectedConversation?.conversationContact?.contactConsents;
};