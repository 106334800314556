import {Pressable, Text, View} from 'native-base';
import React from 'react';
import {ORDER_SUMMARY_DATE_FROMATED} from '../../../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import {Colors} from '../../../../../../../styles';
import {getDateStrFromFormat} from '../../../../../../../utils/DateUtils';
import {capitalizeText} from '../../../../../../common/ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import ContactProfileName from '../../OrderDetailView/MembershipOrderDetailView/ContactProfileName';
// import ActionsView from './ActionsView/ActionsView';
import TagView from './ActionsView/TagView';
export const getOrderTableViewColumns = (
  sortedInfo: any
  // onActionPerformed: (
  //   actionCode: string,
  //   rowData: any,
  //   checked?: boolean
  // ) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="number"
        />
      ),
      dataIndex: 'number',
      key: 'number',
      width: '15%',
      fixed: 'left',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text
                color={Colors.primary['500'] || ''}
                textDecorationLine={'underline'}
                size={'smMedium'}
              >
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="orderAt"
        />
      ),
      dataIndex: 'order_at',
      key: 'order_at',
      width: '17%',
      // sorter: (nameOne: any, nameTow: any) => {
      //   nameOne.order_at.localeCompare(nameTow.order_at);
      // },
      // sortOrder: sortedInfo.columnKey === 'order_at' && sortedInfo.order,
      // ellipsis: true,
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {' '}
                {getDateStrFromFormat(text, ORDER_SUMMARY_DATE_FROMATED)}{' '}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: <DisplayText size={'thMedium'} extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}} textLocalId="lineItems" />,
    //   dataIndex: 'rawOrderDataobj',
    //   key: 'rawOrderDataobj',
    //   width: '17%',
    //   sorter: (nameOne: any, nameTow: any) => {
    //     nameOne.order_at.localeCompare(nameTow.order_at);
    //   },
    //   sortOrder: sortedInfo.columnKey === 'line_item' && sortedInfo.order,
    //   ellipsis: true,
    //   render: (text: any): JSX.Element => {
    //     return (
    //       <View>
    //           <Text size={'smMedium'} color={Colors.Custom.Gray900} > {text.relationships.line_items.data.length} </Text>
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    // {
    //   title: <DisplayText size={'thMedium'} extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}} textLocalId="risky" />,
    //   dataIndex: 'risky',
    //   key: 'risky',
    //   width: '20%',
    //   sorter: (nameOne: any, nameTow: any) => {
    //     nameOne.name.localeCompare(nameTow.name);
    //   },
    //   // sortOrder: sortedInfo.columnKey === 'risky' && sortedInfo.order,
    //   ellipsis: true,
    //   render: (text: string): JSX.Element => {
    //     return (
    //       <View>
    //         {text === '' || text === null ? (
    //           <Text color={'gray.400'}>--</Text>
    //         ) : (
    //           <Text>{text}</Text>
    //         )}
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="state"
        />
      ),
      dataIndex: 'state',
      key: 'state',
      width: '14%',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <TagView text={text} />
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="paymentState"
        />
      ),
      dataIndex: 'payment_state',
      key: 'payment_state',
      width: '14%',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <TagView text={text} />
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: <DisplayText size={'thMedium'} extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}} textLocalId="shipmentState" />,
    //   dataIndex: 'shipment_state',
    //   key: 'shipment_state',
    //   width: '17%',
    //   sorter: (nameOne: any, nameTow: any) => {
    //     nameOne.shipment_state.localeCompare(nameTow.shipment_state);
    //   },
    //   sortOrder: sortedInfo.columnKey === 'shipment_state' && sortedInfo.order,
    //   ellipsis: true,
    //   render: (text: string): JSX.Element => {
    //     return (
    //       <View>
    //         {text === '' || text === null ? (
    //           <Text color={'gray.400'}>--</Text>
    //         ) : (
    //           <TagView text={text} />
    //         )}
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="email"
        />
      ),
      dataIndex: 'email',
      key: 'email',
      width: '22%',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="total"
        />
      ),
      dataIndex: 'total',
      key: 'total',
      width: '14%',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'smMedium'} color={Colors.Custom.Gray900}>
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
      fixed: 'right',
    },
    // {
    //   title: <DisplayText size={'thMedium'} extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}} textLocalId="actions" />,
    //   key: 'action',
    //   width: '20%',
    //   render: (text: any, selectedRecord: any) => {
    //     return (
    //       <>
    //         <ActionsView
    //           selectedRecord={selectedRecord}
    //           onActionClick={(actionCode: string): any => {
    //             onActionPerformed(actionCode, selectedRecord);
    //           }}
    //         />
    //       </>
    //     );
    //   },
    //   responsive: ['md'],
    // },
  ];
};
export const getMemberShipOrderColumns = (
  sortedInfo: any,
  onActionPerformed: (actionCode: string, rowData?: any) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="customer"
        />
      ),
      dataIndex: 'customer',
      key: 'customer',
      width: '15%',
      fixed: 'left',
      render: (text: {name: string; id: number}): JSX.Element => {
        return (
          <View>
            <Text color={Colors.FoldPixel.GRAY400 || ''} size={'smMedium'}>
              {text.name}
            </Text>
            <ContactProfileName
              onActionPerformed={onActionPerformed}
              id={text.id}
              name={'View Profile'}
              size={'xsNormal'}
              extraStyle={{color: Colors.FoldPixel.GRAY250}}
            />
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="createdAt"
        />
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text color={Colors.FoldPixel.GRAY250 || ''} size={'smRegular'}>
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    // {
    //   title: (
    //     <DisplayText
    //       size={'thMedium'}
    //       extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
    //       textLocalId="amount"
    //     />
    //   ),
    //   dataIndex: 'amount',
    //   key: 'amount',
    //   width: '15%',
    //   render: (text: string): JSX.Element => {
    //     return (
    //       <View>
    //         {text === '' || text === null ? (
    //           <Text color={'gray.400'}>--</Text>
    //         ) : (
    //           <Text color={Colors.primary['500'] || ''} size={'smMedium'}>
    //             {text}
    //           </Text>
    //         )}
    //       </View>
    //     );
    //   },
    //   responsive: ['md'],
    // },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="product"
        />
      ),
      dataIndex: 'product',
      key: 'product',
      width: '15%',
      render: (text: any): JSX.Element => {
        return (
          <View>
            {text?.name === '' || text?.name === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text color={Colors.FoldPixel.GRAY250 || ''} size={'smRegular'}>
                {text?.name}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="status"
        />
      ),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      fixed: 'right',
      render: (text: string): JSX.Element => {
        const bgColor =
          text == 'ACTIVE'
            ? Colors.success['50']
            : text == 'IN_ACTIVE'
            ? Colors.info['100']
            : Colors.danger['100'];
        const borderColor =
          text == 'ACTIVE'
            ? Colors.success['200']
            : text == 'IN_ACTIVE'
            ? Colors.info['200']
            : Colors.danger['200'];
        const textColor =
          text == 'ACTIVE'
            ? Colors.success['500']
            : text == 'IN_ACTIVE'
            ? Colors.info['400']
            : Colors.danger['400'];
        return (
          <View
            style={{
              width: 'max-content',
              paddingHorizontal: 8,
              paddingVertical: 3,
              borderRadius: 6,
              backgroundColor: bgColor,
              borderWidth: 1,
              borderColor: borderColor,
            }}
          >
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text size={'mdNormal'} color={textColor}>
                {text.toLowerCase()}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
  ];
};

export const getMemberShipOrderColumnsOneTime = (
  sortedInfo: any,
  onActionPerformed: (actionCode: string, rowData?: any) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="customer"
        />
      ),
      dataIndex: 'customer',
      key: 'customer',
      width: '15%',
      fixed: 'left',
      render: (text: {name: string; id: number}): JSX.Element => {
        return (
          <View>
            <Text color={Colors.FoldPixel.GRAY400 || ''} size={'smMedium'}>
              {text.name}
            </Text>
            <Pressable
              onPress={() => {
                onActionPerformed(COMMON_ACTION_CODES.ON_NAVIGATE_TO_DETAIL, {
                  id: text.id,
                });
              }}
              style={{paddingRight: 12, paddingVertical: 5}}
            >
              <Text size={'xsNormal'} style={{color: Colors.FoldPixel.GRAY250}}>
                View Profile
              </Text>
            </Pressable>
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="date"
        />
      ),
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text color={Colors.FoldPixel.GRAY250 || ''} size={'smRegular'}>
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="amount"
        />
      ),
      dataIndex: 'amount',
      key: 'amount',
      width: '15%',
      render: (text: string): JSX.Element => {
        return (
          <View>
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text color={Colors.FoldPixel.GRAY250 || ''} size={'smRegular'}>
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="status"
        />
      ),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      fixed: 'right',
      render: (text: string): JSX.Element => {
        return (
          <View
            style={{
              backgroundColor:
                text == 'succeeded'
                  ? Colors.success[100]
                  : Colors.Custom.Gray100,
              padding: 2,
              paddingHorizontal: 4,
              borderRadius: 6,
              width: 'max-content',
            }}
          >
            {text === '' || text === null ? (
              <Text color={'gray.400'}>--</Text>
            ) : (
              <Text
                color={
                  text == 'succeeded'
                    ? Colors.success[600]
                    : Colors.Custom.Gray400
                }
                size={'smMedium'}
              >
                {text}
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
  ];
};
