import React from "react";
import "./ProgressBar.css";
import ProgressArrowSvg from "../../../common/Svg/MeasurePerformanceSvg/ProgressArrowSvg";
import {getProgressBarColor} from "./TablesColumnsView";
import {getLeadingOffTrackValue, isCriteriaExceed, isCriteriaMet} from "./utils";
import {useIntl} from "react-intl";
import ProgressArrowInverseSvg from "../../../common/Svg/MeasurePerformanceSvg/ProgressArrowInverseSvg";
import {Colors} from "../../../../styles";
import {IProgressBar} from "./interface";
import {Tooltip} from "antd";


const ProgressBar = (props: IProgressBar) => {
  const { percentage, goal, inInverse, showProgressInfo } = props;
  const intl = useIntl();

  const tooltipContent = (
    <div>
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <span style={{ fontWeight: 400, color: Colors.FoldPixel.GRAY400, fontSize: '10px' }}>
          {inInverse ? '<-' : '->'}
        </span>
        <span style={{ fontWeight: 400, color: Colors.FoldPixel.GRAY400, fontSize: '10px' , marginLeft: '2px'}}>
          {intl.formatMessage({id: inInverse ? 'inverseGoal': 'linearGoal'})}
        </span>
      </div>
      <div style={{ fontSize: '10px', lineHeight: '12px', color: Colors.FoldPixel.GRAY200, fontWeight: 400 }}>
       {intl.formatMessage({id: inInverse ? 'stayWithinGoal': 'moveTowardGoal'})}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ 
          fontSize: '10px', 
          lineHeight: '12px', 
          color: Colors.FoldPixel.GRAY300,
          fontWeight: 500,
          marginTop: '2px'
        }}>
          Goal: {goal}%
        </div>
        <div style={{ 
          fontSize: '10px', 
          lineHeight: '12px', 
          color: getProgressBarColor(percentage || 0, goal || 0, inInverse),
          fontWeight: 500 
        }}>
          Current: {percentage}%
        </div>
      </div>
    </div>
  );

  return (
    <div style={{
      width: '100%'
    }} className="progress-container">
      <div className="progress-bar">
        <div
          className="progress-fill"
          style={{
            width: `${percentage}%`,
            backgroundColor: getProgressBarColor(
              percentage || 0, goal || 0, inInverse
            )
          }}
        ></div>

        <div
          className="progress-arrow-wrapper"
          style={{
            left: inInverse ? `calc(${goal}%)` : `calc(${goal}% - 12px)`
          }}
        >
          <Tooltip overlayClassName='performance-indicator' title={tooltipContent} placement={'topLeft'}>
          <div className="progress-arrow">
            <div className="progress-arrow-text" style={{
              color: isCriteriaExceed(percentage || 0, goal || 0, inInverse) ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY300,
              left: inInverse ? '0px' :'4px',
              right: inInverse ? '4px': '0px'
            }}>
              {goal}%
            </div>
            {inInverse ? (
              <ProgressArrowInverseSvg 
                met={isCriteriaExceed(percentage || 0, goal || 0, inInverse)}
              />
            ) : (
              <ProgressArrowSvg 
                met={isCriteriaExceed(percentage || 0, goal || 0, inInverse)}
              />
            )}
          </div>
          </Tooltip>
        </div>
      </div>
      {showProgressInfo && <div className="progress-info">
        <span className="leading-text">
          {/* {intl.formatMessage({id: isCriteriaMet(percentage || 0, goal || 0, inInverse) ? (inInverse ? 'underControlBy' : 'leadingBy') : (inInverse ? 'outOfControlBy' : 'offTrackBy')})} */}
          {intl.formatMessage({id: 'currentScore'})}
        </span>
        <span style={{
          color: getProgressBarColor(percentage || 0, goal || 0, inInverse),
        }} className="leading-percentage">{percentage}%</span>
      </div>}
    </div>
  );
};

export default ProgressBar;