import { Liquid } from 'liquidjs';
import { ContentTypes } from '../ContentManagementConsts';
import {
  createTemplate,
  deleteTemplate,
  getTemplates,
  isObject,
  ITemplateQueryParams,
  updateTemplate
} from '../ContentManagementUtils';
import { ITemplateCategory } from '../EmailTemplates/interfaces';
import {
  Category,
  NotificationApiResponse,
  SingleNotification
} from './interfaces';

export const validateNotification = (
  singleNotification: SingleNotification
) => {
  const errors: any = {};
  let valid = true;

  errors.name = !singleNotification.name
    ? 'Name is required'
    : singleNotification.name.length < 3
    ? 'Name is too short'
    : '';

  errors.category = !singleNotification?.category?.id
    ? 'Category is required'
    : '';

  errors.subject = !singleNotification.subject
    ? 'Subject is required'
    : singleNotification.subject.length < 3
    ? 'Subject is too short'
    : '';

  errors.body = !singleNotification.body
    ? 'Body is required'
    : singleNotification.body.length < 10
    ? 'Body is too short'
    : '';

  if (errors.name || errors.category || errors.subject || errors.body) {
    valid = false;
  }

  return {
    valid: valid,
    errors: errors,
  };
};

export const getPushNotifications = (
  categoryQueryString?: string,
  queryParams?: ITemplateQueryParams
) => {
  const url = `${ContentTypes.pushNotifications.path}?${categoryQueryString}`;
  return getTemplates(url, queryParams);
};

export const deletePushNotification = (id: string | undefined) => {
  const url = `${ContentTypes.pushNotifications.path}`;
  return deleteTemplate(url, id);
};

export const createPushNotification = (args: {
  singleNotification: SingleNotification;
  override?: boolean;
}) => {
  const {singleNotification, override} = args;
  const url = `${ContentTypes.pushNotifications.path}`;
  const data = getPostTemplateObject(singleNotification, override);

  return createTemplate(url, data);
};

export const updatePushNotification = (args: {
  id: string | undefined;
  singleNotification: SingleNotification;
  override?: boolean;
}) => {
  const {id, singleNotification, override} = args;
  const url = `${ContentTypes.pushNotifications.path}`;
  const data = getPostTemplateObject(singleNotification, override);
  return updateTemplate(url, id, data);
};

export const getNotificationTemplateByCategory = (args: {
  categoryCode: string;
  isDefault?: boolean;
}) => {
  const {categoryCode, isDefault} = args;
  let url;
  if (isDefault) {
    url = `${ContentTypes.pushNotifications.path}?category=${categoryCode}&isDefault=${isDefault}`;
  } else {
    url = `${ContentTypes.pushNotifications.path}?category=${categoryCode}`;
  }
  return getTemplates(url);
};

export const getFormattedPushNotifications = (
  response: NotificationApiResponse
): SingleNotification[] => {
  const data: SingleNotification[] = [];
  const apiData = response.data;
  apiData.forEach((item) => {
    data.push({
      id: item.id,
      body: item.attributes.body,
      name: item.attributes.name,
      subject: item.attributes.subject,
      isDefault: item.attributes.isDefault,
      lastUpdated: item.attributes.updatedAt,
      category: getFormattedCategoryData(item.attributes.category),
    });
  });
  return data;
};

export const getFormattedPushNotification = (
  response: any
): SingleNotification => {
  const item = response.data;
  return {
    id: item.id,
    body: item.attributes.body,
    name: item.attributes.name,
    subject: item.attributes.subject,
    isDefault: item.attributes.isDefault,
    lastUpdated: item.attributes.updatedAt,
    category: getFormattedCategoryData(item.attributes.category),
  };
};

export const getFormattedCategoryData = (
  category: Category
): ITemplateCategory => {
  const data = category.data;
  return {
    id: data.id,
    name: data.attributes.name,
    code: data.attributes.code,
    mergeTags: data.attributes.mergeTags,
  };
};

export const getPostTemplateObject = (
  singleNotification: SingleNotification,
  override?: boolean
) => {
  const {name, subject, body, category, isDefault} = singleNotification;
  return {
    data: {
      name: name,
      subject: subject,
      body: body,
      category: category.id,
      isDefault: isDefault,
      forceDefault: override || false,
    },
  };
};

export const sorter = (a: SingleNotification, b: SingleNotification) => {
  const diff =
    new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
  return diff;
};

export const onFilter = (value: any, record: SingleNotification) => {
  return record.category.name.indexOf(value) === 0;
};

export const getAddOrUpdateText = (condition: any) => {
  if (condition) {
    return {
      title: 'updateTemplate',
      btnText: 'Update',
      successText: 'Template Updated Successfully',
    };
  }
  return {
    title: 'createTemplate',
    btnText: 'Create',
    successText: 'Template Created Successfully',
  };
};

export const getMenuItems = (object: any): any => {
  return Object.keys(object).map((item: any) => {
    if (Array.isArray(object[item])) {
      return {
        label: item,
        key: item,
        children: object[item].map((arrayItem: any, index: number) => ({
          label: index,
          key: `${index}`,
          children: getMenuItems(arrayItem)
        }))
      };
    } else if (isObject(object[item])) {
      return {
        label: item,
        key: item,
        children: getMenuItems(object[item]),
      };
    } else {
      return {
        label: item,
        key: item,
      };
    }
  });
};

export const insertAtIndex = (str: string, index: number, value: string) => {
  return str.substring(0, index) + value + str.substring(index);
};

export const getParseBody = (args: {bodyString: string; mergeTags: any}) => {
  const {bodyString, mergeTags} = args;
  const body = bodyString || '';
  const engine = new Liquid();
  try {
    const tpl = engine.parse(body);
    return engine.renderSync(tpl, mergeTags);
  } catch (error) {
    return 'Body';
  }
};

export const getSelectedMergeTag = (keyPath: string[]) => {
  return `{{${keyPath.reverse().join('.')}}}`;
};
