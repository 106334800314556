import { View, Pressable, StyleSheet } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { IStateForHandlingPoolAfterTaskAssigneeChange, IStateForHandlingSubTaskAssigneeAfterPoolChange, ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { useIntl } from 'react-intl';
import { filterWorkflowUser, getAccountUUID, getBulkTaskOperationConfigs, getUserEmployerId, getUserUUID, isEmployerRole } from '../../../../utils/commonUtils';
import { getMlovId, getMlovIdFromCode, getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { Button, HStack, VStack, useMediaQuery, Text, Spacer, Image, Spinner } from 'native-base';
import {useCustomToast} from '../../../Toast/ToastProvider';
import { BUTTON_TYPE, DATE_FORMATS, DISPLAY_DATE_FORMAT, IPAD_MINI_WIDTH, IPAD_WIDTH, MLOV_CATEGORY, TASK_POOL_LOCAL_TYPE } from '../../../../constants';
import { cloneDeep } from 'lodash';
import { ParticipantType } from '../../../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { TASK_ASSIGNEE_TYPE_CODES } from '../../../common/CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import { BulkLabelChangeAction, BulkViewCode, IBulkOperationData, ITaskConflict, IUserPatientSearchItem, MultiSelectAction } from '../../TaskInterfaces';
import { IPoolInfo, ITaskCommentsList, ITaskPool } from '../../../common/AddTask/interfaces';
import UserAutoComplete, { IUserSearch } from '../../../common/CalendarWidget/UserAutoComplete/UserAutoComplete';
import PatientSearchAndSelect from '../../../common/PatientSearch/PatientSearchAndSelect';
import { ModalActionDatePicker } from '../../../common/ModalActionCommonComponent/ModalActionDatePicker';
import { ModalActionAntSelect } from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import { Colors } from '../../../../styles';
import { Checkbox, Drawer, Modal, Progress, notification } from 'antd';
import TaskPoolSearchAndSelect from '../../../common/AddTask/TaskPoolSearchAndSelect';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ORDERED_TASK_PRIORITY_CODES_ASC, ORDERED_TASK_STATUS_CODES_DESC, SQS_MESSAGE_STATUS_CODES, TASK_STATUS_CODES, USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { IUser } from '../../../../Interfaces';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { ModalActionTitle } from '../../../common/ModalActionTitle/ModalActionTitle';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { GET_USER_FOR_SCHEDULE_ACCESS } from '../../../../services/User/UserQueries';
import TaskPoolQueries from '../../../../services/TaskPool/TaskPoolQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { v4 as uuidv4 } from 'uuid';
import AddOrUpdateCommentForTask from '../../../common/AddTask/AddEditComment/AddOrUpdateCommentForTask';
import { getMessage } from '../../../common/AddTask/CommonUtilsForWebAndNative';
import Stack from '../../../common/LayoutComponents/Stack';
import LabelSearch from './LabelSearch';
import Close from '../../../../assets/svg/Close';
import { getDateStrFromFormat, getMomentObj, getMomentObjectWithDateStringAndFormat } from '../../../../utils/DateUtils';
import WarningSvg from '../../../common/Svg/WarningSvg';
import InfoSvg from '../../../common/Svg/InfoSvg';
import BulkTaskCardListView from './BulkTaskCardListView';
import { BulkTaskOperationState, getLabelOptionTitle } from '../../TaskModuleHelper';
import { TaskQueries } from '../../../../services';
import { EventBus } from '../../../../utils/EventBus';
import { TASK_EVENTS } from '../../../common/CareDashboard/CareDashboardConstants';
import BulkTaskOperationAutomationList from './BulkTaskOperationAutomationList';
import { mentionPartPostPattern } from '../../../common/AddTask/AddTaskUtils';
import CustomViewer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CustomEditor/CustomViewer';
import { ToastType } from '../../../../utils/commonViewUtils';

interface IBulkTaskEditViewProps {
  selectedTasks: ITask[];
  isVisible: boolean;
  onCancel?: () => void;
  onTaskRemoved?: (task: ITask) => void;
}

const BulkTaskEditView = (props: IBulkTaskEditViewProps) => {
  // Constants and variables
  const {
    selectedTasks,
    isVisible,
    onCancel,
    onTaskRemoved,
  } = props;
  const eventBus = EventBus.getEventBusInstance();
  const currentUserId = getUserUUID();
  const employerId = getUserEmployerId();
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const accountUuid = getAccountUUID();
  const operationConfigs = getBulkTaskOperationConfigs(mlovData.userSettings);
  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const isEmployer = isEmployerRole();
  let taskPriorityMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  taskPriorityMlov = taskPriorityMlov?.sort((currentMlov, nextMlov) => {
    const currentMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      currentMlov.code
    );
    const nextMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      nextMlov.code
    );
    return currentMlovScore - nextMlovScore;
  });
  let taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];

  taskStatusMlov = taskStatusMlov
  ?.filter(item => ORDERED_TASK_STATUS_CODES_DESC.includes(item.code))
  ?.sort((currentMlov, nextMlov) => {
    const currentMlovScore = ORDERED_TASK_STATUS_CODES_DESC.indexOf(
      currentMlov.code
    );
    const nextMlovScore = ORDERED_TASK_STATUS_CODES_DESC.indexOf(
      nextMlov.code
    );
    return currentMlovScore - nextMlovScore;
  });
  const sqsStatusList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.SQS_MESSAGE_STATUS,
    false
  );
  const SQS_STATUS_IDS = {
    processed: getMlovIdFromCode(sqsStatusList || [], SQS_MESSAGE_STATUS_CODES.PROCESSED),
    failed: getMlovIdFromCode(sqsStatusList || [], SQS_MESSAGE_STATUS_CODES.FAILED),
  }

  // States
  const [componentState, setComponentState] = useState<{
    accountUsers: IUser[];
    taskPools: any[];
    bulkViewCode: BulkViewCode,
    showErrors: boolean;
    labelOptions: {key: string, label: string, value: string}[];
    validationLoading: boolean;
    taskConflicts: ITaskConflict[];
    taskWithoutConflicts: ITaskConflict[];
    bulkTaskOperationState: BulkTaskOperationState;
    transactionId?: string;
    processedCount?: number;
    allowToTriggerNotification: boolean,
    allowToSendCompletionNotification: boolean,
  }>({
    accountUsers: [],
    taskPools: [],
    showErrors: false,
    bulkViewCode: BulkViewCode.configure,
    validationLoading: false,
    taskConflicts: [],
    taskWithoutConflicts: [],
    bulkTaskOperationState: BulkTaskOperationState.none,
    allowToTriggerNotification: true,
    allowToSendCompletionNotification: true,
    labelOptions: operationConfigs.labelOperation.map((item) => {
      return {
        key: item,
        label: getLabelOptionTitle(item as BulkLabelChangeAction),
        value: item,
      }
    })
  });

  const [taskPoolData, setTaskPoolData] = useState<ITaskPool>({
    assigneeChangedDueToChangeInTaskPool: false,
  });

  const [stateForHandlingSubTaskAssigneeAfterPoolChange, setStateForHandlingSubTaskAssigneeAfterPoolChange] = useState<IStateForHandlingSubTaskAssigneeAfterPoolChange>({
    showAlertOfSubTaskAssigneeChange: false,
    temporaryUserPoolSelection: {assigneeChangedDueToChangeInTaskPool: false}
  })
  const [stateForHandlingPoolAfterTaskAssigneeChange, setStateForHandlingPoolAfterTaskAssigneeChange] = useState<IStateForHandlingPoolAfterTaskAssigneeChange>({
    showAlertOfEmptyingTaskPool: false,
    closeSelect: false,
    temporaryData: {}
  })

  const [selectedData, setSelectedData] = useState<IBulkOperationData>({});

  // API
  const [getTaskPools] = useLazyQuery(TaskPoolQueries.GetTaskPool, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_SCHEDULE_ACCESS, {
    fetchPolicy: 'no-cache',
  });

  const [validateBulkTasks] = useLazyQuery(TaskQueries.VALIDATE_BULK_TASKS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [applyBulkTaskChanges] = useMutation(TaskQueries.PERFORM_BULK_TASK_ACTION, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [bulkOperationStatus] = useLazyQuery(TaskQueries.BULK_OPERATION_STATUS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  // Life cycle methods
  useEffect(() => {
    fetchInitialTaskDetail();
  }, []);

  useEffect(() => {
    if (componentState.transactionId) {
      setComponentState((prev) => ({...prev, processedCount: 0 }));
      // getBulkOperationStatus();
    }
  }, [componentState.transactionId]);

  useEffect(() => {
    configureTaskPool();
  }, [componentState.taskPools, componentState.accountUsers]);

  // toast
  const toast = useCustomToast();
  // Other methods
  const getBulkOperationStatus = async () => {
    try {
      const total = componentState.taskWithoutConflicts.length;
      if (componentState.bulkTaskOperationState === BulkTaskOperationState.inProgress) {
        const response = await bulkOperationStatus({
          variables: {
            transactionId: componentState.transactionId,
            processedStatusIds: [SQS_STATUS_IDS.processed, SQS_STATUS_IDS.failed]
          },
        });
        const count = response.data.processed?.aggregate?.count ?? -1;
        if (count > -1 && count <= total) {
          setComponentState((prev) => ({...prev, processedCount: count }));
          if (count < total) {
            setTimeout(getBulkOperationStatus, 1000);
          }
        } else {
          setComponentState((prev) => ({...prev, bulkTaskOperationState: BulkTaskOperationState.none }));
        }
      } else {
        setComponentState((prev) => ({...prev, bulkTaskOperationState: BulkTaskOperationState.none }));
      }
    } catch {
      setComponentState((prev) => ({...prev, bulkTaskOperationState: BulkTaskOperationState.none }));
    }
  }

  const fetchInitialTaskDetail = async () => {
    try {
      const taskPoolTypeId = getMlovId(mlovData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');
      const taskPoolParams = { userPoolTypeId: taskPoolTypeId };
      const accountUserParams = { accountUUID: accountUuid, roleCode: USER_ROLE_CODES.EMPLOYER };

      const promiseList = [
        getTaskPools({ variables: { params: taskPoolParams }}),
        getAccountUsers({ variables: accountUserParams }),
      ];
      const apiResponse = await Promise.all(promiseList);
      const taskPools = apiResponse[0]?.data?.getUserPools?.userPools || [];
      const accountUsers = getUserFormattedList(apiResponse[1]?.data?.users || []);

      setComponentState(prev => {
        return {
          ...prev,
          loading: false,
          accountUsers,
          taskPools,
          labelsLoading: false,
        }
      });
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  }

  const getUserFormattedList = (users: any[]) => {
    const filterUsers = filterWorkflowUser(users || [], currentUserId);
    return (filterUsers || []).map((user: any) => {
      return {
        ...user,
        userName: user.name,
        userId: user.uuid,
        email: user.email || '',
      };
    });
  };

  const checkIfUserIsEmployer = (user: IUser) => {
    if (user.userRoles && user.userRoles.length > 0) {
      const isEmployer = user.userRoles.find(
        (role) => role.userRole?.userRole?.code === USER_ROLE_CODES.EMPLOYER
      );
      return isEmployer ? true : false;
    }
    return false;
  };

  const userDataPostProcessing = (users: IUser[]) => {
    let filteredUserData: IUser[] = [];
    if (users && users.length > 0) {
      if (isEmployer) {
        filteredUserData = users.filter((user: IUser) => {
          if (user.uuid === currentUserId) {
            // if user is employer then show only his own name
            return true;
          }
          return !checkIfUserIsEmployer(user); // add only non-employer users
        });
      }
    }
    return filteredUserData;
  };

  const isAssigneeTypePatientOrProspect = () => {
    const isMember = (
      selectedData.assignee?.type === ParticipantType.patient ||
      selectedData.assignee?.type === ParticipantType.leads ||
      selectedData.assignee?.type === TASK_ASSIGNEE_TYPE_CODES.Contact
    );
    return isMember
  };

  const getMatchActiveUserById = (userId: string, accountUserList?: any[]): any => {
    return (accountUserList || componentState.accountUsers || []).find(user => {
      return user?.userId === userId;
    });
  }

  const getUserObjectForDisplay = (selectedUser: any) => {
    return {
      key: selectedUser?.userId,
      value: selectedUser?.userId,
      label: selectedUser?.name,
      contactData: selectedUser,
      type: ParticipantType?.staff
    }
  }

  const filterActiveUsersAndDefaultAssigneeOfPool = (pool: any) => {
    const selectedPoolUsers: any[] = [];
    let defaultAssignee: any = undefined;
    if (pool?.id && pool?.userPoolUsers?.length) {
      (pool?.userPoolUsers || []).forEach((user: any) => {
        const selectedUser = getMatchActiveUserById(user?.userId);
        if (selectedUser?.userId) {
          const userDisplayObject = getUserObjectForDisplay(selectedUser);
          if (user.isDefault) {
            defaultAssignee = userDisplayObject;
          } else {
            selectedPoolUsers.push(userDisplayObject);
          }
        }
      });
    }
    return {
      defaultAssignee,
      selectedPoolUsers,
    };
  }

  const getPoolListByUserId = (userId: string) => {
    const userPoolList: any[] = [];
    const otherUserPoolList: any[] = [];
    (componentState.taskPools || [])?.forEach((pool: any) => {
      const isSelectedUserInPool = pool?.userPoolUsers?.some((user: any) => user?.userId === userId);
      const poolClone = {
        ...pool,
        type: isSelectedUserInPool ? TASK_POOL_LOCAL_TYPE.SELECTED_USER : TASK_POOL_LOCAL_TYPE.OTHER_USER,
      };
      if (isSelectedUserInPool) {
        userPoolList.push(poolClone);
      } else {
        otherUserPoolList.push(poolClone);
      }
    });
    return {
      userPool: userPoolList,
      otherPool: otherUserPoolList,
    };
  };

  const getMatchTaskPoolDetail = (userPoolId: string | undefined, userId: string) => {
    const selectedPool = (componentState.taskPools || [])?.find((pool: any) => {
      return pool?.id === userPoolId;
    });
    if (!selectedPool) {
      return undefined;
    }
    const isSelectedUserInPool = selectedPool?.userPoolUsers?.some((user: any) => user?.userId === userId);
    return {
      key: selectedPool.id,
      value: selectedPool.id,
      label: selectedPool.name,
      userPoolUsers: selectedPool.userPoolUsers,
      type: isSelectedUserInPool ? TASK_POOL_LOCAL_TYPE.SELECTED_USER : TASK_POOL_LOCAL_TYPE.OTHER_USER,
    };
  };

  const configureTaskPool = () => {
    let filterUsers: any[] = [];
    if (!taskPoolData?.accountUsers?.length && isEmployer) {
      filterUsers = userDataPostProcessing(cloneDeep(componentState.accountUsers || []));
    }
    if (componentState.taskPools?.length && (!taskPoolData?.otherUserPools?.length || !taskPoolData?.currentUserPools?.length)) {
      const { userPool, otherPool } = getPoolListByUserId(currentUserId);
      let selectedPool: any = {};
      if (selectedData.taskPool?.value) {
        const poolId = selectedData?.taskPool?.value;
        selectedPool = getMatchTaskPoolDetail(poolId, currentUserId) || {};
        selectedPool.id = selectedPool?.key;
        // if login user present in selected task pool and add or update modal open from task pool list
        if (selectedPool?.type === TASK_POOL_LOCAL_TYPE.SELECTED_USER && !selectedData?.assignee) {
          const selectedUser = getMatchActiveUserById(currentUserId, componentState.accountUsers);
          setSelectedData((prev) => {
            return {
              ...prev,
              assignee: {
                key: selectedUser?.uuid,
                value: selectedUser?.uuid,
                label: selectedUser?.name,
                type: ParticipantType.staff,
                details: selectedUser,
              },
            }
          });
        }
      }
      const { selectedPoolUsers, defaultAssignee } = filterActiveUsersAndDefaultAssigneeOfPool(selectedPool);
      setTaskPoolData(prev => {
        return {
          ...prev,
          otherUserPools: otherPool,
          currentUserPools: userPool,
          accountUsers: filterUsers,
          ...(selectedPool?.id && { selectedPool }),
          ...(selectedPool?.id && { selectedPoolUsers }),
          ...(selectedPool?.id && { defaultAssignee }),
        }
      });
    }
  }

  const handleChangeInTaskPool = (data: any, isAssigneeInPool?: boolean) => {
    if (data?.key !== taskPoolData?.selectedPool?.id) {
      if (data?.key) {
        const taskPool = { key: data?.key, value: data?.key, label: data?.label }
        data.id = data?.key;
        const { selectedPoolUsers, defaultAssignee } = filterActiveUsersAndDefaultAssigneeOfPool(data);
        const assignee = defaultAssignee ? defaultAssignee : undefined;

        if (selectedData?.assignee?.key && !isAssigneeInPool) {
          setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
            return {
              ...prev,
              showAlertOfSubTaskAssigneeChange: true,
              temporaryUserPoolSelection: {
                selectedPoolUsers: selectedPoolUsers,
                defaultAssignee: defaultAssignee,
                assigneeChangedDueToChangeInTaskPool: assignee && !isAssigneeInPool ? true : false,
                selectedPool: { name: data?.label || data?.value, ...data },
                isAssigneeInPool: isAssigneeInPool,
                assignee: assignee,
                taskPool: taskPool,
                ...((!defaultAssignee && taskPoolData?.currentUserPools?.map((pool: IPoolInfo) => pool?.id)?.includes(data?.key)) && { taskAssigneeChangeToCurrentUser: true })
              }
            }
          })
        } else {
          setTaskPoolData(prev => {
            return {
              ...prev,
              selectedPoolUsers,
              defaultAssignee,
              assigneeChangedDueToChangeInTaskPool: assignee && !isAssigneeInPool ? true : false,
              selectedPool: { name: data?.label || data?.value, ...data },
            }
          });
          setSelectedData((prev) => {
            return {
              ...prev,
              ...(!prev?.assignee?.key && { assignee }),
              taskPool,
            }
          })
        }
      } else {
        setTaskPoolData(prev => {
          return {
            ...prev,
            selectedPool: undefined,
            selectedPoolUsers: [],
            defaultAssignee: undefined,
          }
        });
        setSelectedData((prev) => {
          return {
            ...prev,
            taskPool: undefined,
          }
        })
      }
    }
  }

  const handleAssigneeChange = (assignee: IUserPatientSearchItem, type?: string) => {
    if (isEmployer) {
      setSelectedData((prev) => {
        return {
          ...prev,
          assignee: assignee,
        }
      });
    }
    else {
      setTaskPoolData(prev => {
        return {
          ...prev,
          assigneeChangedDueToChangeInTaskPool: false
        }
      });
      if (type && type !== ParticipantType.staff) {
        setTaskPoolData(prev => {
          return {
            ...prev,
            selectedPool: undefined,
            selectedPoolUsers: [],
            defaultAssignee: undefined,
          }
        });
      }
      setSelectedData((prev) => {
        return {
          ...prev,
          assignee: assignee,
          ...(type === ParticipantType.staff && !(taskPoolData?.selectedPool?.userPoolUsers?.some((user: any) => { if (user.userId === assignee?.key) return true }))) && {
            taskPool: undefined
          },
          ...(type && type !== ParticipantType.staff && {
            member: undefined,
            selectedContact: undefined,
            taskPool: undefined,
          }),
        }
      });
    }
  }

  const getMessageToDisplay = () => {
    const defaultAssignee = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.defaultAssignee;
    const isSubTaskAssigneeChange = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.isSubtaskAssigneeChange;
    const isAssigneeInPool = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.isAssigneeInPool;
    const taskAssigneeChangeToCurrentUser = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.taskAssigneeChangeToCurrentUser;
    const subtaskAssigneeChangeToCurrentUser = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.subtaskAssigneeChangeToCurrentUser;
    return getMessage(defaultAssignee, isSubTaskAssigneeChange, isAssigneeInPool, taskAssigneeChangeToCurrentUser, subtaskAssigneeChangeToCurrentUser)
  }

  const handleAddLocalComment = (comment: string) => {
    const trimmedComment = comment.trim();
    if (!trimmedComment) {
      setSelectedData((prev) => {
        return {
          ...prev,
          comment: undefined,
        }
      });
      return;
    }
    const newComment : ITaskCommentsList =  {
      id: '',
      taskId: '',
      isModified: false,
      createdOn: new Date().toISOString(),
      createdBy: currentUserId,
      isDeleted: false,
      tempId: uuidv4(),
      userName: currentUserId,
      comment: trimmedComment
    }

    setSelectedData((prev) => {
      return {
        ...prev,
        comment: newComment,
      }
    });
  }

  const handleDeleteLocalComment = (comment: ITaskCommentsList) => {
    setSelectedData((prev) => {
      return {
        ...prev,
        comment: undefined,
      }
    });
  }

  const renderAssigneeTo = () => (
    <View style={styles.fullWidth}>
      {isEmployer ? (
        <UserAutoComplete
          labelText={'Change Assignee'}
          selectedValue={selectedData.assignee as IUserSearch}
          excludeOtherEmployers={true}
          onChange={(selectedUser) => {
            setSelectedData((prev) => {
              return {
                ...prev,
                assignee: selectedUser as IUserPatientSearchItem,
              }
            });
          }}
          usersData={isEmployer ? taskPoolData?.accountUsers : []}
        />
      ) : (
        <PatientSearchAndSelect
          showUserPendingTasksCount={true}
          closeSelect={stateForHandlingPoolAfterTaskAssigneeChange?.closeSelect}
          setCloseSelect={() => { setStateForHandlingPoolAfterTaskAssigneeChange((prev) => { return { ...prev, closeSelect: false } }) }}
          labelColor={Colors.Custom.Gray500}
          showCurrentUserAtTop={taskPoolData?.selectedPool?.id ? false : true}
          label={'Change Assignee'}
          isProspect={true}
          disableLeadsSearch={true}
          disablePatientSearch
          showEmail
          // isDisabled={props.isAssigneeDisabled}
          placeholder="Search Staff or Member"
          value={selectedData.assignee?.label || ''}
          poolSelected={selectedData?.taskPool?.key ? true : false}
          selectedPoolUsers={taskPoolData?.selectedPoolUsers}
          defaultAssigneeInSelectedPool={taskPoolData?.defaultAssignee}
          onChange={(participants: any, data: any) => {
            const contactIdValue =
              participants?.label?.props?.contactData?.uuid ||
              participants?.value;
            const contactIdKey = contactIdValue || participants?.key;
            const assignee = {
              key: contactIdKey,
              value: contactIdValue,
              label: participants?.label?.props?.contactData?.name,
              type: data?.type,
              details: participants?.label?.props?.contactData,
            }
            data?.type !== ParticipantType.staff || !taskPoolData?.selectedPool?.id || taskPoolData?.selectedPool.userPoolUsers?.some((user) => user?.userId === contactIdKey) ? handleAssigneeChange(assignee, data?.type) :
              setStateForHandlingPoolAfterTaskAssigneeChange({
                showAlertOfEmptyingTaskPool: true,
                temporaryData: {
                  assignee: assignee,
                  type: data?.type
                }
              })
          }}
          employerId={employerId}
          handleOnClear={true}
        />
      )}
    </View>
  );
  const renderDueDate = () => (
    <ModalActionDatePicker
      label={'Change Due Date'}
      placeholder={'Select Due Date'}
      leftMargin={'0'}
      format={DISPLAY_DATE_FORMAT}
      value={selectedData.dueDate
      ? getMomentObjectWithDateStringAndFormat(
          selectedData.dueDate,
          DISPLAY_DATE_FORMAT
        )
      : undefined}
      onChange={(data: any, dateString) => {
        setSelectedData((prev) => {
          return {
            ...prev,
            dueDate: dateString,
          }
        });
      }}
      customStyle={{ flex: 1 }}
      allowClear={true}
    />
  );
  const renderPriority = () => (
    <ModalActionAntSelect
      allowClear={true}
      leftMargin={'0'}
      showSearch={false}
      labelInValue={true}
      filterOption={false}
      value={selectedData.priority}
      isRequired={false}
      label={'Change Priority'}
      placeholder={'Select Priority'}
      onChange={(data: any) => {
        setSelectedData((prev) => {
          return {
            ...prev,
            priority: data,
          }
        });
      }}
      data={taskPriorityMlov}
      optionProps={{
        key: 'id',
        value: 'code',
        label: 'value',
      }}
      extraStyle={{ flex: 1 }}
    />
  );
  const renderStatus = () => (
    <ModalActionAntSelect
      allowClear={true}
      leftMargin={'0'}
      showSearch={false}
      labelInValue={true}
      filterOption={false}
      value={selectedData.status}
      isRequired={false}
      label={'Change Status'}
      placeholder={'Select Status'}
      onChange={(data: any) => {
        setSelectedData((prev) => {
          return {
            ...prev,
            status: data,
          }
        });
      }}
      data={taskStatusMlov}
      optionProps={{
        key: 'id',
        value: 'code',
        label: 'value',
      }}
      extraStyle={{ flex: 1 }}
    />
  );
  const isDisabledMemberField = () => {
    return isAssigneeTypePatientOrProspect();
  }
  const renderMember = () => (
    <PatientSearchAndSelect
      labelColor={Colors.Custom.Gray500}
      label={'Change Member'}
      labelInfo={intl.formatMessage({
        id: 'memberContextInfoMsg',
      })}
      isProspect={true}
      isDisabled={isDisabledMemberField()}
      disableUserSearch
      placeholder="Search Member"
      value={selectedData.member?.label}
      showPatientProfileLink={false}
      onChange={(participants: any, data: any) => {
        const contactIdValue =
          participants?.label?.props?.contactData?.uuid ||
          participants?.value;
        const contactIdKey = contactIdValue || participants?.key;
        setSelectedData((prev) => {
          return {
            ...prev,
            selectedContact: participants?.label?.props?.contactData,
            member: {
              key: contactIdKey,
              value: contactIdValue,
              label: participants?.label?.props?.contactData?.name,
              type: data?.type,
              details: participants?.label?.props?.contactData,
            },
          };
        });
      }}
      employerId={employerId}
    />
  );
  const renderTaskPool = () => (
    <>
      {!isEmployer && (
        <>
          <TaskPoolSearchAndSelect
            isDisabled={isAssigneeTypePatientOrProspect()}
            allowClear={true}
            filterOption={true}
            labelColor={Colors.Custom.Gray500}
            value={selectedData.taskPool || undefined}
            isRequired={false}
            label={'Change Task Pool'}
            labelInfo={intl.formatMessage({ id: 'addTaskTaskPoolInfoMsg' })}
            placeholder={intl.formatMessage({
              id: 'addTaskTaskPoolPlaceholder',
            })}
            onChange={handleChangeInTaskPool}
            userPools={taskPoolData?.currentUserPools}
            otherPools={taskPoolData?.otherUserPools}
            assigneeId={selectedData.assignee?.key}
          />
        </>
      )}
    </>
  );
  const renderComment = () => (
    <>
      {componentState.accountUsers?.length > 0 && (
        <Stack direction="column" space={2}>
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: 400,
              fontSize: 14,
            }}
            textLocalId={'Add Comment'}
          />
          <AddOrUpdateCommentForTask
            showOnlyCommentBox
            commentBoxFlex={1}
            defaultComment={selectedData.comment?.comment || ''}
            accountUsers={componentState.accountUsers}
            comments={selectedData.comment ? [selectedData.comment] : []}
            handleDeleteLocalComment={handleDeleteLocalComment}
            handleAddLocalComment={handleAddLocalComment}
            onCommentChange={handleAddLocalComment}
          />
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: 400,
              fontSize: 12,
            }}
            textLocalId={'Entered comments will appear in every task selected'}
          />
        </Stack>
      )}
    </>
  );
  const renderLabelOptions = () => {
    const showSelectLabels = selectedData.labelOption?.option && selectedData.labelOption?.option !== BulkLabelChangeAction.deleteAll;
    const isFindAndReplace = selectedData.labelOption?.option && selectedData.labelOption?.option === BulkLabelChangeAction.findAndReplace;
    let labelFieldTitle = '';
    let labelFieldInfo = '';
    if (selectedData.labelOption?.option === BulkLabelChangeAction.findAndDelete) {
      labelFieldTitle = 'Delete these labels from tasks';
    } else if (selectedData.labelOption?.option === BulkLabelChangeAction.replaceAllLabels) {
      labelFieldTitle = 'Replace all existing labels with these labels';
    }
    if (selectedData.labelOption?.option === BulkLabelChangeAction.deleteAll) {
      labelFieldInfo = 'This action will delete all labels attached to the selected tasks';
    }
    return (
      <VStack space={1}>
        <ModalActionAntSelect
          allowClear={true}
          leftMargin={'0'}
          showSearch={false}
          labelInValue={true}
          filterOption={false}
          value={selectedData.labelOption?.option}
          isRequired={false}
          label={'Update Labels'}
          placeholder={'Select Label Options'}
          onChange={(data: any) => {
            setSelectedData((prev) => {
              let labelOption = prev.labelOption;
              if (data) {
                labelOption = labelOption || { option: data?.key };
                labelOption.option = data?.key;
                labelOption.replaceLabelPairs = [];
                labelOption.labels = [];
              } else {
                labelOption = undefined;
              }
              return {
                ...prev,
                labelOption
              }
            });
          }}
          data={componentState.labelOptions}
          optionProps={{
            key: 'key',
            value: 'value',
            label: 'label',
          }}
          extraStyle={{ flex: 1 }}
        />
        {labelFieldInfo && (
          <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.Custom.Gray500,
              fontWeight: 400,
              fontSize: 12,
            }}
            textLocalId={labelFieldInfo}
          />
        )}
        {showSelectLabels && !isFindAndReplace && (
          <VStack space={2} style={styles.width100PaddingRight1}>
            {labelFieldTitle && (
              <DisplayText
                size={'smMedium'}
                extraStyles={{
                  color: Colors.Custom.Gray500,
                  fontWeight: 400,
                  fontSize: 14,
                }}
                textLocalId={labelFieldTitle}
              />
            )}
            <LabelSearch
              allowMultiple
              value={selectedData.labelOption?.labels}
              isShowError={componentState.showErrors && !selectedData.labelOption?.labels?.length}
              disallowLabels={operationConfigs.disallowLabels}
              onChange={(data) => {
                setSelectedData((prev) => {
                  const labelOption = prev.labelOption || { option: BulkLabelChangeAction.addToExisting };
                  labelOption.labels = data;
                  return {
                    ...prev,
                    labelOption,
                  }
                });
              }}
              />
          </VStack>
        )}
        {showSelectLabels && isFindAndReplace && renderReplaceLabelPairs()}
      </VStack>
    );
  }
  const renderReplaceLabelPairs = () => {
    const pairs = selectedData.labelOption?.replaceLabelPairs?.length ? selectedData.labelOption?.replaceLabelPairs : [{}];
    const showRemoveButton = pairs.length > 1;
    const fieldWidth = showRemoveButton ? '48%' : '50%';
    return (
      <VStack space={2}>
        <HStack space={2} style={styles.justifyContentSpaceAround}>
          <View style={{width: fieldWidth, paddingRight: 1}}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 400,
                fontSize: 14,
              }}
              textLocalId={'Find'}
            />
          </View>
          <View style={{width: fieldWidth, paddingRight: 1}}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.Gray500,
                fontWeight: 400,
                fontSize: 14,
              }}
              textLocalId={'Replace with'}
            />
          </View>
          {showRemoveButton && (<View style={styles.width4PercentPaddingRight1} />)}
        </HStack>
        {pairs.map((pair, index) => {
          return (
            <HStack
              space={2}
              style={styles.justifyContentSpaceAroundAlignItemsCenter}
              key={'findAndReplace_' + index + pair.find?.uuid + pair.replace?.uuid}
            >
              <View style={{width: fieldWidth, paddingRight: 1}}>
                <LabelSearch
                  value={pair.find ? [pair.find] : []}
                  isShowError={componentState.showErrors && !pair.find}
                  disallowLabels={operationConfigs.disallowLabels}
                  onChange={(data) => {
                    setSelectedData((prev) => {
                      const labelOption = prev.labelOption || {
                        option: BulkLabelChangeAction.findAndReplace,
                      };
                      if (!labelOption.replaceLabelPairs?.length) {
                        labelOption.replaceLabelPairs = [{}];
                      }
                      labelOption.replaceLabelPairs[index].find = data?.length ? data[0] : undefined;
                      return {
                        ...prev,
                        labelOption,
                      };
                    });
                  }}
                />
              </View>
              <View style={{width: fieldWidth, paddingRight: 1}}>
                <LabelSearch
                  value={pair.replace ? [pair.replace] : []}
                  isShowError={componentState.showErrors && !pair.replace}
                  disallowLabels={operationConfigs.disallowLabels}
                  onChange={(data) => {
                    setSelectedData((prev) => {
                      const labelOption = prev.labelOption || {
                        option: BulkLabelChangeAction.findAndReplace,
                      };
                      if (!labelOption.replaceLabelPairs?.length) {
                        labelOption.replaceLabelPairs = [{}];
                      }
                      labelOption.replaceLabelPairs[index].replace = data?.length ? data[0] : undefined;
                      return {
                        ...prev,
                        labelOption,
                      };
                    });
                  }}
                />
              </View>
              {showRemoveButton && (
                <View style={styles.width4PercentPaddingRight1}>
                  <Pressable onPress={() => {
                    setSelectedData((prev) => {
                      const labelOption = prev.labelOption || {
                        option: BulkLabelChangeAction.findAndReplace,
                      };
                      let replaceLabelPairs = labelOption.replaceLabelPairs;
                      if (!replaceLabelPairs?.length) {
                        replaceLabelPairs = [{}];
                      }
                      replaceLabelPairs.splice(index, 1);
                      return {
                        ...prev,
                        labelOption: {
                          ...labelOption,
                          replaceLabelPairs: [...replaceLabelPairs],
                        },
                      }
                    })
                  }}>
                    <Close fill={Colors.Custom.Gray500} size={20} />
                  </Pressable>
                </View>
              )}
            </HStack>
          );
        })}
        <HStack>
          <Pressable
            onPress={() => {
              setSelectedData((prev) => {
                const labelOption = prev.labelOption || {option: BulkLabelChangeAction.findAndReplace};
                if (labelOption.replaceLabelPairs?.length) {
                  labelOption.replaceLabelPairs.push({});
                } else {
                  labelOption.replaceLabelPairs = [{}, {}];
                }
                return {
                  labelOption,
                }
              })
            }}>
            <Text color={Colors.Custom.mainPrimaryPurple}>+ Add More</Text>
          </Pressable>
          <Spacer />
        </HStack>
      </VStack>
    );
  };
  const renderPreviewData = () => {
    const previewList = getPreviewList();
    previewList.unshift({field: 'FIELDS', value: 'CHANGE TO'});

    return (
      <VStack space={4}>
        <Checkbox
          checked={componentState.allowToSendCompletionNotification}
          onChange={(event) => {
            setComponentState((prev) => ({...prev, allowToSendCompletionNotification: event.target.checked}));
          }}>
          <Text style={styles.colorGray600FontSize14}>{'Notify me when bulk update is completed'}</Text>
        </Checkbox>
        <VStack borderColor={Colors.Custom.Gray300} borderWidth={0.5} borderRadius={8}>
          {previewList.map((item, index) => {
            const fontWeight = index === 0 ? '600' : '400';
            return (
              <HStack key={'PreviewField' + index} borderBottomColor={Colors.Custom.Gray300} borderBottomWidth={index != previewList.length - 1 ? 0.5 : 0} paddingX={4} paddingY={2}>
                <View style={styles.paddingRight1Flex3}>
                  <Text style={styles.colorGray500FontWeight}>{item.field}</Text>
                </View>
                <View style={styles.paddingRight1Flex7}>
                  <Text style={{color: Colors.Custom.Gray600, fontWeight}}>{item.value}</Text>
                </View>
              </HStack>
            );
          })}
        </VStack>
      </VStack>
    );
  }
  const getPreviewList = () => {
    const previewList: {field: string, value: string | JSX.Element}[] = [];
    if (selectedData.priority) {
      previewList.push({field: 'Priority', value: selectedData.priority?.label || '' });
    }
    if (selectedData.status) {
      previewList.push({field: 'Status', value: selectedData.status?.label || '' });
    }
    if (selectedData.dueDate) {
      const date = getDateStrFromFormat(selectedData.dueDate, DATE_FORMATS.TASK_DUE_DATE_DISPLAY_FORMAT);
      previewList.push({field: 'Due Date', value: date || '' });
    }
    if (selectedData.member) {
      const memberName = selectedData.member.label;
      previewList.push({field: 'Member', value: memberName || '' });
    }
    if (selectedData.assignee?.label) {
      const assigneeName = selectedData.assignee.label;
      previewList.push({field: 'Assignee', value: assigneeName || '' });
    }
    if (selectedData.taskPool) {
      const taskPoolName = selectedData.taskPool?.label;
      previewList.push({field: 'Task Pool', value: taskPoolName || '' });
    }
    if (selectedData.labelOption?.option) {
      const labelOption = selectedData.labelOption;
      const actionText = getLabelOptionTitle(labelOption.option as BulkLabelChangeAction);
      const field = `Labels (${actionText})`;
      let value = (labelOption.labels || [])?.map(item => item.title || '').join(', ');
      if (labelOption.option === BulkLabelChangeAction.deleteAll) {
        value = 'All the labels of selected tasks will be deleted'
      } else if (labelOption.option === BulkLabelChangeAction.findAndReplace) {
        value = labelOption.replaceLabelPairs?.map((item) => `${item.find?.title}  ->  ${item.replace?.title}`)?.join('\n') || '';
      }
      previewList.push({field: field, value: value || '' });
    }
    if (selectedData.comment) {
      previewList.push({
        field: 'New Comment',
        value: <View style={styles.marginTopBottomMinus8}>
          <CustomViewer
            linkAttributes={{
              target: '_blank',
          }}
          extendedAutolinks={true}
          initialValue={selectedData.comment.comment || '' }
        /></View>
      });
    }
    return previewList;
  }
  const isValidLabelData = () => {
    const labelOption = selectedData.labelOption;
    if (labelOption?.option === BulkLabelChangeAction.deleteAll) {
      return true;
    } else if (labelOption?.option === BulkLabelChangeAction.findAndReplace) {
      return !labelOption.replaceLabelPairs?.some(item => !item.find || !item.replace);
    } else {
      return !!labelOption?.labels?.length;
    }
  }
  const isValidData = (): boolean => {
    return !!selectedData.priority ||
      !!selectedData.status ||
      !!selectedData.dueDate ||
      !!selectedData.member?.label ||
      !!selectedData.assignee?.label ||
      !!selectedData.taskPool?.label ||
      !!selectedData.comment ||
      !!(selectedData.labelOption?.option && isValidLabelData());
  }
  const renderTaskErrors = () => {
    if (componentState.validationLoading && !componentState.taskConflicts.length) {
      return <Spinner />;
    }
    if (!componentState.taskConflicts.length && !componentState.taskWithoutConflicts.length) {
      return <></>;
    }
    return (
      <Stack direction="column" space={24}>
        {componentState.taskConflicts.length > 0 && (
          <VStack backgroundColor={Colors.Custom.Red100} space={2} borderRadius={8}>
            <HStack space={2} flex={1} marginX={4} marginTop={4} marginBottom={-2} alignItems={'center'}>
              <View>
                <WarningSvg customStrokeColor={Colors.Custom.crossIconColor} strokeWidth={16} />
              </View>
              <HStack alignItems={'center'}>
                <Text style={styles.colorGray500FontWeight600}>{'TASKS  WITH  CHANGE  CONFLICT'}</Text>
                <Text style={styles.textStyle}>
                  {componentState.taskConflicts.length}
                </Text>
              </HStack>
              {componentState.validationLoading && <View style={styles.alignItemsFlexEnd}><Spinner /></View>}
            </HStack>
            <View style={styles.padding12}>
              <BulkTaskCardListView
                taskList={componentState.taskConflicts}
                operations={selectedData}
                onRemove={(task) => {
                  onTaskRemoved?.(task);
                  setComponentState((prev) => {
                    return {
                      ...prev,
                      taskConflicts: prev.taskConflicts.filter(item => item.task.id !== task.id)
                    };
                  });
                }}
              />
            </View>
          </VStack>
        )}
        {componentState.taskWithoutConflicts.length > 0 && (
          <VStack space={2}>
            <HStack alignItems={'center'}>
              <Text style={styles.bulkChangesText}>BULK  CHANGES  WILL  BE  APPLIED  ONLY  TO  THE  FOLLOWING  TASKS</Text>
              <Text style={styles.taskCountText}>
                {componentState.taskWithoutConflicts.length}
              </Text>
            </HStack>
            <BulkTaskCardListView
              taskList={componentState.taskWithoutConflicts}
              onRemove={(task) => {
                onTaskRemoved?.(task);
                setComponentState((prev) => {
                  return {
                    ...prev,
                    taskWithoutConflicts: prev.taskWithoutConflicts.filter(item => item.task.id !== task.id)
                  };
                });
                const filteredTasks = selectedTasks.filter(item => item.id !== task.id);
                checkTaskValidation(filteredTasks);
              }} />
          </VStack>
        )}
      </Stack>
    );
  }
  const getTitle = () => {
    switch (componentState.bulkViewCode) {
      case BulkViewCode.configure: return 'Bulk Actions';
      case BulkViewCode.preview: return 'Bulk Update Preview';
      case BulkViewCode.bulkInProgress: return 'Confirmation';
    }
  }
  const renderBulkInProgressView = () => {
    const total = componentState.taskWithoutConflicts.length;
    const processed = componentState.processedCount || 0;
    const progress = total ? Math.round(processed * 100 / total) : 0;
    return (
      <VStack style={styles.justifyContentCenterAlignItemsCenter} flex={1}>
        <View style={styles.verticalMargin40}>
          <Image
            size={160}
            source={progress === 100 ? require('../../../../assets/gifs/success.gif') : require('../../../../assets/gifs/task_bulk.gif')}
            alt='image'
            key={Math.random()}
          />
        </View>
        <VStack space={3} width={'100%'} borderRadius={8} borderColor={Colors.Custom.Gray300} borderWidth={0.5} padding={4} flex={1}>
          <VStack space={1}>
            <Text color={Colors.Custom.Gray700} fontWeight={'600'} fontSize={14} flex={1}>{'Applying bulk changes to the selected tasks'}</Text>
            <Text color={Colors.Custom.Gray500} fontWeight={'500'} fontSize={12} flex={1}>{`${total} selected tasks`}</Text>
          </VStack>
          <Progress percent={progress} />
        </VStack>
      </VStack>
    );
  }
  const closeDrawer = () => {
    eventBus.broadcastEvent(TASK_EVENTS.ON_MULTI_TASK_STATE_CHANGE, { action: MultiSelectAction.disableMultiSelect });
    setSelectedData({});
    setTaskPoolData(prev => {
      return {
        ...prev,
        selectedPool: undefined,
        selectedPoolUsers: [],
        defaultAssignee: undefined,
      }
    });
    if (componentState.bulkTaskOperationState === BulkTaskOperationState.inProgress) {
      eventBus.broadcastEvent(TASK_EVENTS.REFRESH_TASKS, {});
    }
    setComponentState((prev) => ({ ...prev,
      bulkViewCode: BulkViewCode.configure,
      transactionId: undefined,
      taskConflicts: [],
      taskWithoutConflicts: [],
      validationLoading: false,
      bulkTaskOperationState: BulkTaskOperationState.none,
      allowToSendCompletionNotification: true,
      allowToTriggerNotification: true,
      showErrors: false,
      processedCount: 0,
    }));
    onCancel?.();
  }
  // const renderInfoData = () => {
  //   if (selectedData.status?.key) {
  //     return (
  //       <Stack direction="row" space={8} style={ { padding: 8, backgroundColor: Colors.Custom.infoBannerBgColor, borderRadius: 8}}>
  //         <View style={ {marginTop: 2}}>
  //           <InfoSvg customColor={'#0E66E9'} />
  //         </View>
  //         <Text style={ { color: Colors.Custom.Gray500, fontSize: 14 }}>{'The status of tasks associated with a linked note can only be updated in accordance with the signed state of that note.'}</Text>
  //       </Stack>
  //     );
  //   }
  //   return <></>;
  // }
  const getBulkTaskAPIParams = (taskIds: string[]) => {
    // Check if any mention is present in the content
    let commentText = selectedData.comment?.comment;
    if (commentText) {
      const isMentionPresent = mentionPartPostPattern.test(commentText);
      if (isMentionPresent) {
        commentText = commentText.replace(mentionPartPostPattern, (match, userName, type, uuid) => {
          return `@[mention://USER/${uuid}]`;
        });
      }
    }

    return {
      operationCode: 'UPDATE',
      taskIds: taskIds,
      allowToTriggerNotification: componentState.allowToTriggerNotification,
      allowToSendCompletionNotification: componentState.allowToSendCompletionNotification,
      updatedField: {
        ...(selectedData.assignee?.value && { assigneeId: selectedData.assignee?.value }),
        ...(selectedData.dueDate && { dueDate: getMomentObj(selectedData.dueDate).toISOString() }),
        ...(commentText && { comment: commentText }),
        ...(selectedData.priority?.key && { priority: selectedData.priority?.key }),
        ...(selectedData.status?.key && { statusId: selectedData.status?.key }),
        ...(selectedData.taskPool?.value && {
          poolId: selectedData.taskPool?.value,
          taskUserPool: componentState.taskPools.filter(
            item => item.id === selectedData.taskPool?.value
          ).map(item => {
            return {
              id: item.id,
              userPoolUsers: item.userPoolUsers?.map((user: any) => {
                return {
                  userId: user.userId,
                  isDefault: user.isDefault
                }
              }) || []
            }
          })?.[0]
        }),
        ...(selectedData.labelOption?.option && {
          label: {
            labelOperationCode: selectedData.labelOption.option,
            ...(selectedData.labelOption.labels?.length && {
              labelIds: selectedData.labelOption.labels.map((item) => item.uuid)
            }),
            ...(selectedData.labelOption.replaceLabelPairs?.length && {
              findReplaceLabelIds: selectedData.labelOption.replaceLabelPairs.map((item) => {
                return {
                  labelId: item.find?.uuid,
                  replaceId: item.replace?.uuid,
                }
              })
            }),
          },
        }),
      },
    }
  }
  const checkTaskValidation = async (taskList: ITask[]) => {
    try {
      const selectedTaskIds = taskList.map((item) => item.id);
      if (!selectedTaskIds.length) {
        return;
      }
      setComponentState((prev) => ({...prev, validationLoading: true}));
      const response = await validateBulkTasks({
        variables: {
          data: getBulkTaskAPIParams(selectedTaskIds),
        },
        fetchPolicy: 'no-cache',
      });
      if (response.data?.bulkTaskInputValidation?.tasks?.length) {
        const tasks: {id: string; conflicts: {conflictCodes: string[]; key: string;}[]}[] = response.data?.bulkTaskInputValidation?.tasks || [];
        const taskConflicts: ITaskConflict[] = [];
        const taskWithoutConflicts: ITaskConflict[] = [];
        tasks.forEach((item) => {
          const taskDetail = taskList.find(task => task.id === item.id);
          if (taskDetail) {
            if (item.conflicts.length) {
              taskConflicts.push({ task: taskDetail, conflicts: item.conflicts });
            } else {
              taskWithoutConflicts.push({ task: taskDetail, conflicts: [] });
            }
          }
        })
        setComponentState((prev) => ({
          ...prev,
          validationLoading: false,
          taskConflicts,
          taskWithoutConflicts,
        }));
      } else {
        setComponentState((prev) => ({...prev, validationLoading: false}));
      }
    } catch {
      notification.error({
        message: 'Something went wrong while validating tasks',
        duration: 3.0,
        placement: 'top',
      });
      setComponentState((prev) => ({...prev, validationLoading: false}));
    }
  }
  const applyChanges = async () => {
    try {
      const selectedTaskIds = componentState.taskWithoutConflicts.map((item) => item.task.id);
      if (!selectedTaskIds.length) {
        return;
      }
      setComponentState((prev) => ({...prev,
        bulkTaskOperationState: BulkTaskOperationState.inProgress,
        transactionId: undefined,
      }));
      const response = await applyBulkTaskChanges({
        variables: {
          data: getBulkTaskAPIParams(selectedTaskIds),
        },
        fetchPolicy: 'no-cache',
      });
      const transactionId = response.data?.bulkTaskUpdate?.processId;
      if (transactionId) {
        setComponentState((prev) => ({
          ...prev,
          transactionId,
        }));
        closeDrawer();
        notification.success({
          message: 'Task update is in progress. You\'ll be notified once completed.',
          duration: 3.0,
          placement: 'top',
        });
      } else {
        setComponentState((prev) => ({
          ...prev,
          bulkTaskOperationState: BulkTaskOperationState.error
        }));
      }
    } catch {
      notification.error({
        message: 'Something went wrong while validating tasks',
        duration: 3.0,
        placement: 'top',
      });
      setComponentState((prev) => ({
        ...prev,
        bulkTaskOperationState: BulkTaskOperationState.error
      }));
    }
  }

  return (
    <Drawer
      destroyOnClose
      placement="right"
      open={isVisible}
      closable
      width={isSidecarContext ? '100%' : (isIPadScreen || isIPadMiniScreen ? '70%' : '50%')}
      mask={isSidecarContext ? false : true}
      title={
        <ModalActionTitle
          title={getTitle()}
          titleColor={''}
          buttonList={[
            {
              show: componentState.bulkViewCode === BulkViewCode.configure,
              id: 1,
              btnText: isSidecarContext ? 'back' : 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: closeDrawer,
            },
            {
              show: componentState.bulkViewCode === BulkViewCode.configure,
              id: 2,
              btnText: 'Preview Changes',
              size: 'sm',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isDisabled: false,
              isTransBtn: false,
              onClick: () => {
                setComponentState((prev) => ({...prev, showErrors: true}));
                if (isValidData()) {
                  checkTaskValidation(selectedTasks);
                  setComponentState((prev) => ({...prev, bulkViewCode: BulkViewCode.preview}));
                } else {
                  const message = selectedData.labelOption?.option ? 'Please fill mandatory fields' : 'Please select at least one field';
                  notification.error({ message, duration: 3.0, placement: 'top' });
                }
              },
            },
            {
              show: componentState.bulkViewCode === BulkViewCode.preview,
              id: 3,
              btnText: 'Previous',
              size: 'sm',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                setComponentState((prev) => ({...prev, bulkViewCode: BulkViewCode.configure, taskConflicts: [], taskWithoutConflicts: [] }));
              },
            },
            {
              show: componentState.bulkViewCode === BulkViewCode.preview,
              id: 4,
              btnText: 'Apply Changes',
              size: 'sm',
              isLoading: componentState.bulkTaskOperationState === BulkTaskOperationState.inProgress,
              isDisabled: !componentState.taskWithoutConflicts.length || componentState.validationLoading,
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: applyChanges,
            },
            {
              show: componentState.bulkViewCode === BulkViewCode.bulkInProgress,
              id: 5,
              btnText: 'close',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                closeDrawer()
              },
            },
          ]}
        />
      }
    >
      <VStack>
        {componentState.bulkViewCode === BulkViewCode.preview && (
          <VStack space={6}>
            {renderPreviewData()}
            {/* {renderInfoData()} */}
            {renderTaskErrors()}
          </VStack>
        )}
        {/* {componentState.bulkViewCode === BulkViewCode.bulkInProgress && renderBulkInProgressView()} */}
        {componentState.bulkViewCode === BulkViewCode.configure &&(
          <VStack mx={0} space={4}>
            <HStack space={2} style={styles.justifySpaceAround}>
              <View style={styles.halfWidth}>
                {renderPriority()}
              </View>
              <View style={styles.halfWidth}>
                {renderStatus()}
              </View>
            </HStack>

            <HStack space={2} style={styles.justifySpaceAround}>
              <View style={styles.halfWidth}>
                {renderAssigneeTo()}
              </View>
              <View style={styles.halfWidth}>
                {renderTaskPool()}
              </View>
            </HStack>

            <HStack space={2} style={styles.justifySpaceAround}>
              <View style={styles.halfWidth}>
                {renderDueDate()}
              </View>
              <View style={styles.halfWidth}>
                {/* {renderMember()} */}
              </View>
            </HStack>

            <View style={styles.verticalMargin}>
              {renderLabelOptions()}
            </View>
            {renderComment()}
            <View>
              <BulkTaskOperationAutomationList
                allowToTriggerNotification={componentState.allowToTriggerNotification}
                onAllowToTriggerNotificationChange={(value) => {
                  setComponentState((prev) => ({ ...prev, allowToTriggerNotification: value }));
                }}
              />
            </View>
          </VStack>
        )}
        <Modal
          title="Change Task Pool" open={stateForHandlingSubTaskAssigneeAfterPoolChange.showAlertOfSubTaskAssigneeChange}
          onCancel={() => {
            setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
              return {
                ...prev,
                showAlertOfSubTaskAssigneeChange: false,
                temporaryUserPoolSelection: {
                  ...prev.temporaryUserPoolSelection,
                  subtaskAssigneeChangeToCurrentUser: false,
                  taskAssigneeChangeToCurrentUser: false,
                }
              }
            })
          }}
          footer={null}
          bodyStyle={{ paddingTop: 12 }}
        >
          <View>
            <Text size={'mdMedium'} >{getMessageToDisplay()}</Text>
            <Button.Group style={styles.modalButtonGroup}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  style: styles.modalButton,
                  onPress: () => {
                    setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
                      return {
                        ...prev,
                        showAlertOfSubTaskAssigneeChange: false,
                        temporaryUserPoolSelection: {},
                      }
                    })
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'No',
                  }),
                  withRightBorder: false,
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  style: styles.modalButton,
                  onPress: () => {
                    if (stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.selectedPool) {
                      setTaskPoolData(prev => {
                        return {
                          ...prev,
                          selectedPoolUsers: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.selectedPoolUsers,
                          defaultAssignee: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.defaultAssignee,
                          assigneeChangedDueToChangeInTaskPool: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.assigneeChangedDueToChangeInTaskPool,
                          selectedPool: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.selectedPool
                        }
                      });
                      const assignee = stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection.assignee
                      const taskPool = stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection.taskPool
                      setSelectedData((prev) => {
                        return {
                          ...prev,
                          ...(!(stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.isAssigneeInPool) && { assignee }),
                          taskPool,
                        }
                      })
                    }
                    setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
                      return {
                        ...prev,
                        showAlertOfSubTaskAssigneeChange: false,
                        temporaryUserPoolSelection: {},
                      }
                    })
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'Yes',
                  }),
                  withRightBorder: false,
                }}
              />
            </Button.Group>
          </View>
        </Modal>
        <Modal
          title="Change Task Pool" open={stateForHandlingPoolAfterTaskAssigneeChange.showAlertOfEmptyingTaskPool}
          onCancel={() => {
            setStateForHandlingPoolAfterTaskAssigneeChange({
              showAlertOfEmptyingTaskPool: false,
              closeSelect: true,
              temporaryData: {}
            })
          }}
          footer={null}
          bodyStyle={{ paddingTop: 12 }}
        >
          <View>
            <Text size={'mdMedium'} >{`Assignee chosen is not part of ${taskPoolData?.selectedPool?.label}. Going ahead will reset the task pool. Continue?`}</Text>
            <Button.Group style={styles.modalButtonGroup}>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  style: styles.modalButton,
                  onPress: () => {
                    setStateForHandlingPoolAfterTaskAssigneeChange({
                      showAlertOfEmptyingTaskPool: false,
                      closeSelect: true,
                      temporaryData: {}
                    })
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'No',
                  }),
                  withRightBorder: false,
                }}
              />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  style: styles.modalButton,
                  onPress: () => {
                    handleAssigneeChange(stateForHandlingPoolAfterTaskAssigneeChange?.temporaryData?.assignee, stateForHandlingPoolAfterTaskAssigneeChange?.temporaryData?.type)
                    setStateForHandlingPoolAfterTaskAssigneeChange({
                      showAlertOfEmptyingTaskPool: false,
                      closeSelect: true,
                      temporaryData: {}
                    })
                    setTaskPoolData(prev => {
                      return {
                        ...prev,
                        selectedPool: undefined,
                        selectedPoolUsers: [],
                        defaultAssignee: undefined,
                      }
                    });
                  },
                }}
                customProps={{
                  btnText: intl.formatMessage({
                    id: 'Yes',
                  }),
                  withRightBorder: false,
                }}
              />
            </Button.Group>
          </View>
        </Modal>
      </VStack>
    </Drawer>
  );
}

const styles = StyleSheet.create({
  justifySpaceAround: {
    justifyContent: 'space-around',
  },
  halfWidth: {
    width: '49%',
    paddingRight: 1,
  },
  verticalMargin: {
    marginVertical: 8,
  },
  modalButtonGroup: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalButton: {
    margin: 3,
  },
  fullWidth: {
    width: '100%',
  },
  width100PaddingRight1: {
    width: '100%',
    paddingRight: 1,
  },
  justifyContentSpaceAroundAlignItemsCenter: {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  paddingRight1Flex3: {
    paddingRight: 1,
    flex: 3,
  },
  colorGray500FontWeight: {
    color: Colors.Custom.Gray500,
    fontWeight: '400',
  },
  colorGray500FontWeight600: {
    color: Colors.Custom.Gray500,
    fontWeight: '600',
  },
  paddingRight1Flex7: {
    paddingRight: 1,
    flex: 7,
  },
  colorGray600FontSize14: {
    color: Colors.Custom.Gray600,
    fontSize: 14,
  },
  flexDirectionRowAlignItemsCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexDirectionRowAlignItemsCenterMarginRight4: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 4,
  },
  subTaskCount2: {
    color: Colors.Custom.Gray600,
    fontSize: 14,
  },
  width4PercentPaddingRight1: {
    width: '4%',
    paddingRight: 1,
  },
  justifyContentSpaceAround: {
    justifyContent: 'space-around',
  },
  marginTopBottomMinus8: {
    marginBottom: -24,
    marginTop: -8,
  },
  textStyle: {
    backgroundColor: '#F47A3E',
    paddingHorizontal: 8,
    paddingVertical: 2,
    color: Colors.Custom.White,
    marginLeft: 12,
    borderRadius: 4,
    fontSize: 12,
  },
  alignItemsFlexEnd: {
    alignItems: 'flex-end',
  },
  padding12: {
    padding: 12,
  },
  justifyContentCenterAlignItemsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalMargin40: {
    marginVertical: 40,
  },
  bulkChangesText: {
    color: Colors.Custom.Gray500,
    fontWeight: '600',
    fontSize: 14,
  },
  taskCountText: {
    backgroundColor: '#F47A3E',
    paddingHorizontal: 8,
    paddingVertical: 2,
    color: Colors.Custom.White,
    marginLeft: 12,
    borderRadius: 4,
    fontSize: 12,
  },
});

export default BulkTaskEditView
