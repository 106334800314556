import {gql} from '@apollo/client';

export const GET_CONTACT_DOCUMENTS_WITH_SEARCH_AND_APPLIED_FILTER = gql`
  query getContactDocuments($contactId: uuid!, $documentTypeCode: [String!], $limit: Int, $offset: Int, $search: String) {
    aggregateDocuments(where: {subjectType: {code: {_eq: "contact"}}, subjectId: {_eq: $contactId}, isDeleted: {_eq: false}, documentAttachments: {isDeleted: {_eq: false}}, documentType: {code: {_in: $documentTypeCode}}, _or: [{notes: {_ilike: $search}}, {caption: {_ilike: $search}}, {documentAttachments: {attachment: {name: {_ilike: $search}}}}]}, order_by: {createdOn: desc}) {
      aggregate {
        count
      }
    }
    documents(where: {subjectType: {code: {_eq: "contact"}}, subjectId: {_eq: $contactId}, isDeleted: {_eq: false}, documentAttachments: {isDeleted: {_eq: false}}, documentType: {code: {_in: $documentTypeCode}}, _or: [{notes: {_ilike: $search}}, {caption: {_ilike: $search}}, {documentAttachments: {attachment: {name: {_ilike: $search}}}}]}, order_by: {createdOn: desc}, limit: $limit, offset: $offset) {
      id
      caption
      notes
      documentType {
        id
        code
        value
      }
      documentAttachments(where: {isDeleted: {_eq: false}}) {
        id
        isDeleted
        attachment {
          id
          key
          name
          type
        }
      }
    }
  }
  `;

export const GET_CARE_JOURNEY_DOCUMENTS = gql`
  query getDocuments($params: GetDocumentsInput!) {
    getDocuments(params: $params) {
      documents{
        id
        notes
        caption
        documentType {
          id
          code
          value
        }
        documentAttachments {
          id
          isDeleted
          attachment {
            id
            key
            name
            type
          }
        }
      }
    }
  }
`;

export const GET_CARE_JOURNEY_DOCUMENTS_NAME = gql`
  query getDocuments($params: GetDocumentsInput!) {
    getDocuments(params: $params) {
      documents{
        id
        caption
      }
    }
  }
`;

export const CREATE_CONTACT_DOCUMENT = gql`
  mutation createContactDocument($data: document_insert_input!) {
    document: createDocument(object: $data) {
      id
      caption
      notes
      documentType {
        id
        code
        value
      }
      documentAttachments {
        id
        isDeleted
        attachment {
          id
          key
          name
          type
        }
      }
    }
  }
`;

export const UPDATE_CONTACT_DOCUMENTS = gql`
  mutation updateContactDocument($id: document_pk_columns_input!, $data: document_set_input!) {
    document: updateDocument(pk_columns: $id, _set: $data) {
      id
      caption
      notes
      documentType {
        id
        code
        value
      }
      documentAttachments {
        id
        isDeleted
        attachment {
          id
          key
          name
          type
        }
      }
    }
  }
`;

export const DELETE_CONTACT_DOCUMENT = gql`
  mutation updateContactDocument($data: document_pk_columns_input!) {
    updateDocument(pk_columns: $data, _set: { isDeleted: true}) {
      id
      caption
      notes
      documentType {
        id
        code
        value
      }
      documentAttachments {
        id
        isDeleted
        attachment {
          id
          key
          name
          type
        }
      }
    }
  }
`;

export const CREATE_OR_UPDATE_CONTACT_DOCUMENT_ATTACHMENTS = gql`
  mutation createOrupdateContactDocumentAttachment($data: [document_attachment_insert_input!]!) {
    createDocumentAttachments(objects: $data, on_conflict: {constraint: document_attachment_pkey, update_columns: isDeleted}) {
      returning {
        id
        attachment {
          id
          key
          name
          type
        }
      }
    }
  }
`;

export const DELETE_CONTACT_DOCUMENT_ATTACHMENTS = gql`
  mutation deleteContactDocumentAttachment($ids: [document_attachment_pk_columns_input!]!) {
    updateDocumentAttachments(where: {id: {_in: $ids}}, _set: { isDeleted: true}) {
      returning {
        id
        attachment {
          id
          key
          name
          type
        }
      }
    }
  }
`;

export const GET_PATIENT_DOCUMENTS = gql`
query getDocuments($params: GetDocumentsInput!) {
  getDocuments(params: $params) {
    documents {
      id
      subjectId
      subjectTypeId
      documentTypeId
      caption
      createdOn
      updatedOn
      subCategory
      documentAttachments {
        id
        isDeleted attachment {
          id
          key
          name
          type
        }
      }
      documentType {
        id
        code
        value
      }
      subjectType {
        id
        code
        value
      }
      patientCareJourneyId
      referenceData
      patientCareJourney {
        title
        careJourney {
          title
        }
      }
    }
    aggregate
  }
}
`;

export const GET_DOC_BY_ATTACHMENTS_IDS = gql`
query getDocumentsByAttachmentIds($ids: [uuid!]!) {
    documents(where: { documentAttachments: { id: { _in: $ids } } }) {
      id
      subjectId
      subjectTypeId
      documentTypeId
      caption
      createdOn
      updatedOn
      subCategory
      documentAttachments {
        id
        isDeleted
        attachment {
          id
          key
          name
          type
        }
      }
      documentType {
        id
        code
        value
      }
      subjectType {
        id
        code
        value
      }
      patientCareJourneyId
      referenceData
    }
  }
`

export const GET_WHOLE_PATIENT_DOCUMENTS = gql`
  query getWholePatientDocument($subjectId: uuid!) {
    documents(
      where: {subjectId: {_eq: $subjectId},isDeleted: {_eq: false}}
    ) {
      id
      subjectId
      subjectTypeId
      documentTypeId
      caption
      createdOn
      updatedOn
      subCategory
      documentAttachments(where: {isDeleted: {_eq: false}}) {
        id
        isDeleted
        attachment {
          id
          key
          name
          type
        }
      }
      documentType {
        id
        code
        value
      }
      subjectType {
        id
        code
        value
      }
      patientCareJourneyId
      referenceData
    }
  }
`;

export const CREATE_NEW_CATEGORY = gql`
mutation createAccountCategory($params: CreateCategoryInput!) {
  createAccountCategory(params: $params) {
    id
    isDefault
    code
    description
    value
  }
  }
`

export const GET_DOCUMENT_CATEGORIES = gql`
query getDocumentCategories($params: GetDocumentCategoryInput!) {
  getDocumentCategories(params: $params) {
    id
    isDefault
    code
    description
    value
  }
  }
`

export const GET_JOURNEYIDS_OF_PATIENT_HAVING_DOCUMENTS_IN_JOURNEY = gql`
query getContactDocumentsJourneyIds($contactId: uuid!, $subCategory: [String!]) {
  documents(where: {subjectId: {_eq: $contactId}, patientCareJourneyId: {_is_null: false}, subCategory: {_in: $subCategory}, isDeleted: {_eq: false}}) {
    patientCareJourneyId
  }
}
`

export const CREATE_MULTIPLE_CONTACT_DOCUMENT = gql`
  mutation createContactDocuments($data: [document_insert_input!]!) {
    document: createDocuments(objects: $data) {
      returning {
        id
        caption
        notes
        documentType {
          id
          code
          value
        }
        documentAttachments {
          id
          isDeleted
          attachment {
            id
            key
            name
            type
          }
        }
      }
    }
  }
`;

export const DELETE_MULTIPLE_CONTACT_DOCUMENT = gql`
  mutation updateContactDocuments($documentIds: [uuid!]!) {
    updateDocuments(where: {id: {_in: $documentIds}}, _set: {isDeleted: true}) {
      returning {
        id
        caption
        notes
        documentType {
          id
          code
          value
        }
        documentAttachments {
          id
          isDeleted
          attachment {
            id
            key
            name
            type
          }
        }
      }
    }
  }
`;