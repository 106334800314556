import {
  Center,
  HStack,
  Image,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  PATIENT_QUICK_PROFILE_PARENT_CODES,
} from '../../../../constants/ActionConst';
import {MLOV_CATEGORY} from '../../../../constants/MlovConst';
import {
  GROUP_TYPES,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MENTION_READ_STATUS,
  PERSON_TYPES,
  MESSAGING_WINDOW_HEADER_HEIGHT} from '../../../../constants/StringConst';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {ConversationFilterContext} from '../../../../context/ConversationFilterContext';
import {
  ConversationListContext,
  defaultConversationList,
} from '../../../../context/ConversationListContext';
import {SelectedConversationContext} from '../../../../context/SelectedConversationContext';
import {IContactDetails, ILoginUserData} from '../../../../Interfaces/CommonInterfaces';
import CommonService from '../../../../services/CommonService/CommonService';
import {IInbox} from '../../../../services/Inbox/interfaces';
import {
  getAccountId,
  getAgentsList,
  getUserId,
  getUserUUID,
} from '../../../../utils/commonUtils';
import LocalStorage from '../../../../utils/LocalStorage';
import {showToast, ToastType} from '../../../../utils/commonViewUtils';
import {
  getContactTypeId,
  getMlovCodeIdObj,
  getMlovListFromCategory,
} from '../../../../utils/mlovUtils';
import HintPlanDetailView from '../../../common/HintPlanDetailView/HintPlanDetailView';
import LoadingSpinner from '../../../common/Loader/LoadingSpinner';
import PageBodyContainer from '../../../common/PageBodyContainer/PageBodyContainer';
import {
  IInboxChannelType,
  IUserInboxList,
} from './ConversationChannelTabs/interfaces';
import {CHANNEL_TYPE_CODE, CONVERSATION_TAB_CODES} from './ConversationConst';
import ConversationContainer from './ConversationContainer/ConversationContainer';
import {getConversationChannelTabFilter} from './ConversationContainer/ConversationFilters/ConversationChannelTabFilterUtils';
import {
  getConversationFilterObj,
  getHeaderConversationFilterObj,
  HEADER_BUTTON_CODES,
} from './ConversationContainer/ConversationUtils';
import {getConversationFilter} from './ConversationFilterUtils.native';
import {IConversationData, IConversationMessage, ISearchMessageData, MsgQueue} from './interfaces';
import {MessagingContactDetails} from './MessagingContactDetails/MessagingContactDetails';
import {MessagingGroupDetail} from './MessagingContactDetails/MessagingGroupDetail';
import {getContactDataAndType} from './MessagingWindow/MessagingUtils';
import MessagingWindow from './MessagingWindow/MessagingWindow';
import {CUSTOM_MESSAGE_EVENT_CODES} from '../../../../constants/WebSocketConst';
import {EventBus} from '../../../../utils/EventBus';
import {isAccountConfigEnabled} from '../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../constants/AccountConfigConst';
import SearchMessageThread from './MessagingWindow/MessageThreadSearch/SearchMessageThread';
import { v4 } from 'uuid';
import SelectConversation from './ConversationContainer/SelectConversation';
import { getViewInEHRLink, redirectToNewTab } from '../../../SideCar/SidecarUtils';
import { TestIdentifiers, testID } from '../../../../testUtils';
import SearchMessageThreadDrawer from './MessagingWindow/MessageThreadSearch/SearchMessageThreadDrawer';
import { getEHRUrl } from '../../../../utils/capabilityUtils';
import { resetSearchParamsForSelectedConversation } from './ConversationChannelNew/ConversationSidebarUtils';
import {IConversationFilterValue} from './ConversationChannelNew/Interfaces';
import {getConversationStyles} from './ConversationsStyles';
import MessagingWindowV2 from './MessagingWindow/MessagingWindowV2';

const Conversations = (props: {
  selectedInboxTypeCode: IInboxChannelType;
  selectedInbox: IInbox;
  conversationFilterQuery: any;
  conversationFilterProps: any,
  conversationUserFilterData: IConversationFilterValue;
  onActionPerformed?: any;
  allInboxList?: IUserInboxList;
  filterModal?: (isFilterDrawerOpen: boolean) => void;
  emailInboxUserPreference?: string
  setUserPreferenceChangeLoading?: () => void;
  sideCarSearchString?: string;
  isConversationSelectedInSideCar?: (isSelected: boolean) => void;
}) => {
  const {selectedInboxTypeCode, selectedInbox, conversationFilterQuery, conversationFilterProps} = props;
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const accountId = getAccountId();
  const userId = getUserId();
  const userUuid = getUserUUID();
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const [stateData, setStateData] = useState({
    agentMembers: [] as any,
    loading: false as boolean,
  });
  const [contactDetails, setContactDetails] = useState<IContactDetails>()
  const [msgQueue, setMsgQueue] = useState<MsgQueue[]>([]);
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const isEmailUserPreferenceEnabled = isAccountConfigEnabled(CONFIG_CODES.EMAIL_VIEW_USER_PREFERENCE);
  const commonService = CommonService.getCommonServiceInstance();
  const axiosService = commonService.axiosService;

  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList);
  const {height} = Dimensions.get('window');
  const toast = useToast()

  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const ehrURL = getEHRUrl(contactDetails?.locationId, '');

  const [conversationState, setConversationState] = useState({
    isLoading: false,
    isFilterOpen: false,
    isNoDataFound: false,
    selectedConversation: {} as IConversationData,
    searchString: '',
    selectedTabCode: CONVERSATION_TAB_CODES.RECENT,
    readConversationData: {} as IConversationData,
    headerFilterObj: {} as any,
    conversationFilter: {} as any,
    isMsgDrawerVisible: false,
    selectedInboxTypeCode: selectedInboxTypeCode,
    isGroupConversationLoading: false,
    isDetailsContainerVisible: false,
    isSeachMsgContainerVisible: false,
    searchedMessage: {} as ISearchMessageData,
    enableConversationSelection: false,
    selectedConversationList: [] as string[],
    selectedInboxId: -1,
    checkedForAllConversation: false,
    deselectedConversationUuidList: [] as string[],
    isDeselectedSomeConversations: false,
    sideCarSearchString: undefined as string | undefined,
  });

  const styles = React.useMemo(
    () => getConversationStyles({isSidecarContext}),
    [isSidecarContext]
  );

  const onMsgSendOnArchiveConversationListenerFn = useCallback(
    (data: {
      conversationData: IConversationData
    }) => {
      const conversationData = data?.conversationData;
      if (conversationData?.id && conversationData?.status === 1) {
        setConversationState((prev) => {
          return {
            ...prev,
            isNoDataFound: false,
            selectedConversation: {
              ...prev.selectedConversation,
              status: 0
            },
            isGroupConversationLoading: false,
          };
        });
      }
    },
    [selectedInboxTypeCode, conversationFilterQuery]
  );
  const otherContactTypeId = getContactTypeId(PERSON_TYPES.OTHER);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    setConversationState((prev) => {
      return {
        ...prev,
        enableConversationSelection: false,
        selectedConversationList: [],
      }
    })

    eventBus.addEventListener(
      CUSTOM_MESSAGE_EVENT_CODES.NEW_MESSAGE_CREATED,
      onMsgSendOnArchiveConversationListenerFn
    );
    const finalConversationFilter = getConversationChannelTabFilter(
      selectedInboxTypeCode,
      {
        accountId,
        userUuid,
        groupTypeCodeIdObj,
        id: userData?.id,
        customFilterData: {
          selectedLocationUuids: conversationFilterProps?.selectedLocationUuids,
          selectedInboxId: conversationFilterProps?.selectedInboxId,
          assigneeIds: conversationFilterProps?.selectedUserIds,
        },
      } as any,
      isEFaxMessagingEnabled,
      isPatientMessagingEnabled,
      props?.emailInboxUserPreference,
      otherContactTypeId,
      isEmailUserPreferenceEnabled
    );
      const conversationFilter = {
        ...finalConversationFilter,
        ...props?.conversationFilterQuery,
      };
      setConversationState((prev) => {
        return {
          ...prev,
          conversationFilter: conversationFilter,
          searchString: '',
        };
      });
    return () => {
      eventBus.removeEventListener(onMsgSendOnArchiveConversationListenerFn);
    };

  }, [selectedInboxTypeCode, conversationFilterQuery, props?.emailInboxUserPreference]);

  useEffect(() => {
    getAllAgentsMember();
    return () => {
      setConversationState((prev) => {
        return {
          ...prev,
          isLoading: false,
          isFilterOpen: false,
          isNoDataFound: false,
          selectedConversation: {} as IConversationData,
          searchString: '',
          selectedTabCode: CONVERSATION_TAB_CODES.RECENT,
          readConversationData: {} as IConversationData,
          headerFilterObj: {} as any,
          conversationFilter: {} as any,
          isMsgDrawerVisible: false,
          selectedInboxTypeCode: selectedInboxTypeCode,
          isGroupConversationLoading: false,
          isDetailsContainerVisible: false,
          isSeachMsgContainerVisible: false,
          searchedMessage: {} as ISearchMessageData,
          enableConversationSelection: false,
          selectedConversationList: [] as string[],
          selectedInboxId: -1,
          checkedForAllConversation: false,
          deselectedConversationUuidList: [] as string[],
          isDeselectedSomeConversations: false,
        };
      });
    };
  }, []);

  useEffect(() => {
    setConversationState((prev) => {
      return {
        ...prev,
        sideCarSearchString: props.sideCarSearchString,
      };
    });
  }, [props.sideCarSearchString]);

  useEffect(() => {
    if (isSidecarContext) {
      props.isConversationSelectedInSideCar?.(
        conversationState.selectedConversation?.id > 0
      );
    }
  }, [conversationState.selectedConversation]);

  useEffect(() => {
    setConversationState((prev)=> {
      return {
        ...prev,
        isDetailsContainerVisible: false,
        isSeachMsgContainerVisible: false
      }
    })
  },[conversationState?.selectedConversation?.id])

  const agentsData = getAgentsList();

  const getAllAgentsMember = async () => {
    setStateData({...stateData, loading: true});
    if (agentsData) {
      setStateData({
        ...stateData,
        agentMembers: agentsData,
        loading: false,
      });
    }
  };

  const onAddMsgToQueue = useCallback(async (msgQueue: MsgQueue[]) => {
    try {
      const promiseList = msgQueue.map((item) => {
        return sendMsgFromQueue({...item});
      });
      await Promise.all(promiseList);
      setMsgQueue([]);
    } catch (error) {}
  }, []);

  const sendMsgFromQueue = async (args: MsgQueue) => {
    const {conversationId, data: formData} = args;
    try {
      const response = await axiosService.post(
        `/accounts/${accountId}/conversations/${conversationId}/messages`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );
      response.data['sender_type'] = 'user';
      return response;
    } catch (error) {
      showToast(toast, 'Message could not be sent!', ToastType.error);
    }
  };

  const isSelectGroupIsInternal = useCallback(() => {
    if (conversationState?.selectedConversation?.groupConversation?.id) {
      const groupCode =
        conversationState?.selectedConversation?.groupConversation?.groupType
          ?.code;
      const contactInfo = getContactDataAndType(
        conversationState?.selectedConversation
      );
      const contactData: any = contactInfo?.contactData || {};
      if (
        groupCode === GROUP_TYPES.INTERNAL ||
        groupCode === GROUP_TYPES.USER_BROADCAST ||
        Object.keys(contactData).length === 0
      ) {
        return true;
      }
    }
    return false;
  }, [conversationState?.selectedConversation?.groupConversation?.id]);
  const isSelectGroupBroadcast = useCallback(() => {
    if (conversationState?.selectedConversation?.groupConversation?.id) {
      const groupCode =
        conversationState?.selectedConversation?.groupConversation?.groupType
          ?.code;
      if (
        groupCode === GROUP_TYPES.PATIENT_BROADCAST ||
        groupCode === GROUP_TYPES.USER_BROADCAST ||
        groupCode === GROUP_TYPES.INTERNAL
      ) {
        return true;
      }
    }
    return false;
  }, [conversationState?.selectedConversation?.groupConversation?.id]);

  const getConversationFilterWhereCondition = (
    selectedFilter: any,
    selectedTabCode: string
  ): any => {
    const currentFilterObj = getConversationFilter(
      selectedFilter,
      selectedTabCode,
      selectedInboxTypeCode,
      conversationState,
      userData,
      selectedInbox
    );

    return currentFilterObj;
  };

  const containerFlexWidth = React.useMemo(
    () => ({
      conversationListContainer: isSidecarContext
        ? conversationState?.selectedConversation?.id
          ? 0
          : 1
        : 0.25,
      msgListContainer: isSidecarContext
        ? conversationState?.selectedConversation?.id
          ? 1
          : 0
        : conversationState.isDetailsContainerVisible
        ? isSelectGroupBroadcast()
          ? 0.49
          : isSelectGroupIsInternal()
          ? 0.75
          : 0.49
        : conversationState.isSeachMsgContainerVisible
        ? 0.49
        : 0.75,
      detailsContainer: isSelectGroupIsInternal() ? 0 : 0.26,
      internalGroupDetail: isSelectGroupIsInternal() ? 0.26 : 0,
    }),
    [
      isSidecarContext,
      conversationState,
      isSelectGroupBroadcast,
      isSelectGroupIsInternal,
    ]
  );

  const onConversationActionPerformed = (
    actionCode: string,
    actionData: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.NO_DATA_FOUND: {
        setConversationState((prev) => {
          return {
            ...prev,
            isNoDataFound: true,
            selectedConversation: {} as IConversationData,
            isGroupConversationLoading: false,
          };
        });
        break;
      }
      case CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT: {
        setConversationState((prev) => {
          return {
            ...prev,
            isDetailsContainerVisible: false,
            isSeachMsgContainerVisible: false,
          };
        });
        break;
      }
      case COMMON_ACTION_CODES.ITEM_CLICKED: {
        setConversationState((prev) => {
          return {
            ...prev,
            isNoDataFound: false,
            selectedConversation: actionData,
            searchedMessage: {} as IConversationMessage,
            isGroupConversationLoading: false,
          };
        });
        break;
      }
      case COMMON_ACTION_CODES.MESSAGE_TYPE_CHANGE: {
        const finalFilterData = getConversationFilterWhereCondition(
          {},
          actionData.tabCode
        );
        setConversationState((prev) => {
          return {
            ...prev,
            selectedTabCode: actionData.tabCode,
            conversationFilter: {...prev.conversationFilter, ...finalFilterData},
            searchString: '',
            isGroupConversationLoading: false,
          };
        });
        break;
      }
      // case for person type changed
      case COMMON_ACTION_CODES.FILTER_CHANGED:
      case COMMON_ACTION_CODES.STATUS_CHANGED:
      case COMMON_ACTION_CODES.DATE_RANGED_CHANGED: {
        const tempHeaderFilterObj = getHeaderConversationFilterObj(
          actionCode,
          actionData,
          conversationState.headerFilterObj
        );
        const finalConversationFilter = getConversationFilterObj(
          actionCode,
          actionData,
          conversationState.conversationFilter
        );
        setConversationState((prev) => {
          return {
            ...prev,
            headerFilterObj: tempHeaderFilterObj,
            conversationFilter: {...prev.conversationFilter, ...finalConversationFilter},
            isGroupConversationLoading: false,
          };
        });
        break;
      }
      case COMMON_ACTION_CODES.INBOX_TYPE_CHANGE: {
        setConversationState((prev) => {
          return {
            ...prev,
            selectedInboxTypeCode: actionData.code,
            searchString: '',
            isGroupConversationLoading: false,
          };
        });
        break;
      }
      case COMMON_ACTION_CODES.ALL: {
        const tempHeaderFilterObj = getHeaderConversationFilterObj(
          actionCode,
          actionData,
          conversationState.headerFilterObj
        );
        const finalConversationFilter = getConversationFilterObj(
          actionCode,
          actionData,
          conversationState.conversationFilter
        );
        setConversationState((prev) => {
          return {
            ...prev,
            headerFilterObj: tempHeaderFilterObj,
            conversationFilter: {...prev.conversationFilter, ...finalConversationFilter},
            searchString: '',
            isGroupConversationLoading: false,
          };
        });
        break;
      }
      case CONVERSATION_ACTION_CODES.MESSAGE_READ: {
        setConversationState((prev) => {
          return {
            ...prev,
            readConversationData: actionData,
            selectedConversation: {
              ...prev.selectedConversation,
              unreadMessages: [],
              unreadMsgCount: 0
            },
            isGroupConversationLoading: false,
          };
        });
        break;
      }
      case CONVERSATION_ACTION_CODES.CONTACT_TYPING: {
        setConversationState((prev) => {
          return {
            ...prev,
            isGroupConversationLoading: false,
            selectedConversation: {
              ...prev.selectedConversation,
              conversationContact: {
                ...prev.selectedConversation.conversationContact,
                isOnline: true,
              },
            },
          };
        });
        break;
      }
      case CONVERSATION_ACTION_CODES.CONVERSATION_HEADER_BTN_CLICKED: {
        const tempConversationFilter = {
          ...conversationState.conversationFilter,
        };
        if (actionData.code === HEADER_BUTTON_CODES.ALL_CONVERSATIONS) {
          delete tempConversationFilter.mentions;
          setConversationState((prev) => {
            return {
              ...prev,
              searchString: '',
              isGroupConversationLoading: false,
            };
          });
        } else {
          tempConversationFilter.mentions = {
            userId: {
              _eq: userId,
            },
          };
        }
        setConversationState((prev) => {
          return {
            ...prev,
            isGroupConversationLoading: false,
            conversationFilter: {
              ...prev.conversationFilter, ...tempConversationFilter,
            },
          };
        });
        break;
      }
      case CONVERSATION_ACTION_CODES.GROUP_CONVERSATION_DATA_LOADING: {
        setConversationState((prev) => {
          return {
            ...prev,
            isGroupConversationLoading: actionData.isGroupConversationLoading,
          };
        });
        break;
      }
      case CONVERSATION_ACTION_CODES.IS_DETAILS_CONTAINER_VISIBLE: {
        if(isSidecarContext){
          const contactInfo = getContactDataAndType(
            conversationState?.selectedConversation
          );
          const ehrPatientId = contactInfo?.contactData?.patient?.patientId || '';
          const openInEhrUrl = getViewInEHRLink(ehrPatientId, ehrURL);
          if (openInEhrUrl) {
            window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
          } else {
            redirectToNewTab(`/members/patient/${contactInfo?.contactData?.id}`)
          }
        }
        else {
          setConversationState((prev) => {
            return {
              ...prev,
              isDetailsContainerVisible: !conversationState.isDetailsContainerVisible,
              isSeachMsgContainerVisible: false,
            };
          });
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.SEARCH_MESSAGE: {
        setConversationState((prev) => {
          return {
            ...prev,
            isSeachMsgContainerVisible: !conversationState.isSeachMsgContainerVisible,
            isDetailsContainerVisible: false,
          }
        })
      }
      break;

      case COMMON_ACTION_CODES.TOGGLE_SMS_INBOX: {
        setConversationState((prev) => {
          return {
            ...prev,
            selectedInboxId: actionData,
            selectedConversationList: prev?.selectedInboxId === actionData ? prev?.selectedConversationList : [],
            enableConversationSelection: (prev?.selectedInboxId === actionData && prev.enableConversationSelection),
          }
        })
      }
      break;

      case CONVERSATION_ACTION_CODES.CONVERSATION_SELECTION: {
        setConversationState((prev) => {
          return {
            ...prev,
            selectedConversationList: actionData,
            enableConversationSelection: (actionData?.length > 0),
            isDetailsContainerVisible: false,
            isSeachMsgContainerVisible: false,
          }
        })
        break;
      }
      case CONVERSATION_ACTION_CODES.DESELECT_ALL_CONVERSATION: {
        setConversationState((prev) => {
          return {
            ...prev,
            selectedConversationList: [],
            enableConversationSelection: false,
            isDetailsContainerVisible: false,
            isSeachMsgContainerVisible: false,
            checkedForAllConversation: false,
          }
        })
        break;
      }
      case CONVERSATION_ACTION_CODES.CONVERSATION_DESELECTION: {
        setConversationState((prev) => {
          return {
            ...prev,
            isDeselectedSomeConversations: false,
            deselectedConversationUuidList: actionData,
          }
        })
        break;
      }
    }
  };
  const setSearchText = useCallback((searchText: string) => {
    setConversationState((prev) => {
      return {
        ...prev,
        searchString: searchText,
      };
    });
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const onSideCarConversationNavigationBack = () => {
    resetSearchParamsForSelectedConversation();
    setConversationState((prev) => {
      return {
        ...prev,
        selectedConversation: {} as IConversationData,
        isGroupConversationLoading: false,
      };
    });
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CUSTOM_MESSAGE_EVENT_CODES.SIDECAR_CONVERSATION_UNMOUNT, {});
  };

  const onConversationFilterPerformed = useCallback(
    (actionCode: string, filterCode: string, selectedFilterData: any) => {
      const finalFilterData = getConversationFilterWhereCondition(
        {
          [filterCode]: selectedFilterData || {},
        },
        conversationState.selectedTabCode
      );
      setConversationState((prev) => {
        return {
          ...prev,
          filterData: {
            [filterCode]: selectedFilterData || {},
          } as any,
          conversationFilter: {
            ...prev.conversationFilter,
            ...finalFilterData,
          },
        };
      });
    },
    [conversationState.selectedTabCode]
  );

  const getRightSideContainerHeight = useCallback(() => {
    if (isSidecarContext && conversationState.selectedConversation?.id > 0) {
      return height;
    }
    return (
      height -
      60 -
      (conversationState.enableConversationSelection ? 49 : 0) -
      (isSidecarContext ? (showOnlyInternalChatInSidecar ? 0 : 40) : 0)
    );
  }, [
    isSidecarContext,
    conversationState.selectedConversation?.id,
    conversationState.enableConversationSelection,
    showOnlyInternalChatInSidecar,
    height,
  ]);

  const onSelectConversationActionPerformed = useCallback(
    (actionCode?: string, actionData?: boolean) => {
      if (actionCode === CONVERSATION_ACTION_CODES.SELECT_ALL_CONVERSATION) {
        setConversationState((prev) => {
          return {
            ...prev,
            checkedForAllConversation: !actionData,
            selectedConversationList: [],
            deselectedConversationUuidList: [],
            enableConversationSelection: actionData ? false : true,
            isDeselectedSomeConversations: !actionData ? true : false,
          };
        });
      } else {
        setConversationState((prev) => {
          return {
            ...prev,
            checkedForAllConversation: false,
            enableConversationSelection: false,
            selectedConversationList: [],
          };
        });
      }
    },
    []
  );

  const onSearchMsgThreadDrawerClickActionPerformed = useCallback(
    (msgData: IConversationMessage) => {
      const searchMessageData = {
        id: msgData?.id,
        uuid: msgData?.uuid,
        createdAt: msgData?.createdAt,
        clickEventId: v4(),
      };
      setConversationState((prev) => {
        return {
          ...prev,
          searchedMessage: searchMessageData,
          isSeachMsgContainerVisible: false,
        };
      });
    },
    []
  );

  const onSearchMsgThreadClickActionPerformed = useCallback(
    (msgData: IConversationMessage) => {
      const searchMessageData = {
        id: msgData?.id,
        uuid: msgData?.uuid,
        createdAt: msgData?.createdAt,
        clickEventId: v4(),
      };
      setConversationState((prev) => {
        return {
          ...prev,
          searchedMessage: searchMessageData,
        };
      });
    },
    []
  );

  const getRightSideContainer = useCallback((): JSX.Element => {
    return (
      <HStack flex={1} height={getRightSideContainerHeight()}>
        <View
          flex={
            isSidecarContext
              ? containerFlexWidth.conversationListContainer
              : isSmallScreen
              ? 1
              : containerFlexWidth.conversationListContainer
          }
          style={styles.conversationContainer}
        >
          <View style={styles.conversationFilterContainer}>
            <ConversationFilterContext.Consumer>
              {(context) => {
                return (
                  <ConversationContainer
                    key={conversationState.selectedInboxTypeCode}
                    isConversationSelected={
                      conversationState?.selectedConversation?.id ? true : false
                    }
                    conversationUserFilterData={props?.conversationUserFilterData}
                    selectedInboxTypeCode={selectedInboxTypeCode}
                    mlovData={commonData.MLOV}
                    searchString={context.searchString}
                    selectedTabCode={context.selectedTabCode}
                    readConversationData={context.readConversationData}
                    setSearchText={setSearchText}
                    conversationFilterData={context.conversationFilter}
                    setUserPreferenceChangeLoading={
                      props?.setUserPreferenceChangeLoading
                    }
                    conversationFilterProps={conversationFilterProps}
                    onConversationActionPerformed={
                      onConversationActionPerformed
                    }
                    onActionPerformed={props.onActionPerformed}
                    allInboxList={props.allInboxList}
                    onConversationFilterPerformed={
                      onConversationFilterPerformed
                    }
                    filterModal={props?.filterModal}
                    checkedSelectAllConversation={
                      conversationState.checkedForAllConversation
                    }
                    enableConversationSelection={
                      conversationState?.enableConversationSelection
                    }
                    isDeselectedSomeConversations={
                      conversationState?.isDeselectedSomeConversations
                    }
                    emailInboxUserPreference={props?.emailInboxUserPreference}
                    otherContactTypeId={otherContactTypeId}
                    isEmailUserPreferenceEnabled={isEmailUserPreferenceEnabled}
                    sideCarSearchString={conversationState.sideCarSearchString}
                  />
                );
              }}
            </ConversationFilterContext.Consumer>
          </View>
        </View>
        {conversationState?.selectedConversation?.id ? (
          <>
            {conversationState.isGroupConversationLoading && <LoadingSpinner />}
            <SelectedConversationContext.Consumer>
              {(context) => {
                const contactInfo = getContactDataAndType(
                  context.selectedConversation
                );

                return (
                  <>
                    <View
                      flex={
                        isSidecarContext
                          ? containerFlexWidth.msgListContainer
                          : isSmallScreen
                          ? 1
                          : containerFlexWidth.msgListContainer
                      }
                      pointerEvents={
                        conversationState.enableConversationSelection
                          ? 'none'
                          : 'auto'
                      }
                      opacity={
                        conversationState.enableConversationSelection ? 0.2 : 1
                      }
                      style={styles.msgWindow}
                    >
                      <View flex={1}>
                        <MessagingWindowV2
                          moduleCode={`MessagingWindowV2/Conversations`}
                          msgQueue={msgQueue}
                          onAddMsgToQueue={onAddMsgToQueue}
                          key={context.selectedConversation?.id}
                          selectedInboxTypeCode={selectedInboxTypeCode}
                          isDisplayHeader={true}
                          selectedConversation={context.selectedConversation}
                          conversationInbox={
                            context.selectedConversation?.conversationInbox
                          }
                          showNavigationBack={isSidecarContext}
                          navigationBackPressed={
                            isSidecarContext ? onSideCarConversationNavigationBack : undefined
                          }
                          onConversationActionPerformed={
                            onConversationActionPerformed
                          }
                          selectedTabCode={conversationState.selectedTabCode}
                          showInfoIconInHeader={true}
                          isDetailsContainerVisible={
                            conversationState.isDetailsContainerVisible
                          }
                          isSeachMsgContainerVisible={
                            conversationState.isSeachMsgContainerVisible
                          }
                          searchMessage={conversationState?.searchedMessage}
                          enableConversationSelection={
                            conversationState.enableConversationSelection
                          }
                        />
                        
                      </View>
                    </View>
                    {conversationState.isDetailsContainerVisible &&
                    !isSelectGroupIsInternal() &&
                    !isSelectGroupBroadcast() &&
                    !isSmallScreen ? (
                      <View
                        overflow={'hidden'}
                        flex={containerFlexWidth.detailsContainer}
                      >
                        <View flex={1}>
                          <MessagingContactDetails
                            selectedInboxTypeCode={selectedInboxTypeCode}
                            selectedConversation={context.selectedConversation}
                            contactId={contactInfo?.contactData?.id}
                            height={height - MESSAGING_WINDOW_HEADER_HEIGHT}
                            onConversationActionPerformed={
                              onConversationActionPerformed
                            }
                            onContactDataFetched={(contactDetails: {
                              locationId: string;
                            }) => {
                              setContactDetails((prev) => {
                                return {
                                  ...prev,
                                  locationId: contactDetails?.locationId,
                                };
                              });
                            }}
                            hintExtraDetail={
                              <HintPlanDetailView
                                id={contactInfo?.contactData?.id}
                                isEmployerReportDrillDown={false}
                                locationId={contactDetails?.locationId}
                              />
                            }
                            btnTextVisibility={{
                              // showMeetBtn: true,
                              // showChatBtn : true,
                              showAppointmentBtn: true,
                              showCharts: true,
                              // showViewProfile: true,
                              showSmsBtn: true,
                              showEmailBtn: true,
                            }}
                            parentCode={
                              PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING
                            }
                            showCloseDrawerIcon={true}
                          />
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                    {conversationState.isDetailsContainerVisible && (
                        <View
                          overflow={'scroll'}
                          flex={containerFlexWidth.internalGroupDetail}
                        >
                          <View flex={1}>
                            <MessagingGroupDetail
                              key={context.selectedConversation?.id}
                              selectedConversation={
                                context.selectedConversation
                              }
                              agentsData={stateData?.agentMembers}
                              locationId={contactDetails?.locationId}
                            />
                          </View>
                        </View>
                      )}
                    {conversationState.isSeachMsgContainerVisible ? (
                      <>
                        {isSidecarContext ? (
                          <SearchMessageThreadDrawer
                            showTitle={false}
                            conversationUuid={
                              context.selectedConversation?.uuid
                            }
                            onActionPerformed={onConversationActionPerformed}
                            onMsgClickActionPerformed={
                              onSearchMsgThreadDrawerClickActionPerformed
                            }
                          />
                        ) : (
                          <View overflow={'scroll'} flex={0.26}>
                            <View flex={1}>
                              <SearchMessageThread
                                showTitle={true}
                                conversationUuid={
                                  context.selectedConversation?.uuid
                                }
                                onActionPerformed={
                                  onConversationActionPerformed
                                }
                                onMsgClickActionPerformed={
                                  onSearchMsgThreadClickActionPerformed
                                }
                              />
                            </View>
                          </View>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              }}
            </SelectedConversationContext.Consumer>
          </>
        ) : (
          <>
            {isSidecarContext ? null : (
              <VStack flex="0.74" style={styles.noDataFound}>
                <VStack flex={1}>
                  <Center
                    alignItems={'center'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    height={'100%'}
                  >
                    <Text
                      {...testID('Messages')}
                      fontSize={16}
                      fontWeight={500}
                      color={'gray.500'}
                    >
                      {'Messages'}
                    </Text>
                    <Image
                      mt={4}
                      resizeMode="cover"
                      style={styles.noDataFoundImg}
                      source={require('../../../../assets/images/noConversation.png')}
                      alt="image"
                    />
                  </Center>
                </VStack>
              </VStack>
            )}
          </>
        )}
      </HStack>
    );
  }, [conversationState]);

  return (
    <>
      <View>
        <CommonDataContext.Consumer>
          {() => {
            return (
              <PageBodyContainer style={styles.pageBodyContainer}>
                <ConversationListContext.Provider
                  value={defaultConversationList}
                >
                  <ConversationFilterContext.Provider
                    value={{
                      conversationFilter: conversationState.conversationFilter,
                      searchString: conversationState.searchString,
                      selectedTabCode: conversationState.selectedTabCode,
                      readConversationData:
                        conversationState.readConversationData,
                    }}
                  >
                    <SelectedConversationContext.Provider
                      value={{
                        selectedConversation:
                          conversationState.selectedConversation,
                      }}
                    >
                      {conversationState.enableConversationSelection && (
                        <View
                          style={styles.selectConversationContainer}
                        >
                          <SelectConversation
                            selectedInboxTypeCode={selectedInboxTypeCode}
                            selectedConversationUuids={
                              conversationState.selectedConversationList
                            }
                            conversationFilterQuery={conversationFilterQuery}
                            selectedInboxId={conversationState.selectedInboxId}
                            deselectedConversationUuidList={
                              conversationState.deselectedConversationUuidList
                            }
                            onActionPerformed={onSelectConversationActionPerformed}
                            conversationUserFilterData={props?.conversationUserFilterData}
                          />
                        </View>
                      )}
                      <View
                        style={styles.rightSideContainerView}
                      >
                        <HStack
                            style={styles.rightSideContainer}
                        >
                          {conversationState.isLoading && Object.keys(conversationState.selectedConversation).length === 0 ? (
                            <Spinner size={'lg'} {...testID(TestIdentifiers.pageLoading)}/>
                          ) : (
                            <HStack flex={1}>{getRightSideContainer()}</HStack>
                          )}
                        </HStack>
                      </View>
                    </SelectedConversationContext.Provider>
                  </ConversationFilterContext.Provider>
                </ConversationListContext.Provider>
              </PageBodyContainer>
            );
          }}
        </CommonDataContext.Consumer>
      </View>
    </>
  );
};

export default Conversations;
