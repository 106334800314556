import { gql } from "@apollo/client";

export const GetAllLabels = gql`
  query GetAllLabels {
    labels {
      id
      accountId
      description
      title
      color
    }
  }
`;

export const GetContactMasterTags = gql`
query getContactMasterTags($tagIds:[uuid!]) {
  labels(
  where: {
  labelTypes:{
  labelType:{
  code: {
  _eq: "CONTACT"
  }
  }
  },
  }
  order_by: {
  title: asc
  }
  ) {
    id
    color
    description
    title
  }
  }`


export const GetAllLabelsByFilter = gql`
  query GetAllLabelsByFilter(
    $labelTypeId: uuid!
    $search: String!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    labels(
      where: {
        title: {_ilike: $search}
        labelTypes: {labelTypeId: {_eq: $labelTypeId}}
      }
      order_by: {title: asc}
      limit: $limit
      offset: $offset
    ) {
      id
      color
      description
      title
      tagCount {
        id
      }
      labelTypes {
        labelId
        labelTypeId
      }
    }
  }
`;


export const GetLabelsByFilter = gql`
query GetLabelsByFilter($limit:Int!,$offset:Int!,$labelTypeId: uuid!, $search: String !) {
  labels(
    limit:$limit
    offset:$offset
    where: {
      title: {_ilike: $search}
      labelTypes: {
        labelTypeId: {
          _eq:
            $labelTypeId
          }
        }
      },
      order_by: {
        title: asc
      },
      ) {
    id
    color
    description
    title
    tagCount {
      id
    }
    labelTypes {
      labelId
      labelTypeId
    }
  }
}`;


/*


query MyQuery {
  labelTaggings(where: {tagging: {taggableId: {_eq: 15}, taggableType: {_eq: "Conversation"}}}) {
    id
    labels {
      color
      description
      title
    }
    tagging {
      id
      taggableId
      taggableType
      taggerId
      taggerType
      createdAt
      context
    }
  }
}



mutation MyMutation {
  createLabelTagging(object: {accountId: 6, labelId: 6, tagging: {data: {taggableId: 15, taggableType: "Conversation", taggerId: 7, taggerType: "User"}}}) {
    id
    tagging {
      id
    }
    taggingId
  }
}



*/

export const GetLabelsByLabelTitleAndTypeId = gql`
query getLabelsByLabelTitleAndTypeId($limit:Int!,$offset:Int!,$labelTypeId: uuid!, $search: String !) {
  labels(
    limit:$limit
    offset:$offset
    where: {
      title: {_ilike: $search}
      labelTypes: {
        labelTypeId: {
          _eq:
            $labelTypeId
          }
        }
      }) {
    id
    color
    description
    title
    uuid
    labelTypes {
      labelId
      labelTypeId
    }
  }
}`;


export const GET_LABEL_BY_IDS = gql`
query getLabelByIds($labelTypeId: uuid!, $labelIds: [uuid]!) {
  labels(
    where: {
      uuid: {_in: $labelIds}
      labelTypes: {
        labelTypeId: {
          _eq:
            $labelTypeId
          }
        }
      }) {
    id
    title
    uuid
  }
}`;


export const LABELS_BY_ID_IN = gql`
query MyQuery($where: labels_bool_exp = {id: {_in: []}}) {
  labels(where: $where) {
    id
    color
    description
    title
    uuid
  }
}

`

export default {GetAllLabels, GetAllLabelsByFilter, GetContactMasterTags, GetLabelsByFilter, GetLabelsByLabelTitleAndTypeId
  ,LABELS_BY_ID_IN
};


