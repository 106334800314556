export enum HealthMapTemplateViewCodes {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  ADD = 'ADD',
  LIST = 'LIST',
  CANCEL = 'CANCEL'
}

export const HEALTH_MAP_VIEW_ROLE = {
  PRIVATE: 'PRIVATE',
  ALL_USERS: 'ALL_USERS',
  SELECTED_USERS: 'SELECTED_USERS',
  SELECTED_ROLES: 'SELECTED_ROLES'
}

export const UI_STATE_ACTIONS = {
  DRAWER_OPEN: 'isDrawerOpen',
  SUBMIT_PRESSED: 'isSubmitPressed',
  ITEMS: 'items'
}

export const VALIDATION_STATE = {
  VALID_NAME: 'isValidName',
  VALIED_ROLE: 'isValidRole',
  VALID_USER: 'isValidUser',
  DUPLICATE_NAME : 'isDuplicateName',
  VALID_SECTIONS : 'validSections'
}