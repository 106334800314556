import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Checkbox, Drawer, Select as MultiSelect, Select, notification} from 'antd';
import {
  Button,
  Center,
  FormControl,
  HStack,
  Icon,
  Input,
  InputGroup,
  Spinner,
  Text,
  VStack,
  View,
  useMediaQuery,
  useToast,
} from 'native-base';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useIntl} from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import {ILoginUserData} from '../../../../../../Interfaces';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  PARENT_CODE,
} from '../../../../../../constants';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {USER_ROLE_CODES} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {GetAccountIdBySubdomain} from '../../../../../../services/AccountSubdomain/AccountSubdomainQuery';
import InboxQueries from '../../../../../../services/Inbox/InboxQueries';
import {
  IInboxData,
  IInboxMember,
} from '../../../../../../services/Inbox/interfaces';
import {Colors} from '../../../../../../styles/Colors';
import {
  getCurrentSubdomainWeb,
  isOutlookEnabled,
} from '../../../../../../utils/commonUtils';
import {
  ToastType,
  showPhiWarningToast,
  showToast,
} from '../../../../../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {ModalActionSubTitle} from '../../../../../common/ModalActionTitle/ModalActionSubTitle';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import GmailSvg from '../../../../../common/Svg/GmailSvg';
import OutlookSvg from '../../../../../common/Svg/OutlookSvg';
import {CHANNEL_TYPE} from '../../../Conversations/ConversationConst';
import {addOrUpdateInboxMembersAPI, createInboxMembersAPI} from '../SmsInboxCreate/SmsService';
import {
  createOutlookEmailInbox,
  getInboxAuthTokenIdAndEmail,
} from '../../../../../common/EmailDrawerCommonV2/EmailInboxApi';
import {
  EMAIL_INBOX_ACTION_CODES,
  EMAIL_INBOX_ERROR,
  EMAIL_PLATFORMS,
  EMAIL_PLATFORM_OPTIONS,
  EMAIL_REF_KEYS,
  EMAIL_SETTING,
  GOOGLE_SMTP_INFO_LINK,
} from './EmailInboxConst';
import {styles} from './EmailInboxStyle';
import {
  getChildWindowDimensions,
  getEmailInboxBodyData,
  getSmtpEmailBodyData,
  getUpdatedEmailInboxErrorMessages,
  isInboxPresent,
  isOutlookEmailInbox,
  openOutlookLoginWindow,
  validateOutlookEmailData,
} from './EmailInboxUtils';
import {
  createEmailForwardRoute,
  createEmailInboxAPI,
  createSMTPEmail,
  updateSmtpChannelSettingsForEmailInbox,
} from './EmailService';
import {
  ICreateEmailRoute,
  IEmailInboxCreateParams,
  IEmailInboxDrawerProps,
  IEmailInboxDrawerState,
  IEmailInboxErrors,
  IEmailInboxFormError,
  IEmailInboxFormKeys,
  IEmailInboxRef,
  IEmailPlatform,
  IForwardRoute,
} from './interfaces';
import {IAddUser, IInboxMembers} from '../SmsInboxCreate/interfaces';
import {CustomUserSearch} from '../../../../../common/CustomUserSearch/CustomUserSearch';
import {USER_SEARCH_TYPE} from '../../../../constants';
import {IInternalUsers} from '../../../../../common/CustomUserSearch/interface';
import {UserQueries} from '../../../../../../services';
import {
  getFormattedInboxMembers,
  getUserIdsFromUserData,
} from '../../../Conversations/MessagingWindow/MessagingUtils';
import {getSelectedUsersUUIDs} from '../../../../../common/CustomUserSearch/CustomUserSearchUtils';
import { getCurrentSubdomain } from '../../../../../../screens/MainScreen/MainScreenHelper';

const {Option} = MultiSelect;
const EmailInboxDrawer = (props: IEmailInboxDrawerProps) => {
  const smsInboxToast = useToast();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const userData = commonData.userData || ({} as ILoginUserData);
  const {isDrawerVisible, selectedInboxData, onEmailInboxActionPerformed} =
    props;
  const emailFormRef = useRef<IEmailInboxRef>({} as any);
  const [emailInboxState, setEmailInboxState] =
    useState<IEmailInboxDrawerState>({
      selectedUsersIds: [],
      selectedPhoneNumber: '',
      isLoading: false,
      errors: {} as any,
      showImapPassword: false,
      showSmtpPassword: false,
      emailSetting: EMAIL_SETTING.CUSTOM,
      inboxName: '',
      selectedPlatform: undefined,
      outlookAuthCode: '',
      email: '',
      inboxAuthTokenId: '',
      showInboxNameInEmailRecipients: false,
      isOutlookElementVisible: false,
      selectedUsersUuids: [],
      selectedUsersData: [],
    });

  const [errors, setErrors] = React.useState<IEmailInboxErrors>({
    code: '',
    email: '',
    inboxName: '',
    selectedAgents: '',
  });
  const isOutlookInbox = isOutlookEmailInbox(
    props.selectedInboxData?.singleInboxData?.channelEmail
  );

  const enableChatHistoryRead = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_CHAT_HISTORY_READ
  );
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const isOutlookEnabledForAccount = isOutlookEnabled();

  const [updateInboxName] = useMutation(InboxQueries.updateSMSInboxName);
  const [updateInbox] = useMutation<{updateInbox: {id: number}}>(
    InboxQueries.UpdateInbox
  );

  const [UpdateChannelEmailForwardRoutId] = useMutation(
    InboxQueries.UpdateChannelEmailForwardRoutId
  );
  const [getAllNonDeletedInboxesMembers] = useLazyQuery<{
    inboxMembers: IInboxMember[];
  }>(InboxQueries.GetAllNonDeletedInboxesMembers, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
  });

  const [getUsersByUuid] = useLazyQuery(UserQueries.GET_USER_BY_UUID_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      uuidList: emailInboxState.selectedUsersUuids,
    },
  });

  const [getInboxDataWithChannel] = useLazyQuery<IInboxData>(
    InboxQueries.GetInboxesData,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      notifyOnNetworkStatusChange: true,
    }
  );
  const [deleteInboxesMembers] = useMutation(InboxQueries.deleteInboxesMembers);
  const [createInboxMembers] = useMutation(InboxQueries.createInboxMembers);

  const {loading: subdomainLoading, data: subdomainData} = useQuery(
    GetAccountIdBySubdomain,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        subdomain: getCurrentSubdomain(),
      },
    }
  );

  const closeModal = () => {
    onEmailInboxActionPerformed(EMAIL_INBOX_ACTION_CODES.DRAWER_CLOSE);
  };

  const handleInboxCreateOrUpdate = () => {
    if (emailInboxState.selectedPlatform === EMAIL_PLATFORMS.GMAIL) {
      onEmailInboxCreateOrUpdate();
    } else if (emailInboxState.selectedPlatform === EMAIL_PLATFORMS.OUTLOOK) {
      onOutlookEmailInboxCreateOrUpdate();
    } else if (emailInboxState.selectedPlatform === EMAIL_PLATFORMS.MAILGUN) {
      updateMailgunInbox(selectedInboxData?.singleInboxData?.id);
    }
  };

  const onOutlookEmailInboxCreateOrUpdate = async () => {
    const {errors, isValid} = validateOutlookEmailData({
      inboxName: emailInboxState.inboxName,
      code: emailInboxState.outlookAuthCode,
      selectedAgents: emailInboxState.selectedUsersUuids,
      email: emailInboxState.email,
      inboxId: props.selectedInboxData?.singleInboxData?.id,
    });
    setErrors(errors);
    if (!isValid) {
      return;
    }
    setEmailInboxState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    if (props.selectedInboxData?.singleInboxData?.id) {
      // update inbox name
      try {
        const response = await updateInbox({
          variables: {
            id: props.selectedInboxData?.singleInboxData?.id,
            _set: {
              name: emailInboxState.inboxName,
            },
          },
        });

        // update inbox users
        await addOrUpdateInboxMembers(
          props.selectedInboxData?.singleInboxData?.id
        );
        successHandler({message: 'Inbox updated successfully!'});
      } catch (error) {
        errorHandler({
          message: 'Something went wrong. Please try again later.',
        });
      }
    } else {
      try {
        // create inbox
        const emailInbox = await createOutlookEmailInbox({
          code: emailInboxState.outlookAuthCode,
          email: emailInboxState.email,
          name: emailInboxState.inboxName,
          inboxAuthTokenId: emailInboxState.inboxAuthTokenId,
        });
        const inboxId = emailInbox.id;
        // create inbox members
        const inboxMembers: {
          userId: number;
          inboxId: number;
        }[] = emailInboxState.selectedUsersIds.map((item) => ({
          userId: item,
          inboxId: inboxId,
        }));

        await createInboxMembers({
          variables: {
            objects: inboxMembers,
          },
        });
        successHandler({message: 'Inbox created successfully!'});
        onEmailInboxActionPerformed(EMAIL_INBOX_ACTION_CODES.ON_INBOX_SAVE);
      } catch (error) {
        errorHandler({
          message: 'Something went wrong. Please try again later.',
        });
      }
    }
    closeModal();
  };

  const successHandler = (data: {message: string}) => {
    showToast(smsInboxToast, data.message, ToastType.success);
  };

  const errorHandler = (data: {message: string}) => {
    showToast(smsInboxToast, data.message, ToastType.error);
  };

  const addOrUpdateInboxMembers = async (inboxId: number) => {
    const userData = await getUsersByUuid({
      variables: {
        uuidList: emailInboxState.selectedUsersUuids,
      },
    });
    const currentSelectedInboxUserIds = getUserIdsFromUserData(
      userData?.data?.users
    );
    const existingInboxMembersResponse = await getAllNonDeletedInboxesMembers({
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      variables: {
        inboxId: inboxId,
      },
    });
    const existingInboxMemberIds = (
      existingInboxMembersResponse.data?.inboxMembers || []
    ).map((item) => item.userId);

    // existing user is not selected
    const memberIdsToDelete: number[] = [];
    const userObjectList: IInboxMembers[] = [];
    existingInboxMemberIds.forEach((item) => {
      if (!currentSelectedInboxUserIds.includes(item)) {
        // memberIdsToDelete.push(item);
        userObjectList.push({
          userId: item,
          inboxId: inboxId,
          isDeleted: true,
        });
      }
    });

    // selected user is not inbox member
    const memberIdsToAdd: number[] = [];
    currentSelectedInboxUserIds.forEach((item) => {
      if (!existingInboxMemberIds.includes(item)) {
        // memberIdsToAdd.push(item);
        userObjectList.push({
          userId: item,
          inboxId: inboxId,
          isDeleted: false,
        });
      }
    });

    // if (memberIdsToDelete.length > 0) {
    //   await deleteInboxesMembers({
    //     variables: {
    //       inboxId: inboxId,
    //       userIds: memberIdsToDelete,
    //     },
    //   });
    // }
    if (userObjectList.length > 0) {
      // const inboxMembers: {
      //   userId: number;
      //   inboxId: number;
      // }[] = memberIdsToAdd.map((item) => ({
      //   userId: item,
      //   inboxId: inboxId,
      // }));
      // await createInboxMembers({
      //   variables: {
      //     objects: inboxMembers,
      //   },
      // });
      const addedMemberData = await addOrUpdateInboxMembersAPI({memberList: userObjectList, inboxId: inboxId});
    }
    onEmailInboxActionPerformed(EMAIL_INBOX_ACTION_CODES.ON_INBOX_SAVE);
  };

  const onEmailInboxCreateOrUpdate = () => {
    const errorData = getUpdatedEmailInboxErrorMessages(
      emailInboxState,
      emailFormRef
    );

    if (errorData?.isErrorPresent) {
      setEmailInboxState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            ...errorData.errors,
          },
        };
      });
    } else {
      createOrUpdateEmailInbox();
    }
  };

  const onUserSelect = (value: string[], selectedData: IInternalUsers[]) => {
    const userIds = selectedData.map((item: IInternalUsers) => {
      return item.userId;
    });
    setEmailInboxState((prev) => {
      return {
        ...prev,
        selectedUsersIds: userIds,
      };
    });
  };

  const createSMTPUser = async (params: {username: string; domain: string}) => {
    return await createSMTPEmail(params).catch(() => {
      showToast(
        smsInboxToast,
        intl.formatMessage({id: intl.formatMessage({id: 'apiErrorMsg'})}),
        ToastType.error
      );
    });
  };

  const createEmailInboxWithAllConfig = async (
    bodyParams: IEmailInboxCreateParams
  ) => {
    return await createEmailInboxAPI({
      bodyParams: bodyParams,
      pathParams: {
        accountId: userData.account_id,
      },
    }).catch(() => {
      showToast(
        smsInboxToast,
        intl.formatMessage({id: intl.formatMessage({id: 'apiErrorMsg'})}),
        ToastType.error
      );
      setEmailInboxState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
  };

  const createEmailRoute = async (bodyParams: ICreateEmailRoute) => {
    return await createEmailForwardRoute(bodyParams).catch(() => {
      showToast(
        smsInboxToast,
        intl.formatMessage({id: intl.formatMessage({id: 'apiErrorMsg'})}),
        ToastType.error
      );
    });
  };

  const createInboxWithBodyData = async (bodyData: any) => {
    const inboxCreateResp = await createEmailInboxWithAllConfig(bodyData);
    if (inboxCreateResp?.data?.id) {
      if (subdomainData?.accountSubdomainMaps?.[0]?.subDomain) {
        const recipientEmail = getForwardToEmail(bodyData?.channel?.smtp_email);
        const inboxData = await getSingleInboxDataFromAPI(
          inboxCreateResp?.data?.id
        );
        if (inboxData?.data?.inboxes?.[0]?.channelEmail?.uuid) {
          const emailRouteResp = await createEmailRoute({
            recipientEmail,
            description: `${userData.name} Email Route`,
            channelId: inboxData?.data?.inboxes?.[0]?.channelEmail?.uuid,
          });
          const emailRouteData: IForwardRoute = emailRouteResp?.data as any;
          if (emailRouteData?.route?.id) {
            UpdateChannelEmailForwardRoutId({
              variables: {
                id: inboxData?.data?.inboxes?.[0]?.channelEmail?.id,
                forwardRouteId: emailRouteData?.route?.id,
                forwardToEmail: recipientEmail,
              },
            });
          }
          if (emailRouteResp?.data) {
          }
        }
      }
      updateInboxUser(inboxCreateResp.data.id, true);
      updateEmailInbox(inboxCreateResp?.data?.id);
    }
  };

  const getSingleInboxDataFromAPI = async (inboxId: number) => {
    const inboxData = await getInboxDataWithChannel({
      variables: {
        whereCondition: {
          id: {
            _eq: inboxId,
          },
          accountId: {
            _eq: userData.account_id,
          },
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_EMAIL,
          },
          isDeleted: {
            _eq: false,
          },
        },
      },
    });
    return inboxData;
  };
  const getForwardToEmail = (email: string) => {
    const subDomain =
      subdomainData?.accountSubdomainMaps?.[0]?.customDomain ||
      subdomainData?.accountSubdomainMaps?.[0]?.subDomain;
    let emailName = '';
    const emailList = email?.split('@');
    emailName = emailList?.[0] || userData?.email;
    if (emailName) {
      emailName = emailName + '_replyto@' + subDomain;
    }
    return emailName;
  };

  const createEmailInbox = async () => {
    if (subdomainData?.accountSubdomainMaps?.[0]?.subDomain) {
      const subDomain =
        subdomainData?.accountSubdomainMaps?.[0]?.customDomain ||
        subdomainData?.accountSubdomainMaps?.[0]?.subDomain;
      const bodyData = getEmailInboxBodyData(emailInboxState, emailFormRef, {
        subDomain,
      });
      if (EMAIL_SETTING.FOLD_POWERED === emailInboxState.emailSetting) {
        const smtpCreateResp: any = await createSMTPUser({
          username: bodyData?.channel?.email,
          domain: subDomain,
        });
        if (smtpCreateResp?.data?.credentials) {
          bodyData.channel.smtp_password = smtpCreateResp?.data?.credentials;

          await createInboxWithBodyData(bodyData);
        }
      } else {
        await createInboxWithBodyData(bodyData);
      }
    }
  };

  const updateEmailInbox = async (inboxId: number) => {
    setEmailInboxState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const inboxName =
      emailFormRef?.current?.['inboxName']?.value ||
      selectedInboxData?.singleInboxData?.name ||
      '';
    const updatedInboxData = await updateInboxName({
      variables: {
        id: inboxId,
        name: inboxName,
        enableAutoAssignment: false,
        showInboxNameInEmailRecipients: emailInboxState.showInboxNameInEmailRecipients,
        inboxType: EMAIL_PLATFORMS.GMAIL,
      },
    });

    await updateImapAndSmtpSettings(
      updatedInboxData?.data?.updateInbox?.id,
      false
    );
    updateInboxUser(updatedInboxData?.data?.updateInbox?.id, false);
  };

  const updateMailgunInbox = async (inboxId: number) => {
    setEmailInboxState((prev) => {
      return {
        ...prev,
        isLoading: true,
      }
    })
    const inboxName =
      emailFormRef?.current?.['inboxName']?.value ||
      selectedInboxData?.singleInboxData?.name ||
      '';
    const updatedInboxData = await updateInboxName({
      variables: {
        id: inboxId,
        name: inboxName,
        enableAutoAssignment: false,
        showInboxNameInEmailRecipients: emailInboxState.showInboxNameInEmailRecipients,
        inboxType: EMAIL_PLATFORMS.MAILGUN,
      },
    });
    updateInboxUser(updatedInboxData?.data?.updateInbox?.id, false);
  }

  const getInboxUserIds = (inboxMembers: any[]) => {
    const inboxMemberUserIds: any = [];
    inboxMembers.forEach((singleMember) => {
      if (singleMember.userId) {
        inboxMemberUserIds.push(singleMember.userId);
      }
    });
    return inboxMemberUserIds;
  };

  const updateInboxUser = async (inboxId: number, isCreate: boolean) => {
    const smsCreateOrUpdateMsg = isCreate
      ? 'smsInboxCreateSuccess'
      : 'smsInboxUpdatedSuccess';
    const userData = await getUsersByUuid({
      variables: {
        uuidList: emailInboxState.selectedUsersUuids,
      },
    });
    const selectedUserIds = getUserIdsFromUserData(userData?.data?.users) || [];
    const addNewUserList: IAddUser[] = [];
    const inboxMembers =
      props?.selectedInboxData?.singleInboxData?.inboxMembers || [];
    const inboxMemberUserIds = inboxMembers?.map((item) => {
      return item?.userId;
    });
    selectedUserIds.forEach((singleUserId: any) => {
      if (!inboxMemberUserIds?.includes(singleUserId)) {
        addNewUserList.push({
          userId: singleUserId,
          inboxId: props?.selectedInboxData?.singleInboxData?.id,
          isDeleted: false,
        });
      }
    });
    if (inboxMembers?.length) {
      const deleteUserList: any = [];
      const userObjectList: IInboxMembers[] = [];
      inboxMembers?.forEach((singleMember) => {
        if (!selectedUserIds.includes(singleMember.userId)) {
          deleteUserList.push(singleMember.userId);
          userObjectList.push({
            userId: singleMember.userId,
            inboxId: inboxId,
            isDeleted: true,
          });
        }
      });

      const addNewUserList: any = [];
      const inboxMemberUserIds = getInboxUserIds(inboxMembers);
      selectedUserIds.forEach((singleUserId) => {
        if (!inboxMemberUserIds.includes(singleUserId)) {
          addNewUserList.push({
            userId: singleUserId,
            inboxId: inboxId,
            isDeleted: false,
          });
        }
      });

      userObjectList.push(...addNewUserList);
      if (enableChatHistoryRead) {
        const deleteMemberData = await deleteInboxesMembers({
          variables: {
            inboxId: inboxId,
            userIds: deleteUserList,
          },
        });
        const addedMemberData = createInboxMembersAPI({
          memberList: addNewUserList,
          inboxId: inboxId,
        });
      } else {
        const addedMemberData = await addOrUpdateInboxMembersAPI({memberList: userObjectList, inboxId: inboxId});
        // const addedMemberData = await createInboxMembers({
        //   variables: {
        //     objects: addNewUserList,
        //   },
        // });
      }

      setEmailInboxState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      const addNewUserList: any = [];
      const inboxMemberUserIds = getInboxUserIds([]);
      selectedUserIds.forEach((singleUserId) => {
        if (!inboxMemberUserIds.includes(singleUserId)) {
          addNewUserList.push({
            userId: singleUserId,
            inboxId: inboxId,
            isDeleted: false,
          });
        }
      });
      let addedMemberData = {} as any;
      if (enableChatHistoryRead) {
        addedMemberData = createInboxMembersAPI({
          memberList: addNewUserList,
          inboxId: inboxId,
        });
      } else {
        addedMemberData = await addOrUpdateInboxMembersAPI({memberList: addNewUserList, inboxId: inboxId});
        // addedMemberData = await createInboxMembers({
        //   variables: {
        //     objects: addNewUserList,
        //   },
        // });
      }
      if (addedMemberData) {
        showToast(
          smsInboxToast,
          intl.formatMessage({id: smsCreateOrUpdateMsg}),
          ToastType.success
        );
      }
      setEmailInboxState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }

    onEmailInboxActionPerformed(EMAIL_INBOX_ACTION_CODES.ON_INBOX_SAVE);
  };

  const updateImapAndSmtpSettings = async (
    inboxId: number,
    isCreate: boolean
  ) => {
    const subDomain =
      subdomainData?.accountSubdomainMaps?.[0]?.customDomain ||
      subdomainData?.accountSubdomainMaps?.[0]?.subDomain;

    // const imapBodyData = getImapEmailBodyData(emailInboxState, emailFormRef, {
    //   subDomain,
    // });
    // const imapSettingRespData = await updateImapChannelSettingsForEmailInbox({
    //   bodyParams: imapBodyData,
    //   pathParams: {
    //     accountId: '' + userData.account_id,
    //     inboxId: '' + inboxId,
    //   },
    // }).catch((err) => {
    //   showToast(
    //     smsInboxToast,
    //     intl.formatMessage({id: intl.formatMessage({id: 'imapEmailError'})}),
    //     ToastType.error
    //   );

    //   setEmailInboxState((prev) => {
    //     return {
    //       ...prev,
    //       isLoading: false,
    //     };
    //   });
    // });
    // if (imapSettingRespData?.data) {
    //   showToast(
    //     smsInboxToast,
    //     intl.formatMessage({
    //       id: intl.formatMessage({id: 'imapUpdatedSuccess'}),
    //     }),
    //     ToastType.success
    //   );
    //   setEmailInboxState((prev) => {
    //     return {
    //       ...prev,
    //       isLoading: false,
    //     };
    //   });
    // }
    const smtpBodyData = getSmtpEmailBodyData(emailInboxState, emailFormRef, {
      subDomain,
    });
    const smtpSettingRespData = await updateSmtpChannelSettingsForEmailInbox({
      bodyParams: smtpBodyData,
      pathParams: {
        accountId: '' + userData.account_id,
        inboxId: '' + inboxId,
      },
    }).catch((err) => {
      showToast(
        smsInboxToast,
        intl.formatMessage({id: intl.formatMessage({id: 'smtpEmailError'})}),
        ToastType.error
      );
      setEmailInboxState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
    if (smtpSettingRespData?.data) {
      showToast(
        smsInboxToast,
        intl.formatMessage({
          id: intl.formatMessage({id: 'smtpUpdatedSuccess'}),
        }),
        ToastType.success
      );
      setEmailInboxState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }

    setEmailInboxState((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
  };

  const createOrUpdateEmailInbox = () => {
    setEmailInboxState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    if (selectedInboxData?.singleInboxData?.id) {
      updateEmailInbox(selectedInboxData?.singleInboxData?.id);
    } else {
      createEmailInbox();
    }
  };

  const handleError = (errorMessage: any) => {
    notification.open({
      message: errorMessage,
      duration: 2,
      type: 'error',
      placement: 'topRight',
    });
    closeModal();
  };

  useEffect(() => {
    const selectedUserIDs = getDefaultSelectedUsers();
    const formattedInboxMembers = getFormattedInboxMembers(
      props?.selectedInboxData?.singleInboxData?.inboxMembers
    ) as IInternalUsers[];
    setEmailInboxState((prev) => {
      return {
        ...prev,
        selectedUsersIds: selectedUserIDs || [],
        inboxName: selectedInboxData?.singleInboxData?.name || '',
        selectedPlatform: isOutlookInbox
          ? EMAIL_PLATFORMS.OUTLOOK
          : props.selectedInboxData?.singleInboxData?.inboxType === EMAIL_PLATFORMS.MAILGUN
          ? EMAIL_PLATFORMS.MAILGUN
          : props.selectedInboxData?.singleInboxData?.inboxType ===
          EMAIL_PLATFORMS.GMAIL
          ? EMAIL_PLATFORMS.GMAIL
          : undefined,
        email:
          props.selectedInboxData?.singleInboxData?.channelEmail?.email || '',
        showInboxNameInEmailRecipients:
          selectedInboxData?.singleInboxData?.showInboxNameInEmailRecipients ||
          false,
        isOutlookElementVisible: isOutlookInbox,
        selectedUsersData: formattedInboxMembers,
        selectedUsersUuids: getSelectedUsersUUIDs(formattedInboxMembers),
      };
    });
  }, []);

  useEffect(() => {
    EMAIL_REF_KEYS.forEach((singleKey: IEmailInboxFormKeys) => {
      const tempSingleInboxData: any = selectedInboxData?.singleInboxData;
      if (
        emailFormRef.current[singleKey] &&
        tempSingleInboxData?.channelEmail
      ) {
        emailFormRef.current[singleKey].value =
          tempSingleInboxData?.channelEmail[singleKey] || '';
      }
    });
  }, [emailInboxState.selectedPlatform]);

  useEffect(() => {
    window.addEventListener('message', onEventReceived);
    return () => {
      window.removeEventListener('message', onEventReceived);
    };
  }, []);

  useEffect(() => {
    if (props?.selectedPlatform?.length) {
      handlePlatformSelect(props.selectedPlatform as IEmailPlatform);
    }
  }, [props?.selectedPlatform]);

  const onEventReceived = useCallback(async (event: MessageEvent) => {
    try {
      if (!(event.data.sender === 'FOLD' && event.data.message)) {
        return;
      }
      const code = event.data.message;
      if (code === 'consent_required') {
        handleError(intl.formatMessage({id: 'outlookPermissionError'}));
        return;
      }
      const response = await getInboxAuthTokenIdAndEmail(code);
      const inboxAuthTokenId = response.inboxAuthTokenId;
      const email = response.email;
      setEmailInboxState((prev) => {
        return {
          ...prev,
          outlookAuthCode: code,
          selectedPlatform: EMAIL_PLATFORMS.OUTLOOK,
          inboxAuthTokenId,
          email,
          isOutlookElementVisible: true,
        };
      });
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        'Something went wrong while receiving the auth code. Please try again later';
      handleError(errorMessage);
    }
  }, []);

  const getDefaultSelectedUsers = (): number[] => {
    let userIds: number[] = [];
    if (selectedInboxData?.singleInboxData?.inboxMembers?.length) {
      userIds = selectedInboxData?.singleInboxData?.inboxMembers.map(
        (singleInboxMember: IInboxMember) => {
          return singleInboxMember.userId;
        }
      );
    }
    return userIds;
  };

  const setErrorOnChange = (formField: IEmailInboxFormError) => {
    const keys = Object.keys(formField);
    const singleKey: IEmailInboxFormKeys = keys[0] as any;
    if (formField[singleKey]) {
      setEmailInboxState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            [singleKey]: EMAIL_INBOX_ERROR[singleKey],
          },
        };
      });
    } else {
      setEmailInboxState((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            [singleKey]: '',
          },
        };
      });
    }
  };


  const handlePlatformSelect = (value: IEmailPlatform) => {
    if (value === 'GMAIL') {
      setEmailInboxState((prev) => {
        return {
          ...prev,
          selectedPlatform: value,
          isOutlookElementVisible: false,
        };
      });
      return;
    }
    setEmailInboxState((prev) => {
      return {
        ...prev,
        selectedPlatform: value,
      };
    });
    openOutlookLoginWindow();
  };

  const getCustomUserSearchElem = () => {
    return (
      <CustomUserSearch
        label={intl.formatMessage({id: 'addOrRemoveAgents'})}
        userSelectRef={undefined}
        isRequired={true}
        disabledUsers={[]}
        selectedUsersData={emailInboxState.selectedUsersData}
        userSearchType={
          isCommunicationLocationHandlingEnabled
            ? USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER
            : USER_SEARCH_TYPE.DEFAULT
        }
        onChange={(
          selectedUUIDS: string[],
          selectedUsersData?: IInternalUsers[]
        ) => {
          onUserSelect(selectedUUIDS, selectedUsersData || []);
          setEmailInboxState((prev) => {
            return {
              ...prev,
              selectedUsersUuids: selectedUUIDS,
            };
          });
        }}
      />
    );
  };

  const getEmailInboxBodyElem = () => {
    return (
      <>
        {(emailInboxState.isLoading || subdomainLoading) && (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        )}

        <VStack height={'90%'} overflow={'auto'}>
          <ModalActionSubTitle subTitle={'Inbox Details'} />
          <HStack>
            <FormControl
              style={styles.formElement}
              isInvalid={emailInboxState?.errors?.inboxName}
            >
              <FormControl.Label isRequired>
                <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'inboxName'} />
              </FormControl.Label>
              <Input
                _focus={{borderColor: Colors.Custom.Gray300}}
                ref={(ref) =>
                  (emailFormRef.current['inboxName'] = ref as any as any)
                }
                value={emailInboxState.inboxName}
                onChangeText={(inboxName: string) => {
                  setEmailInboxState((prev) => {
                    return {
                      ...prev,
                      inboxName: inboxName,
                    };
                  });
                }}
              />
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {emailInboxState.errors.inboxName}
              </FormControl.ErrorMessage>
            </FormControl>
          </HStack>
          <HStack>
            <Checkbox
              checked={emailInboxState.showInboxNameInEmailRecipients}
              value={emailInboxState.showInboxNameInEmailRecipients}
              onChange={(item) => {
                setEmailInboxState((prev) => {
                  return {
                    ...prev,
                    showInboxNameInEmailRecipients: item?.target?.checked,
                  };
                });
              }}
            >
              {intl.formatMessage({id: 'useThisForANameForEmailRecipients'})}
            </Checkbox>
          </HStack>
          <HStack>
            <FormControl
              style={styles.formElement}
              isInvalid={emailInboxState?.errors?.users}
            >
              {getCustomUserSearchElem()}
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {emailInboxState.errors.users}
              </FormControl.ErrorMessage>
            </FormControl>
          </HStack>
          <HStack>
            <VStack>
              {emailInboxState.emailSetting === EMAIL_SETTING.FOLD_POWERED && (
                <FormControl
                  style={styles.formElement}
                  isInvalid={emailInboxState?.errors?.email}
                >
                  <FormControl.Label isRequired>
                    <DisplayText
                      size={'mdNormal'}
                      textLocalId={'customersFacingEmail'}
                    />
                  </FormControl.Label>
                  <InputGroup>
                    <Input
                      _focus={{borderColor: Colors.Custom.Gray300}}
                      w={{
                        base: '70%',
                        md: '100%',
                      }}
                      autoComplete="off"
                      ref={(ref) =>
                        (emailFormRef.current['email'] = ref as any)
                      }
                      onChangeText={(email: string) => {
                        setErrorOnChange({
                          email: email ? false : true,
                        } as any);
                      }}
                      isDisabled={isInboxPresent(selectedInboxData)}
                    />
                  </InputGroup>

                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {emailInboxState.errors.email}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            </VStack>
          </HStack>

          {emailInboxState.emailSetting === EMAIL_SETTING.CUSTOM && (
            <View>
              <ModalActionSubTitle
                marginTop={5}
                subTitle={'SMTP Settings'}
                rightView={() => {
                  return (
                    <Button
                      variant={'transparent'}
                      ml={2}
                      leftIcon={
                        <AntIcon
                          size={15}
                          color={Colors.Custom.Gray400}
                          name={'infocirlceo'}
                        />
                      }
                      onPress={() => {
                        window.open(GOOGLE_SMTP_INFO_LINK, '_blank', 'noopener,noreferrer');
                      }}
                    />
                  );
                }}
              />
              <HStack justifyContent={'space-between'}>
                <VStack flex={0.78}>
                  <FormControl
                    style={styles.formElement}
                    isInvalid={emailInboxState?.errors?.smtpAddress}
                  >
                    <FormControl.Label isRequired>
                      <DisplayText
                        size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}}
                        textLocalId={'smtpAddress'}
                      />
                    </FormControl.Label>
                    <Input
                      _focus={{borderColor: Colors.Custom.Gray300}}
                      ref={(ref) =>
                        (emailFormRef.current['smtpAddress'] = ref as any)
                      }
                      onChangeText={(smtpAddress: string) => {
                        setErrorOnChange({
                          smtpAddress: smtpAddress ? false : true,
                        } as any);
                      }}
                      isDisabled={
                        props.parentCode === PARENT_CODE.INTEGRATION_LANDING
                          ? false
                          : false
                      }
                    />
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {emailInboxState.errors.smtpAddress}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </VStack>
                <VStack flex={0.2}>
                  <FormControl
                    style={styles.formElement}
                    isInvalid={emailInboxState?.errors?.smtpPort}
                  >
                    <FormControl.Label isRequired>
                      <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'smtpPort'} />
                    </FormControl.Label>
                    <Input
                      _focus={{borderColor: Colors.Custom.Gray300}}
                      ref={(ref) =>
                        (emailFormRef.current['smtpPort'] = ref as any)
                      }
                      onChangeText={(smtpPort: string) => {
                        setErrorOnChange({
                          smtpPort: smtpPort ? false : true,
                        } as any);
                      }}
                      isDisabled={
                        props.parentCode === PARENT_CODE.INTEGRATION_LANDING
                          ? false
                          : false
                      }
                    />
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {emailInboxState.errors.smtpPort}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </VStack>
              </HStack>

              <HStack>
                <FormControl
                  style={styles.formElement}
                  isInvalid={emailInboxState?.errors?.smtpEmail}
                >
                  <FormControl.Label isRequired>
                    <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'smtpEmail'} />
                  </FormControl.Label>
                  <Input
                    _focus={{borderColor: Colors.Custom.Gray300}}
                    ref={(ref) =>
                      (emailFormRef.current['smtpEmail'] = ref as any)
                    }
                    onChangeText={(smtpEmail: string) => {
                      setErrorOnChange({
                        smtpEmail: smtpEmail ? false : true,
                      } as any);
                    }}
                    isDisabled={
                      props.parentCode === PARENT_CODE.INTEGRATION_LANDING
                        ? false
                        : false
                    }
                  />
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {emailInboxState.errors.smtpEmail}
                  </FormControl.ErrorMessage>
                </FormControl>
              </HStack>

              <HStack>
                <FormControl
                  style={styles.formElement}
                  isInvalid={emailInboxState?.errors?.smtpPassword}
                >
                  <FormControl.Label isRequired>
                    <DisplayText
                      size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}}
                      textLocalId={'smtpPassword'}
                    />
                  </FormControl.Label>
                  <Input
                    _focus={{borderColor: Colors.Custom.Gray300}}
                    autoComplete="off"
                    type={
                      emailInboxState.showSmtpPassword ? 'text' : 'password'
                    }
                    ref={(ref) =>
                      (emailFormRef.current['smtpPassword'] = ref as any)
                    }
                    onChangeText={(smtpPassword: string) => {
                      setErrorOnChange({
                        smtpPassword: smtpPassword ? false : true,
                      } as any);
                    }}
                    isDisabled={
                      props.parentCode === PARENT_CODE.INTEGRATION_LANDING
                        ? false
                        : false
                    }
                    InputRightElement={
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              emailInboxState.showSmtpPassword
                                ? 'visibility'
                                : 'visibility-off'
                            }
                          />
                        }
                        size={5}
                        mr="2"
                        color="muted.400"
                        onPress={() => {
                          setEmailInboxState((prev) => {
                            return {
                              ...prev,
                              showSmtpPassword: !prev.showSmtpPassword,
                            };
                          });
                        }}
                      />
                    }
                  />
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {emailInboxState.errors.smtpPassword}
                  </FormControl.ErrorMessage>
                </FormControl>
              </HStack>
              <View style={styles.marginVertical8}>
                {/* <EmailAutoResponseView inboxId={selectedInboxData?.singleInboxData?.id} /> */}
              </View>
            </View>
          )}
        </VStack>
      </>
    );
  };

  const getOutlookInboxElement = () => {
    return (
      <View>
        {(emailInboxState.isLoading || subdomainLoading) && (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        )}
        <FormControl style={styles.formElement} isInvalid={!!errors.inboxName}>
          <FormControl.Label isRequired>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} marginBottom={1} marginLeft={1}>
              {intl.formatMessage({id: 'inboxName'})}
            </Text>
          </FormControl.Label>
          <Input
            value={emailInboxState.inboxName || ''}
            onChangeText={(text) => {
              setEmailInboxState((prev) => {
                return {
                  ...prev,
                  inboxName: text,
                };
              });
            }}
          />
          {errors.inboxName ? (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {intl.formatMessage({id: errors.inboxName})}
            </FormControl.ErrorMessage>
          ) : (
            <></>
          )}
        </FormControl>

        <FormControl style={styles.formElement} isInvalid={!!errors.email}>
          <FormControl.Label isRequired>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} marginBottom={1} marginLeft={1}>
              {intl.formatMessage({id: 'inboxEmail'})}
            </Text>
          </FormControl.Label>
          <Input
            isDisabled
            value={emailInboxState.email || ''}
            onChangeText={(text) => {
              setEmailInboxState((prev) => {
                return {
                  ...prev,
                  email: text,
                };
              });
            }}
          />
          {errors.email ? (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {intl.formatMessage({id: errors.email})}
            </FormControl.ErrorMessage>
          ) : (
            <></>
          )}
        </FormControl>

        <FormControl
          style={styles.formElement}
          isInvalid={!!errors.selectedAgents}
        >
          {getCustomUserSearchElem()}
          {errors.selectedAgents ? (
            <FormControl.ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {intl.formatMessage({id: errors.selectedAgents})}
            </FormControl.ErrorMessage>
          ) : (
            <></>
          )}
        </FormControl>
      </View>
    );
  };

  const getMailgunInboxBodyElem = () => {
    return (
      <>
        {emailInboxState.isLoading || subdomainLoading ? (
          <View
            height={'full'}
            width={'full'}
            position="absolute"
            zIndex={10}
            bg={'coolGray.50:alpha.70'}
          >
            <Center
              top={'30%'}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Spinner size={'lg'} />
            </Center>
          </View>
        ) : (
          <></>
        )}

        <VStack height={'90%'} overflow={'auto'}>
          <ModalActionSubTitle subTitle={'Inbox Details'} />
          <HStack>
            <FormControl
              style={styles.formElement}
              isInvalid={emailInboxState?.errors?.inboxName}
            >
              <FormControl.Label isRequired>
                <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId={'inboxName'} />
              </FormControl.Label>
              <Input
                _focus={{borderColor: Colors.Custom.Gray300}}
                ref={(ref) =>
                  (emailFormRef.current['inboxName'] = ref as any as any)
                }
                value={emailInboxState.inboxName}
                onChangeText={(inboxName: string) => {
                  setEmailInboxState((prev) => {
                    return {
                      ...prev,
                      inboxName: inboxName,
                    };
                  });
                }}
              />
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {emailInboxState.errors.inboxName}
              </FormControl.ErrorMessage>
            </FormControl>
          </HStack>
          <HStack>
            <Checkbox
              checked={emailInboxState.showInboxNameInEmailRecipients}
              value={emailInboxState.showInboxNameInEmailRecipients}
              onChange={(item) => {
                setEmailInboxState((prev) => {
                  return {
                    ...prev,
                    showInboxNameInEmailRecipients: item?.target?.checked,
                  };
                });
              }}
            >
              {intl.formatMessage({id: 'useThisForANameForEmailRecipients'})}
            </Checkbox>
          </HStack>
          <HStack>
            <FormControl
              style={styles.formElement}
              isInvalid={emailInboxState?.errors?.users}
            >
              <CustomUserSearch
                label={intl.formatMessage({id: 'addOrRemoveAgents'})}
                userSelectRef={undefined}
                isRequired={true}
                disabledUsers={[]}
                selectedUsersData={emailInboxState.selectedUsersData}
                userSearchType={USER_SEARCH_TYPE.DEFAULT}
                onChange={(
                  selectedUUIDS: string[],
                  selectedUsersData?: IInternalUsers[]
                ) => {
                  onUserSelect(selectedUUIDS, selectedUsersData || []);
                  setEmailInboxState((prev) => {
                    return {
                      ...prev,
                      selectedUsersUuids: selectedUUIDS,
                    };
                  });
                }}
              />
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {emailInboxState.errors.users}
              </FormControl.ErrorMessage>
            </FormControl>
          </HStack>
          <HStack>
            <VStack>
              {emailInboxState.emailSetting === EMAIL_SETTING.FOLD_POWERED && (
                <FormControl
                  style={styles.formElement}
                  isInvalid={emailInboxState?.errors?.email}
                >
                  <FormControl.Label isRequired>
                    <DisplayText
                      size={'mdNormal'}
                      textLocalId={'customersFacingEmail'}
                    />
                  </FormControl.Label>
                  <InputGroup>
                    <Input
                      _focus={{borderColor: Colors.Custom.Gray300}}
                      w={{
                        base: '70%',
                        md: '100%',
                      }}
                      autoComplete="off"
                      ref={(ref) =>
                        (emailFormRef.current['email'] = ref as any)
                      }
                      onChangeText={(email: string) => {
                        setErrorOnChange({
                          email: email ? false : true,
                        } as any);
                      }}
                      isDisabled={isInboxPresent(selectedInboxData)}
                    />
                  </InputGroup>

                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {emailInboxState.errors.email}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            </VStack>
          </HStack>
        </VStack>
      </>
    );
  };

  const getPlatformOptions = () => {
    if (isOutlookEnabledForAccount) {
      return EMAIL_PLATFORM_OPTIONS;
    }
    return EMAIL_PLATFORM_OPTIONS.filter(
      (item) => item.code !== EMAIL_PLATFORMS.OUTLOOK
    );
  };

  const getPlatformSelectOptions = () => {
    if (emailInboxState.isLoading || subdomainLoading) {
      return <></>;
    }
    return (
      <View style={styles.marginVertical10}>
        <FormControl>
          <FormControl.Label isRequired>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} marginBottom={1} marginLeft={1}>
              Platform
            </Text>
          </FormControl.Label>

          <Select
            onSelect={(_, option) => {
              handlePlatformSelect(option.value as IEmailPlatform);
            }}
            defaultValue={emailInboxState.selectedPlatform || ''}
            disabled={!!props.selectedInboxData.channel_id}
          >
            {getPlatformOptions().map((item) => {
              const Icon =
                item.code === 'OUTLOOK' ? <OutlookSvg /> : <GmailSvg />;
              const label = item.value;
              return (
                <Select.Option key={item.code} value={item.code}>
                  <View style={styles.selectOption}>
                    <View
                      style={styles.view}
                    >
                      {Icon}
                    </View>
                    <View
                      style={styles.textView}
                    >
                      <Text>{label}</Text>
                    </View>
                  </View>
                </Select.Option>
              );
            })}
          </Select>
        </FormControl>
      </View>
    );
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;
  const finalWidth = isSmallScreen ? '60%' : '33%';

  return (
    <View>
      <Drawer
        visible={isDrawerVisible}
        width={finalWidth}
        onClose={() => closeModal()}
        title={
          <ModalActionTitle
            title={'emailInbox'}
            titleFontSize={24}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'save',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  handleInboxCreateOrUpdate();
                },
              },
            ]}
          />
        }
        headerStyle={{borderWidth: 0}}
      >
        {emailInboxState.selectedPlatform === EMAIL_PLATFORMS.OUTLOOK ? (
          <View>
            {showPhiWarningToast(
              'Please note that sync for previous emails is not supported. Any changes made to emails before enabling sync will not be reflected.',
              '',
              ''
            )}
          </View>
        ) : (
          <></>
        )}
        {emailInboxState.selectedPlatform !== EMAIL_PLATFORMS.MAILGUN ? (
          getPlatformSelectOptions()
        ) : (
          <></>
        )}

        {emailInboxState.selectedPlatform === EMAIL_PLATFORMS.GMAIL ? (
          getEmailInboxBodyElem()
        ) : (
          <></>
        )}

        {emailInboxState.selectedPlatform === EMAIL_PLATFORMS.OUTLOOK &&
        emailInboxState.isOutlookElementVisible ? (
          getOutlookInboxElement()
        ) : (
          <></>
        )}

        {emailInboxState.selectedPlatform === EMAIL_PLATFORMS.MAILGUN ? (
          getMailgunInboxBodyElem()
        ) : (
          <></>
        )}
      </Drawer>
    </View>
  );
};

export default EmailInboxDrawer;
