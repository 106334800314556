import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ReactNode } from 'react';
import { EHRWiseLocations } from '../../../../Interfaces/CommonInterfaces';
import { IAutomation } from '../../Forms/FHFormio/CustomComponents/ContactRules/interfaces';
import { ICustomFieldResponse } from '../../Contacts/CustomField/interface';

export interface ICampaignData {
  id?: string;
  segmentId?: string;
  name?: string;
  description?: string;
  status?: ICampaignStatus;
  isImmediate: boolean;
  startDate: string;
  patientFilter?: any;
  numberOfRetry?: number;
  emailTemplateId?: string | null;
  smsText?: string;
  metadata?: any;
  channelType?: any;
  campaignLocations?: any;
  updatedOn?: string;
}

export interface ICampaignExpandedTextState {
  [key: string]: boolean;
}

export interface ICampaignTableColumnsArgs {
  actionFn: (
    actionCode: string,
    record: ICampaignData,
    status?: boolean
  ) => void;
  descriptionData ?: ICampaignExpandedTextState;
  setDescriptionData: (expandedTextState?: ICampaignExpandedTextState) => void;
  userPracticeLocations: any;
  isMsoEnabled?: boolean;
}

export interface ICampaignTableActionsArgs {
  record: ICampaignData;
  onActionPerformed: (
    actionCode: string,
    recordData: ICampaignData,
    status?: boolean
  ) => void;
  userPracticeLocations: any;
  isMsoEnabled?: boolean;
}

export interface ICampaignErrors {
  campaignName?: string | null;
  campaignType?: string | null;
  reminderError?: string | null;
  campaignTime?: string | null;
  emailTemplateError?: string | null;
  patientAgeError?: string | null;
  segmentError?: string | null;
  audienceName?: string | null;
  locationError?: string | null;
}

export interface ICampaignDetails {
  name: string;
  description: string;
  isImmediate?: boolean;
  startDate?: string | null;
  type: any[];
  enableReminder: boolean;
  numberOfRetry: string;
  retryDelayInDays: string;
  patientFilter: any;
  areSegmentEnabled?: boolean;
  gender?: string[];
  fromYear?: string;
  toYear?: string;
  patientAge?: string;
  zipCodes?: string[];
  tagList?: number[];
  operator?: string;
  smsText?: string;
  segmentType?: string;
  segmentTypeValue?: string;
  audienceName?: string;
  channelType?: any;
  employerIds?: any;
  metadata?: any;
  locations?: string[]
}

export interface ICampaignDetailsFormState {
  type: CheckboxValueType[];
  campaignOptions?: CheckboxOptionType[];
  name: string;
  enableReminder: boolean;
  numberOfRetry: string;
  interval: string;
  campaignTime: {
    type: string | null;
    value?: Date;
  };
  description: string;
}

export interface ICampaignSegmentFormState {
  areSegmentEnabled?: boolean;
  patientList?: any[];
  notAccessPatientList?: any[];
  membersList?: any[];
  audienceName?: string;
  errors?: any;
  description?: string;
  executionFrequency?: string;
  jsonTree?: any;
  id?: string;
  memberStatus?: {code: string, value: string};
  ishintMembershipStatus?: boolean;
  memberStatusCodeFromResponseWhenEditingGroup?: string;
  populationGroupId?: string;
  ruleLocations?: { locationId: string, isDeleted?: boolean }[];
  selectedAccountLocations?: EHRWiseLocations[];
  automationToUpdate?: IAutomation[];
}

export interface IPopulationGroupItem {
  id: string;
  name: string;
}
export interface IListValue {
  title: string;
  value: string;
}

export interface ISourceState {
  loading: boolean;
  options: IListValue[];
}

export interface ICustomFieldState {
  loading: boolean,
  customAttributeData: ICustomFieldResponse[],
}

export interface IPractitionerState {
  loading: boolean,
  options: IListValue[],
}

export interface CampaignAnalyticsProps {
  isOpen: boolean;
  onClose: () => void;
  data: ICampaignData;
  userPracticeLocations?: string[];
  foldLocations?: ILocation[];
}

export interface AnalyticsGraphData {
  data: EmailStatusData[];
  style: Style;
  label: Label;
  xField: string;
  yField: string;
  seriesField: string;
}

export interface EmailStatusData {
  status: string;
  date: string;
  count: number;
}

export interface Style {
  height: number;
}

export interface Label {
  type: string;
  content: string;
}

export interface ICampaignSegmentTableProps {
  context: CampaignSegmentTableContext
  selectedActionCode: string;
  selectedRawData: any;
  onActionPerformed: (tabCode: any, rawData?: any) => void;
  updatePopulationGroupCount?: (popGroupCount: number) => void;
}

export enum CampaignSegmentTableContext {
  POPULATION_GROUP = 'population',
  SCHEDULE_SUGGESTION_RULES = 'schedule_suggestion'
}

export enum CampaignSegmentCategories {
  Appointment = 'Appointment'
}

export interface IPopulationGroupFilter {
  type?: string
}
export interface ILocation {
  uuid: string;
  name: string;
}

export interface IPopoverActionRowArgs {
  code: string;
  icon: ReactNode;
  text: string;
  textColor?: string;
  hoverBgColor?: string;
  onPress?: (action: string) => void;
}

export type ICampaignStatus = 'ACTIVE' | 'ENDED' | 'DRAFT' | 'ABORTED' | 'PAUSED' | 'RESUMED';

export type ICampaignActions = 'ABORT' | 'RESUME' | 'PAUSE' | 'START'

