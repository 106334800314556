import React, { useState } from 'react';
import {INoteRiskScore} from '../../interfaces';
import {HStack, useDisclose, Text, View, Pressable, FlatList, VStack, Divider, Spinner} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../styles';
import {useIntl} from 'react-intl';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { GET_RISK_SCORE_BY_FORM_LOG_ID } from '../../../../../services/Forms/FormsQueries';
import { useLazyQuery } from '@apollo/client';

interface INoteRiskScoreDetailsProps {
  formLogId?: string;
}

const NoteRiskScoreDetails = (props: INoteRiskScoreDetailsProps) => {
  const {formLogId} = props;
  const [riskScoreListState, setRiskScoreListState] = useState<{
    riskScoreList: INoteRiskScore[], 
    isLoading: boolean
  }>({
    riskScoreList: [],
    isLoading: false,
  });
  const {isOpen: expanded, onToggle: toggleExpand} = useDisclose();
  const intl = useIntl();

  const [getRiskScoreByFormLogId] = useLazyQuery(GET_RISK_SCORE_BY_FORM_LOG_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const onNoteScoreDropdownClick = async () => {
    if (expanded) {
      toggleExpand();
      return;
    }
    setRiskScoreListState(prev => ({
      ...prev,
      isLoading: true,
    }));
    try{
      const response = await getRiskScoreByFormLogId({variables: {formId: formLogId}});
      const riskScoreListResponse = response?.data?.formLogs?.[0]?.formResponse?.metadataScore || [];
      const mappedRiskScoreList = riskScoreListResponse.map((item: any) => ({
        name: item.metadata.name,
        state: item.metadata.state,
        score: item.metadata.score,
        operation: item.metadata.operation,
        groupId: item.metadata.groupId,
      }));
      setRiskScoreListState({
        riskScoreList: mappedRiskScoreList,
        isLoading: false,
      });
      toggleExpand();
    }catch (error) {
      setRiskScoreListState(prev => ({
        ...prev,
        isLoading: false,
      }));
    }
  }

  const renderSingleColumnField = (title: string, value: string,flex:number) => {
    return (
      <VStack flex={flex} paddingX={2}>
        <Text color={Colors.Custom.Gray400}>{title}</Text>
        <Text>{value}</Text>
      </VStack>
    );
  };
  const renderItem = ({item, index}: {item: INoteRiskScore; index: number}) => {
    return (
      <>
        <HStack>
          {renderSingleColumnField(
            '',
            item.name,
            4
          )}
          {renderSingleColumnField(
            '',
            item.state,
            4
          )}
          {renderSingleColumnField(
            '',
            item.score,
            2
          )}
        </HStack>
      </>
    );
  };

  return (
    <View w={'100%'}>
      <Pressable flexDir={'row'} alignItems={'center'} onPress={onNoteScoreDropdownClick}>
        <Text
          size={'smRegular'}
          selectable={false}
          color={Colors.FoldPixel.PRIMARY300}
        >Linked Score Groups</Text>
        {riskScoreListState.isLoading ? (
          <Spinner size="sm" />
        ):(
          <Feather
            color={Colors.Custom.mainPrimaryPurple}
            size={20}
            name={!expanded ? 'chevron-down' : 'chevron-up'}
          />
        )}
      </Pressable>
      {expanded && (
      <>
        {riskScoreListState.riskScoreList.length === 0 ? (
          <Text color={Colors.Custom.Gray400}> No linked Score Groups</Text>
        ) : (
        <>
          <HStack>
            {renderSingleColumnField(
              intl.formatMessage({id: 'scoreGroupLabel'}),
              '',
              4
            )}
            {renderSingleColumnField(
              intl.formatMessage({id: 'scoreInterpretationLabel'}),
              '',
              4
            )}
            {renderSingleColumnField(
              intl.formatMessage({id: 'scoreLabel'}),
              '',
              2
            )}
          </HStack>
          <Divider my={0.5} />
          <FlatList
            renderItem={renderItem}
            data={riskScoreListState.riskScoreList}
            ItemSeparatorComponent={() => <Divider my={0.5} />}
            keyExtractor={(item, index) =>
              `${item.name}${item.operation}${index}`
            }
          />
          </>
        )}
      </>
      )}
    </View>
  );
};

export default NoteRiskScoreDetails;
