import moment from 'moment';
import {IconButton, Tooltip, View, useToast, Text, Pressable} from 'native-base';
import {ReactNode, useState} from 'react';
import {useIntl} from 'react-intl';
import Icon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {DeleteCampaignModal} from '../DeleteCampaignModal';
import {ICampaignActions, ICampaignData, ICampaignStatus, ICampaignTableActionsArgs, IPopoverActionRowArgs} from '../interfaces';
import {
  getContactsWithinGroup,
  insertUserAction,
  insertUserActionLog,
  updateCampaignStatus,
} from '../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {ToastType, showToast} from '../../../../../utils/commonViewUtils';
import {getAccountUUID, getUserUUID, isLoginUserBusinessOwner} from '../../../../../utils/commonUtils';
import {Switch, Popover, Button} from 'antd';
import {BUTTON_TYPE, CAMPAIGN_ACTIONS, CAMPAIGN_STATUS, STATE_WISE_CAMPAIGN_ACTIONS} from '../../../../../constants';
import { FoldButton } from '../../../../CommonComponents/FoldButton/FoldButton';
import { FHAlertDialog } from '../../../../common/FHAlertDialog';
import { isSubsetArray } from '../../../Workflow/workflowUtils';
import AutomationAnalyticsV2Svg from '../../../../common/Svg/AutomationActionIcons/AutomationAnalyticsV2Svg';
import DuplicateSvgV2 from '../../../../common/Svg/AutomationActionIcons/DuplicateSvgV2';
import PlayButtonSvg from '../../../../common/Svg/AutomationActionIcons/PlayButtonSvg';
import PauseButtonSvg from '../../../../common/Svg/AutomationActionIcons/PauseButtonSvg';
import HorizontalEllipsisSvg from '../../../../common/Svg/AutomationActionIcons/HorizontalEllipsisSvg';
import EditSvgV2 from '../../../../common/Svg/AutomationActionIcons/EditSvgV2';
import ClockHistorySvg from '../../../../common/Svg/ClockHistorySvg';
import AbortSvg from '../../../../common/Svg/AutomationActionIcons/AbortSvg';
import TrashSvg from '../../../../common/Svg/TrashSvg';
import Stack from '../../../../common/LayoutComponents/Stack';
import { ModalActionDialog } from '../../../../common/ModalActionDialog/ModalActionDialog';
import WarningSvgV2 from '../../../../common/Svg/WarningSvgV2';
import { status } from '../../../TeamInbox/Conversations/ConversationContainer/ConversationConst';
import { IUserActionLog } from '../../../Workflow/Workflow/AddOrUpdateWorkflow/IWorkflow';
import { result } from 'lodash';
import CampaignHistory from '../CampaignHistory/CampaignHistory';
// import { Pressable } from 'react-native';

const CAMPAIGN_ACTION_MAP: { 
  [key: string] : {
    key: string;
    icon: ReactNode;
    text: string;
    textColor?: string;
    hoverBgColor?: string;
  }
} = {
  EDIT: {
    key: 'EDIT',
    icon: <EditSvgV2/>,
    text: 'Edit'
  },
  HISTORY: {
    key: 'HISTORY',
    icon: <ClockHistorySvg/>,
    text: 'History'
  },
  DUPLICATE: {
    key: 'DUPLICATE',
    icon: <DuplicateSvgV2/>,
    text: 'Duplicate'
  },
  ABORT: {
    key: 'ABORT',
    icon: <AbortSvg/>,
    text: 'Abort',
    textColor: Colors.FoldPixel.STATUS_ERROR,
  },
  DELETE: {
    key: 'DELETE',
    icon: <TrashSvg width={16} height={16}/>,
    textColor: Colors.FoldPixel.STATUS_ERROR,
    text: 'Delete'
  }
}


const PopoverActionRow = (props: IPopoverActionRowArgs) => {
  const hoverBgColor = props?.hoverBgColor || Colors.FoldPixel.GRAY100;
  return (
    <Pressable
      _hover={{
        backgroundColor: hoverBgColor
      }}
      onPress={() => {props?.onPress?.(props.code);}}
    >
      <Stack
        direction='row'
        style={{
          alignItems: 'center',
          padding: '8px'
        }}
        space={8}
      >
        {props.icon}
        <Text size={'smRegular'} color={props?.textColor || Colors.FoldPixel.GRAY400} lineHeight={16.8}>
          {props.text}  
        </Text>
      </Stack>
    </Pressable>
  );
}

const CampaignTableActions = (props: ICampaignTableActionsArgs) => {
  const {onActionPerformed, record, userPracticeLocations, isMsoEnabled} = props;
  const CAMPAIGN = 'CAMPAIGN';
  const isBusinessOwner = isLoginUserBusinessOwner()
  const intl = useIntl();
  const isEditable = (isMsoEnabled && !isBusinessOwner) ? isSubsetArray(record?.campaignLocations, userPracticeLocations) : true ;
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const userUuid = getUserUUID();
  const accountUuid = getAccountUUID();
  const [action, setAction] = useState<ICampaignActions>('RESUME');  //passing a temp action
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCampaignStateModal, setOpenCampaignStateModal] = useState(false)
  const [openHistoryPage, setOpenHistoryPage] = useState<boolean>(false);

  const ACTION_TO_TOAST_MESSAGE_MAP = {
    [CAMPAIGN_ACTIONS.ABORT]: 'Campaign Aborted Successfully',
    [CAMPAIGN_ACTIONS.RESUME]: 'Campaign Resumed Successfully',
    [CAMPAIGN_ACTIONS.PAUSE]: 'Campaign Paused Successfully',
    [CAMPAIGN_ACTIONS.START]: 'Campaign Started Successfully'
  }
  const insertCampaignActionLog = async (recordId: string, text: string, action: ICampaignActions) => {
    // const resourceType = 'CAMPAIGN';
    const userActionLogObject: IUserActionLog = {
      resourceType: CAMPAIGN,
      resourceId: recordId,
      updatedBy: userUuid,
      action: action,
      comment: text
    }
    await insertUserActionLog(userActionLogObject).then(
      (result: any) => {
        showToast(toast,
          ACTION_TO_TOAST_MESSAGE_MAP[action],
          ToastType.success,
        );
      }
    ).catch(
      (err: any) => {
        showToast(toast,
          'Failed to perform the action',
          ToastType.error,
        );
      }
    );

  }
  const CAMPAIGN_ACTION_MODAL_MAP = {
    ABORT: {
      title: intl.formatMessage({id: 'abortCampaignTitle'}),
      subtitle: intl.formatMessage({id: 'abortCampaignSubtitle'}),
      leftButtonText: intl.formatMessage({id: 'abortCampaignLeftButtonText'}),
      rightButtonText: intl.formatMessage({id: 'abortCampaignRightButtonText'}),
      icon: <WarningSvgV2 strokeColor={Colors.FoldPixel.STATUS_ERROR} />,
      onRightButtonClick: (text: string) => {
        onToggle(CAMPAIGN_STATUS.ABORTED);
        insertCampaignActionLog(record.id as string,text, CAMPAIGN_ACTIONS.ABORT as ICampaignActions);
      },
      onLeftButtonClick: (text: string) => {
        setOpenCampaignStateModal(false);
      },
      isAlert: true
    },
    RESUME: {
      title: intl.formatMessage({id: 'resumeCampaignTitle'}),
      subtitle: intl.formatMessage({id: 'resumeCampaignSubtitle'}),
      leftButtonText: intl.formatMessage({id: 'resumeCampaignLeftButtonText'}),
      rightButtonText: intl.formatMessage({id: 'resumeCampaignRightButtonText'}),
      icon: <WarningSvgV2/>,
      onLeftButtonClick: (text: string) => {
        onToggle(CAMPAIGN_STATUS.RESUMED);
        insertCampaignActionLog(record.id as string, text, CAMPAIGN_ACTIONS.RESUME as ICampaignActions);
      },
      onRightButtonClick: (text: string) => {
        setOpenCampaignStateModal(false);
      },
      isAlert: false
    },
    PAUSE: {
      title: intl.formatMessage({id: 'pauseCampaignTitle'}),
      subtitle: intl.formatMessage({id: 'pauseCampaignSubtitle'}),
      leftButtonText: intl.formatMessage({id: 'pauseCampaignLeftButtonText'}),
      rightButtonText: intl.formatMessage({id: 'pauseCampaignRightButtonText'}),
      icon: <WarningSvgV2/>,
      onLeftButtonClick: (text: string) => {
        onToggle(CAMPAIGN_STATUS.PAUSED);
        insertCampaignActionLog(record.id as string, text, CAMPAIGN_ACTIONS.PAUSE as ICampaignActions);
      },
      onRightButtonClick: (text: string) => {
        setOpenCampaignStateModal(false);
      },
      isAlert: false
    },
    START: {
      title: intl.formatMessage({id: 'startCampaignTitle'}),
      subtitle: intl.formatMessage({id: 'startCampaignSubtitle'}),
      leftButtonText: intl.formatMessage({id: 'startCampaignLeftButtonText'}),
      rightButtonText: intl.formatMessage({id: 'startCampaignRightButtonText'}),
      icon: <WarningSvgV2/>,
      onRightButtonClick: (text: string) => {
        onToggle(CAMPAIGN_STATUS.ACTIVE);
        insertCampaignActionLog(record.id as string, text, CAMPAIGN_ACTIONS.START as ICampaignActions);
      },
      onLeftButtonClick: (text: string) => {
        setOpenCampaignStateModal(false);
      },
      isAlert: false
    }
  };

  const handleDeleteCampaign = () => {
    onActionPerformed(COMMON_ACTION_CODES.DELETE, record);
  };

  const handleGraphViewClick = () => {
    onActionPerformed(COMMON_ACTION_CODES.SHOW_GRAPH, record);
  };

  const getPopoverActions = (record: ICampaignData) => {
    const state = record.status as ICampaignStatus
    let actionList = STATE_WISE_CAMPAIGN_ACTIONS[state];

    if ((state===CAMPAIGN_STATUS.ACTIVE && moment().isBefore(record.startDate)) || (state===CAMPAIGN_STATUS.DRAFT) || (state===CAMPAIGN_STATUS.RESUMED && moment().isBefore(record.startDate))) {
      actionList = [CAMPAIGN_ACTIONS.EDIT, ...actionList];
    }

    const onActionButtonPressed = (key: string) => {
      if(key==CAMPAIGN_ACTIONS.ABORT) {
        setAction(CAMPAIGN_ACTIONS.ABORT as ICampaignActions);
        setOpenCampaignStateModal(true)
        // onToggle(CAMPAIGN_STATUS.ABORTED);
      } else if (key==CAMPAIGN_ACTIONS.HISTORY) {
        setOpenHistoryPage(true);
      } else {
        onActionPerformed(key, record);
      }
    }
    return (
      <Stack
        space={4}
        direction='column'
      >
        {
          actionList.map((action: string) => {
            if (action in CAMPAIGN_ACTION_MAP) {
              const actionProps = CAMPAIGN_ACTION_MAP[action]
              return (<PopoverActionRow
                text={actionProps?.text}
                hoverBgColor={actionProps?.hoverBgColor}
                textColor={actionProps?.textColor}
                code={actionProps.key}
                icon={actionProps.icon}
                onPress={onActionButtonPressed}
              />
              )
            }
            
          })
        }
      </Stack>
    );
  }

  const getTootipText = () => {
    if (record.status != CAMPAIGN_STATUS.DRAFT) {
      return 'abort';
    }
    return 'runCampaign';
  };
  const isShowGraph = record.status != CAMPAIGN_STATUS.DRAFT;
  const [isDisabledStatusSwitch, setIsDisabledStatusSwitch] = useState(false);
  const tooltipText = getTootipText();

  const onToggle = async (status: ICampaignStatus) => {
    setIsLoading(true);
    const templateId = String(record.id);
    // const statusStr = status ? CAMPAIGN_STATUS.ACTIVE : CAMPAIGN_STATUS.ABORTED;
    // if (record?.segmentId) {
    //   const data = await getContactsWithinGroup(
    //     record?.segmentId || '',
    //     undefined
    //   );
    //   if (!data?.data?.data.length) {
    //     showToast(
    //       toast,
    //       intl.formatMessage({id: 'populationGroupDeleted'}),
    //       ToastType.error
    //     );
    //     setIsLoading(false);
    //     return;
    //   }
    // }
    // commenting for future use
    const response = await updateCampaignStatus({
      id: templateId,
      status: status,
      updatedBy: userUuid,
    });
    if (!response?.updateCampaign?.id) {
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
    setIsLoading(false);
    onActionPerformed(
      COMMON_ACTION_CODES.ENABLE,
      record,
      record.status !== CAMPAIGN_STATUS.DRAFT ? false : true
    );
  };

  return (
    <View flex={1} flexDirection={'row'} gap={'8px'} alignContent={'flex-end'}>
      {
        (record.status == CAMPAIGN_STATUS.RESUMED || record.status == CAMPAIGN_STATUS.ACTIVE) &&
          <Tooltip
            label={ isEditable ? intl.formatMessage({id: 'pauseCampaignTooltip'}) : intl.formatMessage({ id: 'notAllowedToRunCampaign' }) }
            placement='top'
            width={ isEditable ? undefined : 250 }
            height={ isEditable ? undefined : 70 }
          >
            <IconButton
              _hover={{ backgroundColor: 'transparent' }}
              justifyContent={'center'}
              icon={
                <PauseButtonSvg/>
              }
              onPress={() => {
                if (isEditable) {
                  setOpenCampaignStateModal(true);
                  setAction(CAMPAIGN_ACTIONS.PAUSE as ICampaignActions);
                }
                // onToggle(CAMPAIGN_STATUS.PAUSED);
                
              }}
              isDisabled={!isEditable}
            />
          </Tooltip>
      }
      {
        (record.status == CAMPAIGN_STATUS.PAUSED || record.status == CAMPAIGN_STATUS.DRAFT) &&
          <Tooltip
            label={isEditable ? record.status == CAMPAIGN_STATUS.PAUSED ? intl.formatMessage({id: 'resumeCampaignTooltip'}) : intl.formatMessage({id: 'startCampaignTooltip'}) : intl.formatMessage({ id: 'notAllowedToRunCampaign' }) }
            placement='top'
            width={ isEditable ? undefined : 250 }
            height={ isEditable ? undefined : 70 }
          >
            <IconButton
              _hover={{ backgroundColor: 'transparent' }}
              justifyContent={'center'}
              icon={
                <PlayButtonSvg/>
              }
              onPress={() => {
                if (isEditable && record.status === CAMPAIGN_STATUS.DRAFT) {
                  setOpenCampaignStateModal(true);
                  setAction(CAMPAIGN_ACTIONS.START as ICampaignActions);
                } else if (isEditable && record.status === CAMPAIGN_STATUS.PAUSED) {
                  setOpenCampaignStateModal(true);
                  setAction(CAMPAIGN_ACTIONS.RESUME as ICampaignActions);
                }
                // if (record.status == CAMPAIGN_STATUS.PAUSED) {
                //   onToggle(CAMPAIGN_STATUS.RESUMED);
                // } else {
                //   onToggle(CAMPAIGN_STATUS.ACTIVE);
                // }
              }}
              isDisabled={!isEditable}
            />
          </Tooltip>
      }
      {/* {(record.status == CAMPAIGN_STATUS.DRAFT || record.status == CAMPAIGN_STATUS.PAUSED) &&
        (
        )
      }
      {
        (record.status == CAMPAIGN_STATUS.ACTIVE || record.status == CAMPAIGN_STATUS.RESUMED) &&
        <Tooltip
          label={isEditable ? intl.formatMessage({ id:tooltipText}) : intl.formatMessage({ id: 'notAllowedToAbortCampaign' }) }
          placement='top'
          width={ isEditable ? undefined : 250 }
          height={ isEditable ? undefined : 70 }
        >
          <IconButton
            _hover={{ backgroundColor: 'transparent' }}
            justifyContent={'center'}
            icon={
              <PauseButtonSvg/>
            }
            onPress={() => {
              if (isEditable) {
                setOpenCampaignStateModal(true);
              }
            }}
            isDisabled={!isEditable}
          />
        </Tooltip>
      }*/}

      {isShowGraph && (
        <Tooltip
          label={intl.formatMessage({ id: 'viewAnalytics' })}
          placement='top'
        >
          <IconButton
            _hover={{ backgroundColor: 'transparent' }}
            justifyContent={'center'}
            icon={
              <AutomationAnalyticsV2Svg/>
            }
            onPress={() => {
              handleGraphViewClick();
            }}
          />
        </Tooltip>
      )}
      {
        (record.status == CAMPAIGN_STATUS.DRAFT || record.status == CAMPAIGN_STATUS.ENDED || record.status == CAMPAIGN_STATUS.ABORTED) &&
        <Tooltip label='Clone' placement='top'>
          <IconButton
            _hover={{ backgroundColor: 'transparent' }}
            justifyContent={'center'}
            icon={
              <DuplicateSvgV2/>
            }
            onPress={() => {
              onActionPerformed(CAMPAIGN_ACTIONS.DUPLICATE, record);
            }}
          />
        </Tooltip>
      }
      {/* {(record.status === CAMPAIGN_STATUS.DRAFT ||
        (record.status === CAMPAIGN_STATUS.ACTIVE &&
          moment().isBefore(record.startDate))) && (
          <Tooltip 
          width={ isEditable ? undefined : 250 }
          height={ isEditable ? undefined : 70 } 
          label={isEditable ? intl.formatMessage({ id:'edit'}) : intl.formatMessage({ id: 'notAllowedToEditCampaign' })} placement='top'>
            <IconButton
              _hover={{ backgroundColor: 'transparent' }}
              justifyContent={'center'}
              icon={
                <Feather name='edit-2' size={18} 
                color={ isEditable ? Colors.Custom.Gray600 : Colors.FoldPixel.GRAY150} 
                />
              }
              onPress={() => {
                if (isEditable) {
                  onActionPerformed(COMMON_ACTION_CODES.EDIT, record);
                }
              }}
              isDisabled={!isEditable}
            />
          </Tooltip>
        )}
      <Tooltip label='Clone' placement='top'>
        <IconButton
          _hover={{ backgroundColor: 'transparent' }}
          justifyContent={'center'}
          icon={
            <DuplicateSvgV2/>
          }
          onPress={() => {
            onActionPerformed(COMMON_ACTION_CODES.COPY, record);
          }}
        />
      </Tooltip>

      {record.status === CAMPAIGN_STATUS.DRAFT ||
        record.status === CAMPAIGN_STATUS.ENDED ||
        record.status === CAMPAIGN_STATUS.ABORTED ||
        (record.status === CAMPAIGN_STATUS.ACTIVE &&
          moment().isBefore(record.startDate)) ? (
        <Tooltip 
        width={ isEditable ? undefined : 250 }
        height={ isEditable ? undefined : 70 } 
        label={ isEditable ? intl.formatMessage({id : 'delete'}) : intl.formatMessage({ id: 'notAllowedToDeleteCampaign' })} placement='top'>
          <IconButton
            _hover={{ backgroundColor: 'transparent' }}
            justifyContent={'center'}
            icon={
              <Feather
                name='trash-2'
                style={{
                  fontSize: 18,
                  color :  isEditable ? Colors.Custom.Gray600 : Colors.FoldPixel.GRAY150
                }}
              />
            }
            onPress={() => {
              if (isEditable) {
                setOpenDeleteModal(true)
              }
            }}
            isDisabled={!isEditable}
          />
        </Tooltip>
      ) : null} */}
      {
        <Popover
          // open={openPopover}
          placement="bottomLeft"
          content={getPopoverActions(record)}
          trigger="click"
          style={{
            padding: '8px',
            width: '150px',
            borderRadius: '8px'
          }}
        >
          <Button
            type="text"
            style={{ padding: 0 }}
            icon={<HorizontalEllipsisSvg/>}
          />
        </Popover>
      }
      <DeleteCampaignModal
        isOpen={openDeleteModal}
        onConfirm={() => {
          handleDeleteCampaign();
          setOpenDeleteModal(false);
        }}
        onClose={() => setOpenDeleteModal(false)}
      />
      {/* <FHAlertDialog
        isOpen={openCampaignStateModal}
        customHeight="24%"
        header={intl.formatMessage({ id: record.status == CAMPAIGN_STATUS.ACTIVE ? 'abortTheCampaign' : 'runTheCampaign', })}
        message={intl.formatMessage({ id: record.status == CAMPAIGN_STATUS.ACTIVE ? 'abortCampaignAlertMessage' : 'runCampaignAlertMessage' })}
        buttonActions={[
          {
            textLocalId: 'cancel',
            buttonProps: {
              variant: BUTTON_TYPE.SECONDARY,
            },
            onPress: () => {
              setOpenCampaignStateModal(false)
            },
          },
          {
            textLocalId: record.status == CAMPAIGN_STATUS.ACTIVE ? 'abort' : 'runCampaign',
            buttonProps: {
              variant: BUTTON_TYPE.PRIMARY,
            },
            onPress: () => {
              onToggle(CAMPAIGN_STATUS.ABORTED);
              setOpenCampaignStateModal(false)
            },
          },
        ]}
      /> */}
      {
        openCampaignStateModal &&
        <ModalActionDialog
          title={CAMPAIGN_ACTION_MODAL_MAP[action]?.title}
          subTitle={CAMPAIGN_ACTION_MODAL_MAP[action]?.subtitle}
          isTextBoxVisible={true}
          icon={CAMPAIGN_ACTION_MODAL_MAP[action]?.icon}
          open={openCampaignStateModal}
          onLeftButtonClick={CAMPAIGN_ACTION_MODAL_MAP[action]?.onLeftButtonClick}
          onRightButtonClick={CAMPAIGN_ACTION_MODAL_MAP[action]?.onRightButtonClick}
          leftButtonText={CAMPAIGN_ACTION_MODAL_MAP[action]?.leftButtonText}
          rightButtonText={CAMPAIGN_ACTION_MODAL_MAP[action]?.rightButtonText}
          textPlaceHolder='Please add any specific reason'
          isAlert={CAMPAIGN_ACTION_MODAL_MAP[action]?.isAlert}
          onClose={() => {
            setOpenCampaignStateModal(false);
          }}
        />
      }
      {
        openHistoryPage &&
        <CampaignHistory
          open={openHistoryPage}
          onClose={() => {
            setOpenHistoryPage(false);
          }}
          resourceId={record.id as string}
          currentCampaignStatus={record.status}
          currentUpdatedAt={record.updatedOn as string}
      />
      }
    </View>
  );
};

export default CampaignTableActions;
