import {useContext} from 'react';
import { IAvailableEhr } from '../components/PersonOmniView/MiddleContainer/interfaces';
import { OrderType } from '../components/PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderConstants';
import { getOrderType } from '../components/PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { EHRName } from '../components/PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {HEALTH_COMPONENT_CAPABILITY_MAP} from '../components/RightSideContainer/Forms/FHFormio/Builder/SupportedComponents';
import {IFormComponentConfig} from '../components/RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { EHR, FHIR_RESOURCE } from '../constants/FhirConstant';
import {CommonDataContext, ICommonData} from '../context/CommonDataContext';
import {
  IAbility,
  IAllowedOperation,
  IEhrCapability,
  ILoggedInUserLocationDetails,
  IVitalData,
  PossibleValue,
} from '../Interfaces';
import { Vital, getEnabledVitals } from './VitalUtils';
import useEHRCapabilities from '../screens/BusinessStudio/useEHRCapabilities';
import { getBooleanFeatureFlag } from './commonUtils';
import FeatureFlags from '../constants/FeatureFlags.enums';

export const EHR_CAPABILITY_CONST = {
  DEFAULT_GENDER_IDENTITY_LABEL: 'Gender',
  DEFAULT_BIRTH_SEX_LABEL: 'Sex at birth',
};

export enum InputType {
  choice = 'choice',
  openChoice = 'open-choice',
  freeText = 'freetext',
}

export const getCapability = (
  resourceName: string,
  locationGroupId: string | undefined,
  locationId: string | undefined,
  isPopGroupContext?: boolean,
  commonDataObject?: ICommonData
): IEhrCapability => {
  const commonData = commonDataObject ?? useContext(CommonDataContext);
  let ehrCapabilities = commonData.ehrCapabilities;

  const isMultiTenancyEnabled = getBooleanFeatureFlag(
    commonData.userSettings,
    FeatureFlags.IS_MULTI_TENANCY_ENABLED
  );

  if (locationGroupId || locationId || !isMultiTenancyEnabled) {
    ehrCapabilities = useEHRCapabilities({
      locationGroupId: locationGroupId,
      locationId: locationId,
      locationGroupIds: undefined,
      commonDataObject: commonData
    });
  } else if ((isPopGroupContext || isMultiTenancyEnabled) && commonData.popGroupCapabilities) {
    ehrCapabilities = commonData.popGroupCapabilities;
  } else {
    ehrCapabilities = commonData.ehrCapabilities;
  }

  for (const capability of ehrCapabilities || []) {
    if (capability.resourceName == resourceName) {
      return capability;
    }
  }
  return {};
};

export const getFullEhrCapability = (
  locationGroupId: string | undefined,
  locationId: string | undefined
) => {
  const ehrCapabilities = useEHRCapabilities({
    locationGroupId: locationGroupId,
    locationId: locationId,
  });

  return ehrCapabilities;
};

export const getAccountConfigCapability = (resourceName: string): IEhrCapability => {
  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = commonData.accountConfigCapabilities;

  for (const capability of ehrCapabilities || []) {
    if (capability.resourceName == resourceName) {
      return capability;
    }
  }
  return {};
};

export const getCapabilityForMultipleLocationGrpups = (
  resourceName: string,
  locationId?: string
): IEhrCapability[] => {
  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = useEHRCapabilities({
    locationGroupIds: locationId
      ? undefined
      : commonData?.loggedInUserLocationDetails?.locationGroupIds,
    locationId: locationId,
  });

  return ehrCapabilities?.filter(
    (capability) => capability?.resourceName === resourceName
  );
};

export const getResourceAbilities = (
  resourceName: string,
  locationGroupId: string | undefined,
  locationId: string | undefined,
  isPopGroupContext?: boolean,
  commonDataObject?: ICommonData,
): IAbility => {
  return (
    getCapability(resourceName, locationGroupId, locationId, isPopGroupContext, commonDataObject)?.abilities || {}
  );
};


export const getAccountConfigResourceAbilities = (resourceName: string): IAbility => {
  return getAccountConfigCapability(resourceName)?.abilities || {};
};


export const canCreate = (ability: any): boolean => {
  return (ability && ability.isEnabled && ability.canCreate) || false;
};

export const isRequiredArgument = (abilities: IAbility, keyName: string) => {
  const ability = abilities?.keyAllowedOperations?.[keyName];
  return (ability && ability.isRequired) || false;
};

export const isKeyEnabled = (abilities: IAbility, keyName: string): boolean => {
  const ability = abilities?.keyAllowedOperations?.[keyName];
  return (ability && ability.isEnabled) || false;
};

export const getMrnNumber = (resource: any) => {
  if (resource && resource.resourceType === FHIR_RESOURCE.PATIENT) {
    for (const identifier of resource.identifier || []) {
      const type = identifier?.type?.coding?.[0]?.code;
      if (type === 'MR') {
        return identifier.value;
      }
    }
  }
  return '';
};

export const getPossibleValuesByOperationName = (
  operationName: string,
  locationGroupId: string,
  locationId: string | undefined,
  isPopGroupContext?: boolean,
): PossibleValue[] => {
  const keyOperations = getResourceAbilities(
    FHIR_RESOURCE.PATIENT,
    locationGroupId,
    locationId,
    isPopGroupContext,
  ).keyAllowedOperations;
  if (keyOperations) {
    return keyOperations[operationName]?.possibleValues || [];
  }
  return [];
};

export const getSexAtBirthTransformValues = (
  operationName: string,
  locationGroupId: string | undefined,
  locationId: string,
  isPopGroupContext?: boolean,
): PossibleValue[] => {
  const keyOperations = getResourceAbilities(
    FHIR_RESOURCE.PATIENT,
    locationGroupId,
    locationId,
    isPopGroupContext,
  ).keyAllowedOperations;
  if (keyOperations) {
    return keyOperations[operationName]?.transformValues || [];
  }
  return [];
};

export const getEHRUrl = (
  locationId: string | undefined,
  locationGroupId: string | undefined,
  commonDataObject?: ICommonData,
): string => {
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.PATIENT,
    locationGroupId,
    locationId,
    false,
    commonDataObject
  );
  return resourceAbilities.openEhrUrl || '';
};

export const getPatientEHRUrl = (
  patientId: string,
  locationId: string | undefined,
  locationGroupId: string | undefined
): string => {
  const stringToReplace = '#PATIENT_ID#';
  const ehrUrl = getEHRUrl(locationId, locationGroupId);
  return (patientId && ehrUrl.replace(stringToReplace, patientId)) || '';
};

export const getHintUrl = (locationId: string | undefined): string => {
  const resourceAbilities = getAccountConfigResourceAbilities(FHIR_RESOURCE.HINT);
  return resourceAbilities?.url || '';
};

export const getOpenInHintUrl = (patientId: string, locationId: string | undefined) => {
  const hintUrl = getHintUrl(locationId);
  const stringToReplace = '#HINT_PATIENT_ID#';
  return (patientId && hintUrl.replace(stringToReplace, patientId)) || '';
};

export const getEHRName = (
  locationId: string | undefined,
  locationGroupId: string | undefined
) => {
  const commonData = useContext(CommonDataContext);
  const ehrCapabilities = useEHRCapabilities({
    locationGroupId: locationGroupId,
    locationId: locationId,
  });
  if (ehrCapabilities?.length === 0) {
    return '';
  }
  const capability = ehrCapabilities.find((item) => {
    return item.ehrName !== null;
  });
  return capability?.ehrName;
};

export const isFoldEhr = (ehrName: string | undefined) => {
  return  ehrName && ehrName === EHR.FOLD || ehrName === EHR.FHIR_STORE;
};

export const getLabelForKey = (
  resourceName: string,
  key: string,
  locationGroupId: string | undefined,
  locationId: string | undefined,
  isPopGroupContext?: boolean,
) => {
  return getResourceAbilities(resourceName, locationGroupId, locationId, isPopGroupContext)
    ?.keyAllowedOperations?.[key]?.label;
};

export const getGenderIdentityLabel = (
  locationGroupId: string | undefined,
  locationId: string | undefined,
  isPopGroupContext?: boolean,
) => {
  return (
    getLabelForKey(
      FHIR_RESOURCE.PATIENT,
      'gender',
      locationGroupId,
      locationId,
      isPopGroupContext,
    ) || EHR_CAPABILITY_CONST.DEFAULT_GENDER_IDENTITY_LABEL
  );
};

export const getBirthSexLabel = (locationGroupId: string, locationId: string | undefined, isPopGroupContext?: boolean) => {
  return (
    getLabelForKey(FHIR_RESOURCE.PATIENT, 'birthSex', locationGroupId, '', isPopGroupContext) ||
    EHR_CAPABILITY_CONST.DEFAULT_BIRTH_SEX_LABEL
  );
};

export const getSexAtBirth = (patientDetail: any) => {
  if (patientDetail?.extension?.length) {
    const birthSex = patientDetail.extension.find((extension: any) => {
      return extension?.url?.includes('us-core-birthsex');
    });
    return birthSex?.valueCode || birthSex?.valueString || '';
  }
  return '';
};

export const isBirthSexPrimary = (locationId: string, commonDataObject?: ICommonData) => {
  return (
    getResourceAbilities(FHIR_RESOURCE.PATIENT, '', locationId, false, commonDataObject)
      ?.keyAllowedOperations?.birthSex?.isPrimary || false
  );
};


export const getPrimaryGenderLabel = (locationId: string) => {
  const patientCapability = getResourceAbilities(
    FHIR_RESOURCE.PATIENT,
    '',
    locationId
  );
  const isGenderIdentityHidden =
    patientCapability?.keyAllowedOperations?.gender?.isHidden || false;
  return isGenderIdentityHidden || isBirthSexPrimary(locationId)
    ? getBirthSexLabel('', locationId)
    : getGenderIdentityLabel('', locationId);
};

export const getVitalListFromCapability = (
  locationGroupId: string,
  locationId: string | undefined,
  isPopGroupContext?: boolean,
): IVitalData[] => {
  return (
    getResourceAbilities(FHIR_RESOURCE.OBSERVATION, locationGroupId, locationId, isPopGroupContext)
      ?.allowedVitalList || []
  );
};

export const getEnabledVitalsFromCapability = (locationId: string | undefined): Vital[] => {
  const capabilities = getCapability(FHIR_RESOURCE.OBSERVATION, '', locationId);
  return getEnabledVitals(
    capabilities,
    undefined
  )
};


export const getEnabledFormComponents = (
  components: IFormComponentConfig[],
  ehrCapabilities: IEhrCapability[]
) => {
  return components.filter((component) => {
    const capabilityKey = HEALTH_COMPONENT_CAPABILITY_MAP[component.key];
    const capabilityData = ehrCapabilities.find(
      (capability) => capability.resourceName === capabilityKey
    );
    return capabilityData?.isEnabled;
  });
};

export const getEHRResourceAbilities = (resourceName: string, ehrCapabilities: IEhrCapability[]): IAbility | undefined => {
  return ehrCapabilities.find(
    (capability) => capability.resourceName === resourceName
  )?.abilities;
};

export const getEHRResourceCapability = (resourceName: string, ehrCapabilities: IEhrCapability[]): IEhrCapability | undefined => {
  return ehrCapabilities.find(
    (capability) => capability.resourceName === resourceName
  );
};

export const getVitalConfigFromCapability = (loinc: string, locationId: string | undefined): IVitalData | undefined => {
  const list = getVitalListFromCapability('', locationId);
  return list.find((data) => data.loinc === loinc);
};

export const isOrderTypeEnabled = (
  type: OrderType,
  locationGroupId: string | undefined,
  locationId: string | undefined
) => {
  const resource =
    type === OrderType.LAB || type === OrderType.RAD
      ? FHIR_RESOURCE.SERVICE_REQUEST
      : FHIR_RESOURCE.MEDICATION_REQUEST;
  const abilities = getResourceAbilities(resource, locationGroupId, locationId);
  return abilities?.isEnabled || false;
};

export const isOrderTypeEnabledWithCapability = (
  type: OrderType,
  ehrCapabilities: IEhrCapability[],
  locationId: string
) => {
  const resource =
    type === OrderType.LAB || type === OrderType.RAD
      ? FHIR_RESOURCE.SERVICE_REQUEST
      : FHIR_RESOURCE.MEDICATION_REQUEST;
  // const abilities = ehrCapabilities.find((capability: any) => capability.resourceName === resource)?.abilities;
  const resourceCapability = getResourceAbilities(resource, '', locationId);
  return resourceCapability?.isEnabled || false;
};

export const isOrderingEnabled = (locationId: string | undefined) => {
  let isEnabled = false;
  [FHIR_RESOURCE.SERVICE_REQUEST, FHIR_RESOURCE.MEDICATION_REQUEST].forEach((resourceName) => {
    const abilities = getResourceAbilities(resourceName, '', locationId);
    isEnabled = isEnabled || (abilities?.isEnabled || false);
  });
  return isEnabled;
}

export const isNewHGFlowEnabled = () => {
  return true;
}

export const getEhrConfig = (
  locationId: string | undefined,
  locationGroupId: string | undefined
): IAvailableEhr => {
  const currentEHR = getEHRName(locationId, locationGroupId);
  return {
    isAthena: currentEHR === EHRName.ATHENA,
    isElation: currentEHR === EHRName.ELATION,
    isFold: currentEHR === EHRName.FOLD,
    isCanvas: currentEHR === EHRName.CANVAS,
  };
};

export const isDiagnosticReportEnabled = (type: OrderType, locationId: string | undefined) => {
  const abilities = getResourceAbilities(
    FHIR_RESOURCE.DIAGNOSTIC_REPORT,
    '',
    locationId
  );
  if (abilities.enabledTypes?.length) {
    const key = type == OrderType.LAB ? 'LAB' : (type == OrderType.RAD ? 'IMAGING' : undefined);
    return abilities.isEnabled && key && abilities.enabledTypes?.includes(key);
  }
  return abilities.isEnabled;
}

export const isMarkAsReviewEnabled = (locationId: string | undefined) => {
  const abilities = getResourceAbilities(
    FHIR_RESOURCE.DIAGNOSTIC_REPORT,
    '',
    locationId
  );
  return abilities.canReviewResult;
};

export const getOrgAbilites = (resourceName:string) => {
  const capability = getAccountConfigCapability(
      FHIR_RESOURCE.ORGANIZATION,
  );

  const capabilities = capability?.abilities?.capabilities;
  const patientPatientOrgAbilities = capabilities?.filter(
    (res) => res.resourceName === resourceName
  )?.[0]?.abilities?.allowedOperations;
  return patientPatientOrgAbilities;
}

export const checkAbilityAccess = (
  resourceName: string,
  abilityField: keyof IAllowedOperation,
  locationId?: string,
) => {
  const locationGroupIds : string[]= [];
  const capabilities = getCapabilityForMultipleLocationGrpups(
    resourceName,
    locationId
  );
  let isEnabled = false;
  capabilities?.forEach((capability) => {
    if (capability.abilities?.allowedOperations?.[abilityField]) {
      isEnabled = true;
      if (capability.locationGroupId) {
        locationGroupIds.push(capability.locationGroupId);
      }
    }
  });
  return {isEnabled, locationGroupIds};
};


export const checkAccountConfigAbilityAccess = (
  resourceName: string,
  abilityField: keyof IAllowedOperation,
) => {
  const allowedOperations = getOrgAbilites(
    resourceName,
  );
  const isEnabled = allowedOperations?.[abilityField] || false;
  return {isEnabled, allowedOperations};
};

export const checkKeyAllowedOperationAccess = (
  resourceName: string,
  abilityField: keyof IAllowedOperation,
  subAbilityField: keyof IAllowedOperation,
  locationId?: string,
) => {
  const capabilities = getCapabilityForMultipleLocationGrpups(
    resourceName,
    locationId
  );
  let isEnabled = false;
  capabilities?.forEach((capability) => {
    if (capability.abilities?.keyAllowedOperations?.[abilityField]?.[subAbilityField]) {
      isEnabled = true;
    }
  });
  return isEnabled;
};

export const FOLD_MEMBERSHIP_STATUS_LIST = [
  { display: 'Active', code: 'active' },
  { display: 'Inactive', code: 'inactive' }
]

export const getFieldCapabilities = (capabilities: any) => {
  return capabilities?.abilities?.keyAllowedOperations;
};

export const getCapabilityFunction = (
  locationGroupId: string | undefined,
  locationId: string | undefined,
  ehrCapabilityLocationMap: Map<string, IEhrCapability[]> | undefined,
  loggedInUserLocationDetails: ILoggedInUserLocationDetails | undefined,
  isMultiTenancyEnabled: boolean
): IEhrCapability[] => {
  let capabilities: IEhrCapability[] = [];
  const locationGroup = loggedInUserLocationDetails?.locationGroupList?.find(
    (locationGroup) => locationGroup.accountLocationUuid === locationId
  );

  if (!isMultiTenancyEnabled && ehrCapabilityLocationMap) {
    const defaultCapability = Array.from(ehrCapabilityLocationMap.keys())[0];
    capabilities = ehrCapabilityLocationMap.get(defaultCapability) || [];
    return capabilities;
  }

  if((locationGroupId || locationGroup?.locationGroupId) && ehrCapabilityLocationMap) {
    capabilities = ehrCapabilityLocationMap?.get(locationGroupId || locationGroup?.locationGroupId || '') || [];
    return capabilities;
  }

  return capabilities
};

export const getResourceAbilitiesFunction = (
  resourceName: string,
  locationGroupId: string | undefined,
  locationId: string | undefined,
  ehrCapabilityLocationMap: Map<string, IEhrCapability[]> | undefined,
  loggedInUserLocationDetails: ILoggedInUserLocationDetails | undefined,
  isMultiTenancyEnabled: boolean
): IEhrCapability => {
  if (
    resourceName &&
    (locationGroupId || locationId)
  ) {
    const ehrCapabilities = getCapabilityFunction(
      locationGroupId,
      locationId,
      ehrCapabilityLocationMap,
      loggedInUserLocationDetails,
      isMultiTenancyEnabled
    );
    for (const capability of ehrCapabilities || []) {
      if (capability.resourceName === resourceName) {
        return capability;
      }
    }
  }
  return {};
};
