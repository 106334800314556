import { APPOINTMENT_STATUS_CODES } from "../../../constants/MlovConst";
import { IAppointmentDetail } from "../../common/CalendarWidget/CalendarWidgetInterfaces";

export const isExpiredDateTime = (givenDateTime?: Date) => {
    const currentDateTime = new Date();
    if (givenDateTime && currentDateTime > givenDateTime) {
      return true;
    }
    return false;
  }

  export const isMemberLimitExceeded = (
    appointmentDetail: IAppointmentDetail,
    acceptedStatusId: string,
  ) => {
    const acceptedUsers = appointmentDetail?.participants?.filter((item) => {
      if (item?.contact?.id) {
        if (item?.statusId === acceptedStatusId) {
          return item;
        }
      }
    });

    const locationTypeCode: string = appointmentDetail?.locationType?.code as string;
    const memberLimit = appointmentDetail?.appointmentType?.memberLimit?.[locationTypeCode];
    if (
      memberLimit &&
      acceptedUsers?.length >= memberLimit
    ) {
      return true;
    }
    return false;
  };

  export const getAppointmentDateDisplayText = (appointmentStatus?: string) => {
    switch (appointmentStatus) {
      case APPOINTMENT_STATUS_CODES.PENDING:
        return 'Pending RSVP for ';
      case APPOINTMENT_STATUS_CODES.CHECKED_IN:
        return 'Checked-in on ';
      case APPOINTMENT_STATUS_CODES.PROPOSED:
        return 'Proposed for ';
      case APPOINTMENT_STATUS_CODES.BOOKED:
        return 'Scheduled on ';
      default:
        return 'Scheduled on ';
    }
  };