import { gql } from '@apollo/client';

const CORE_GET_TASK_FIELDS = gql`
  fragment CoreGetTaskFields on GetTasksOutput {
    tasks {
      id
      assigneeId
      createdBy
      assigneeTypeCode
      completedBy
      assigneeUser
      assignedById
      assignedByUser
      startDateTime
      endDateTime
      title
      description
      isEscalated
      referenceData
      contactId
      contact
      userPoolId
      userPool
      placeholderTaskId
      placeholderTaskDerivedId
      patientCareJourney
      statusId
      parentId
      status {
        id
        value
        code
      }
      taskType {
        code
        id
        value
      }
      patientEducations {
        patientEducation {
          documentType {
            code
          }
          url
          id
          description
        }
      }
      priorityId
      priority {
        id
        code
        value
      }
      subTasks {
        id
      }
      attachments {
        id
        taskId
        attachmentId
        taskDocumentAttachments
      }
      labels {
        id
        labelId
      }
      orders {
          id
          externalOrderId
          description
          orderDate
          orderNote
          orderType
          reference
          subOrderType
          orderStatus
          isModified
      }
      resourceMap {
        messages
        notes
        carePlan
      }
      taskLocations {
        id
        locationId
        resourceId
        resourceTypeId
      }
    }
    aggregate
  }
`;

export const GET_CARE_DASHBOARD_TASKS = gql`
  ${CORE_GET_TASK_FIELDS}
  query GetTasksByAssigneeId(
    $startDateTime: String
    $endDateTime: String
    $assigneeIds: [uuid]
    $timezone: String
    $contactIds: [uuid]
    $assigneeTypeCodes: [String]
    $assigneeIdsNotIn: [uuid],
    $statusIdsNotIn: [uuid],
    $statusIds: [uuid],
    $limit: Int,
    $orderBy: GetTasksOrderByInput,
    $offset: Int,
    $taskDueDateCategoryIds: [uuid],
    $priorityIds: [uuid],
  ) {
    getTasks(
      params: {
        contactIds: $contactIds
        assigneeIds: $assigneeIds
        startDateTime: $startDateTime
        endDateTime: $endDateTime
        timezone: $timezone
        assigneeTypeCodes: $assigneeTypeCodes
        assigneeIdsNotIn: $assigneeIdsNotIn
        statusIdsNotIn: $statusIdsNotIn
        limit: $limit
        orderBy: $orderBy
        offset: $offset
        statusIds: $statusIds
        taskDueDateCategoryIds: $taskDueDateCategoryIds
        priorityIds: $priorityIds
      }
    ) {
      ...CoreGetTaskFields
    }
  }
`;

export const GET_CARE_DASHBOARD_TASKS_WITH_PAGINATION = gql`
  ${CORE_GET_TASK_FIELDS}
  query GetTasksByAssigneeId($params: GetTasksInput!) {
    getTasks(params: $params) {
      tasks {
        id
        assigneeId
        createdBy
        assigneeTypeCode
        assigneeUser
        assignedById
        assignedByUser
        startDateTime
        endDateTime
        title
        description
        isEscalated
        referenceData
        contactId
        contact
        userPoolId
        userPool
        placeholderTaskId
        placeholderTaskDerivedId
        patientCareJourney
        statusId
        parentId
        status {
          id
          value
          code
        }
        taskType {
          code
          id
          value
        }
        patientEducations {
          patientEducation {
            documentType {
              code
            }
            url
            id
            description
          }
        }
        priorityId
        priority {
          id
          code
          value
        }
        subTasks {
          id
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
          id
          labelId
        }
        taskLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
        resourceMap {
          messages
          notes
          carePlan
        }
      }
      aggregate
    }
  }
`;

export const GET_CONVERSATION_TASK = gql`
  ${CORE_GET_TASK_FIELDS}
  query GetConversationTask($ids: [String!]) {
    getTasks(params: {ids: $ids}) {
      ...CoreGetTaskFields
    }
  }
`;

export const GET_TASKS_BY_IDS = gql`
  ${CORE_GET_TASK_FIELDS}
  query getTasksByIds($ids: [String!]) {
    getTasks(params: {ids: $ids}) {
      ...CoreGetTaskFields
    }
  }
`;

export const GET_TASK_DELETED_STATUS_BY_ID = gql`
  query getTaskDeletedStatus($taskId: uuid) {
    tasks(where: {id: {_eq: $taskId}}) {
      isDeleted
      id
      title
    }
  }
`;

export const UPDATE_TASK_STATUS = gql`
  mutation UpdateTask($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      statusId
    }
  }
`;

export const UPDATE_TASK_STATUS_AND_REFERENCE = gql`
  mutation UpdateTaskStatusAndAssignee($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      statusId
      referenceData
    }
  }
`;

export const UPDATE_TASK_ASSIGNEE = gql`
  mutation UpdateTaskAssignee($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      assigneeId
    }
  }
`;

export const ADD_OR_UPDATE_TASK = gql`
  mutation AddOrUpdateTask($data: TaskInput!) {
    addOrUpdateTask(params: $data) {
      id
      title
      description
      assigneeId
      assignedById
      assigneeTypeCode
      startDateTime
      isEscalated
      referenceData
      contactId
      patientCareJourneyId
      statusId
      status {
        id
        code
        value
      }
      priorityId
      priority {
        id
        code
        value
      }
      subjectId
      patientEducations {
        isDeleted
        patientEducation {
          id
          url
          description
          documentType {
            code
          }
        }
      }
      taskTypeId
      taskType {
        id
        code
        value
      }
      taskDisplayCategoryId
      userPoolId
      startDateTime
      endDateTime
      attachments {
        id
        taskId
        attachmentId
        taskDocumentAttachments
      }
      labels {
        labelId
      }
      taskLocations {
        id
        locationId
        resourceId
        resourceTypeId
      }
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTaskAssignee($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      id
      title
      description
      assigneeId
      assignedById
      startDateTime
      isEscalated
      referenceData
      contactId
      patientCareJourneyId
      statusId
      status {
        id
        code
        value
      }
      priorityId
      priority {
        id
        code
        value
      }
      subjectId
      patientEducations {
        isDeleted
        patientEducation {
          id
          url
          description
          documentType {
            code
          }
        }
      }
      taskTypeId
      taskType {
        id
        code
        value
      }
      taskDisplayCategoryId
      userPoolId
      startDateTime
      endDateTime
    }
  }
`;

export const UPDATE_TASK_WORKFLOW = gql`
  mutation UpdateTaskWorkflow($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      id
    }
  }
`;

export const UPDATE_TASK_CATEGORY = gql`
  mutation updateTaskDisplayCategory($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      id
    }
  }
`;

export const UPDATE_TASK_DISPLAY_CATEGORY_NAME = gql`
  mutation createUserTaskDisplayCategories(
    $objects: [user_task_display_categories_insert_input!]!
  ) {
    createUserTaskDisplayCategories(
      objects: $objects
      on_conflict: {
        constraint: user_task_display_categories_pkey
        update_columns: [
          categoryValue
          sequenceNumber
          userId
          isDeleted
        ]
      }
    ) {
      affected_rows
      returning {
        id
        taskDisplayCategoryId
        categoryValue
        userId
      }
    }
  }
`;

// export class GetTasksInput {
//   offset: number;
//   limit: number;
//   assigneeIds: [string];
//   priorityIds?: [string];
//   statusIds?: [string];
//   startDateTime?: string;
//   endDateTime?: string;
//   orderBy?: {
//     endDateTime?: 'asc' | 'desc';
//     createdOn?: 'asc' | 'desc';
//     priority?: 'asc' | 'desc';
//   };
// }

export const GET_TASKS = gql`
  query GetTasks($params: GetTasksInput!) {
    getTasks(params: $params) {
      tasks {
        id
        title
        description
        parentId
        assigneeId
        assignedByUser
        createdBy
        createdBy
        assigneeTypeCode
        assigneeUser
        assignedById
        assignedByUser
        startDateTime
        isEscalated
        referenceData
        contactId
        contact
        patientCareJourneyId
        patientCareJourney
        placeholderTaskId
        placeholderTaskDerivedId
        resourceMap {
          messages
          notes
          carePlan
        }
        statusId
        status {
          id
          code
          value
        }
        priorityId
        priority {
          id
          code
          value
        }
        subjectId
        patientEducations {
          isDeleted
          patientEducation {
            id
            url
            description
            documentType {
              code
            }
          }
        }
        taskTypeId
        taskType {
          id
          code
          value
        }
        taskDisplayCategoryId
        userPoolId
        userPool
        startDateTime
        endDateTime
        subTasks {
          id
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
            id
            labelId
        }
        orders {
          id
          externalOrderId
          description
          orderDate
          orderNote
          orderType
          reference
          subOrderType
          orderStatus
          isModified
        }
        subjectId
        subjectTypeId
        taskLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
      }
    aggregate
  }
}
`;

export const GET_TASK_BY_ID = gql`
  query GetTaskById(
    $id: String
  ) {
    getTasks(
      params: {
        ids: [$id],
        includeDeletedTask: true,
      }
    ) {
      tasks {
        id
        assigneeId
        createdBy
        assigneeTypeCode
        assigneeUser
        assignedById
        assignedByUser
        startDateTime
        endDateTime
        title
        description
        isEscalated
        referenceData
        contactId
        contact
        userPoolId
        userPool
        placeholderTaskId
        placeholderTaskDerivedId
        patientCareJourney
        statusId
        parentId
        status {
          id
          value
          code
        }
        taskType {
          code
          id
          value
        }
        patientEducations {
          patientEducation {
            documentType {
              code
            }
            url
            id
            description
          }
        }
        priorityId
        priority {
          id
          code
          value
        }
        subTasks {
          id
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
          id
          labelId
        }
        taskLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
      }
      aggregate
    }
  }
`
export const GET_TASK_BY_MULTI_IDS = gql`
  query GetTaskByIds(
    $ids: [String]
  ) {
    getTasks(
      params: {
        ids: $ids,
        includeDeletedTask: true,
      }
    ) {
      tasks {
        id
        assigneeId
        createdBy
        assigneeTypeCode
        assigneeUser
        assignedById
        assignedByUser
        startDateTime
        endDateTime
        title
        description
        isEscalated
        referenceData
        contactId
        contact
        userPoolId
        userPool
        placeholderTaskId
        placeholderTaskDerivedId
        patientCareJourney
        statusId
        parentId
        status {
          id
          value
          code
        }
        taskType {
          code
          id
          value
        }
        patientEducations {
          patientEducation {
            documentType {
              code
            }
            url
            id
            description
          }
        }
        priorityId
        priority {
          id
          code
          value
        }
        subTasks {
          id
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
          id
          labelId
        }
        taskLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
      }
      aggregate
    }
  }
`

export const GET_SUB_TASK_BY_ID = gql`
  query GetSubTaskById(
    $ids: [String!]
  ) {
    getTasks(
      params: {
        ids: $ids,
        includeSubTasks: true
      }
    ) {
      tasks {
        id
        title
        description
        startDateTime
        endDateTime
        statusId
        status {
          value
          code
          id
        }
        assigneeId
        assigneeUser
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
      }
    }
  }
`
export const ADD_SUB_TASK_BY_PARENT_ID = gql`
  mutation AddOrUpdateTask($data: TaskInput!) {
    addOrUpdateTask(params: $data) {
      id
      title
      description
      startDateTime
      endDateTime
      statusId
      assigneeId
    }
  }
`;

export const UPDATE_SUB_TASK = gql`
  mutation UpdateTask($params: UpdateTaskInput!) {
    updateTask(params: $params) {
      id
      title
      description
      startDateTime
      endDateTime
      statusId
      assigneeId
      priorityId
      assignedById
      contactId
      userPoolId
      createdBy
    }
  }
`;

export const GET_TASKS_TEAM_COUNT = gql`
  query GetTaskCounts($paramsB: GetTasksInput!) {
    team_task_count: getTasks(params: $paramsB) {
      aggregate
    }
  }
`;

export const GET_TASKS_COUNT_WITHOUT_TEAM_COUNT = gql`
  query GetTaskCounts($paramsA: GetTasksInput!, $paramsC: GetTasksInput!, $paramsD: GetTasksInput!) {
    assigned_to_me_count: getTasks(params: $paramsA) {
      aggregate
    }

    created_by_me_count: getTasks(params: $paramsC) {
      aggregate
    }

    mentioned_me_count: getTasks(params: $paramsD) {
      aggregate
    }
  }
`;

export const CREATE_CONVERSATION_TAGGED_ITEM = gql`
  mutation CREATE_CONVERSATION_TAGGED_ITEM(
    $accountUuid: uuid!
    $conversationUuid: uuid!
    $itemId: uuid!
    $itemTypeId: uuid!
    $messageUuid: uuid!
  ) {
    createConversationTaggedItem(
      object: {
        accountUuid: $accountUuid
        conversationUuid: $conversationUuid
        itemTypeId: $itemTypeId
        itemId: $itemId
        messageUuid: $messageUuid
      }
    ) {
      id
    }
  }
`;

export const GET_TASK_ITEM_IDS = gql`
  query GetTaskItemIds($conversationUuid: uuid!) {
    conversationTaggedItems(
      where: {conversationUuid: {_eq: $conversationUuid}}
    ) {
      itemId
    }
  }
`;

export const GET_PROVIDER_TASKS_COUNT = gql`
  query GetTaskCounts(
    $assignCount: GetTasksInput!
    $todayCount: GetTasksInput!
    $tomorrowCount: GetTasksInput!
    $laterCount: GetTasksInput!
  ) {
    all_assigned: getTasks(params: $assignCount) {
      aggregate
    }

    do_today: getTasks(params: $todayCount) {
      aggregate
    }

    do_tomorrow: getTasks(params: $tomorrowCount) {
      aggregate
    }

    do_later: getTasks(params: $laterCount) {
      aggregate
    }
  }
`;

export const GET_COMPLETED_NOT_COMPLETED_TASKS_COUNT = gql`
  query GetTaskCounts(
    $completedParams: GetTasksInput!
    $notCompletedParams: GetTasksInput!
  ) {
    completed: getTasks(params: $completedParams) {
      aggregate
    }

    notCompleted: getTasks(params: $notCompletedParams) {
      aggregate
    }
  }
`;

export const GET_ORDER_TASK_NOTES = gql`
  query getOrderTaskNotes($taskId: uuid!) {
    rte_subject(where: {subject_id: {_eq: $taskId}, is_deleted: {_eq: false}}) {
      content
    }
  }
`;
export const GET_TASKS_COUNT = gql`
  query GetTaskStatusCount(
    $assigneeId: String!
    $startDate: String
    $endDate: String
    $groupBy: [String]
    $timezone: String
  ) {
    getTaskStatusCount(
      params: {
        assigneeId: $assigneeId
        startDate: $startDate
        endDate: $endDate
        groupBy: $groupBy
        timezone: $timezone
      }
    ) {
      assigneeId
      completedCount
      totalCount
    }
  }
`;
export const GET_AGGREGATE_TASK_COUNT = gql`
  query GetTaskCounts($params: GetTasksInput!) {
    getTasks(params: $params) {
      aggregate
    }
  }
`;

export const GET_AGGREGATE_TASK_COUNTS = gql`
  query GetTaskCounts($pendingParams: GetTasksInput!, $completedParams: GetTasksInput!) {
    pendingTasks: getTasks(params: $pendingParams) {
      aggregate
    }
    completedTasks: getTasks(params: $completedParams) {
      aggregate
    }
  }
`;


export const GET_LABELS_BY_IDS = gql`
  query getLabelsByLabelIds($labelIds: [uuid!], $labelTypeId: uuid!) {
    labels(
      where: {
        uuid: {_in: $labelIds}
        isDeleted: {_eq: false}
        labelTypes: {labelTypeId: {_eq: $labelTypeId}}
      }
    ) {
      id
      color
      description
      title
      uuid
      labelTypes {
        labelId
        labelTypeId
      }
    }
  }
`;

export const GET_LABELS_BY_TITLE = gql`
  query getLabelsByTitle($names: [String!], $labelTypeId: uuid!) {
    labels(
      where: {
        title: {_in: $names}
        isDeleted: {_eq: false}
        labelTypes: {labelTypeId: {_eq: $labelTypeId}}
      }
    ) {
      id
      uuid
      title
    }
  }
`;

export const ADD_OR_UPDATE_COMMENT = gql`
  mutation AddOrUpdateTaskComments($data: AddOrUpdateTaskCommentsInput!) {
    addOrUpdateTaskComments(params: $data) {
      comments {
        comment
        id
        isDeleted
        isModified
        taskId
        createdOn
        createdBy
      }
    }
  }
`;
export const GET_TASK_COMMENTS = gql`
  query GetTasksComments($taskId: uuid!) {
    taskComments(where: {taskId: {_eq: $taskId}, isDeleted: {_eq: false}}
      order_by: {createdOn: desc}
      ) {
      taskId
      comment
      id
      isModified
      createdOn
      createdBy
      isDeleted
    }
  }
`;

export const VALIDATE_MENTIONED_USER_IN_TASK_COMMENT = gql`
  query validateMentionedUserInTaskComment(
    $taskId: uuid!
    $commentId: uuid
    $mentionedUserId: uuid!
  ) {
    taskComments(
      where: {
        id: {_eq: $commentId}
        taskId: {_eq: $taskId}
        isDeleted: {_eq: false}
        mentionedUsers: {userId: {_eq: $mentionedUserId}, isDeleted: {_eq: false}}
      }
    ) {
      id
    }
  }
`;

export const GET_TASK_ORDER_DETAIL_BY_ID = gql`
  query getTaskOrder($externalOrderId: String) {
    taskOrders(where: {externalOrderId: {_eq: $externalOrderId}}) {
      description
      orderType
      orderNote
      orderDate
      orderStatus
      isDeleted
    }
  }
`;

export const GET_TASK_ORDER_BY_IDS = gql`
  query getTaskOrders($externalOrderIds: [String!]) {
    taskOrders(where: {externalOrderId: {_in: $externalOrderIds}}) {
      id
      externalOrderId
      description
      orderType
      orderNote
      orderDate
      orderStatus
      isDeleted
    }
  }
`;

// export const GET_TASK_LOGS = gql`
//   query getTaskLogs($resourceId: uuid!) {
//     activityLogs(
//       where: {resourceId: {_eq: $resourceId}}
//       ) {
//         resourceId
//         resourceTypeCode
//         performedById
//         performedByTypeCode
//         timestamp
//         actionCode
//         data
//     }
//   }
// `;

export const GET_TASK_LOGS = gql`
query getActivityLogsDetail($resourceId: uuid!, $limit: Int, $offset: Int, $orderBy: GetActivityLogOrderByInput, $excludedResourceType: [String])   {
  getActivityLogs(params: {resourceId: $resourceId, limit: $limit, offset: $offset, orderBy: $orderBy, excludedResourceType: $excludedResourceType}) {
    aggregate
    activityLogs {
      transactionId
      actionCode
      id
      parentResourceId
      parentResourceTypeCode
      performedById
      performedByTypeCode
      resourceId
      resourceTypeCode
      source
      timestamp
      parentResourceData {
        oldData
      }
      data {
        newData
        oldData
      }
    }
  }
}`;

export const GET_ATTACHMENT_DETAILS = gql`
query getAttachmentDetailsByAttchmentId($attachmentIds: [uuid!]!) {
  attachments(where: {id: {_in: $attachmentIds}}){
    name
    id
  }
}`;

export const GET_COMMENTS_BY_ID = gql`
query getCommentsById($commentIds: [uuid!]) {
  taskComments(where: {id: {_in: $commentIds}}) {
      comment
      id
  }
}`;

export const CREATE_TASKS = gql`
  mutation CreateMultipleTasks($data: [TaskInput]!) {
    addOrUpdateTasks(params: $data) {
      affected_rows
      returning {
        id
        formId
        assigneeId
      }
    }
  }
`;

export const GET_NON_DELETED_TASK_BY_ID = gql`
  query GetTaskById(
    $id: String
  ) {
    getTasks(
      params: {
        ids: [$id],
        includeDeletedTask: false,
      }
    ) {
      tasks {
        id
        assigneeId
        createdBy
        assigneeTypeCode
        assigneeUser
        assignedById
        assignedByUser
        startDateTime
        endDateTime
        title
        description
        isEscalated
        referenceData
        contactId
        contact
        userPoolId
        userPool
        placeholderTaskId
        placeholderTaskDerivedId
        patientCareJourney
        statusId
        parentId
        status {
          id
          value
          code
        }
        taskType {
          code
          id
          value
        }
        patientEducations {
          patientEducation {
            documentType {
              code
            }
            url
            id
            description
          }
        }
        priorityId
        priority {
          id
          code
          value
        }
        subTasks {
          id
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
          id
          labelId
        }
        taskLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
        resourceMap {
          messages
          notes
          carePlan
        }
      }
      aggregate
    }
  }
`
export const GET_TASK_LINK_NOTE = gql`
  query getSourceMapWithTask($resourceId: uuid!, $sourceTypeCode: String!) {
    resourceMappings(where: {isDeleted: {_eq: false}, resourceId: {_eq: $resourceId}, sourceTypeCode: {_eq: $sourceTypeCode}}) {
      sourceId
      sourceTypeCode
    }
  }
`;

export const GET_TASKS_LINK_WITH_NOTES = gql`
  query getResourceMapWithNote($sourceIds: [uuid!]!) {
    resourceMappings(where: {isDeleted: {_eq: false}, sourceId: {_in: $sourceIds}}) {
      resourceId
      sourceId
    }
  }
`;

export const GET_LABELS_BY_NAME = gql`
  query getLabelsByTitle($names: [String!]) {
    labels(
      where: {
        title: {_in: $names}
        isDeleted: {_eq: false}
      }
    ) {
      id
      uuid
      title
    }
  }
`;

export const GET_TASK_LABELS = gql`
  query GetTasksLabels($taskId: uuid!) {
    taskLabels(where: {taskId: {_eq: $taskId}, isDeleted: {_eq: false}}
      ) {
      id
      labelId
    }
  }
`;

export const VALIDATE_BULK_TASKS = gql`
  query ValidateBulkTasks($data: BulkTaskInput!) {
    bulkTaskInputValidation(params: $data) {
      tasks {
        id
        conflicts {
          conflictCodes
          key
        }
      }
    }
  }
`;

export const PERFORM_BULK_TASK_ACTION = gql`
  mutation BulkTaskUpdate($data: BulkTaskInput!) {
    bulkTaskUpdate(params: $data) {
    	processId
  	}
}
`;

export const BULK_OPERATION_STATUS = gql`
  query GetBulkTaskOperationStatus($transactionId: uuid!, $processedStatusIds: [uuid!]) {
    processed: message_execution_log_aggregate(
      where: {transactionId: {_eq: $transactionId}, statusId: {_in: $processedStatusIds}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

// incomplete query
export const GET_TASK_ORDER_DETAILS = gql`
  query GetTaskOrderDetails($taskId: uuid!) {
    taskOrder(where: {taskId: {_eq: $taskId}, isDeleted: {_eq: false}}) {
      externalOrderId
      orderDate
      orderNote
      orderStatus
    }
  }
`

export const GET_PATIENT_CASE= gql`
  query getPatientCaseData($subjectId: uuid!) {
    contactCases(where: {id: {_eq: $subjectId}, isDeleted: {_eq: false}}) {
      callbackName
      callbackNumber
      contactId
      description
      documentDate
      documentId
      documentStatus
      documentSubject
      documentType
      patientCaseDescription
      priority
      reference
      userId
    }
  }
`

export const GET_PATIENT_REFERRAL= gql`
  query getpatientReferralData($subjectId: uuid!) {
    contactReferrals(where: {id: {_eq: $subjectId}, isDeleted: {_eq: false}}) {
      documentId
      documentDate
      description
      documentStatus
      internalNote
      actionNote
      alarmDays
      performedDate
      providerNote
      icd10
      diagnosis
      contactId
      userId
      reference
      orderType
    }
  }
`
export const GET_TASK_ALERTS = gql`
  query getAlerts($params: GetTasksInput!) {
    getTasks(params: $params) {
      tasks {
        id
        title
        description
        priorityId
        taskTypeId
        statusId
        startDateTime
        status {
          code
        }
        taskActionConfigs {
          taskTypeActionId
        }
        subTasks {
           id
          title
          description
        }
        additionalAttributes
        taskDiagnosisGapDetail {
          hccCategory {
            id
            code
            value
          }
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        taskAlertDisplayPreferences {
          userId
          reasonId
          note
          taskAlertDisplayCategoryId
        }
        referenceData
      }
      aggregate
    }
  }
`;
export const GET_TASK_ALERT_DETAIL = gql`
  query getAlerts($params: GetTasksInput!) {
    getTasks(params: $params) {
      tasks {
        id
        title
        description
        parentId
        assigneeId
        assigneeTypeCode
        assignedById
        startDateTime
        isEscalated
        referenceData
        contactId
        patientCareJourneyId
        placeholderTaskId
        placeholderTaskDerivedId
        createdBy
        patientCareJourney
        resourceMap {
          messages
          notes
          carePlan
        }
        statusId
        status {
          id
          code
          value
        }
        priorityId
        priority {
          id
          code
          value
        }
        subjectId
        patientEducations {
          isDeleted
          patientEducation {
            id
            url
            description
            documentType {
              code
            }
          }
        }
        taskTypeId
        taskType {
          id
          code
          value
        }
        taskDisplayCategoryId
        userPoolId
        startDateTime
        endDateTime
        subTasks {
          id
          title
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
            id
            labelId
        }
        subjectId
        subjectTypeId
        taskLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
        taskCareGapDetail {
          id
          measureIdentifiersId
          evaluationDateTime
          evidenceDescription
          measureIdentifiers {
          measureStewardId
            categoryId
          }
        }
        taskDiagnosisGapDetail {
          id
          hccCategoryId
          diagnosisCode
          evidenceDescription
          diagnosisSystemCode
          hccCategory {
            id
          code
            value
          }
        }
      }
      aggregate
    }
  }
`;

export const GET_SUB_TASK_ALERT = gql`
  query GetSubTaskById(
    $ids: [String!]
  ) {
    getTasks(
      params: {
        ids: $ids,
        includeSubTasks: true
      }
    ) {
      tasks {
        id
        title
        description
        startDateTime
        endDateTime
        statusId
        status {
          value
          code
          id
        }
        assigneeId
        assigneeUser
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        taskDiagnosisGapDetail {
          id
          hccCategoryId
          diagnosisCode
          evidenceDescription
          diagnosisSystemCode
          hccCategory {
            id
          code
            value
          }
        }
      }
    }
  }
`

export const GET_MEASURE_STEWARDS_AND_MEASURE_IDENTIFIERS = gql`
  query getMeasureStewardsAndMeasureIdentifiers($params: GetMeasureStewardsAndMeasureIdentifiersInput) {
    getMeasureStewardsAndMeasureIdentifiers(params: $params) {
    measureStewardAndMeasureIdentifiers {
      measureIdentifiers {
        id
        measureName
      }
    }
    measureStewards {
        id
        name
      }
    }
  }
`;

export const GET_TASK_ALERTS_TYPES = gql`
  query getAlertsType($params: GetTaskTypeInput!) {
    getTaskTypes(params: $params) {
      taskTypes {
        isAlert
        id
        value
        code
        taskTypeActionConfigs {
          taskTypeActionId
        }
      }
    }
  }
`;

export const UPDATE_ALERT_PREFERENCE = gql`
  mutation updateUserTaskAlertDisplayPreference($params: UpdateUserTaskAlertDisplayPreferenceInput!) {
    updateUserTaskAlertDisplayPreference(params: $params) {
      id
    }
  }
`;

export const GET_TASK_ALERT_PREFERENCE_REASONS = gql`
  query getTaskAlertDisplayPreferenceReasonConfigs($params: GetTaskAlertDisplayPreferenceReasonConfigsInput!) {
    getTaskAlertDisplayPreferenceReasonConfigs(params: $params) {
      configs {
        id
        taskAlertDisplayPreferenceReasonId
      sequenceNumber
        taskAlertDisplayPreferenceId
        taskTypeId
      }
    }
  }
`;

export const GET_CONTACTIDS_WHICH_HAVE_ATLEAST_ONE_ALERT =  gql`
  query getAlerts($params: GetTasksInput!) {
    getTasks(params: $params) {
      tasks {
        contactId
      }
      aggregate
    }
  }
`;
export const CREATE_AUDIT_LOG_FOR_ALERT_ACTION = gql`
  mutation AuditTaskActionLog($params: AuditTaskActionLogInput!) {
    auditTaskActionLog(params: $params){
      id
    }
  }
`;

export const GET_TASK_TYPE = gql `query getTaskTypes($searchString:String) {
  getTaskTypes(params: { searchText:$searchString,isAlert: true}) {
    taskTypes {
      id
      code
      value
      isAlert
    }
  }
}`;

export const GET_TASK_COUNTS = gql `
  query GetTaskCounts($params: GetTaskAggregateByFiltersInput!) {
    getTaskAggregateByFilters(params: $params) {
      filterAggregate {
        filterKey
        aggregate
      }
    }
  }
`;

export const GET_TASKS_FOR_DASHBOARD = gql`
  query GetTasks($params: GetTasksInput!) {
    getTasks(params: $params) {
      tasks {
        id
        title
        description
        parentId
        assigneeId
        assigneeTypeCode
        assignedById
        startDateTime
        isEscalated
        referenceData
        contactId
        patientCareJourneyId
        placeholderTaskId
        placeholderTaskDerivedId
        createdBy
        patientCareJourney
        resourceMap {
          messages
          notes
          carePlan
        }
        statusId
        status {
          id
          code
          value
        }
        priorityId
        priority {
          id
          code
          value
        }
        subjectId
        patientEducations {
          isDeleted
          patientEducation {
            id
            url
            description
            documentType {
              code
            }
          }
        }
        taskTypeId
        taskType {
          id
          code
          value
        }
        taskDisplayCategoryId
        userPoolId
        startDateTime
        endDateTime
        subTasks {
          id
        }
        attachments {
          id
          taskId
          attachmentId
          taskDocumentAttachments
        }
        labels {
            id
            labelId
        }
        orders {
          id
          externalOrderId
          description
          orderDate
          orderNote
          orderType
          reference
          subOrderType
          orderStatus
          isModified
        }
        subjectId
        subjectTypeId
        taskLocations {
          id
          locationId
          resourceId
          resourceTypeId
        }
      }
    aggregate
  }
}
`;

export default {
  GET_TASK_TYPE,
  GET_CARE_DASHBOARD_TASKS,
  GET_CONVERSATION_TASK,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_AND_REFERENCE,
  UPDATE_TASK_ASSIGNEE,
  UPDATE_TASK,
  UPDATE_TASK_WORKFLOW,
  GET_TASKS,
  GET_TASK_BY_ID,
  GET_TASK_BY_MULTI_IDS,
  UPDATE_TASK_CATEGORY,
  UPDATE_TASK_DISPLAY_CATEGORY_NAME,
  GET_TASKS_TEAM_COUNT,
  GET_TASKS_COUNT_WITHOUT_TEAM_COUNT,
  CREATE_CONVERSATION_TAGGED_ITEM,
  GET_TASK_ITEM_IDS,
  GET_PROVIDER_TASKS_COUNT,
  GET_ORDER_TASK_NOTES,
  GET_TASKS_COUNT,
  ADD_OR_UPDATE_TASK,
  GET_TASKS_BY_IDS,
  GET_AGGREGATE_TASK_COUNT,
  GET_AGGREGATE_TASK_COUNTS,
  GET_COMPLETED_NOT_COMPLETED_TASKS_COUNT,
  GET_SUB_TASK_BY_ID,
  UPDATE_SUB_TASK,
  ADD_SUB_TASK_BY_PARENT_ID,
  GET_LABELS_BY_IDS,
  ADD_OR_UPDATE_COMMENT,
  GET_TASK_COMMENTS,
  VALIDATE_MENTIONED_USER_IN_TASK_COMMENT,
  GET_TASK_ORDER_DETAIL_BY_ID,
  GET_TASK_ORDER_BY_IDS,
  GET_TASK_LOGS,
  GET_ATTACHMENT_DETAILS,
  GET_COMMENTS_BY_ID,
  CREATE_TASKS,
  GET_NON_DELETED_TASK_BY_ID,
  GET_TASK_LINK_NOTE,
  GET_TASKS_LINK_WITH_NOTES,
  GET_LABELS_BY_TITLE,
  GET_LABELS_BY_NAME,
  GET_TASK_LABELS,
  VALIDATE_BULK_TASKS,
  PERFORM_BULK_TASK_ACTION,
  BULK_OPERATION_STATUS,
  GET_TASK_ORDER_DETAILS,
  GET_PATIENT_CASE,
  GET_PATIENT_REFERRAL,
  GET_TASK_ALERTS,
  GET_TASK_ALERTS_TYPES,
  UPDATE_ALERT_PREFERENCE,
  GET_TASK_ALERT_PREFERENCE_REASONS,
  GET_CONTACTIDS_WHICH_HAVE_ATLEAST_ONE_ALERT,
  CREATE_AUDIT_LOG_FOR_ALERT_ACTION,
  GET_TASK_COUNTS,
  GET_TASKS_FOR_DASHBOARD,
  GET_TASK_ALERT_DETAIL,
  GET_SUB_TASK_ALERT
};
