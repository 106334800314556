import {debounce} from 'lodash';
import {Text, View} from 'native-base';
import React, {memo, useEffect} from 'react';
import {Dimensions, FlatList, StyleSheet} from 'react-native';
import {ITaskDistributionTable, ITaskDistributionWidget} from '../interface';
import {ToolTipView} from './ToolTipView';
import {getFormattedDataForTable, secondsToHM} from './UtilsConst';

const TaskDistributionWidget = (props: {
  widgetData: ITaskDistributionWidget;
  fetchMoreItems?: ()=> void,
  loadingView?: JSX.Element,
  isDrillDown?: boolean;
  isLoading?: boolean;
  isEndReached?: boolean;
}) => {
  const {widgetData, fetchMoreItems, isDrillDown, loadingView, isLoading, isEndReached} = props;
  const formattedData = getFormattedDataForTable(widgetData);
  const filteredData = (formattedData ?? []).filter(
    (item) => item?.name?.trim() && item?.name !== '-'
  );
  const height = Dimensions.get('window').height * 0.69;

  const toolTipView = (singleRowData: ITaskDistributionTable)=> {
    return (
      <View>
        <View style={[styles.toolTipRow, styles.marginBottom8]}>
          <Text size={'smSemibold'} color={'#12B76A'}>COMPLETED</Text>
          <Text size={'smSemibold'} color={'#12B76A'}>{singleRowData.completedTask}</Text>
        </View>
        <View style={styles.toolTipRow}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>ON TIME</Text>
          <Text size={'xsSemibold'} color={'#3A485F'}>{singleRowData.onTimeCompletedTask}</Text>
        </View>
        <View style={styles.toolTipRow}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>AFTER DUE DATE</Text>
          <Text size={'xsSemibold'} color={'#3A485F'}>{singleRowData.afterDueDateCompletedTask}</Text>
        </View>
        <View style={styles.toolTipRow}>
          <Text size={'xsSemibold'} color={'#6F7A90'}>AVG. DELAY</Text>
          <Text size={'xsSemibold'} color={'#3A485F'}>{secondsToHM(singleRowData.completedTaskTime)}</Text>
        </View>
      </View>
    )
  }
  const handleEndReached = debounce(() => {
    if (!isLoading && !isEndReached) {
      fetchMoreItems?.();
    }
  }, 500);
  return (
    <View>
      <View style={styles.headerContainer}>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={[styles.headerText, styles.userHeader]}
        >
          USERS
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={[styles.headerText, styles.taskCompletionHeader]}
        >
          TASK COMPLETION
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={[styles.headerText, styles.totalTasksHeader]}
        >
          TOTAL TASKS
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={[styles.headerText, styles.pendingHeader]}
        >
          PENDING
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={[styles.headerText, styles.missedHeader]}
        >
          MISSED
        </Text>
        <Text
          size={'xsSemibold'}
          color={'#6F7A90'}
          style={[styles.headerText, styles.completedHeader]}
        >
          COMPLETED
        </Text>
      </View>
      <FlatList
          style={{
            maxHeight: isDrillDown ? height : 'auto'
          }}
          data={filteredData || []}
          renderItem={({item}: any) => (
            <View style={styles.rowContainer}>
              <Text
                size={'smMedium'}
                style={[styles.cellText, styles.nameCell, isDrillDown && styles.drillDownPadding]}
                lineBreakMode="clip"
              >
                {item?.name || '--'}
              </Text>
              <View style={styles.taskCompletionContainer}>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={styles.percentageText}
              >
                {item.taskCompletedPercentage}%
              </Text>
              <View style={styles.progressBarContainer}>
                <div style={{
                    height: 8,
                    borderRadius: 25,
                    backgroundColor: '#F6F7F8',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex'
                  }}
>
                  <div
                    style={{
                      height: 8,
                      backgroundColor: '#8DE3D4',
                      width: `${((item.completedTask/item.totalTask)*100).toFixed(2) || 0}%`,
                    }}
                  ></div>
                  <div
                    style={{
                      height: 8,
                      backgroundColor: '#BFD7F6',
                      width: `${((item.missedTask/item.totalTask)*100).toFixed(2)}%`,
                    }}
                  ></div>
                  <div
                    style={{
                      height: 8,
                      backgroundColor: '#80A4D5',
                      width: `${((item.pendingTask/item.totalTask)*100).toFixed(2)}%`,
                    }}
                  ></div>
                </div>
              </View>
              </View>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={[styles.cellText, styles.totalTasksCell]}
              >
                {item.totalTask}
              </Text>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={[styles.cellText, styles.pendingCell]}
              >
                {item.pendingTask}
              </Text>
              <Text
                size={'smMedium'}
                color={'#3A485F'}
                style={[styles.cellText, styles.missedCell]}
              >
                {item.missedTask}
              </Text>
              <ToolTipView overlay={toolTipView(item)}>
              <Text
                size={'smMedium'}
                color={'#8C5AE2'}
                style={[styles.cellText, styles.completedCell]}
              >
                {item.completedTask}
              </Text>
              </ToolTipView>
            </View>
          )}
          keyExtractor={(item: any, index: number) =>
            `${item?.id}_item_${item.name || ''}_${index}`
          }
          onEndReached={handleEndReached}
          onEndReachedThreshold={0.5}
          ListFooterComponent={loadingView}
        />
    </View>
  );
};

const styles = StyleSheet.create({
  toolTipRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  marginBottom8: {
    marginBottom: 8,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderColor: '#D0D6E1',
    borderStyle: 'solid',
    width: '100%',
  },
  headerText: {
    paddingHorizontal: 12,
    paddingVertical: 9,
  },
  userHeader: {
    width: '18%',
  },
  taskCompletionHeader: {
    width: '40%',
  },
  totalTasksHeader: {
    width: '11%',
    paddingLeft: 20,
  },
  pendingHeader: {
    width: '9%',
  },
  missedHeader: {
    width: '9%',
  },
  completedHeader: {
    width: '12%',
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellText: {
    paddingHorizontal: 12,
    paddingVertical: 3,
  },
  nameCell: {
    width: '18%',
    paddingVertical: 4,
  },
  drillDownPadding: {
    paddingVertical: 8,
  },
  taskCompletionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '40%',
    paddingRight: 8,
  },
  percentageText: {
    width: '24%',
    paddingHorizontal: 12,
    paddingVertical: 3,
  },
  progressBarContainer: {
    width: '76%',
  },
  progressBar: {
    height: 8,
    borderRadius: 25,
    backgroundColor: '#F6F7F8',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  progressBarSegment: {
    height: 8,
  },
  completedSegment: {
    backgroundColor: '#8DE3D4',
  },
  missedSegment: {
    backgroundColor: '#BFD7F6',
  },
  pendingSegment: {
    backgroundColor: '#80A4D5',
  },
  totalTasksCell: {
    width: '11%',
    paddingLeft: 20,
  },
  pendingCell: {
    width: '9%',
  },
  missedCell: {
    width: '9%',
  },
  completedCell: {
    width: '12%',
  },
});

export default memo(TaskDistributionWidget);
