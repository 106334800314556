import {Colors} from '../../../../../styles';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import {
  ITableTopBarProps,
  TABLE_TOP_BAR_ACTION_CODES,
} from '../../../../common/TableTopBar';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {IGetProduct, IProductPackageTag, ProductLocation, StripeProductData} from './interfaces';
import {HStack, VStack, Text, View, Tooltip} from 'native-base';
import PackagesActionsView from './PackagesActionsView';
import {getMediaTypeFromMime} from '../../../ContentManagement/MediaLibrary/MediaLibraryUtils';
import {getMediaPreviewElementForList} from '../../../ContentManagement/MediaLibrary/MediaLibraryTable/Helper';
import {LocationDataListView} from './LocationDataListView';
import {styles} from './styles';
import UserSvg from '../../../../common/Svg/UserSvg';
import {Switch} from 'antd';
import {PRODUCT_PACKAGE_STATUS} from './PackagesConst';
import {capitalizeFirstOtherSmallLetter} from '../../../ContentManagement/CampaignManagement/Helper';
import GalleryPlaceholderSvg from '../../../../common/Svg/GalleryPlaceholderSvg';
import {getDateObjectFromAPIFormatToMyFormat} from '../../../../../utils/DateUtils';
import {DATE_FORMATS} from '../../../../../constants';
import {getFormattedExpiryDate, isExpiredDate, packageSorter} from './PackagesUtils';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {PackageTagsView} from './PackagesTagsView';

export const getPackagesTopBarButtons = (
  onActionPerformed: (actioncode: any, rawData: any) => void
): ITableTopBarProps => {
  return {
    title: '',
    buttonList: [
      {
        id: 0,
        btnText: `new`,
        size: 'sm',
        colorScheme: 'primary',
        backgroundColor: Colors.secondary['100'],
        borderColor: Colors.secondary['200'],
        textColor: Colors.secondary['800'],
        variant: '',
        leftIcon: <PlusIcon />,
        btnClick: () => {
          onActionPerformed(TABLE_TOP_BAR_ACTION_CODES.NEW_PACKAGE, '');
        },
      },
    ],
  };
};

export const getPackagesTableColumns = (
  onActionPerformed: (actionCode: string, rowData: any) => void
): any[] => {
  return [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: 350,
      fixed: 'left',
      ellipsis: true,
      render: (name: string, selectedRecord: IGetProduct): JSX.Element => {
        const type = getMediaTypeFromMime(selectedRecord.media.mime || '');
        const url =
          selectedRecord.media.thumbnail || selectedRecord.media.url || '';
        const previewElement = getMediaPreviewElementForList({url, type});
        return (
          <HStack
            style={{
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <View
              style={[
                {
                  height: 60,
                  width: 90,
                  marginRight: 8,
                  borderRadius: 5,
                  overflow: 'hidden',
                },
              ]}
            >
              {url ? (
                previewElement
              ) : (
                <View
                  height={60}
                  width={90}
                  borderColor={Colors.Custom.Gray200}
                  borderWidth={1}
                  borderRadius={4}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <GalleryPlaceholderSvg />
                </View>
              )}
            </View>
            <VStack flex={1}>
              <Tooltip label={name} placement="top">
                <Text
                  size={'smMedium'}
                  noOfLines={1}
                  style={{
                    lineHeight: 24,
                    color: Colors.FoldPixel.GRAY400,
                  }}
                >
                  {name}
                </Text>
              </Tooltip>
              <Text
                size={'smRegular'}
                noOfLines={2}
                style={{
                  color: Colors.FoldPixel.GRAY300,
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: 21,
                }}
              >
                {selectedRecord.description}
              </Text>
            </VStack>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="location"
        />
      ),
      dataIndex: 'productLocation',
      key: 'productLocation',
      width: 180,
      render: (
        productLocation: ProductLocation[],
        selectedRecord: IGetProduct
      ): JSX.Element => {
        return (
          <View width={250} flex={1}>
            <LocationDataListView
              key={selectedRecord.id}
              hideAddMoreButton={true}
              locationList={productLocation || []}
              style={{width: 250}}
            />
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="Tags"
        />
      ),
      dataIndex: 'productPackageTag',
      key: 'productPackageTag',
      width: 250,
      render: (productPackageTag: IProductPackageTag[]): JSX.Element => {
        const filteredProductPackageTag = productPackageTag?.filter((tag) => {
          return tag?.labels?.title && !tag?.labels?.is_deleted;
        });
        return (
          <PackageTagsView productPackageTag={filteredProductPackageTag}/>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="enrolledMembers"
        />
      ),
      dataIndex: 'productPurchaseDetails',
      key: 'productPurchaseDetails',
      width: 150,
      render: (productPurchaseDetails: any[]): JSX.Element => {
        const memberCount = productPurchaseDetails?.length?.toString() || '0';
        return (
          <HStack
            width={`${memberCount?.length * 8 + 40}px`}
            space={1}
            style={[styles.locationList]}
          >
            <View size={4}>
              <UserSvg customStrokeColor={Colors.Custom.Gray400} />
            </View>
            <Text
              size={'smRegular'}
              wordBreak={'break-word'}
              color={Colors.FoldPixel.GRAY300}
            >
              {memberCount}
            </Text>
          </HStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="expiryDate"
        />
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      width: 120,
      render: (text: string): JSX.Element => {
        const formattedDate = getFormattedExpiryDate(text);
        return (
          <VStack>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {formattedDate}
            </Text>
            {text && isExpiredDate(new Date(text)) && (
              <Text
                size={'smRegular'}
                style={{color: 'red'}}
              >
                {'Expired'}
              </Text>
            )}
          </VStack>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="priceTextOnly"
        />
      ),
      dataIndex: 'stripeProductData',
      key: 'stripeProductData',
      width: 85,
      render: (StripeProductData: StripeProductData): JSX.Element => {
        return (
          <View>
            {StripeProductData?.prices?.[0]?.unit_amount ? (
              <Text
                size={'smRegular'}
                color={Colors.FoldPixel.GRAY250}
                fontWeight={500}
              >
                {'$' + StripeProductData?.prices?.[0]?.unit_amount / 100}
              </Text>
            ) : (
              <Text
                size={'smRegular'}
                color={Colors.FoldPixel.GRAY250}
              >
                FREE
              </Text>
            )}
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="lastUpdated"
        />
      ),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      // sorter: packageSorter,
      render: (text: string): JSX.Element => {
        return (
          <View>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {text
                ? getDateObjectFromAPIFormatToMyFormat(
                    text,
                    DATE_FORMATS.CRM_ACTIVITY_DATE_FORMAT
                  )
                : '--'}
            </Text>
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300,}}
          textLocalId="status"
        />
      ),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      fixed: 'right',
      onCell: ()=> {
        return {
          onClick: (e: Event) => {
            e.stopPropagation();
          },
        };
      },
      render: (status: string, selectedRecord: IGetProduct): JSX.Element => {
        const isChecked = status === PRODUCT_PACKAGE_STATUS.PUBLISHED;
        return (
          <View width={100} key={selectedRecord?.id}>
            <Switch
            style={{
              backgroundColor: isChecked
                ? Colors.Custom.AntCalendarDayColor
                : '#BFBFBF',
                width: 90
            }}
              key={selectedRecord?.id}
              checkedChildren={capitalizeFirstOtherSmallLetter(
                PRODUCT_PACKAGE_STATUS.PUBLISHED
              )}
              unCheckedChildren={capitalizeFirstOtherSmallLetter(
                PRODUCT_PACKAGE_STATUS.DRAFT
              )}
              checked={status === PRODUCT_PACKAGE_STATUS.PUBLISHED ? true : false}
              defaultChecked={status === PRODUCT_PACKAGE_STATUS.PUBLISHED ? true : false}
              onChange={(checked) => {
                onActionPerformed(COMMON_ACTION_CODES.STATUS_CHANGED, selectedRecord);
              }}
            />
          </View>
        );
      },
      responsive: ['md'],
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId="actions"
        />
      ),
      key: 'action',
      width: 120,
      fixed: 'right',
      render: (text: any, selectedRecord: IGetProduct) => {
        return (
          <>
            <PackagesActionsView
              selectedRecord={selectedRecord}
              onActionPerformed={(actionCode: string): any => {
                onActionPerformed(actionCode, selectedRecord);
              }}
            />
          </>
        );
      },
      responsive: ['md'],
    },
  ];
};
