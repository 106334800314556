import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Drawer, Select, Steps, notification} from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Content} from 'antd/lib/layout/layout';
import {
  FormControl,
  HStack,
  Input,
  useMediaQuery,
  useToast,
  View,
  VStack,
  Text,
  Spacer,
  Icon,
  Center,
  Pressable,
  Divider,
} from 'native-base';
import {useContext, useEffect, useState} from 'react';
import {
  BUTTON_TYPE,
  DATE_FORMATS,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  SMALL_WINDOW,
} from '../../../../../constants';
import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {ScheduleQueries, UserQueries, ZipCodeQueries} from '../../../../../services';
import {
  createLocation,
  createPracticeLocationDetails,
  getPracticeLocationDetails,
  getPracticeLocations,
  updatePracticeLocationDetails,
} from '../../../../../services/CommonService/AidBoxService';
import {IUserPracticeLocation} from '../../../../../services/Location/interfaces';
import {Colors} from '../../../../../styles';
import { getEHRName, getResourceAbilities } from '../../../../../utils/capabilityUtils';
import { getAccountUUID, isEmptyArray} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {CityAutoComplete} from '../../../../common/City';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionSelect} from '../../../../common/ModalActionCommonComponent/ModalActionSelect';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {StateAutoComplete} from '../../../../common/State';
import {TimezoneSelect} from '../../../../common/TimezoneSelect/TimezoneSelect';
import {ZipCodeAutoComplete} from '../../../../common/ZipCodeComponent';
import {IZipCodeData} from '../../../../common/ZipCodeComponent/interfaces';
import {DEFAULT_TIME_ZONE} from '../../../TeamInbox/Integrations/IntegrationEdit/timeZoneList';
import {styles, reactStyles} from './AddEditUserStyles';
import { v4 as uuidV4 } from 'uuid';
import { ITimezone } from '../../../../../services/Location/interfaces';
import {
  createLocationErrorHandler,
  getFormattedLocationData,
  getPostLocationObject,
  getZipCodeData,
} from './AddEditUserUtils';
import {ehrCodeDisplay, IAddEditUserProps, IComponentStateState, IMapLocationonRequest, ISelectedLocationGroup, LocationData, LocationDetails} from './interfaces';
import { isValidHttpUrl } from '../EditAccount/EditAccountUtils';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import { AddOrUpdateAvailabilitiesCard } from '../../../AccountSettings/PracticeAvailabilityNew/AddOrUpdateAvailabilitiesCard';
import { IAddOrUpdateAvailability, IAvailabilitiesByDaysOfWeek, IAvailabilityCalendarData, IPracticeAvailability, IUserAvailability, IAvailabilityCalendarTableData, IAvailabilitiesByDateRangeKey } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityInterfaces';
import { getFormattedDate } from '../../../../../utils/DateUtils';
import { areAvailabilitiesInvalid, createAvailabilityCalendarGroupData, getDefaultTimezone, groupAvailabilitiesByDaysOfWeek, isAvailabilitiesPresent } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailabilityHelper';
import { IAccountLocation } from '../interfaces';
import UserPracticeLocationQueries from '../../../../../services/Location/UserPracticeLocationQueries';
import { IUser } from '../../../../../Interfaces/CommonInterfaces';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { AVAILABILITY_TYPE_CODES, MLOV_CATEGORY, USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { VIRTUAL_LOCATION_CODE } from '../../../AccountSettings/PracticeAvailabilityNew/PracticeAvailability';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import PlusIcon from '../../../../common/Svg/PlusSvg';
import { updateBusinessHours, updateBusinessHoursInCloudTelephony } from '../../../AutoReplyView/AutoReplyViewUtils';
import { ModalActionAntSelect } from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import CardForLocationAndUser from './components/CardForLocationAndUser';
import { getEhrList } from '../../../../../services/CommonService/IntegrationService';
import DefaultLocationNumber from '../../../../common/DefaultLocationNumber/DefaultLocationNumber';
import { Dimensions } from 'react-native';
import { updateHolidayInTelephonyCache } from '../../../Holidays/HolidayApiService';
import TeamQueries from '../../../../../services/Team/TeamQueries';
import {LOCATION_WITH_SAME_NAME_EXIST} from './constants';
import useLocationGroup from '../../../../common/MemebersView/customHook/useLocationGroup';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
const {Step} = Steps;


const AddNewPracticeLocation = (props: IAddEditUserProps) => {
  const {onClose} = props;
  const accountUuid = getAccountUUID();
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const [cleared, setCleared] = useState(false)
  const [current, setCurrent] = useState(0);
  const screenDimensions = Dimensions.get('window');
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const {
    locationGroups,
    loading: locationGroupLoading,
    error: locationGroupError,
  } = useLocationGroup();
  const locationGroupId = !isMsoEnabled && locationGroups.length === 1 ? locationGroups?.[0]?.code : ''
  const [stateData, setStateData] = useState({
    loading: true,
    practiceLocations: [] as IUserPracticeLocation[],
    showDrawer: false,
    locationList: [] as LocationData[],
    selectedLocation: {} as LocationData,
    locationDetails: [] as LocationDetails[],
    selectedLocationGroup: {
      id: props?.selectedLocation?.locationGroup?.id,
    } as any,
  });

  const [errors, setErrors]: any = useState({
    errors: {} as any,
    validate: true,
  });
  const [formDataState, setFromDataState] = useState({
    accountLocationId: '',
    name: '',
    phone: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    zipCodeId: '',
    stateId: '',
    stateName: '',
    cityId: '',
    cityName: '',
    countryId: '',
    googleMapLink: '',
    timeZone: DEFAULT_TIME_ZONE,
  } as { selectedTimezone?: ITimezone} & any );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const API_ERROR_MESSAGE = 'Something went wrong, Please try again later';
  const locationCapability = getResourceAbilities(
    FHIR_RESOURCE.LOCATION,
    stateData?.selectedLocationGroup?.id,
    ''
  );
  const isLocationEnabled = locationCapability?.isEnabled || false;

  const [ componentState, setComponentState ] = useState<IComponentStateState>({
    searchUserText: '',
    //selectedPracticeLocation: props.inboxData?.inbox?.inboxAvailabilities[0]?.accountLocationUuid,

    availabilitiesByDaysOfWeek: {} as IAvailabilitiesByDaysOfWeek,
    overriddenAvailabilitiesByDateRangeKey: undefined,
    updatedAvailabilitiesByLocalId: {},
    showErrors: false,
  });
  const [accountLocationState, setAccountLocationState] = useState({
    uuid: '',
    id: undefined,
    practiceLocationId: undefined,
  });

  const availabilityTypes = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.AVAILABILITY_TYPE) || [];
  const practiceScheduleAvailabilityTypeId = getMlovIdFromCode( availabilityTypes, AVAILABILITY_TYPE_CODES.PRACTICE_SCHEDULE);
  const defaultIAvailability: IAddOrUpdateAvailability = {
    isDeleted: false,
    localId: '',
    typeId: practiceScheduleAvailabilityTypeId,
    locationId: accountLocationState.uuid ||  props.selectedLocation?.uuid,
  };

  const [ehrList, setEhrList] = useState<ehrCodeDisplay[]>([]);
  const currentCodeEHR: any = getEHRName('', stateData?.selectedLocationGroup?.id);

  const getEhrDisplayName = (ehrCode?: string) => {
    return (ehrList || []).find(ehr => ehr.code === (ehrCode || currentCodeEHR))?.display || '';
  };

  const setEhr = async () => {
    try {
      const ehrResponse = await getEhrList();
      if (ehrResponse?.data?.expansion?.contains?.length > 0) {
        setEhrList(ehrResponse?.data?.expansion?.contains);
      }
    } catch (error) {
    }
  };

  const [GetLocations] = useLazyQuery(TeamQueries.getLocationByAccount, {
    fetchPolicy: 'no-cache',
  });

  const {refetch} = useQuery(
    UserPracticeLocationQueries.GetTimezoneOfAccountAndLocations, {
      variables: { tenantId: accountUuid,
        roleCode: USER_ROLE_CODES.EMPLOYER
      },
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        let accountLocations: IAccountLocation[] = [];
        let timezones: ITimezone[] = [];
        let accountUsers: IUser[] = [];

        accountLocations = res?.accountLocations?.length ? res.accountLocations : [];
        timezones = res?.timezones?.length ? res.timezones : [];
        accountUsers = res?.users?.length ? res.users : [];

        accountUsers = accountUsers?.filter((item) => {
          let roles = '';
          item.userRoles?.forEach((element: any) => {
            roles += element?.userRole?.userRole?.code;
          });
          if (!roles.includes('WORKFLOW') && !roles.includes('CUSTOMER_SUCCESS') ) {
            return item;
          }
        });

        const defaultTimezone = getDefaultTimezone(res?.accountTimezones?.[0]?.timezone, res?.timezones);

        setComponentState((prev: any) => {
          const availabilityCalendarData: IAvailabilityCalendarData[] = createAvailabilityCalendarGroupData({
            practiceAvailabilities: prev.practiceAvailabilities,
            timezones,
            accountLocations,
            accountUsers,
            isUserSchedule: false,
          });
          return {
            ...prev,
            availabilityCalendarData,
            accountLocations,
            accountUsers,
            timezones,
            //defaultTimezone,
          };
        });
      },
    }
  );

  const [CREATE_PRACTICE_LOCATION] = useMutation(
    UserQueries.CREATE_PRACTICE_LOCATION
  );
  const [CREATE_ACCOUNT_LOCATION] = useMutation(
    UserQueries.CREATE_ACCOUNT_LOCATION
  );

  const [UPDATE_PRACTICE_LOCATION] = useMutation(
    UserQueries.UPDATE_PRACTICE_LOCATION
  );
  const [UPDATE_ACCOUNT_LOCATION] = useMutation(
    UserQueries.UPDATE_ACCOUNT_LOCATION
  );
  const [UPDATE_ACCOUNT_LOCATION_CONTAINING_MAPPED_LOCATION] = useMutation(
    UserQueries.UPDATE_ACCOUNT_LOCATION_CONTAINING_MAPPED_LOCATION
  );

  const [getZipCodeByCode] = useLazyQuery(ZipCodeQueries.getZipCodeByCode);
  const [addUpdateAvailabilitiesAPI, {loading: isAddUpdateScheduleLoading}] = useMutation(ScheduleQueries.ADD_UPDATE_SCHEDULE, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const checkLocationWithNameExist = async (name: string) => {
    try {
      const locationData = await GetLocations({
        variables: {
          accountUuid: accountUuid,
          limit: 2,
          offset: 0,
          searchString: `%${name}%`,
        },
      });
      if (locationData?.data?.accountLocations?.length && locationData?.data?.accountLocations?.length > 1) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (props?.showDrawer) {
      onAddScheduleBtnClick();
      fetchAvailabilityData();
    }
  }, [props?.showDrawer]);
  useEffect(()=> {
    if (locationGroupId) {
      getLocationList(locationGroupId);
      fetchAccountDetails(locationGroupId);
      setStateData(prev=> {
        return {
          ...prev,
          selectedLocationGroup: {
            id: locationGroupId
          }
        }
      })
    }
  },[locationGroupId])
  useEffect(()=> {
    if(props?.isEdit){
      setFromDataState((prev: any) => {
        return {
          ...prev,
           accountLocationId: props?.selectedLocation?.id,
           uuid: props?.selectedLocation?.uuid,
           name: props?.selectedLocation?.practiceLocation?.name,
           addressLine1: props?.selectedLocation?.practiceLocation?.addressLine1,
           addressLine2: props?.selectedLocation?.practiceLocation?.addressLine2,
           cityId: props?.selectedLocation?.practiceLocation?.cityUuid,
           stateId: props?.selectedLocation?.practiceLocation?.stateUuid,
           zipCodeId: props?.selectedLocation?.practiceLocation?.zipcodeUuid,
           zipCode: props?.selectedLocation?.practiceLocation?.practiceZipcode?.code,
           timeZone: props?.selectedLocation?.practiceLocation?.timezoneUuid,
           countryId: props?.selectedLocation?.practiceLocation?.countryUuid,
           googleMapLink: props?.selectedLocation?.googleMapLink,
           selectedTimezone: {
            uuid: props?.selectedLocation?.practiceLocation?.timezoneUuid
           } as ITimezone,
        };
      });
    }
  },[props?.isEdit])

  function fetchAvailabilityData() {
    getAvailabilitiesAPI();
  }

  useEffect(() => {
    setTimeout(()=> {
      if(!isGetAvailabilitiesAPILoading && props?.isEdit){
        editAvailabilitiesByLocationOrUser();
      }
    }, 1000)
  }, [props?.isEdit && componentState.availabilityCalendarData?.length]);

  const onCancel = () => {
    onClose();
    setStateData((prev) => {
      return {
        ...prev,
        showDrawer: false,
      };
    });
  };

  function editAvailabilitiesByLocationOrUser() {
    const availabilityCalendarDataObject = componentState.availabilityCalendarData?.find((data) => {
      const id = data['locationData']?.practiceLocation.id;
      if (id && id === props.selectedLocation?.practiceLocation?.id) {
        return true;
      }
    });
    if (availabilityCalendarDataObject) {
      setComponentState((prev) => ({
        ...prev,
        availabilitiesByDaysOfWeek: JSON.parse(JSON.stringify(availabilityCalendarDataObject.availabilitiesByDaysOfWeek)),
        overriddenAvailabilitiesByDateRangeKey: undefined,
        selectedUserData: undefined,
      }));
    }
  }

  function getPracticeAvailabilityWhereCondition() {
    let whereCondition: any = {};
    // if (props.isInboxHours) {
    //   whereCondition = {
    //     isDeleted: {_eq: false},
    //     // inboxId: {_eq: props.inboxData.inbox.uuid},
    //     // typeId: {_eq: inboxAvailabilityTypeId},
    //     _or: [
    //       {endDate: {_is_null: true}},
    //       {endDate: {_gte: (new Date().toLocaleDateString())}},
    //     ],
    //   };
    // } else {
      whereCondition = {
        isDeleted: {_eq: false},
        typeId: {_eq: practiceScheduleAvailabilityTypeId},
        userId: {_is_null: true},
        locationId: {_eq: accountLocationState.uuid ||  props.selectedLocation?.uuid },
      };

      // if (!props.isUserSchedule) {
      //   whereCondition.userId = {_is_null: true};
      // }
    //}

    return whereCondition;
  }

  const [ getAvailabilitiesAPI, { loading: isGetAvailabilitiesAPILoading }] = useLazyQuery(ScheduleQueries.GET_PRACTICE_SCHEDULE, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      whereCondition: getPracticeAvailabilityWhereCondition(),
    },
    onCompleted: (response) => {
      setComponentState((prev) => {
        const availabilityCalendarData: IAvailabilityCalendarData[] = createAvailabilityCalendarGroupData({
          practiceAvailabilities: response?.schedules || [],
          timezones: (prev.timezones || []),
          accountLocations: prev.accountLocations,
          accountUsers: prev.accountUsers,
          isUserSchedule: false,
        });
        return {
          ...prev,
          practiceAvailabilities: response?.schedules?.length ? response.schedules : [],
          availabilityCalendarData,
        };
      });
    },
    onError: (error) => {

      setComponentState((prev) => ({
        ...prev,
        practiceAvailabilities: [],
      }));
      showToast(toast, API_ERROR_MESSAGE, ToastType.error);
    },
  }
);

  function onAddScheduleBtnClick() {
    const availabilities = componentState.availabilityCalendarData?.reduce((prevValue, data) => {
      if (data.availabilities?.length) {
        prevValue = prevValue.concat(data.availabilities);
      }
      return prevValue;
    }, [] as IUserAvailability[]);

    const availabilitiesByDaysOfWeek = groupAvailabilitiesByDaysOfWeek(availabilities || [], true);

    setComponentState((prev) => ({
      ...prev,
      availabilitiesByDaysOfWeek: JSON.parse(JSON.stringify(availabilitiesByDaysOfWeek)),
      selectedUserData: undefined,
    }));
  }

  const validates = (formData: any) => {
    const errors: any = {};
    let validate = true;
    const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    errors.name = !formData.name ? 'name is required' : '';
    errors.addressLine1 = !formData.addressLine1
      ? 'Adressline1 is required'
      : '';
    errors.zipCodeId = !formData.zipCodeId ? 'Zipcode is required' : '';

    if (formData.email && !formData.email.match(emailRegex)) {
      errors.email = 'Invalid email';
    }
    if (formData.googleMapLink && !isValidHttpUrl(formData.googleMapLink)) {
      errors.googleMapLink = 'Please enter valid google map link';
    }
    if (!formData.cityId) {
      errors.cityId = 'City is not set'
    }
    if (!formData.stateId) {
      errors.stateId = 'State is not set'
    }

    if (errors.cityId || errors.stateId || errors.name || errors.addressLine1 || errors.zipCodeId || !formDataState.selectedTimezone?.uuid) {
      validate = false;
    }
    return {validate: validate, errors: errors};
  };


  function onBusinessHoursSave() {
    setComponentState((prev) => ({ ...prev, showErrors: true}));

    const availabilities = Object.values(componentState.updatedAvailabilitiesByLocalId || {});
    if (areAvailabilitiesInvalid(availabilities)) {
      return;
    }

    // availabilities = availabilities.filter((availability) => {
    //   return !availability.isDeleted || availability.id;
    // });

    if (!availabilities.length) {
      showToast(toast, 'No records found to update', ToastType.success );
    }

    // if (props.isUserSchedule && !props.selectedUserData?.uuid) {
    //   return props.onSave({ isSuccess: false });
    // }

    // if (props.isUserSchedule && props.availabilitiesByDaysOfWeek.isPracticeScheduleApplied) {
    //   const isPracticeSchedulesChanged = availabilities.some((availability) => {
    //     if (!availability.isOverrideAvailability) {
    //       return true;
    //     }
    //     return false;
    //   });

    //   if (isPracticeSchedulesChanged) {
    //     componentState.availabilitiesByDaysOfWeek.data.forEach((data) => {
    //       data.availabilities.forEach((availability) => {
    //         if (!componentState.updatedAvailabilitiesByLocalId[availability.localId]) {
    //           componentState.updatedAvailabilitiesByLocalId[availability.localId] = availability;
    //         }
    //       });
    //     });
    //     availabilities = Object.values(componentState.updatedAvailabilitiesByLocalId);
    //   }
    // }

    // if (props.isUserSchedule) {
    //   availabilities = availabilities.map((availability) => {
    //     const userAvailability = {
    //       ...availability,
    //       userId: props.selectedUserData?.uuid,
    //     };
    //     if (!availability.isOverrideAvailability && (props.availabilitiesByDaysOfWeek.isPracticeScheduleApplied || !availability.userId)) {
    //       delete userAvailability.id;
    //     }
    //     return userAvailability
    //   })
    // }

    const deletedAvailabilities: IPracticeAvailability[] = [];
    const updatedAvailabilities: IPracticeAvailability[] = [];
    const allAvailabilities: IPracticeAvailability[] = []

    availabilities.forEach((availability) => {
      if (availability.isDeleted && !availability.id) {
        return;
      }
      const isVirtualAvailability = availability.locationId === VIRTUAL_LOCATION_CODE || availability.isVirtualLocation;
      const practiceAvailability: IPracticeAvailability = {
        id: availability.id,
        beginDate: availability.beginDate,
        endDate: availability.endDate,
        startTime: availability.startTime,
        endTime: availability.endTime,
        daysOfWeek: availability.daysOfWeek,
        isVirtualLocation: isVirtualAvailability,
        locationId: isVirtualAvailability ? null : availability.locationId,
        timezoneId: availability.timezoneId,
        typeId: availability.typeId,
        userId: availability.userId,
        inboxId: availability.inboxId,
        isDeleted: availability.isDeleted,
      }
      if (availability.isDeleted) {
        deletedAvailabilities.push(practiceAvailability);
      } else {
        updatedAvailabilities.push(practiceAvailability)
        allAvailabilities.push({
          ...practiceAvailability,
          timezone:
            availability.timezone ||
            (componentState.timezones &&
              componentState.timezones.find(
                (item) => item.uuid == availability.timezoneId
              )),
        });
      }
    });

    if (!deletedAvailabilities.length && !updatedAvailabilities?.length) {
      showToast(toast, 'No records found to update', ToastType.success );
    }

    setComponentState((prev) => {
      return {
        ...prev,
        isSaveLoading: true,
      }
    });

    addUpdateAvailabilitiesAPI({
      variables: {
        data: [
          ...deletedAvailabilities,
          ...updatedAvailabilities
        ],
      },
      onCompleted: async () => {
        setComponentState((prev) => ({ ...prev, isSaveLoading: false }));
        await updateBusinessHours({data: allAvailabilities})
        await updateBusinessHoursInCloudTelephony({data: allAvailabilities})
        showToast(toast, intl.formatMessage({id: 'scheduleUpdated'}), ToastType.success );
      },
      onError: (error) => {
        setComponentState((prev) => ({ ...prev, isSaveLoading: false }));
        showToast(toast, 'Something went wrong', ToastType.error );
      }
    });
  }
  const isLocationCreationRequired = async (name: string) => {
    const isLocationWithSameNameExist = await checkLocationWithNameExist(name);
    if (isLocationWithSameNameExist) {
      notification.error({
        message: LOCATION_WITH_SAME_NAME_EXIST,
      });
      setIsSubmitting(false);
      return true; 
    }
    return false;
  };
  const handleSubmit = async () => {
    switch (current) {
      case 0: {
        if (!validates(formDataState).validate) {
          setErrors((prev: any) => {
            return {
              ...prev,
              errors: validates(formDataState).errors,
            };
          });
        } else {
          setIsSubmitting(true);
          const defaultTimezone = getDefaultTimezone(
            {} as ITimezone,
            componentState?.timezones
          );
          setComponentState((prev) => ({
            ...prev,
            defaultTimezone: formDataState?.selectedTimezone || defaultTimezone,
          }));
          if (!props?.isEdit && !(accountLocationState.id || accountLocationState.uuid)) {
            const locationName = formDataState.name || stateData.selectedLocation.name
            const locationExists = await isLocationCreationRequired(locationName);
            if (locationExists) return; 

            if (isLocationEnabled && getMappedLocation()) {
              await createLocationUsingIntegration(
                stateData?.selectedLocationGroup?.id
              );
              return;
            }
            if (!accountLocationState.practiceLocationId) {
              const createPracticeLocationRes = await CREATE_PRACTICE_LOCATION({
                variables: {
                  name: formDataState.name,
                  addressLine1: formDataState.addressLine1,
                  addressLine2: formDataState.addressLine2,
                  cityUuid: formDataState.cityId,
                  stateUuid: formDataState.stateId,
                  zipcodeUuid: formDataState.zipCodeId,
                  countryUuid: formDataState.countryId,
                  timeZoneUuid: formDataState.selectedTimezone?.uuid,
                },
              });
              if (
                createPracticeLocationRes?.data?.createPracticeLocation?.uuid
              ) {
                const createAccountLocation = await CREATE_ACCOUNT_LOCATION({
                  variables: {
                    accountUuid: accountUuid,
                    locationUuid:
                      createPracticeLocationRes?.data?.createPracticeLocation
                        ?.uuid,
                    googleMapLink: formDataState.googleMapLink,
                    locationGroupId: stateData?.selectedLocationGroup?.id,
                  },
                });
                if (createAccountLocation?.data?.createAccountLocation?.uuid) {
                  const accountLocation =
                    createAccountLocation?.data?.createAccountLocation;
                  setIsSubmitting(false);
                  setAccountLocationState({
                    uuid: accountLocation?.uuid,
                    id: accountLocation?.id,
                    practiceLocationId:
                      createPracticeLocationRes?.data?.createPracticeLocation
                        ?.id,
                  });
                  refetch();
                  fetchAvailabilityData();
                }
              } else {
              }
            }
          } else {
            //if edit location
            const updatePracticeLocationRes = await UPDATE_PRACTICE_LOCATION({
              variables: {
                id: props.selectedLocation?.practiceLocation?.id ?? accountLocationState.practiceLocationId,
                name: formDataState.name,
                addressLine1: formDataState.addressLine1,
                addressLine2: formDataState.addressLine2,
                cityUuid: formDataState.cityId,
                stateUuid: formDataState.stateId,
                zipcodeUuid: formDataState.zipCodeId,
                countryUuid: formDataState.countryId,
                timeZoneUuid: formDataState.selectedTimezone?.uuid,
              },
            });
            if (updatePracticeLocationRes?.data?.updatePracticeLocation?.uuid) {
              const addOrUpdateRes: any =
                await updateHolidayInTelephonyCache().catch((err: any) => {
                  showToast(
                    toast,
                    intl.formatMessage({id: 'apiErrorMsg'}),
                    ToastType.error
                  );
                });
              const accountLocationId = props?.selectedLocation?.uuid || accountLocationState.uuid;
              const selectedLocationUuid = stateData.selectedLocation.id || getExternalId(accountLocationId)?.externalId;
              const getUpdateLocationParams = () => {
                const updateLocationParams: any = {
                  id:
                    accountLocationState.id ?? formDataState.accountLocationId,
                  googleMapLink: formDataState.googleMapLink,
                };
                return updateLocationParams;
              };

              const selectedEhrLocation = stateData.locationDetails.find(
                (location) => location.foldId === accountLocationId
              );

              if (!accountLocationId) {
                return;
              }

              const updateIntegrationLocationReq: IMapLocationonRequest = {
                resourceName: 'Location',
                foldId: accountLocationId,
                externalId: selectedLocationUuid,
              };

              if (
                selectedEhrLocation &&
                !stateData.selectedLocation?.id &&
                cleared
              ) {
                updateIntegrationLocationReq.isDeleted = true;
                await updatePracticeLocationDetails(
                  updateIntegrationLocationReq,
                  stateData?.selectedLocationGroup?.id
                );
              } else if (
                selectedEhrLocation &&
                stateData.selectedLocation?.id
              ) {
                updateIntegrationLocationReq.displayName =
                  stateData.selectedLocation?.name;
                await updatePracticeLocationDetails(
                  updateIntegrationLocationReq,
                  stateData?.selectedLocationGroup?.id
                );
              } else if (
                !selectedEhrLocation &&
                stateData.selectedLocation?.id
              ) {
                updateIntegrationLocationReq.displayName =
                  stateData.selectedLocation?.name;
                await createPracticeLocationDetails(
                  updateIntegrationLocationReq,
                  stateData?.selectedLocationGroup?.id
                );
              }

              const updateAccountLocation = await UPDATE_ACCOUNT_LOCATION({
                variables: getUpdateLocationParams(),
              });
              if (
                updateAccountLocation?.data?.updateAccountLocations
                  ?.returning?.[0]?.uuid
              ) {
                // onClose();
                const accountLocation =
                  updateAccountLocation?.data?.updateAccountLocations
                    ?.returning?.[0];
                setIsSubmitting(false);
                setAccountLocationState({
                  uuid: accountLocation?.uuid,
                  id: accountLocation.id,
                  practiceLocationId: updatePracticeLocationRes?.data?.updatePracticeLocation?.id
                });
                refetch();
                fetchAvailabilityData();
              }
            } else {
            }
          }
          setCurrent(current + 1);
        }
        return;
      }
      default:
        break;
    }
    setIsSubmitting(true);
    onBusinessHoursSave();
    setIsSubmitting(false);
    setStateData((prev) => {
      return {
        ...prev,
        showDrawer: false,
      };
    });
    onClose();

  };

  const getLocationList = async (locationId: string | undefined) => {
    try {
      const response = await getPracticeLocations(locationId);
      const list = getFormattedLocationData(response.data);
      setStateData((prev) => {
        return {
          ...prev,
          locationList: list,
        };
      });
    } catch (error) {
      setErrors((prev: any) => {
        return {
          ...prev,
          location: 'Error in fetching the locations',
        };
      });
    }
  };

  const fetchAccountDetails = async (locationGroupId: string | undefined) => {
    try {
      const response = await getPracticeLocationDetails(locationGroupId);
      const list = response?.data as LocationDetails[];
      setStateData((prev) => {
        return {
          ...prev,
          locationDetails: list,
        };
      });
    } catch (error) {
      setErrors((prev: any) => {
        return {
          ...prev,
          locationDetails: 'Error in fetching the locations details',
        };
      });
    }
  }

  const createLocationUsingIntegration = async (locationGroupId: string | undefined) => {
    let postalCode: any = stateData?.selectedLocation?.postalCode || '';
    if (stateData?.selectedLocation?.postalCode) {
      postalCode = postalCode?.substring?.(0, 5) || '';
    }
    try {
      const zipcodeData = await getZipCodeByCode({
        variables: {
          code: postalCode || formDataState?.zipCode || '',
        },
      });
      const zipCodes = zipcodeData?.data?.zipcodes || [];
      if (isEmptyArray(zipCodes)) {
        showToast(toast, 'Selected zipcode is not available', ToastType.error);
        setStateData((prev) => {
          return {
            ...prev,
            showDrawer: false,
          };
        });
        return;
      }
      const {postalCodeId, countryId, stateId, cityId} =
        getZipCodeData(zipcodeData);
      const body = getPostLocationObject({
        ...stateData.selectedLocation,
        postalCodeId: postalCodeId,
        countryId: countryId,
        stateId: stateId,
        timeZoneId: formDataState.selectedTimezone?.uuid,
        cityId: cityId,
        name: formDataState.name
      });
      const response = await createLocation(body, locationGroupId);
      const uuid = response?.data?.reference?.locationId
      setIsSubmitting(false);
      setAccountLocationState((prev) => {
        return {
          ...prev,
          uuid: uuid,
        }
      });
      refetch();
      fetchAvailabilityData();
      //onClose();
      setCurrent(current + 1);
    } catch (error) {
      createLocationErrorHandler({toast, error});
      setIsSubmitting(false);
      setStateData((prev) => {
        return {
          ...prev,
          showDrawer: false,
        };
      });
      onClose();
    }
  };

  useEffect(() => {
    if (isLocationEnabled) {
      setEhr()
    }
    if (props?.isEdit || accountLocationState.uuid) {
      getLocationList(props?.selectedLocation?.locationGroup?.id);
      if (isLocationEnabled) {
        fetchAccountDetails(props?.selectedLocation?.locationGroup?.id);
      }
    }
    setTimeout(() => {
      setStateData((prev) => {
        return {
          ...prev,
          showDrawer: true,
        };
      });
    }, 100);
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const drawerWidth =
    isIPadMiniScreen || isIPadScreen
      ? '60vw'
      : screenDimensions?.width < SMALL_WINDOW
      ? '60%'
      : '42%';

  function onAddNewCard(isOverrideAvailability: boolean) {
    setComponentState((prev: any) => {
      const cardId = uuidV4();

      const defaultAvailability: IAddOrUpdateAvailability = {
        ...defaultIAvailability,
        localId: uuidV4(),
        locationId: accountLocationState.uuid ||  props.selectedLocation?.uuid,
        timezoneId: componentState.defaultTimezone?.uuid,
      };

      if (isOverrideAvailability) {
        if (!prev.overriddenAvailabilitiesByDateRangeKey) {
          return prev;
        }
        const beginDate = new Date();
        const endDate = new Date();

        defaultAvailability.daysOfWeek = '[]';
        defaultAvailability.isOverrideAvailability = true;
        defaultAvailability.beginDate = getFormattedDate(beginDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);
        defaultAvailability.endDate = getFormattedDate(endDate, DATE_FORMATS.AVAILABILITY_DATE_FORMAT);

        prev.overriddenAvailabilitiesByDateRangeKey?.data.unshift({
          availabilities: [ defaultAvailability ],
          localId: cardId,
          beginDate,
          endDate,
          timezoneId: componentState.defaultTimezone?.uuid || '',
        });

        prev.overriddenAvailabilitiesByDateRangeKey.isAvailabilitiesPresent = true;

      } else {
        const selectedDaysOfWeek = [ 1, 2, 3, 4, 5 ];
        defaultAvailability.daysOfWeek = JSON.stringify(selectedDaysOfWeek);

        prev.availabilitiesByDaysOfWeek.data.unshift({
          availabilities: [ defaultAvailability ],
          localId: cardId,
          selectedDaysOfWeek,
          timezoneId: componentState.defaultTimezone?.uuid || '',
        });
        prev.availabilitiesByDaysOfWeek.isAvailabilitiesPresent = true;
      }

      prev.updatedAvailabilitiesByLocalId[defaultAvailability.localId] = defaultAvailability;

      return { ...prev };
    });
  }
  const accountLocations = componentState.accountLocations || [];
  // if (props.isUserSchedule) {
  //   accountLocations = extractAccountLocationsFromUserPracticeLocations(props.selectedUserData?.userPracticeLocations || []);
  // }
  function onUpdateAvailabilities(availabilities: IAddOrUpdateAvailability[]) {
    setComponentState((prev: any) => {
      availabilities.forEach((availability) => {
        prev.updatedAvailabilitiesByLocalId[availability.localId] = availability;
      });

      prev.availabilitiesByDaysOfWeek.isAvailabilitiesPresent = isAvailabilitiesPresent(prev.availabilitiesByDaysOfWeek);
      return { ...prev };
    });
  }

  const getMappedLocation = () => {
    if (Object.keys(stateData?.selectedLocation)?.length > 0) {
      return `${stateData?.selectedLocation?.name}${getEhrDisplayName(currentCodeEHR) ? `(${getEhrDisplayName(currentCodeEHR)})` : '' }`
    }
    const selectedLocationId = props?.selectedLocation?.uuid || "";
    const value = stateData.locationDetails.find((location) => location.foldId === selectedLocationId)?.displayName
    return value ? `${value}${getEhrDisplayName(currentCodeEHR) ? `(${getEhrDisplayName(currentCodeEHR)})` : '' }` : ''
  }
  const getExternalId = (accountLocationId: string) => {
    return stateData.locationDetails.find((location) => location.foldId === accountLocationId)
  }

  const filterEhrLocations = () => {
    const selectedLocationId = props?.selectedLocation?.uuid || "";
    return stateData.locationList.filter((location)=> {
      return !(stateData.locationDetails.some((mappedLocation) => mappedLocation.externalId === location.id && mappedLocation.foldId !== selectedLocationId))
    })
  }

  const renderPracticeLocation = (): JSX.Element => {
    const filteredEhrLocations = filterEhrLocations();
    return (
      <Content>
        <View style={styles.marginTop32} mx={0}>
          <VStack>
          {isMsoEnabled && (
              <ModalActionAntSelect
                allowClear={true}
                showSearch={false}
                label={'groupLocation'}
                placeholder={'Please select'}
                onChange={(value: any, data: any) => {
                  if (data?.key) {
                    getLocationList(data?.key);
                    fetchAccountDetails(data?.key);
                  }
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedLocationGroup: {
                        id: data?.key,
                        groupName: data?.children,
                      },
                    };
                  });
                }}
                customStyle={{
                  width: '100%',
                }}
                disabled={!props?.isEdit ? false : true}
                value={props?.selectedLocation?.locationGroup?.groupName}
                mode="single"
                data={locationGroups}
                optionProps={{key: 'code', label: 'display'}}
              />
            )}
            {isLocationEnabled && (
              <VStack>
                <ModalActionAntSelect
                label='mapLocationToEhr'
                infoText={intl.formatMessage({id: 'mapLocationToEhrText'})}
                errors={errors.errors?.location ? true : false}
                errorText={errors.errors?.location}
                customStyle={{
                  width: '100%',
                }}
                value={
                  cleared ? '' : getMappedLocation()
                }
                allowClear={getMappedLocation()}
                data={filteredEhrLocations}
                onChange={(value: string) => {
                  if (!value) {
                    setCleared(true)
                    return
                  }
                  value && setCleared(false);
                  const selectedLocation = filteredEhrLocations.find(
                    (item) => item.id === value
                  );
                    setStateData((prev) => {
                      return {
                        ...prev,
                        selectedLocation:
                          selectedLocation || ({} as LocationData),
                      };
                    });
                    setFromDataState((prev: any) => {
                      return {
                        ...prev,
                        addressLine1: selectedLocation?.line1,
                        name: selectedLocation?.name,
                        zipCode: selectedLocation?.postalCode,
                        stateName: selectedLocation?.state,
                        cityName: selectedLocation?.city,
                      };
                    });
                    setErrors((prev: any) => {
                      return {
                        ...prev,
                        errors: {},
                      };
                    });
                }}
                selectItemProps={{
                  key: 'id',
                  value: 'id',
                  label: 'name',
                }}
                optionsArray={
                  filteredEhrLocations &&
                  filteredEhrLocations.map((option: any, index) => {
                    return (
                      <Select.Option label={option?.name} key={option?.id} value={option?.id} className={'addNewPracticeOption'}>
                        <CardForLocationAndUser
                          name={option.name}
                          uuid={option.id}
                          isSearchComponent={true}
                          ehrName={getEhrDisplayName(currentCodeEHR)}
                        />
                        {
                          index < filteredEhrLocations.length - 1 ? <Divider marginX={'4'} color={Colors.Custom.Gray200} /> : null
                        }
                      </Select.Option>
                    );
                  })
                }
                dropdownStyle={{
                  borderRadius: 20,
                }}
              />
              </VStack>
            )}
            <FormControl
              style={styles.formElement}
              isInvalid={errors.errors.name}
              isRequired
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smRegular'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250}}
                  textLocalId={'name'}
                />
              </FormControl.Label>
              <Input
                _focus={{
                    borderColor: Colors.Custom.Gray200
                }}
                value={formDataState.name}
                placeholder={'Enter Name'}
                onChangeText={(value) => {
                  setFromDataState((prev: any) => {
                    return {
                      ...prev,
                      name: value,
                    };
                  });
                  setErrors((prev: any) => {
                    return {
                      ...prev,
                      errors: {},
                    };
                  });
                }}
              />
              {errors.errors.name && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {errors.errors.name}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            {false &&
              <HStack flex={1} space={1}>
                <FormControl
                  flex={0.5}
                  style={styles.formElement}
                  isInvalid={errors.errors.phone}
                  //isRequired
                >
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                      textLocalId={'phone'}
                    />
                  </FormControl.Label>
                  <Input
                    _focus={{
                                borderColor: Colors.Custom.Gray200
                    }}
                    value={formDataState.phone}
                    placeholder={intl.formatMessage({id: 'enterPhone'})}
                    onChangeText={(value) => {
                      setFromDataState((prev: any) => {
                        return {
                          ...prev,
                          phone: value,
                        };
                      });
                      setErrors((prev: any) => {
                        return {
                          ...prev,
                          errors: {},
                        };
                      });
                    }}
                  />
                  {errors.errors.phone && (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {errors.errors.phone}
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  flex={0.5}
                  style={styles.formElement}
                  isInvalid={errors.errors.email}
                  //isRequired
                >
                  <FormControl.Label style={styles.formLabel}>
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{color: Colors.Custom.Gray700}}
                      textLocalId={'email'}
                    />
                  </FormControl.Label>
                  <Input
                    _focus={{
                                borderColor: Colors.Custom.Gray200
                    }}
                    value={formDataState.email}
                    placeholder={intl.formatMessage({id: 'enterPhone'})}
                    onChangeText={(value) => {
                      setFromDataState((prev: any) => {
                        return {
                          ...prev,
                          email: value,
                        };
                      });
                      setErrors((prev: any) => {
                        return {
                          ...prev,
                          errors: {},
                        };
                      });
                    }}
                  />
                  {errors.errors.email && (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {errors.errors.email}
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>
              </HStack>
              }

            <FormControl
              style={styles.formElement}
              isRequired
              isInvalid={errors.errors.addressLine1}
            >
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smRegular'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250}}
                  textLocalId={'addressLine1'}
                />
              </FormControl.Label>
              <Input
                _focus={{
                                borderColor: Colors.Custom.Gray200
                }}
                value={formDataState.addressLine1}
                placeholder={'Enter Address 1'}
                onChangeText={(value) => {
                  setFromDataState((prev: any) => {
                    return {
                      ...prev,
                      addressLine1: value,
                    };
                  });
                  setErrors((prev: any) => {
                    return {
                      ...prev,
                      errors: {},
                    };
                  });
                }}
              />
              {errors.errors.addressLine1 && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {errors.errors.addressLine1}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
            <FormControl style={styles.formElement}>
              <FormControl.Label style={styles.formLabel}>
                <DisplayText
                  size={'smRegular'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250}}
                  textLocalId={'addressLine2'}
                />
              </FormControl.Label>
              <Input
                _focus={{
                                borderColor: Colors.Custom.Gray200
                }}
                value={formDataState.addressLine2}
                placeholder={'Enter Address 2'}
                onChangeText={(value) => {
                  setFromDataState((prev: any) => {
                    return {
                      ...prev,
                      addressLine2: value,
                    };
                  });
                }}
              />
            </FormControl>
            <HStack flex={1} space={1}>
              <FormControl
                flex={0.5}
                style={[styles.formElement, styles.zipCode]}
                isRequired
                isInvalid={errors.errors.zipCodeId}
              >
                <View>
                  <ZipCodeAutoComplete
                    sendZipCodeChange
                    name={formDataState.zipCode}
                    onAutoCompleteChange={(selectedZipCode: IZipCodeData) => {
                      setFromDataState((prev: any) => {
                        return {
                          ...prev,
                          zipCodeId: selectedZipCode?.id,
                          stateId: selectedZipCode?.stateId,
                          stateName: selectedZipCode?.zipcodeState?.name,
                          cityId: selectedZipCode?.cityId,
                          countryId: selectedZipCode?.zipcodeState?.countryId,
                          cityName: selectedZipCode?.zipcodeCity?.name,
                        zipCode: selectedZipCode?.code || ''
                        };
                      });
                      setErrors((prev: any) => {
                        return {
                          ...prev,
                          errors: {},
                        };
                      });
                    }}
                    flex={1}
                  />
                </View>
                {errors.errors.zipCodeId && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {errors.errors.zipCodeId}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>

              {true && (
                <View flex={0.5} mt={1.5}>
                  <TimezoneSelect
                    showLabel={true}
                    isRequired={true}
                    selectedTimezoneId={formDataState.selectedTimezone?.uuid}
                    onChange={(timezone?: ITimezone) => {
                      setFromDataState((prev: any) => ({
                        ...prev,
                        selectedTimezone: timezone,
                      }));
                    }}
                  />
                </View>
              )}
            </HStack>

            <HStack flex={1} space={1}>
              <FormControl flex={0.5} style={[styles.formElement, styles.city]}>
                <CityAutoComplete
                  isInvalid={errors.errors.cityId ? true : false}
                  errors={errors.errors}
                  errorText={errors.errors.cityId}
                  isTabDisabled={true}
                  name={formDataState.cityName}
                  onAutoCompleteChange={(selectedId) => {
                    setFromDataState({
                      ...formDataState,
                      cityId: selectedId,
                    });
                  }}
                />
              </FormControl>
              <FormControl flex={0.5} style={[styles.formElement, styles.city]}>
                <StateAutoComplete
                  isInvalid={errors.errors.stateId ? true : false}
                  errors={errors.errors}
                  errorText={errors.errors.stateId}
                  isTabDisabled={true}
                  name={formDataState.stateName}
                  onAutoCompleteChange={(selectedId) => {
                    setFromDataState({
                      ...formDataState,
                      stateId: selectedId,
                    });
                  }}
                />
              </FormControl>
            </HStack>

          {true &&
              <FormControl
                style={styles.formElement}
                isInvalid={errors.errors.googleMapLink}
                //isRequired
              >
                <FormControl.Label style={styles.formLabel}>
                  <DisplayText
                    size={'smRegular'}
                    extraStyles={{color: Colors.FoldPixel.GRAY250}}
                    textLocalId={'googleMapLink'}
                  />
                </FormControl.Label>
                <Input
                  _focus={{
                                borderColor: Colors.Custom.Gray200
                  }}
                  value={formDataState.googleMapLink}
                  placeholder={'Enter Google Map Link'}
                  onChangeText={(value) => {
                    setFromDataState((prev: any) => {
                      return {
                        ...prev,
                        googleMapLink: value,
                      };
                    });
                    setErrors((prev: any) => {
                      return {
                        ...prev,
                        errors: {},
                      };
                    });
                  }}
                />
                {errors.errors.googleMapLink && (
                  <FormControl.ErrorMessage
                    _text={{
                      fontSize: 'xs',
                      color: 'error.500',
                      fontWeight: 500,
                    }}
                  >
                    {errors.errors.googleMapLink}
                  </FormControl.ErrorMessage>
                )}
              </FormControl>
            }
            <FormControl
              style={styles.formElement}
              //isRequired
            >
              <FormControl.Label style={[styles.formLabel,{marginBottom: 6}]}>
                <DisplayText
                  size={'smMedium'}
                  extraStyles={{color: Colors.FoldPixel.GRAY400}}
                  textLocalId={'defaultCommunicationNumber'}
                />
              </FormControl.Label>
              <DisplayText
                textLocalId="setDefaultLocationInfo"
                size="xsMedium"
                extraStyles={{
                  color: Colors.Custom.Gray500,
                  marginBottom: 6,
                  marginLeft: 4,
                }}
              />
              <DefaultLocationNumber
                accountLocationId={props.selectedLocation?.uuid || ''}
                locationId={props.selectedLocation?.locationUuid || ''}
                locationName={props.selectedLocation?.practiceLocation?.name || ''}
              />
            </FormControl>
          </VStack>
        </View>
      </Content>
    );
    };

    const renderBusinessHours = (): JSX.Element => {
      return (
        <VStack>
          <HStack space={2}>
            <Spacer />
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress: () => {
                  onAddNewCard(false);
                },
                style: {marginLeft: 20},
                leftIcon: (
                  <PlusIcon />
                ),
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'practiceAvailabilityAddSlots',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
            {/* {
              props.isUserSchedule &&
              <ModalActionBtn
                onClickAction={() => {
                  onAddNewCard(true);
                }}
                btnText={'practiceAvailabilityScheduleOverride'}
                btnStype={BUTTON_TYPE.PRIMARY}
                textColor={Colors.secondary[800]}
                leftIcon={
                  <Icon
                    as={AntIcon}
                    name={'plus'}
                    size="4"
                    color={Colors.secondary['800']}
                  />
                }
              />
            } */}
          </HStack>
          <VStack>
            {componentState.overriddenAvailabilitiesByDateRangeKey
              ?.isAvailabilitiesPresent &&
              componentState.overriddenAvailabilitiesByDateRangeKey.data?.map(
                (data) => {
                  const availabilities = data.availabilities;
                  const localId = data.localId;
                  const beginDate = data.beginDate;
                  const endDate = data.endDate;

                  return (
                    <AddOrUpdateAvailabilitiesCard
                      key={localId}
                      cardId={localId}
                      disabled={props?.isEdit || !!accountLocationState.uuid}
                      isOverrideAvailability={true}
                      beginDate={beginDate}
                      endDate={endDate}
                      selectedTimezoneId={data.timezoneId}
                      accountLocations={accountLocations}
                      availabilities={availabilities}
                      defaultTimezone={
                        componentState.defaultTimezone || ({} as ITimezone)
                      }
                      timezones={componentState.timezones || []}
                      defaultAvailability={defaultIAvailability}
                      onUpdateAvailabilities={onUpdateAvailabilities}
                      showErrors={componentState.showErrors}
                    />
                  );
                }
              )}

            {componentState.availabilitiesByDaysOfWeek
              ?.isAvailabilitiesPresent &&
              componentState.availabilitiesByDaysOfWeek.data?.map((data) => {
                const availabilities = data.availabilities;
                const localId = data.localId;
                const selectedDaysOfWeek = data.selectedDaysOfWeek;
                return (
                  <AddOrUpdateAvailabilitiesCard
                    key={localId}
                    cardId={localId}
                    disabled={props?.isEdit || !!accountLocationState.uuid}
                    isOverrideAvailability={false}
                    selectedDaysOfWeek={selectedDaysOfWeek}
                    selectedTimezoneId={data.timezoneId}
                    accountLocations={accountLocations}
                    availabilities={availabilities}
                    defaultTimezone={
                      componentState.defaultTimezone || ({} as ITimezone)
                    }
                    timezones={componentState.timezones || []}
                    defaultAvailability={defaultIAvailability}
                    onUpdateAvailabilities={onUpdateAvailabilities}
                    showErrors={componentState.showErrors}
                  />
                );
              })}
            {!componentState.availabilitiesByDaysOfWeek
              ?.isAvailabilitiesPresent &&
              !componentState.overriddenAvailabilitiesByDateRangeKey
                ?.isAvailabilitiesPresent && (
                <Center
                  height={654}
                  width="full"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontWeight={200} fontSize="lg" textAlign="center">
                    No schedule present.
                    <Text>
                      {'\n'}Please click{' '}
                      <Pressable
                        onPress={() => {
                          onAddNewCard(false);
                        }}
                      >
                        <Text color="primary.500">here</Text>
                      </Pressable>{' '}
                      to add new schedule.
                    </Text>
                  </Text>
                </Center>
              )}
          </VStack>
        </VStack>
      );
      };

  const steps = [
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{
            backgroundColor: current == 0 ?  Colors.primary[400] : Colors.Custom.Gray200,
             borderRadius: 8,
             color: current == 0 ? 'white' : 'black',
            padding: 10,
            }}
        >
          {'Practice location'}
        </Text>
      ),
      content: renderPracticeLocation(),
    },
    {
      title: (
        <Text
          size={'xsMedium'}
          style={{
            backgroundColor: current == 1 ?  Colors.primary[400] : Colors.Custom.Gray200,
             borderRadius: 8,
             color: current == 1 ? 'white' : 'black',
            padding: 10,
            }}
        >
          {'Business hours'}
        </Text>
      ),
      content: renderBusinessHours(),
    },
  ];

  return (
    <Drawer
      visible={stateData.showDrawer}
      style={reactStyles.drawer}
      width={drawerWidth}
      onClose={() => {
        onClose();
      }}
      title={
        <>
          <ModalActionTitle
            title={props?.isEdit ? 'editPracticeLocation' : 'addPracticeLocation'}
            titleSize={'28px'}
            buttonList={[
              {
                show: current === 1 ?  true : false,
                id: 3,
                btnText: 'previous',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  setCurrent(current - 1);
                },
              },
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onCancel();
                },
              },
              {
                show: true,
                id: 2,
                btnText: props.isEdit && current !== 1 ? 'next': current === 1 ? 'submit': 'saveAndNext',
                textColor: Colors.Custom.mainPrimaryPurple,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  handleSubmit();
                },
                isLoading: isSubmitting,
              },
            ]}
          />
        </>
      }
    >
    <Steps
        style={reactStyles.steps}
        //progressDot={renderProgressDot}
        labelPlacement="vertical"
        current={current}
      >
        {steps.map((item, index) => (
          <Step
            key={index + 'step'}
            title={item.title}
          />
        ))}
      </Steps>
      <View>{steps[current].content}</View>
    </Drawer>
  );
};

export default AddNewPracticeLocation;
