import {useMutation} from '@apollo/client';
import {Editor} from '@toast-ui/react-editor';
import {Drawer} from 'antd';
import {
  Center,
  FormControl,
  Input,
  Select,
  Skeleton,
  Text,
  TextArea,
  View,
  VStack,
} from 'native-base';
import React, {useEffect, useState} from 'react';
import {Dimensions, StyleSheet} from 'react-native';
import {BUTTON_TYPE, RESPONSE_CATEGORY_CODE} from '../../../../../constants';
import ResponseQueries from '../../../../../services/Responses/ResponseQueries';
import {Colors} from '../../../../../styles';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {IEditResponseProps} from '../interfaces';

export const EditResponse = (props: IEditResponseProps) => {
  const getSelectedCategory = (itemId: string) => {
    const selectedCategory = props.categoryList?.find((item) => {
      return item.id == itemId;
    });
    return selectedCategory;
  };

  const [templateData, setTemplateData] = useState({
    shortCode: props.editRecordData?.shortCode,
    content: props.editRecordData?.content,
    id: props.editRecordData?.id,
    categoryId: props.editRecordData?.type_id,
    categoryCode: getSelectedCategory(props.editRecordData?.type_id)?.code,
    modalVisible: true,
  });

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const editorRef: any = React.createRef();
  const [updateResponse] = useMutation(ResponseQueries.UpdateResponse);

  const validate = () => {
    setErrors({});
    if (templateData.shortCode == '') {
      setErrors({shortCodeError: 'Please fill field'});
      return false;
    }
    if (templateData.content == '') {
      setErrors({contentError: 'Please fill field'});
      return false;
    }
    return true;
  };

  const handleResponseUpdate = async () => {
    setLoading(true);
    const updateRespObj = await updateResponse({
      variables: {
        content: templateData.content,
        shortCode: templateData.shortCode,
        id: templateData.id,
        categoryCode: templateData.categoryId,
      },
    });
    if (updateRespObj?.data?.updateCannedResponse?.content) {
      setLoading(false);
      setTemplateData((prev) => {
        return {
          ...prev,
          modalVisible: false,
        };
      });
      props.handleCloseAfterUpdateModal();
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    setTemplateData((prev) => {
      return {
        ...prev,
        shortCode: props.editRecordData?.shortCode,
        content: props.editRecordData?.content,
        id: props.editRecordData?.id,
        categoryId: props.editRecordData?.type_id,
        categoryCode: props.editRecordData?.type?.code,
      };
    });
  }, [props.editRecordData.id]);
  const {width} = Dimensions.get('window');
  const finalWidth = 800;
  return (
    <Center>
      <Drawer
        width={finalWidth}
        visible={templateData.modalVisible}
        onClose={() => {
          setTemplateData((prev) => {
            return {
              ...prev,
              modalVisible: false,
            };
          });
          props.handleCloseModal();
        }}
        closable={false}
        title={<ModalActionTitle
          text={`Edit Response - ${templateData.shortCode}`}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                setTemplateData((prev) => {
                  return {
                    ...prev,
                    modalVisible: false,
                  };
                });
                props.handleCloseModal();
              },
            },
            {
              show: true,
              id: 2,
              btnText: 'submit',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: () => {
                if (validate()) {
                  handleResponseUpdate();
                }
              },
            },
          ]}
        />}
      >
        {loading ? (
          <VStack height={'100%'}>
            <Skeleton padding={10} height="60%" />
            <Skeleton.Text padding={10} />
          </VStack>
        ) : (
          <>
            <View mx={0}>
              <FormControl>
                <FormControl.Label>
                  <DisplayText textLocalId="shortCode" size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} />
                </FormControl.Label>
                <Input
                _focus={{borderColor: '#D0D5DD'}}
                  size={'mdNormal'}
                  value={templateData?.shortCode}
                  placeholder="Enter Short Code"
                  onChangeText={(value) => {
                    setTemplateData({...templateData, shortCode: value});
                  }}
                />
                <Text fontSize={'xs'} color={'error.500'}>
                  {errors.shortCodeError}
                </Text>
              </FormControl>
              <FormControl style={styles.marginVertical5}>
                <FormControl.Label>
                  <DisplayText textLocalId="category" size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} />
                </FormControl.Label>
                <Select
                  selectedValue={templateData.categoryId}
                  placeholder="Select Category"
                  onValueChange={(itemValue) => {
                    const selectedCategory = getSelectedCategory(itemValue);
                    setTemplateData({
                      ...templateData,
                      categoryId: itemValue,
                      categoryCode: selectedCategory?.code || '',
                    });
                  }}
                >
                  {props.categoryList?.map((item) => {
                    return (
                      <Select.Item
                        key={item.id}
                        label={item.value}
                        value={item.id}
                      />
                    );
                  })}
                </Select>
                <Text size={'smLight'} color={'error.500'}>
                  {errors.categoryError}
                </Text>
              </FormControl>
              <FormControl marginTop={6}>
                <FormControl.Label>
                  <DisplayText textLocalId="content" size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} />
                </FormControl.Label>
                {templateData.categoryCode == RESPONSE_CATEGORY_CODE.CHAT ||
                  templateData.categoryCode == RESPONSE_CATEGORY_CODE.EMAIL ? (
                    <div className="template-response-editor">
                      <Editor
                        placeholder=""
                        initialValue={templateData?.content}
                        height="420px"
                        initialEditType="wysiwyg"
                        useCommandShortcut={true}
                        previewHighlight={false}
                        ref={editorRef}
                        onKeyup={(editorType: any, event: KeyboardEvent) => {
                          const keyCode = event.which || event.keyCode;

                          if (keyCode === 13 && !event.shiftKey) {
                            event.preventDefault();
                            event.stopPropagation();
                            // handleAddResponse();
                          }
                        }}
                        onChange={() => {
                          if (editorRef && editorRef?.current) {
                            const inst = editorRef.current.getInstance();
                            const plainString = inst.getMarkdown();
                            setTemplateData((prev) => {
                              return {
                                ...prev,
                                content: plainString,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <View paddingTop={2}>
                      <TextArea
                        defaultValue={templateData.content}
                        variant={'outline'}
                        value={templateData.content}
                        style={styles.textAreaStyle}
                        height={65}
                        maxH={100}
                        width={'100%'}
                        autoComplete="off"
                        multiline={true}
                        ref={editorRef}
                        onKeyPress={(event: any) => {
                          const keyCode = event.which || event.keyCode;
                          if (keyCode === 13 && !event.shiftKey) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        onChangeText={(plainString) => {
                          setTemplateData({
                            ...templateData,
                            content: plainString,
                          });
                        }}
                      />
                    </View>
                  )}
                <Text fontSize={'xs'} color={'error.500'}>
                  {errors.contentError}
                </Text>
              </FormControl>
            </View>
          </>
        )}
      </Drawer>
    </Center>
  );
};

const styles = StyleSheet.create({
  marginVertical5: {
    marginVertical: 5,
  },
  marginTop6: {
    marginTop: 6,
  },
  paddingTop2: {
    paddingTop: 2,
  },
  textAreaStyle: {
    color: Colors.Custom.TextColor,
    fontSize: 18,
  },
});