import {ColumnsType} from 'antd/es/table';
import {ColumnType} from 'antd/lib/table';
import moment from 'moment';
import {HStack, Icon, Text, View, VStack} from 'native-base';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {getEnvVariable, isDemoEnv} from '../../../constants/BaseUrlConst';
import {IMlov} from '../../../Interfaces';
import {Colors} from '../../../styles';
import {getTenantForDemo, isAthenaSandboxTenant, isEmptyObject, isLoginUserBusinessOwner, isMultiTenancyEnabled, isSandboxEnv, numericStringMask} from '../../../utils/commonUtils';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {getUSLocaleDate} from '../../PersonOmniView/MiddleContainer/FormResponseTableView/Helper';
import {EmployeeCount} from './EmployeeCount';
import {EmployerTableActions} from './EmployerTableActions';
import {
  CSVData,
  CSVHeaders,
  IEmployerConsent,
  IEmployerContact,
  IEmployerData,
  IEmployerTableColumnsArgs,
} from './interfaces';
import { Tooltip } from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useIntl } from 'react-intl';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { useContext } from 'react';
import { DEAL_STATUS_LIST, DEAL_STATUS_LIST_FOR_DEMO, getTenantForDemo as isDemoTenant } from '../Analytics/EmployerAnalytics/tempDataFile';
import { getMlovListByCategory } from '../../../utils/mlovUtils';

const isDemoMode = getTenantForDemo();

export const getFormattedEmployerData = (
  data: any,
  dealStatusList: IMlov[],
  managers: any[],
  statusString?: string
): IEmployerData[] => {
  const demoPercentage = [
    {
      consentPercentage: 95,
      cost: 100,
    },
    {
      consentPercentage: 70,
      cost: 80,
    },
    {
      consentPercentage: 50,
      cost: 70,
    },
  ];
  let index = 0;
  const formattedData = data?.map((singleEmployer: any) => {
    const statusId = singleEmployer?.employerDeal?.[0]?.statusId;
    const statusInfo = dealStatusList.find((value: IMlov) => {
      if (value?.id == statusId) {
        return true;
      }
    });

    const managerId = singleEmployer?.employerDeal?.[0]?.managerId;
    const managerInfo = managers.find((value: any) => {
      if (value?.uuid == managerId) {
        return true;
      }
    });
    const employeeCount = singleEmployer?.employee_aggregate?.aggregate?.count;
    const consentValue = employeeCount && demoPercentage?.[index]?.consentPercentage ? Math.floor((employeeCount * demoPercentage[index].consentPercentage) / 100): null;
    const consentPercentage = employeeCount && demoPercentage?.[index]?.consentPercentage ? demoPercentage[index].consentPercentage: null;
    const lastBilling = employeeCount && demoPercentage?.[index]?.consentPercentage ? Math.floor(employeeCount * demoPercentage?.[index]?.consentPercentage): null;

    index++;
    if (index === demoPercentage.length) {
      index = 0
    }

    return {
      raw: singleEmployer,
      id: singleEmployer?.id,
      name: singleEmployer?.name,
      contact: {
        name: singleEmployer?.employerDeal?.[0]?.contactEmployee?.contact?.name,
        email:
          singleEmployer?.employerDeal?.[0]?.contactEmployee?.contact?.email,
      },
      members: employeeCount || 0,
      consent: {
        value: isDemoMode ? consentValue ? consentValue :'200' : '-',
        percent: isDemoMode ? consentPercentage ? consentPercentage : '39' : '-',
      },
      lastBilling: isDemoMode ? lastBilling ? lastBilling : '343423' : ' -',
      accountManager: {
        name: managerInfo?.name || '-',
        email: managerInfo?.email || '-',
      },
      lastInteraction: isDemoMode ? '' : null,
      status: statusInfo?.value,
    };
  });

  if (statusString) {
    return formattedData?.filter((data: any) => {
      if (data?.status == statusString) {
        return data;
      } else if (statusString == 'All') {
        return data;
      }
    });
  }

  return formattedData;
};

export const getEmployerColumns = (args: IEmployerTableColumnsArgs): any => {
  const {actionFn} = args;
  const intl = useIntl();
  const context = useContext(CommonDataContext);
  const userSettings = context.userSettings;
  const isEnabledMultiTenancy = isMultiTenancyEnabled(userSettings);
  const isBusinessOwner = isLoginUserBusinessOwner()

  const columns: ColumnsType<any> = [
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId= {args?.isVbcTenant? "contractName":"employerName"}
        />
      ),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '15%',
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (name: string,record:any) => {
        return (
          <VStack style={{paddingHorizontal: 10,}}>
          <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
            {name.length < 30 ? name : name.slice(0, 30) + '...'}
          </Text>
          </VStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="contactPerson"
        />
      ),
      dataIndex: 'contact',
      key: 'contact',
      width: '15%',
      sorter: (a: any, b: any) =>
        a?.contact?.name?.localeCompare(b?.contact?.name),
      render: (contact: IEmployerContact) => {
        return (
          <VStack>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
              {contact.name}
            </Text>
            <Text size={'smNormal'} color={Colors.FoldPixel.GRAY250}>
              {contact.email}
            </Text>
          </VStack>
        );
      },
    },
    {
      title: (
        <HStack space={2}>
          <DisplayText
            size={'smMedium'}
            extraStyles={{color: Colors.Custom.Gray500}}
            textLocalId={args?.isVbcTenant ? "members": "employees"}
          />
          {isEnabledMultiTenancy && !isBusinessOwner && (
            <Tooltip title={intl.formatMessage({id: 'employerMemberCountsTooltip'})} placement="top">
              <Icon as={AntIcon} name="infocirlceo" size="smSmall" color={Colors.Custom.Gray500} />
            </Tooltip>
          )}
        </HStack>
      ),
      dataIndex: 'members',
      key: 'members',
      width: '10%',
      sorter:true,
      render: (members: string, record: IEmployerData) => {
        return <EmployeeCount employerId={record.id || ''} count={record.members || 0} />;
      },
    },
  ];
  if (isDemoMode) {
    columns.push(
      {
        title: (
          <DisplayText
            size={'smMedium'}
            extraStyles={{ color: Colors.Custom.Gray500 }}
            textLocalId="consent"
          />
        ),
        dataIndex: 'consent',
        key: 'consent',
        width: '10%',
        render: (consent: IEmployerConsent) => {
          return (
            <HStack
              style={{
                paddingHorizontal: 10,
                paddingVertical: 2,
                width: 'max-content',
                borderRadius: 16,
                backgroundColor:
                  consent.percent && parseInt(consent.percent) > 50
                    ? Colors.success[50]
                    : Colors.error[50],
              }}
            >
              <Text
                size={'smMedium'}
                color={
                  consent.percent && parseInt(consent.percent) > 50
                    ? Colors.success['700']
                    : Colors.error['700']
                }
              >
                {consent.value}
              </Text>
              <Text
                size={'smMedium'}
                color={
                  consent.percent && parseInt(consent.percent) > 50
                    ? Colors.success['700']
                    : Colors.error['700']
                }
              >
                {` (${consent.percent}%)`}
              </Text>
            </HStack>
          );
        },
      }
    )
  }
  if (isDemoMode && !args?.isVbcTenant) {
    columns.push({
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{ color: Colors.Custom.Gray500 }}
          textLocalId="lastBilling"
        />
      ),
      dataIndex: 'lastBilling',
      key: 'lastBilling',
      width: '10%',
      render: (lastBilling: string) => {
        return (
          <Text size={'smMedium'} color={Colors.Custom.Gray500}>
            {`$${lastBilling.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          </Text>
        );
      },
    })
  }
  columns.push(
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="accountManager"
        />
      ),
      dataIndex: 'accountManager',
      key: 'accountManager',
      width: '20%',
      render: (accountManager: IEmployerContact) => {
        return (
          <VStack>
            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
              {accountManager.name}
            </Text>
            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250}>
              {accountManager.email}
            </Text>
          </VStack>
        );
      },
    },
  )
  if (isDemoMode) {
    columns.push(
      {
        title: (
          <DisplayText
            size={'smMedium'}
            extraStyles={{ color: Colors.Custom.Gray500 }}
            textLocalId="lastInteraction"
          />
        ),
        dataIndex: 'lastInteraction',
        key: 'lastInteraction',
        width: '10%',
        render: (lastInteraction: string) => {
          return (
            <Text size={'smMedium'} color={Colors.Custom.Gray500}>
              {lastInteraction
                ? moment(lastInteraction).format('MMM DD, yyyy')
                : '-'}
            </Text>
          );
        },
      },
    )
  }
  columns.push(
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="status"
        />
      ),
      key: 'status',
      width: '10%',
      filters: [
        {text: 'Active', value: 'Active'},
        {text: 'Not Qualified', value: 'Not Qualified'},
        {text: 'Lost', value: 'Lost'},
      ],
      // onFilter: (value: any, record: IEmployerData) => {
      //   return record.status?.indexOf(value) == 0;
      // },
      filterMultiple: false,
      render: (record: IEmployerData) => {
        return (
          <HStack
            style={{
              paddingVertical: 2,
            }}
          >
            <View style={{flex: 1, padding: 0}}>
              <View
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 2,
                  borderRadius: 16,
                  width: 'max-content',
                  backgroundColor: Colors.success[50],
                }}
              >
                <Text size={'smRegular'} color={Colors.success[700]}>
                  {record.status}
                </Text>
              </View>
            </View>
          </HStack>
        );
      },
    },
    {
      title: (
        <DisplayText
          size={'smMedium'}
          extraStyles={{color: Colors.Custom.Gray500}}
          textLocalId="actions"
        />
      ),
      fixed: 'right',
      width: '10%',
      render(value, record, index) {
        return (
          <EmployerTableActions record={record} onActionPerformed={actionFn} />
        );
      },
    },
  )

  return columns;
};

export const csvHeaders: CSVHeaders[] = [
  {label: 'First Name', key: 'firstName'},
  {label: 'Middle Name', key: 'middleName'},
  {label: 'Last Name', key: 'lastName'},
  {label: 'Email', key: 'email'},
  {label: 'Sex at birth', key: 'sexAtBirth'},
  {label: 'Phone Number', key: 'phoneNumber'},
  {label: 'Birth Date', key: 'birthDate'},
  {label: 'Location', key: 'location'},
  {label: 'Address', key: 'address'},
  {label: 'Zipcode', key: 'zipCode'}
];

export const getFormattedMemberDataForCSV = (data: any) => {
  const mask = `(###) ###-####`;
  const employees = data.employees || [];
  const result: CSVData[] = [];
  employees.forEach((item: any) => {
    const contact = item?.contact || {};
    const person = item?.contact?.person || {};
    const {address, location, zipCode} = getAddressAndLocation(contact);
    const birthDate = getUSLocaleDate(person?.birthDate);
    result.push({
      firstName: person?.firstName || '',
      lastName: person?.lastName || '',
      middleName: person?.middleName || '',
      address: address,
      birthDate: birthDate,
      email: contact.email || '',
      sexAtBirth: person?.sexAtBirth?.value,
      location: location,
      phoneNumber: contact?.phoneNumber ? numericStringMask(contact.phoneNumber, mask) :  '',
      zipCode: zipCode,
    });
  });
  return result;
};

export const getAddressAndLocation = (
  contact: any
): {
  address: string;
  location: string;
  zipCode: string;
} => {
  const result: {
    address: string;
    location: string;
    zipCode: string;
  } = {
    address: '',
    location: '',
    zipCode: '',
  };
  if (isEmptyObject(contact)) {
    return result;
  }
  const personAddress = contact.personAddress || [];
  const contactPracticeLocations = contact.contactPracticeLocations || [];
  if (personAddress.length > 0) {
    result.address = personAddress[0]?.line1 || '';
    result.zipCode = personAddress[0]?.zipcodes?.code || '';
  }
  if (contactPracticeLocations.length > 0) {
    result.location = contactPracticeLocations[0]?.practiceLocation?.name || '';
  }
  return result;
};

export const getAddressLine = (location: any) => {
  const addressName = location?.name ? location?.name + ', ' : ''
  const addressLine1 = location?.addressLine1 ? location?.addressLine1 + ', ' : ''
  const addressLine2 = location?.addressLine2 ? location?.addressLine2 + ', ' : ''
  const city = location?.practiceCity ? location?.practiceCity?.name + ', ' : ''
  const state = location?.practiceState ? location?.practiceState?.name + ', ' : ''
  const country = location?.practiceCountry ? location?.practiceCountry?.name + ', ' : ''
  const zipCode = location?.practiceZipcode ? location?.practiceZipcode.code + '.' : ''
  const result = addressName + addressLine1 + addressLine2 + city + state + country + zipCode
  return result
}

export const openEmployerScreenDrawers = (viewCode: string): boolean => {
  const check =
    viewCode === COMMON_ACTION_CODES.CREATE_EMPLOYER ||
    viewCode === COMMON_ACTION_CODES.MANAGE_DEAL ||
    viewCode == COMMON_ACTION_CODES.QUICK_CREATE;
  return check;
};

export const getDealStatusList = () => {
  const dealStatusList = getMlovListByCategory('DealStatus');
  if (isDemoTenant() || isAthenaSandboxTenant()) {
    return dealStatusList.filter((item) => {
      return DEAL_STATUS_LIST_FOR_DEMO.includes(item.code);
    });
  }
  if (isSandboxEnv()) {
    return dealStatusList.filter((item) => {
      return DEAL_STATUS_LIST.includes(item.code);
    });
  }
  return dealStatusList;
};

export const getSicCodeList = () => {
  const sicCodeList = getMlovListByCategory(
    'StandardIndustrialClassificationCode'
  );
  const dealStatusList = getMlovListByCategory('DealStatus');
  if (isDemoTenant() || isAthenaSandboxTenant()) {
    return sicCodeList;
  }
  return dealStatusList;
};
