import {Drawer, Form, Radio, Select, Tooltip, notification} from "antd";
import {Colors} from "../../../styles";
import {COMMON_ACTION_CODES} from "../../../constants/ActionConst";
import {Pressable, View, Text, TextInput} from "react-native";
import AntIcon from 'react-native-vector-icons/AntDesign';
import {FoldButton} from "../../CommonComponents/FoldButton/FoldButton";
import React, {useEffect, useState} from "react";
import {CALL_CONFIGURATION_STATUS, CALL_CONFIGURATION_STATUS_CODE, CALL_CONFIGURATION_TRANSFER_TO, CALL_CONFIGURATION_TRANSFER_TO_CODE} from "./CallExtensionConst";
import {reactStyles, styles} from "./Styles";
import {validateAddExtensionData} from "./AddCallExtensionUtils";
import {useLazyQuery} from "@apollo/client";
import {getAccountId, getAccountUUID,getAllowedUserAccountLocationUuids,getAllowedUserPracticeLocationUuids,getUserUUID,numericStringMask} from "../../../utils/commonUtils";
import './style.css'
import {CloudTelephonyQueries, UserQueries} from "../../../services";
import {IVirtualPhoneNumber, IVirtualPhoneNumberResp} from "../CloudTelephony/interfaces";
import {CLOUD_TELEPHONY_APOLLO_CONTEXT} from "../../../constants/Configs";
import {getSipPhoneCredentialList} from "../../../services/CloudTelephony/CloudTelephonyQueries";
import {OptionCheck} from "../../common/CustomUserSearch/UserOptionCheck";
import {addUpdateCallExtension} from "./AddCallExtensionService";
import {useIntl} from "react-intl";
import {PHONE_NUMBER_MASK} from "../../../constants";
import {IAddCallExtension, IAddCallExtensionDrawerState, IAddCallExtensionErrors, INumberList, ISpiPhoneCredData, IUserList, IUsersResponse} from "./interface";
import {USER_ROLE_CODES} from "../../../constants/MlovConst";
import {TestIdentifiers, testID} from "../../../testUtils";
import InfoSvg from "../../common/Svg/InfoSvg";
import {isAccountConfigEnabled} from "../../../utils/configUtils";
import {CONFIG_CODES} from "../../../constants/AccountConfigConst";
import { MAIN_MENU_CODES } from "../../SideMenuBar/SideBarConst";
import { USER_ACCESS_PERMISSION } from "../UserAccess/UserAccessPermission";

export const AddCallExtensionDrawer = (props: IAddCallExtension) => {
  const [form] = Form.useForm();
  const [stateData, setStateData] = useState<IAddCallExtensionDrawerState>({
    callExtensionId: '',
    status: CALL_CONFIGURATION_STATUS[0],
    transferTo: CALL_CONFIGURATION_TRANSFER_TO[2],
    configurationCode: '',
    configurationName: '',
    spiPhoneCredData: [],
    selectedDeskPhone: {} as ISpiPhoneCredData,
    visible: false,
    hoveredIndex: null,
    userList: [],
    selectedUser: {} as IUserList,
    numberList: [],
    selectedNumber: {} as INumberList,
    searchString: '',
    errors: {} as IAddCallExtensionErrors,
    limit: 20,
    listLoading: false,
    addUpdateCallExtensionLoading: false,

  });
  const intl = useIntl();
  const { Option } = Select;
  const accountUUID = getAccountUUID();
  const inputRef = React.useRef<any>(null);
  const userUuid = getUserUUID();
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const accountId = getAccountId();

  const handleMouseEnter = (index: number) => {
    setStateData((prev)=> {
      return {
        ...prev,
        hoveredIndex: index
      }
    })
  };

  const handleMouseLeave = () => {
    setStateData((prev)=> {
      return {
        ...prev,
        hoveredIndex: null
      }
    })
  };

  const [GET_VIRTUAL_PHONE_NUMBER] = useLazyQuery<IVirtualPhoneNumberResp>(
    CloudTelephonyQueries.GET_VIRTUAL_PHONE_NUMBER,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getUserListByLocationUuids] = useLazyQuery(
    UserQueries.GET_USERS_BY_IVR_AND_LOCATION_UUID_WITH_SEARCH, {
      fetchPolicy: 'no-cache',
    }
  )

  const [GET_AND_SEARCH_OUTGOING_CALL_LOCATION_NUMBERS] = useLazyQuery(
    CloudTelephonyQueries.GET_AND_SEARCH_OUTGOING_CALL_LOCATION_NUMBERS,{
      fetchPolicy: 'no-cache',
    }
  );

  const [getUsersData] = useLazyQuery<IUsersResponse>(UserQueries.GET_ACTIVE_USERS);

  const getUserData =  async () => {
    try {
      setStateData((prev) => {
        return {
          ...prev,
          isUserListLoading: true,
        };
      });
      let userListData;
      if (isCommunicationLocationHandlingEnabled) {
        userListData = await getUserListByLocationUuids({
          variables: {
            roleCode: USER_ROLE_CODES.EMPLOYER,
            locationUuids: props?.locationsUuid,
            searchString: `%${stateData?.searchString}%`,
            limit: stateData?.limit,
          },
        });
      } else {
        userListData = await getUsersData({
          variables: {
            searchString: `%${stateData?.searchString}%`,
            accountId: accountId,
            limit: stateData?.limit,
          },
        });
      }
      let userData = userListData?.data?.users?.map((singleUser: IUserList) => {
        return {
          ...singleUser,
          id: singleUser?.uuid || singleUser?.id,
          title: singleUser?.name || '',
        }
      })
      if (props?.selectedCallExtension?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.USER && !stateData?.searchString) {
        const exists = userData?.some((user: IUserList) => user?.id === props?.selectedCallExtension?.selectedUser?.id);
        if (!exists) {
          userData = [props?.selectedCallExtension?.selectedUser, ...userData];
        }
      }
      setStateData((prev) => {
        return {
          ...prev,
          userList: userData,
          listLoading: false
        }
      });
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          listLoading: false
        }
      });
    }
  }

  const getSipCredList = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        listLoading: true,
      }
    })
    const params = {
      limit: stateData?.limit,
      searchString: stateData?.searchString,
    }
    try {
      const spiPhoneCredResponse = await getSipPhoneCredentialList(params);
      const spiPhoneCredList = spiPhoneCredResponse?.data;
      let spiPhoneCredListData = spiPhoneCredList.map((spiCred: ISpiPhoneCredData) => {
        return {
          ...spiCred,
          title: spiCred?.phoneName
        }
      })
      if (props?.selectedCallExtension?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE && !stateData?.searchString) {
        const exists = spiPhoneCredListData?.some((spi: ISpiPhoneCredData) => spi.id === props?.selectedCallExtension?.selectedDeskPhone?.id);
        if (!exists) {
          spiPhoneCredListData = [props?.selectedCallExtension?.selectedDeskPhone, ...spiPhoneCredListData];
        }
      }
      setStateData((prev) => {
        return {
          ...prev,
          spiPhoneCredData: spiPhoneCredListData,
          listLoading: false,
        }
      });
    } catch (err) {
      setStateData((prev) => {
        return {
          ...prev,
          listLoading: false,
        }
      })
    }
  }

  const getPhoneData = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        listLoading: true
      }
    })
    try {
      const usersWithMatchingLocations = [...props?.userPracticeLocation, ...allowedUserAccountLocationUuids]
      if (!usersWithMatchingLocations.length) {
        usersWithMatchingLocations.push(userUuid)
      }
      let response;
      if (isCommunicationLocationHandlingEnabled) {
        response = await GET_AND_SEARCH_OUTGOING_CALL_LOCATION_NUMBERS({
          context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
          variables: {
            accountUuid: accountUUID,
            assigneeIds: usersWithMatchingLocations,
            searchString: `%${stateData?.searchString}%`,
            limit: stateData?.limit,
            offset: 0
          },
        });
      } else {
        response = await GET_VIRTUAL_PHONE_NUMBER({
          context: {service: CLOUD_TELEPHONY_APOLLO_CONTEXT},
          variables: {
            accountUuid: accountUUID,
            searchString: `%${stateData?.searchString}%`,
            limit: stateData?.limit,
            offset: 0
          },
        });
      }
      const numberList: IVirtualPhoneNumber[] = response?.data?.virtualPhoneNumberAssignees || [];
      let numberData = numberList.map((number) => {
        return {
          ...number,
          title: number?.virtualPhoneNumber,
        }
      })
      if (props?.selectedCallExtension?.transferToType === CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER && !stateData?.searchString) {
        const exists = numberData?.some(number => number.id === props?.selectedCallExtension?.selectedNumber?.id);
        if (!exists) {
          numberData = [props?.selectedCallExtension?.selectedNumber, ...numberData];
        }
      }
      setStateData((prev) => {
        return {
          ...prev,
          numberList: numberData,
          listLoading: false
        }
      });
    } catch (err) {
      setStateData((prev) => {
        return {
          ...prev,
          listLoading: false,
        }
      });
    }
  }

  const validateDataOnSubmit = async ()=> {
    const validate = validateAddExtensionData(stateData);
    if (validate.validate) {
      const addUpdateCallExtensionBody = {
        id: props?.selectedCallExtension?.id,
        extensionName: stateData?.configurationName,
        extensionNumber: stateData?.configurationCode,
        transferToType: stateData?.transferTo?.code,
        transferToEntityId: getSelectedId(),
        accountUuid: accountUUID,
        isActive: stateData?.status?.code === CALL_CONFIGURATION_STATUS_CODE.ACTIVE ? true : false,
      }
      try {
        setStateData((prev) => ({
          ...prev,
          addUpdateCallExtensionLoading: true,
        }));
        const response = await addUpdateCallExtension(addUpdateCallExtensionBody);
        let callExtensionObj;
        if (response?.data?.id) {
          const callExtension = response?.data
          callExtensionObj = {
            id: callExtension?.id,
            extensionName: callExtension?.extensionName,
            extensionNumber: callExtension?.extensionNumber,
            transferToType: callExtension?.transferToType,
            transferToEntityId: callExtension?.transferToEntityId,
            isActive: callExtension?.isActive,
            accountUuid: callExtension?.accountUuid,
            isExistingDeskPhoneExtension: callExtension?.isExistingDeskPhoneExtension,
            selectedNumber: {
              ...callExtension?.virtualPhoneNumberAssignee,
              title: callExtension?.virtualPhoneNumberAssignee?.virtualPhoneNumber
            },
            selectedDeskPhone: {
              ...callExtension?.sipPhoneCredential,
              title: callExtension?.sipPhoneCredential?.phoneName,
            },
            selectedUser: {
              ...callExtension?.user,
              id: callExtension?.user?.uuid,
              title: callExtension?.user?.name
            }
          }
        }
        props?.onActionPerformed?.(COMMON_ACTION_CODES.SUCCESS, callExtensionObj);
        setStateData((prev) => ({
          ...prev,
          addUpdateCallExtensionLoading: false,
        }));
      } catch (error: any) {
        setStateData((prev) => ({
          ...prev,
          addUpdateCallExtensionLoading: false,
        }));
        notification.error({
          message: error.response.data.message || intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 4.0,
          placement: 'topRight'
        });
      }
    }
    setStateData((prev)=> {
      return {
        ...prev,
        errors: validate.errors
      }
    });
  }

  const onOptionSelect = (visible: boolean, value?: any) => {
    switch (stateData?.transferTo?.code) {
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
        return setStateData((prev)=> {
          return {
            ...prev,
            visible: visible,
            selectedNumber: value,
          }
        })
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
        return setStateData((prev)=> {
          return {
            ...prev,
            visible: visible,
            selectedDeskPhone: value,
          }
        })
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
        return setStateData((prev)=> {
          return {
            ...prev,
            visible: visible,
            selectedUser: value,
          }
        })
      default:
        return setStateData((prev)=> {
          return {
            ...prev,
            visible: visible
          }
        })
    }
  }

  const onShowCustomFieldOptionVisibleChanges = (visible: boolean) => {
    return setStateData((prev)=> {
      return {
        ...prev,
        visible: visible
      }
    })
  }

  const getDataByTransferDataType = (transferTo: string) => {
    switch (transferTo) {
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
        return stateData?.numberList;
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
        return stateData?.spiPhoneCredData;
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
        return stateData?.userList;
      default:
        return [];
    }
  }

  const getSelectedValue = () => {
    switch (stateData?.transferTo?.code) {
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
        return stateData?.selectedNumber?.title;
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
        return stateData?.selectedDeskPhone?.title;
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
        return stateData?.selectedUser?.title;
      default:
        return '';
    }
  }

  const getSelectedId = () => {
    switch (stateData?.transferTo?.code) {
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
        return stateData?.selectedNumber?.id;
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
        return stateData?.selectedDeskPhone?.id;
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
        return stateData?.selectedUser?.id;
      default:
        return '';
    }
  }

  const getText = (text: string) => {
    if (stateData?.transferTo?.code === CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER) {
      return numericStringMask(
        text.slice(2),
        PHONE_NUMBER_MASK
      );
    } else {
      return text;
    }
  }

  const fetchOptionList = (TransferType: string) => {
    switch (TransferType) {
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
        return getPhoneData();
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
        return getSipCredList();
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
        return getUserData();
      default:
        return;
    }
  }

  const getDefaultSelectedValueByTransferType = (TransferType: string) => {
    switch (TransferType) {
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
        return {
          selectedNumber: props?.selectedCallExtension?.selectedNumber
        }
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
        return {
          selectedDeskPhone: props?.selectedCallExtension?.selectedDeskPhone
        }
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
        return {
          selectedUser: props?.selectedCallExtension?.selectedUser
        }
      default:
        return;
    }
  }

  const getOptionList = async () => {
    if (props?.selectedCallExtension?.id) {
      setStateData((prev) => {
        return {
          ...prev,
          callExtensionId: props?.selectedCallExtension?.id,
          status: {
            ...stateData?.status,
            code: props?.selectedCallExtension?.isActive ? CALL_CONFIGURATION_STATUS_CODE.ACTIVE : CALL_CONFIGURATION_STATUS_CODE?.IN_ACTIVE
          },
          configurationCode: props?.selectedCallExtension?.extensionNumber,
          configurationName: props?.selectedCallExtension?.extensionName,
          transferTo: {
            ...stateData?.transferTo,
            code: props?.selectedCallExtension?.transferToType,
          },
          listLoading: true,
        }
      })
    }
    Promise.all([
      getPhoneData(),
      getSipCredList(),
      getUserData(),
    ]).then(() => {
      if (props?.selectedCallExtension?.id) {
        const defaultSelectedValueByTransferType = getDefaultSelectedValueByTransferType(props?.selectedCallExtension?.transferToType);
        setStateData((prev) => {
          return {
            ...prev,
            ...defaultSelectedValueByTransferType,
            listLoading: false,
          }
        })
      }
    }).catch(error => {
      setStateData((prev) => {
        return {
          ...prev,
          listLoading: false,
        }
      })
    });
  }

  const isSelectedOrHovered = (index: number, id: string) => {
    if (stateData?.hoveredIndex === index || id === getSelectedId()) {
      return true;
    }
    return false;
  }

  const getPlaceholder = ()=> {
    switch (stateData?.transferTo?.code) {
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.NUMBER:
        return 'Select Number';
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.DESK_PHONE:
        return 'Select Desk Phone';
      case CALL_CONFIGURATION_TRANSFER_TO_CODE.USER:
        return 'Select User';
      default:
        return '';
    }
  }

  const renderOptions = (
    <>
      {getDataByTransferDataType(stateData?.transferTo?.code).map((field, index: number) => {
          return (
            <>
            <Option key={field.id} value={field.id}>
              <Pressable
                onPress={() => {
                  onOptionSelect(false, field);
                }}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: isSelectedOrHovered(index, field?.id) ? Colors.FoldPixel.GRAY50 : '',
                  paddingHorizontal: 6,
                }}
                {...testID(`${TestIdentifiers.callExtension}_TransferTo_${field?.id}`)}
              >
                <div
                  style={reactStyles.width100BorderWidth1PaddingBottom10PaddingTop10}
                  key={field.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {stateData?.transferTo?.code !== CALL_CONFIGURATION_TRANSFER_TO_CODE.USER ? <Text style={styles.text}
                    >
                      {getText(field?.title)}
                  </Text> : <OptionCheck
                    name={field?.title}
                    value={field?.id}
                    userRoles={field?.userRoles || []}
                    checked={false}
                    showCheckbox={false}
                    isDisabled={false}
                  />}
                </div>
              </Pressable>
              </Option>
            </>
          )
        })
      }
    </>
  );

  useEffect(() => {
    getOptionList();
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchOptionList(stateData?.transferTo?.code);
    }, 400);
    return () => clearTimeout(delayDebounceFn);
  }, [stateData?.searchString])

  return (
    <>
      <Drawer
        visible={true}
        width={560}
        closable={false}
        onClose={() => {
          props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE)
        }}
        title={
          <View style={styles.flexDirectionRowAlignItemsCenter}>
            <View>
              <Text style={styles.text2}>
                {intl.formatMessage({id : 'newConfiguration'})}
              </Text>
            </View>
            <View style={styles.alignItemsFlexEndFlex1}>
              <View
                style={styles.flexAlignItemsCenterFlexDirectionRow}
              >
                <FoldButton
                  customProps={{
                    btnText: props?.selectedCallExtension?.id ? 'Update' : 'Create',
                  }}
                  nativeProps={{
                    onPress() {
                      validateDataOnSubmit();
                    },
                    isLoading: stateData?.addUpdateCallExtensionLoading,
                    isDisabled: stateData?.addUpdateCallExtensionLoading,
                    backgroundColor: Colors.Custom.Primary300,
                    _text: {
                      fontSize: 14,
                      lineHeight: 16.8,
                    },
                    paddingX: 2,
                    marginLeft: 2
                  }}
                />
                <View
                  style={styles.viewStyle}
                />
                <Pressable
                  style={styles.alignItemsFlexEndJustifyContentCenter}
                  onPress={()=> {
                    props?.onActionPerformed(COMMON_ACTION_CODES.CLOSE)
                  }}
                >
                  <AntIcon size={20} name="close" color={Colors.FoldPixel.GRAY300}/>
                </Pressable>
              </View>
            </View>
          </View>
        }
      >
        <View>
          <Form
            className="custom-form"
            form={form}
            layout="vertical"
            labelCol={{
              style: {
                paddingLeft:3
              }
            }}
          >
            <Form.Item
              label={<span style={reactStyles.colorGray250}>Status</span>}
              tooltip={{
                title: 'Required field',icon: <Tooltip title="Required field">
                  <span className="required-dot"></span>
                </Tooltip>
              }}
              {...testID(`${TestIdentifiers.callExtension}_Status`)}
              validateStatus={stateData?.errors?.status ? 'error' : ''}
            >
              <View>
                <View style={styles.flexDirectionRow}>
                  {CALL_CONFIGURATION_STATUS?.map((status) => {
                    return (
                      <Pressable
                        key={status.code}
                        style={styles.radioButton}
                        onPress={() => {
                          setStateData((prev) => {
                            return {
                              ...prev,
                              status: status
                            }
                          })
                        }}
                        {...testID(`${TestIdentifiers.callExtension}_Status_${status?.code}`)}
                      >
                        <View style={stateData?.status?.code === status?.code ? styles.radioCircleSelected :  styles.radioCircle}>
                          {stateData?.status?.code === status?.code && <View style={styles.selectedRadioCircle} />}
                        </View>
                        <Text style={styles.text3}
                        >
                          {status?.title}
                        </Text>
                      </Pressable>
                    )
                  })}
                </View>
              </View>
              {stateData?.errors?.status && <div style={reactStyles.errorText}>
                {stateData?.errors?.status}
              </div>}
            </Form.Item>

            <Form.Item
              label={<span style={reactStyles.colorGray250}>Extension Name</span>}
              tooltip={{
                title: 'Required field',icon: <Tooltip title="Required field">
                  <span className="required-dot"></span>
                </Tooltip>
              }}
              {...testID(`${TestIdentifiers.callExtension}_Name`)}
              validateStatus={stateData?.errors?.configurationCode ? 'error' : ''}
            >
              <TextInput
                style={styles.textInput}
                defaultValue={stateData?.configurationName}
                ref={inputRef}
                onChangeText={(text) => {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      configurationName: text
                    }
                  })
                }}
                placeholder="Enter Extension Name"
                placeholderTextColor={Colors.FoldPixel.GRAY250}
                {...testID(`${TestIdentifiers.callExtension}_Name_Input`)}
              />
              {stateData?.errors?.configurationName && <div style={reactStyles.errorText}>
                {stateData?.errors?.configurationName}
              </div>}
            </Form.Item>

            <Form.Item
              label={<span style={reactStyles.colorGray250}>Select Configuration</span>}
              tooltip={{
                title: '',icon: <View style={styles.flexDirectionRowAlignItemsCenter}>
                  <Tooltip
                    title={intl.formatMessage({ id: 'excludedNumber' })}
                    placement={'top'} destroyTooltipOnHide={true} >
                    <View style={styles.marginLeft3MarginTop2}>
                      <InfoSvg customColor={Colors.FoldPixel.GRAY200} customDimension={'15'} />
                    </View>
                  </Tooltip>
                  <span className="required-dot"></span>
                </View>
              }}
              validateStatus={stateData?.errors?.configurationCode ? 'error' : ''}
              {...testID(`${TestIdentifiers.callExtension}_Number`)}
            >
              <TextInput
                style={styles.textInput}
                defaultValue={stateData?.configurationCode}
                ref={inputRef}
                onChangeText={(text) => {
                  const validateRegex = /[^0-9]/g;
                  const numericValue = text?.replace(validateRegex, '');
                  inputRef.current.value = numericValue;
                  setStateData((prev) => {
                    return {
                      ...prev,
                      configurationCode: numericValue
                    }
                  })
                }}
                placeholder="Enter Dialpad Configuration"
                placeholderTextColor={Colors.FoldPixel.GRAY250}
                {...testID(`${TestIdentifiers.callExtension}_Number_Input`)}
              />
              <Text style={styles.text4}>
                {intl.formatMessage({id: 'extensionCodeInfo'})}
              </Text>
              {stateData?.errors?.configurationCode && <div style={reactStyles.errorText}>
                {stateData?.errors?.configurationCode}
              </div>}
            </Form.Item>

            <Form.Item
              label={<span style={reactStyles.colorGray250}>Ring To</span>}
              tooltip={{
                title: 'Required field',icon: <Tooltip title="Required field">
                  <span className="required-dot"></span>
                </Tooltip>
              }}
              validateStatus={stateData?.errors?.transferTo ? 'error' : ''}
              {...testID(`${TestIdentifiers.callExtension}_TransferTo`)}
            >
              <View style={styles.flexDirectionRow}>
                {CALL_CONFIGURATION_TRANSFER_TO?.map((transferTo) => {
                  return (
                    <Pressable
                      key={transferTo.code}
                      style={styles.radioButton}
                      onPress={() => {
                        setStateData((prev) => {
                          return {
                            ...prev,
                            transferTo: transferTo,
                            visible: false,
                            selectedDeskPhone: {} as ISpiPhoneCredData,
                            selectedNumber: {} as INumberList,
                            selectedUser: {} as IUserList,
                          }
                        })
                      }}
                      {...testID(`${TestIdentifiers.callExtension}_TransferTo_${transferTo?.code}`)}
                    >
                      <View style={stateData?.transferTo?.code === transferTo?.code ? styles.radioCircleSelected :  styles.radioCircle}>
                        {stateData?.transferTo?.code === transferTo?.code && <View style={styles.selectedRadioCircle} />}
                      </View>
                      <Text style={styles.text5}
                      >
                        {transferTo?.title}
                      </Text>
                    </Pressable>
                  )
                })}
              </View>
              <View style={styles.marginTop8}>
                <Select
                  className="select-call-extension"
                  showSearch
                  popupClassName="select-call-extension"
                  style={reactStyles.select}
                  disabled={stateData?.listLoading}
                  loading={stateData?.listLoading}
                  filterOption={() => true}
                  value={getSelectedValue()}
                  open={stateData?.visible}
                  onSearch={(value)=>{
                    setStateData((prev) => {
                      return {
                        ...prev,
                        searchString: value,
                      }
                    })
                  }}
                  onDropdownVisibleChange={(open) => onShowCustomFieldOptionVisibleChanges(open)}
                  placeholder={getPlaceholder()}
                  {...testID(`${TestIdentifiers.callExtension}_TransferTo_Select`)}
                >
                  {renderOptions}
                </Select>
              </View>
              {stateData?.errors?.transferTo && <div style={reactStyles.errorText}>
                {stateData?.errors?.transferTo}
              </div>}
            </Form.Item>
          </Form>
        </View>
      </Drawer>
    </>
  )
}
