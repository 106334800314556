import React, {useContext, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {CapabilityResource} from '../Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {
  AllowedWearableDataCategories,
  HIDE_WEARABLES_DATA_CONFIGURATION,
  WEARABLE_DATA_SYNC_CATEGORIES,
} from './WearableDataConstants';
import {Colors} from '../../../styles/Colors';
import Stack from '../../common/LayoutComponents/Stack';
import {getIconBasedOnVital} from '../Forms/FHFormio/CustomComponents/HomeMonitoringView/utils';
import {Divider, Switch} from 'antd';
import {
  IAllowedWearableData,
  IEhrCapability,
} from '../../../Interfaces/CommonInterfaces';
import {useIntl} from 'react-intl';
import {cloneDeep} from 'lodash';
import {
  getAccountConfigCapability,
  getAccountConfigResourceAbilities,
} from '../../../utils/capabilityUtils';
import {updateEhrCapabilities} from '../../../services/CommonService/AidBoxService';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import {CommonDataAction} from '../../CommonDataProvider/interface';
import {Spinner, useToast, Text} from 'native-base';
import {getAccountConfigCapabilitiesForContext, getNewCapabilitiesForContext} from './WearableDataSyncUtils';
import WearablesDevicesIcons from '../../../assets/Icons/WearablesDevicesIcons';
import BadgeSvg from '../../common/Svg/BadgeSvg';
import { MonitoringActivityCodes } from '../../../utils/VitalUtils';

const WearablesDataSyncConfigurationView = () => {
  const intl = useIntl();
  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const resource = getAccountConfigResourceAbilities(CapabilityResource.wearable);
  const capability = getAccountConfigCapability(CapabilityResource.wearable);
  const getInitialAllowedWearableList = () => {
    return (
      resource?.allowedWearableList?.filter(
        (wearable) =>
          !HIDE_WEARABLES_DATA_CONFIGURATION.includes(
            wearable.code as MonitoringActivityCodes
          )
      ) || []
    );
  };
  const [allowedWearableList, setAllowedWearableList] = useState(
    getInitialAllowedWearableList()
  );
  const [loading, setLoading] = useState<Record<string, boolean>>();

  const getWearableParameters = (code: AllowedWearableDataCategories) => {
    return allowedWearableList.filter((item) => item.category === code);
  };

  const getUpdatedWearableEnability = (
    code: string,
    updatedEnabled: boolean,
    allowedWearableData?: IAllowedWearableData[]
  ) => {
    return (
      allowedWearableData?.map((wearableData: IAllowedWearableData) => {
        if (wearableData.code === code) {
          wearableData.enabled = updatedEnabled;
        }
        return wearableData;
      }) || []
    );
  };

  const onChangeAbility = async (
    isEnabled: any,
    abilityName: string,
    wearableCode: string
  ) => {
    setLoading((prev) => {
      return {
        ...prev,
        [wearableCode]: true,
      };
    });
    const tempAllowedWearableList = cloneDeep(allowedWearableList);
    const updatedWearableDataList = getUpdatedWearableEnability(
      wearableCode,
      isEnabled,
      tempAllowedWearableList
    );
    const postCapability = {...capability};
    delete postCapability.locationGroupId;
    postCapability.ehrName = null;
    const postData: IEhrCapability = {
      ...postCapability,
      abilities: {...resource, allowedWearableList: updatedWearableDataList},
    };
    await updateEhrCapabilities(
      postData,
      (res) => {
        setAllowedWearableList(updatedWearableDataList);
        setLoading((prev) => {
          return {
            ...prev,
            [wearableCode]: false,
          };
        });
        commonData?.dispatch?.({
          type: CommonDataAction.UPDATE_ACCOUNT_CONFIG_CAPABILITIES,
          payload: getAccountConfigCapabilitiesForContext(
            commonData,
            postData,
            res?.data?.id
          ),
        });
      },
      (err) => {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error,
          1000
        );
        setLoading((prev) => {
          return {
            ...prev,
            [wearableCode]: false,
          };
        });
      }
    );
  };

  const getHeaderRightViewForWearablesSync = (
    wearableParamatersList: IAllowedWearableData[]
  ) => {
    const total = wearableParamatersList;
    let onValues = 0;
    total.map((item) => {
      if (item.enabled) {
        onValues++;
      }
    });
    const text = `${onValues}/${total.length} Enabled`;
    if (!total.length) {
      return <></>;
    }
    return (
      <Stack direction="row" style={styles.headerRightView} space={6}>
        <BadgeSvg />
        <Text style={styles.headerRightText}>
          {text}
        </Text>
      </Stack>
    );
  };

  return (
    <View>
      <Stack
        direction="row"
        style={styles.supportedDevicesContainer}
      >
        <Stack direction="column" style={styles.supportedDevicesTextContainer} space={4}>
          <Text style={styles.supportedDevicesTitle}>
            {intl.formatMessage({id: 'supportedDevices'})}
          </Text>
          <Text style={styles.supportedDevicesSubtitle}>
            {intl.formatMessage({id: 'wearableDevicesList'})}
          </Text>
        </Stack>
        <div style={reactStyles.wearableDevicesIconContainer}>
          <div style={reactStyles.blurEffect}></div>
          <View style={styles.wearableDevicesIconWrapper}>
            <WearablesDevicesIcons />
          </View>
        </div>
      </Stack>
      <Text style={styles.chooseBiomarkerText}>
        {intl.formatMessage({id: 'chosseBiomarkerAndActivityData'})}
      </Text>
      <View style={styles.wearableDataContainer}>
        {WEARABLE_DATA_SYNC_CATEGORIES.map((item, index) => {
          const wearableParamatersList = getWearableParameters(item.key);
          const key = item.key;
          if (!wearableParamatersList?.length) {
            return null;
          }
          return (
            <View
              key={key}
              style={styles.wearableDataCategoryContainer}
            >
              <Stack
                direction="row"
                space={8}
                style={styles.wearableDataCategoryHeader}
              >
                <Text style={styles.wearableDataCategoryTitle}>
                  {item.value}
                </Text>
                <View style={styles.flexFiller} />
                {getHeaderRightViewForWearablesSync(wearableParamatersList)}
              </Stack>
              <Divider style={reactStyles.divider} />
              <Stack
                direction="column"
                style={styles.wearableDataParametersList}
              >
                {wearableParamatersList.map((paramater, index) => {
                  const Icon = getIconBasedOnVital(paramater.code);
                  return (
                    <Stack direction="column" key={index}>
                      <Stack
                        direction="row"
                        space={8}
                        style={styles.wearableDataParameterItem}
                      >
                        <Icon
                          strokeColor={Colors.FoldPixel.GRAY300}
                          width="24"
                          height="24"
                        />
                        <Text size={'smRegular'} style={styles.wearableDataParameterText}>
                          {paramater?.display || ''}
                        </Text>
                        <View style={styles.flexFiller} />
                        {loading?.[paramater.code] ? (
                          <Spinner />
                        ) : (
                          <Switch
                            checked={paramater.enabled}
                            style={paramater.enabled ? reactStyles.switchStyle : reactStyles.switchStyleDisabled}
                            onChange={(checked) =>
                              onChangeAbility(
                                checked,
                                CapabilityResource.wearable,
                                paramater.code
                              )
                            }
                          />
                        )}
                      </Stack>
                      {index !== wearableParamatersList.length - 1 && (
                        <Divider style={reactStyles.parameterDivider} />
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  supportedDevicesContainer: {
    borderRadius: 12,
    paddingLeft: 24,
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.PRIMARY300,
    backgroundColor: Colors.Custom.purpleBackgroundContainer,
    width: '50%',
    alignSelf: 'center',
    marginTop: 16,
    alignItems: 'center',
  },
  supportedDevicesTextContainer: {
    flex: 1,
  },
  supportedDevicesTitle: {
    color: Colors.FoldPixel.GRAY400,
    fontSize: 18,
    fontWeight: '500',
  },
  supportedDevicesSubtitle: {
    color: Colors.FoldPixel.GRAY300,
    fontSize: 14,
    fontWeight: '400',
  },
  
  wearableDevicesIconWrapper: {
    position: 'relative',
    alignItems: 'center',
  },
  chooseBiomarkerText: {
    fontWeight: '500',
    width: '50%',
    alignSelf: 'center',
    marginTop: 16,
    fontSize: 16,
  },
  wearableDataContainer: {
    width: '50%',
    alignSelf: 'center',
    marginTop: 4,
  },
  wearableDataCategoryContainer: {
    borderColor: Colors.Custom.Gray200,
    marginTop: 12,
    borderWidth: 1,
    borderRadius: 8,
  },
  wearableDataCategoryHeader: {
    paddingVertical: 14.5,
    paddingHorizontal: 12,
    backgroundColor: Colors.FoldPixel.GRAY50,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  wearableDataCategoryTitle: {
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '500',
    fontSize: 16,
  },
  flexFiller: {
    flex: 1,
  },
  wearableDataParametersList: {
    paddingVertical: 2,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  wearableDataParameterItem: {
    paddingVertical: 12.5,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  wearableDataParameterText: {
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '400',
  },
  headerRightView: {
    alignItems: 'center',
  },
  headerRightText: {
    marginLeft: 2,
    color: Colors.FoldPixel.GRAY300,
    fontSize: 16,
  },
});

const reactStyles: Record<string, React.CSSProperties> = {
  divider: {
    margin: 0,
  },
  parameterDivider: {
    marginTop: 2,
    marginBottom: 0,
  },
  switchStyle: {
    backgroundColor: Colors.Custom.mainPrimaryPurple,
  },
  switchStyleDisabled: {
    backgroundColor: Colors.Custom.Gray200,
  },
  wearableDevicesIconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  blurEffect: {
    filter: 'blur(20px)',
    position: 'absolute',
    right: 0,
    width: '80%',
    height: '100%',
    background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
    pointerEvents: 'none',
    zIndex: 1,
  },
};

export default WearablesDataSyncConfigurationView;
