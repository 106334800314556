import {View, Text, Button, useMediaQuery} from "native-base";
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {QUALITY_MEASURES_PATIENT_TYPE, SMALL_WINDOW_1500} from "../../../../../../constants";
import {getAccountUUID, isEmployerRole} from "../../../../../../utils/commonUtils";
import CustomTabsView from "../../../../../common/CustomTabsView/CustomTabsView";
import {ITabsList} from "../../../../../common/CustomTabsView/interfaces";
import {TABLE_TOP_BAR_ACTION_CODES, TableTopBar} from "../../../../../common/TableTopBar";
import TitleSubtitleView from "../../../../../common/TitleSubtitleView/TitleSubtitleView";
import {getPathFromHash} from "../../../../ContentManagement/ContentManagementUtils";
import PatientView from "./PatientView/PatientView";
import {useLazyQuery} from "@apollo/client";
import QualityMeasuresService from "../../../../../../services/Contracts/QualityMeasures.service";
import {CARESTUDIO_APOLLO_CONTEXT} from "../../../../../../constants/Configs";
import {Colors} from "../../../../../../styles";
import AntIcon from 'react-native-vector-icons/AntDesign';
import {getProgressBarColor} from "../../../HelperFiles/TablesColumnsView";
import {extractNumber, getPopulationCodes} from "../../../HelperFiles/utils";
import LoopSvg from "../../../../../common/Svg/LoopSvg";
import {Tooltip} from "antd";
import InfoSvg from "../../../../../common/Svg/InfoSvg";
import {useIntl} from "react-intl";
import LocalStorage from "../../../../../../utils/LocalStorage";
import PerformanceSvg from "../../../../../common/Svg/PerformanceSvg";
import {MEASURE_CRITERIA_TYPE, NAME_MAX_LENGTH, QUALITY_MEASURE_STORAGE_KEY} from "../../../HelperFiles/const";
import {IMeasureDetails} from "../../../HelperFiles/interface";
import ProgressBar from "../../../HelperFiles/ProgressBar";
import {getMeasureData} from "../../../../../../services/CommonService/AidBoxService";

const QualityMeasuresDetails = () => {
  const {contractId,qmId} = useParams();
  const accountUuid = getAccountUUID();
  const [selectedTab, setSelectedTab] = useState({} as ITabsList);
  const navigate = useNavigate();
  const [stateData, setStateData] = useState({
    selectedActionCode: '',
    selectedRawData: {} as any,
    measureMetCount: 0,
    measureNotMetCount: 0,
    denominatorExclusion: 0,
    numeratorExclusion: 0,
    denominatorException: 0,
    numeratorException: 0,
    measureDetails: {} as IMeasureDetails,
    populationCodesLoading: true,
    populationCodes: [] as string[],
  })
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW_1500 },
  ]);

  const fetchMeasureData = async (fhirResourceId: string)=> {
    try {
      const resourceDetails = await getMeasureData(fhirResourceId);
      const population = resourceDetails?.data?.entry?.[0]?.resource?.group[0]?.population;
      const populationCodes = getPopulationCodes(population);
      setStateData((prev)=> {
        return {
          ...prev,
          populationCodes: populationCodes,
          populationCodesLoading: false,
        }
      })
    } catch (err) {
      setStateData((prev)=> {
        return {
          ...prev,
          populationCodesLoading: false,
        }
      })
    }
  }

  useEffect(() => {
    LocalStorage.getItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS).then((res) => {
      const measureDetails = JSON.parse(res);
      let data = {} as IMeasureDetails;
      if (measureDetails?.isGroup) {
        data = {
          measureGoal: extractNumber(measureDetails?.goal || ''),
          measurePerformanceScore: extractNumber(measureDetails?.measureGroup?.performanceScore || '0'),
          measureInverse: measureDetails?.isInverse,
          measureName: measureDetails?.measureName,
          groupName: measureDetails?.measureGroup?.name || measureDetails?.measureGroup?.description,
          contractName: measureDetails?.contractName,
          measureGroup: measureDetails?.measureGroup,
          isStratification: measureDetails?.isStratification,
          fhirResourceId: measureDetails?.fhirResourceId
        }
      } else {
        data = {
          measureGoal: extractNumber(measureDetails?.goal || ''),
          measurePerformanceScore: extractNumber(measureDetails?.performanceScore || '0'),
          measureInverse: measureDetails?.isInverse,
          measureName: measureDetails?.measureName,
          contractName: measureDetails?.contractName,
          groupName: measureDetails?.measureGroup?.name || measureDetails?.measureGroup?.description,
          measureGroup: measureDetails?.measureGroup,
          isStratification: measureDetails?.isStratification,
          fhirResourceId: measureDetails?.fhirResourceId
        }
      }
      setStateData((prev) => {
        return {
          ...prev,
          measureDetails: data
        }
      })
      if (data?.fhirResourceId) {
        fetchMeasureData(data?.fhirResourceId);
      }
      const measureGroupId = measureDetails?.isStratification ? measureDetails?.measureGroup?.stratificationId : measureDetails?.measureGroup?.groupId;
      getCountData(measureGroupId, measureDetails?.isStratification);
    });
  },[])
  const intl = useIntl();
  const tabList = (): ITabsList[] => {
    const tabs: ITabsList[] = [
      {
        key: 'patients-unqualified',
        title: 'Measure Not Met',
        path: 'patients-unqualified',
        count: stateData.measureNotMetCount ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    measureNotMetCount: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      },
      {
        key: 'patients-qualified',
        title: 'Measure Met',
        path: 'patients-qualified',
        count: stateData.measureMetCount ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    measureMetCount: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      },
    ];
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.DENOMINATOR_EXCLUSION)) {
      tabs.push({
        key: 'denominator-exclusion',
        title: 'Denominator Exclusion',
        path: 'denominator-exclusion',
        count: stateData.denominatorExclusion ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    denominatorExclusion: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.NUMERATOR_EXCLUSION)) {
      tabs.push({
        key: 'numerator-exclusion',
        title: 'Numerator Exclusion',
        path: 'numerator-exclusion',
        count: stateData.numeratorExclusion ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    numeratorExclusion: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.DENOMINATOR_EXCEPTION)) {
      tabs.push({
        key: 'denominator-exception',
        title: 'Denominator Exception',
        path: 'denominator-exception',
        count: stateData.denominatorException ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    denominatorException: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    if (stateData?.populationCodes?.includes(MEASURE_CRITERIA_TYPE.NUMERATOR_EXCEPTION)) {
      tabs.push({
        key: 'numerator-exception',
        title: 'Numerator Exception',
        path: 'numerator-exception',
        count: stateData.numeratorException ?? 0,
        tabContainerElem: () => {
          return (
            <PatientView 
              updateContactCount={(contactCount: number) => {
                setStateData(prev=> {
                  return {
                    ...prev,
                    numeratorException: contactCount
                  }
                })
              }}
              searchString={stateData.selectedRawData?.searchString || ''}
              patientType={QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION}
              measureGroup={stateData?.measureDetails?.measureGroup}
              isStratification={stateData?.measureDetails?.isStratification}
            />
          )
        }
      })
    }
    return tabs
  }

  const [qualityMeasureReportAggregate] = useLazyQuery(
    QualityMeasuresService.qualityMeasureReportAggregate,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );
  const onTableTopBarActionPerformed = (
    actionCode: string,
    actionData: any,
  ) => {
    switch (actionCode) {
      case TABLE_TOP_BAR_ACTION_CODES.SEARCH:
        setStateData((prev) => {
          return {
            ...prev,
            selectedActionCode: actionCode,
            selectedRawData: actionData,
          };
        });
      break;
    }
  };
  const getSelectedTopBar = (selectedTab: ITabsList) => {
    if ( selectedTab.key === 'programs') {
      return false
    }
    return  <TableTopBar
    selectedTab={selectedTab.key}
    title=""
    alignSearchBarFlexEnd={true}
    hStackMinHight={70}
    isDividerNotVisible={true}
    onActionPerformed={(actionCode: string, actionData: any) => {
      onTableTopBarActionPerformed(actionCode, actionData);
    }}
  />
  }
  const onTabClick = (tab: ITabsList) => {
    setSelectedTab(tab)
  }
  const qualityMeasuresCountByType = async (patientType: string, isStratification: boolean, measureGroupId: string) => {
    const filterObject = {
      isLatest: { _eq: true },
      contractId: { _eq: contractId || '' },
      measureId: { _eq: qmId || '' },
      tenantId: { _eq: accountUuid },
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED && {
        numeratorFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED && {
        numeratorFlag: { _eq: false },
        denominatorFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION && {
        denominatorExclusionFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION && {
        numeratorExclusionFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION && {
        denominatorExceptionFlag: { _eq: true },
      }),
      ...(patientType === QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION && {
        numeratorExceptionFlag: { _eq: true },
      }),
      ...(measureGroupId && {
        ...(isStratification
          ? { stratification: { _eq: measureGroupId } }
          : { groupId: { _eq: measureGroupId } }),
      }),
    };
  
    try {
      const { data } = await qualityMeasureReportAggregate({
        variables: {
          where: filterObject,
        },
      });
      return data?.aggregateQmMeasureReport?.aggregate?.count || 0;
    } catch (error) {
      return 0;
    }
  };
  
  const getCountData = async (measureGroupId: string, isStratification: boolean) => {
    try {
      const measureMetCount = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_QUALIFIED, isStratification, measureGroupId);
      const measureNotMetCount = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.PATIENTS_UNQUALIFIED, isStratification, measureGroupId);
      const denominatorExclusion = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCLUSION, isStratification, measureGroupId);
      const numeratorExclusion = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCLUSION, isStratification, measureGroupId);
      const denominatorException = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.DENOMINATOR_EXCEPTION, isStratification, measureGroupId);
      const numeratorException = await qualityMeasuresCountByType(QUALITY_MEASURES_PATIENT_TYPE.NUMERATOR_EXCEPTION, isStratification, measureGroupId);
  
      setStateData(prev => {
        return {
          ...prev,
          measureMetCount,
          measureNotMetCount,
          denominatorExclusion,
          numeratorExclusion,
          denominatorException,
          numeratorException
        };
      });
    } catch (error) {
      setStateData(prev => ({
        ...prev,
        measureMetCount: 0,
        measureNotMetCount: 0,
        error: "Error fetching data",
      }));
    }
  };
  const showBackButton= isEmployerRole() ? false : true;
  const getMaxLength = ()=> {
    return isSmallWindow ? NAME_MAX_LENGTH.GROUP_NAME_SMALL_SCREEN : NAME_MAX_LENGTH.GROUP_NAME;
  }
  useEffect(() => {
    const path = getPathFromHash(window.location.hash);
    const tabIndex = tabList().findIndex((item) => item.path === path);
    setSelectedTab(tabList()[tabIndex]);
  }, [])
  return (
    <View flex={1}>
      <View>
        <TitleSubtitleView
          title={'contracts'}
        />
      </View>
      <View padding={2} flexDirection={'row'} borderBottomWidth={1} borderBottomColor={Colors.FoldPixel.GRAY150}>
        <View alignItems={'center'} flexDirection={'row'} width={'50%'}>
          <View>
            {showBackButton? (
              <View>
                <Button
                  backgroundColor={'white'}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  onPress={() => {
                    LocalStorage.removeItem(QUALITY_MEASURE_STORAGE_KEY.MEASURE_DETAILS);
                    navigate(`/members/contracts/${contractId}/qualityMeasures`)
                  }}
                >
                  <AntIcon color={Colors.FoldPixel.GRAY300} name="arrowleft" size={18} />
                </Button>
              </View>
            ):(
              <></>
            )}
          </View>
          <View flex={1} marginRight={4}>
            <Text style={{
              color: Colors.FoldPixel.GRAY300,
              fontSize: 12,
              fontWeight: '400',
              lineHeight: 16.8,
              overflow: 'hidden'
            }}>
              {stateData?.measureDetails?.contractName}
            </Text>
            <Tooltip title={stateData?.measureDetails?.measureName?.length > getMaxLength() ? stateData?.measureDetails?.measureName : ''} placement={'top'}>
              <Text noOfLines={2} isTruncated style={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 14,
                fontWeight: '500',
                lineHeight: 16.8,
                fontFamily: 'Manrope-Bold',
                width: 'fit-content'
              }}>
                {stateData?.measureDetails?.measureName}
              </Text>
            </Tooltip>
            <Tooltip title={stateData?.measureDetails?.groupName?.length > getMaxLength() ? stateData?.measureDetails?.groupName : ''} placement={'top'}>
              <Text noOfLines={2} isTruncated style={{
                color: Colors.FoldPixel.GRAY300,
                fontSize: 12,
                fontWeight: '400',
                lineHeight: 16.8,
              }}>
                {stateData?.measureDetails?.groupName}
              </Text>
            </Tooltip>
          </View>
        </View>
        <View alignItems={'flex-end'} justifyContent={'center'} width={'50%'}>
          <View width={'95%'} padding={2} flexDirection={'row'} alignItems={'flex-start'}>
          <Text style ={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 16,
                fontWeight: '500',
                lineHeight: 19.2,
                marginBottom: 2,
              }}>{'Performance rate: '}</Text>
            <View style={{
              flexDirection: 'column',
              width: '68%',
              marginTop: 6
            }}>
              <View style={{
                width: '90%'
              }}>
                <ProgressBar percentage={stateData?.measureDetails?.measurePerformanceScore || 0} goal={stateData?.measureDetails?.measureGoal || 0} inInverse={stateData?.measureDetails?.measureInverse}/>
              </View>
              <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
              }}>
                <Text style={{
                  color: Colors.FoldPixel.GRAY400,
                  fontSize: 10,
                  fontWeight: '500',
                }}>{intl.formatMessage({id: 'currentScore'})} </Text>
                <Text style={{
                  color: getProgressBarColor(stateData?.measureDetails?.measurePerformanceScore || 0, stateData?.measureDetails?.measureGoal || 0, stateData?.measureDetails?.measureInverse),
                  fontSize: 10,
                  fontWeight: '500',
                }}>{stateData?.measureDetails?.measurePerformanceScore || 0}%</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View>
        <CustomTabsView
          contentTopMargin={0}
          showHeading={true}
          backButtonRouteFunc={()=> {
            navigate(`/members/contracts/${contractId}/qualityMeasures`);
          }}
          basePath={`/members/contracts/${contractId}/qualityMeasures/${qmId}`}
          tabsList={tabList()}
          tabListWrapperStyle={{
            paddingHorizontal: 16,
            backgroundColor: '#fff',
          }}
          canNavigate={true}
          onTabClick={(tabItem) => {
            onTabClick(tabItem);
          }}
          tabsLoading={stateData?.populationCodesLoading}
        />
      </View>
    </View>
  )
}

export default QualityMeasuresDetails;
