import {Liquid} from 'liquidjs';
import {ContentTypes} from '../ContentManagementConsts';
import {
  createTemplate,
  deleteTemplate,
  getTemplates,
  isObject,
  ITemplateQueryParams,
  updateTemplate,
} from '../ContentManagementUtils';
import {ComponentType} from '../EmailComponents/interfaces';
import {ITemplateCategory} from '../EmailTemplates/interfaces';
import {
  Category,
  SingleSMSTemplate,
  SMSTemplateApiResponse,
} from './interfaces';

export const validateTemplate = (singleSMSTemplate: SingleSMSTemplate) => {
  const errors: any = {};
  let valid = true;

  errors.name = !singleSMSTemplate.name
    ? 'Name is required'
    : singleSMSTemplate.name.length < 3
    ? 'Name is too short'
    : '';

  errors.category = !singleSMSTemplate?.category?.id
    ? 'Category is required'
    : '';

  errors.body = !singleSMSTemplate.body
    ? 'Body is required'
    : singleSMSTemplate.body.length < 10
    ? 'SMS body too short. Must be over 10 characters.'
    : '';

  if (errors.name || errors.category || errors.body) {
    valid = false;
  }

  return {
    valid: valid,
    errors: errors,
  };
};

export const getSMSTemplates = (
  categoryQueryString?: string,
  queryParams?: ITemplateQueryParams
) => {
  const url = `${ContentTypes.textMessages.path}?${categoryQueryString}`;
  return getTemplates(url, queryParams);
};

export const deleteSMSTemplates = (id: string | undefined) => {
  const url = `${ContentTypes.textMessages.path}`;
  return deleteTemplate(url, id);
};

export const createSMSTemplate = (args: {
  singleSMSTemplate: SingleSMSTemplate;
  override?: boolean;
}) => {
  const {singleSMSTemplate, override} = args;
  const url = `${ContentTypes.textMessages.path}`;
  const data = getPostTemplateObject(singleSMSTemplate, override);
  return createTemplate(url, data);
};

export const updateSMSTemplates = (args: {
  id: string | undefined;
  singleSMSTemplate: SingleSMSTemplate;
  override?: boolean;
}) => {
  const {id, singleSMSTemplate, override} = args;
  const url = `${ContentTypes.textMessages.path}`;
  const data = getPostTemplateObject(singleSMSTemplate, override);
  return updateTemplate(url, id, data);
};

export const getSMSTemplateByCategory = (args: {
  categoryCode: string;
  isDefault?: boolean;
}) => {
  const {categoryCode, isDefault} = args;
  let url;
  if (isDefault) {
    url = `${ContentTypes.textMessages.path}?category=${categoryCode}&isDefault=${isDefault}`;
  } else {
    url = `${ContentTypes.textMessages.path}?category=${categoryCode}`;
  }
  return getTemplates(url);
};

export const getFormattedSMSTemplates = (
  response: SMSTemplateApiResponse
): SingleSMSTemplate[] => {
  const data: SingleSMSTemplate[] = [];
  const apiData = response.data;
  apiData.forEach((item) => {
    data.push({
      id: item.id,
      body: item.attributes.body,
      name: item.attributes.name,
      isDefault: item.attributes.isDefault,
      lastUpdated: item.attributes.updatedAt,
      category: getFormattedCategoryData(item.attributes.category),
    });
  });
  return data;
};

export const getFormattedSingleSMSTemplate = (
  response: any
): SingleSMSTemplate => {
  const item = response.data;
  return {
    id: item.id,
    body: item.attributes.body,
    name: item.attributes.name,
    isDefault: item.attributes.isDefault,
    lastUpdated: item.attributes.updatedAt,
    category: getFormattedCategoryData(item.attributes.category),
  };
};

export const getFormattedCategoryData = (
  category: Category | ComponentType
): ITemplateCategory => {
  const data = category.data;
  return {
    id: data.id,
    name: data.attributes.name,
    code: data.attributes.code,
    mergeTags: data.attributes.mergeTags,
  };
};

export const getPostTemplateObject = (
  singleSMSTemplate: SingleSMSTemplate,
  override?: boolean
) => {
  const {name, body, category, isDefault} = singleSMSTemplate;
  return {
    data: {
      name: name,
      body: body,
      category: category?.id,
      isDefault: isDefault,
      forceDefault: override || false,
    },
  };
};

export const sorter = (a: SingleSMSTemplate, b: SingleSMSTemplate) => {
  const diff =
    new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
  return diff;
};

export const onFilter = (value: any, record: SingleSMSTemplate) => {
  return record.category?.name.indexOf(value) === 0;
};

export const getAddOrUpdateText = (condition: any) => {
  if (condition) {
    return {
      title: 'updateTemplate',
      btnText: 'Update',
      successText: 'Template Updated Successfully',
    };
  }
  return {
    title: 'createTemplate',
    btnText: 'Create',
    successText: 'Template Created Successfully',
  };
};

export const getMenuItems = (object: any): any => {
  return Object.keys(object).map((item: any) => {
    if (Array.isArray(object[item])) {
      return {
        label: item,
        key: item,
        children: object[item].map((arrayItem: any, index: number) => ({
          label: index,
          key: `${index}`,
          children: getMenuItems(arrayItem)
        }))
      };
    } else if (isObject(object[item])) {
      return {
        label: item,
        key: item,
        children: getMenuItems(object[item]),
      };
    } else {
      return {
        label: item,
        key: item,
      };
    }
  });
};

export const insertAtIndex = (str: string, index: number, value: string) => {
  return str.substring(0, index) + value + str.substring(index);
};

export const getParseBody = (args: {bodyString: string; mergeTags: any}) => {
  const {bodyString, mergeTags} = args;
  const body = bodyString || '';
  const engine = new Liquid();
  try {
    const tpl = engine.parse(body);
    return engine.renderSync(tpl, mergeTags);
  } catch (error) {
    return 'Body';
  }
};

export const getSelectedMergeTag = (keyPath: string[]) => {
  return `{{${keyPath.reverse().join('.')}}}`;
};
