import {LazyQueryExecFunction, OperationVariables} from '@apollo/client';
import {
  SUBSCRIPTION_API_STATUS,
  SUBSCRIPTION_SORT_ORDER,
} from '../../../../../../../constants';
import {isMemberShipWithinDateRange} from '../../../../../../../utils/commonUtils';
import {capitalizeText} from '../../../../../../common/ContactRelationView/ContactRelationUtils';
import { getCampaignByResourceIdList } from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {getFormDataFromLeadData} from '../../AddOrUpdateLead/AddOrUpdateUtils';
import {IAttributes} from '../../../../CustomField/interface';
import {
  IMultipleMemberGroupResponse,
  MemberGroupResponse,
} from '../../../interfaces';
import { PROGRAM_FILTER_CODE } from '../../../../../../common/MemebersView/constant';
import {IDeclinedPatientConditonInput} from '../../../../../../../Interfaces';
import {ALERTS_GAPS_STATUS_CODES} from '../../../../../../../constants/MlovConst';

export const formateLeadListData = (dataList: any, mlovData: any, contactsDictionary?: Record<string, string>): any => {
  const leadList: any = [];
  dataList?.contacts.forEach((singleLeadData: any) => {
    const singleLeadTempData = getFormDataFromLeadData(
      singleLeadData,
      mlovData
    );
    const contactType = singleLeadData?.contactType?.contactType?.value || '';
    let subscriptionStatus: any = {};
    const customAttributes = singleLeadData?.customAttributeValues?.map((attribute: IAttributes) => {
      return {
        id: attribute?.id,
        label: attribute?.customAttribute?.label,
        value: attribute?.value,
        booleanValue: attribute?.booleanValue,
        dateValue: attribute?.dateValue
      }
    }) || [];
    if (singleLeadData?.subscriptionInfo?.length) {
      const sortedArray = singleLeadData?.subscriptionInfo.sort(
        (a: any, b: any) => {
          return (
            isMemberShipWithinDateRange(b) &&
            b.plan?.metadata?.category == 'membership' &&
            SUBSCRIPTION_SORT_ORDER.indexOf(a.status) -
              SUBSCRIPTION_SORT_ORDER.indexOf(b.status)
          );
        }
      );
      subscriptionStatus = {
        status: sortedArray[0].status.toUpperCase(),
        label: capitalizeText(sortedArray[0].status.replaceAll('_', ' ')),
        loading: true
      };
    } else {
      if (singleLeadData?.contactAdditionalInfos?.stripeCustomerId) {
        subscriptionStatus = {
          loading: true
        };
      } else {
        subscriptionStatus = {
          status: 'NOT_MEMBER',
          label: 'Not a member',
          loading: false
        };
      }
    }
    leadList.push({
      id: singleLeadTempData.id,
      name: singleLeadData?.name,
      nameData: {
        name: singleLeadData?.name,
        email: singleLeadTempData?.email || '',
        age: singleLeadTempData.age ? singleLeadTempData.age + ' yrs' : '',
        gender: singleLeadTempData?.genderName || '',
        contactType: contactType,
      },
      contactData: singleLeadData,
      // email: singleLeadTempData?.email || '',
      mobileNumber: singleLeadTempData?.phone || '',
      location: singleLeadData?.contactPracticeLocations || [],
      // tags: singleLeadData?.contactTagging || [],
      zipCode: singleLeadTempData.zipCode || '',
      singleLeadData: singleLeadData,
      contactType: singleLeadData?.contactType?.contactType || '',
      subscriptionStatus: subscriptionStatus,
      uuid: singleLeadData?.uuid,
      consentLoading: true,
      ...(contactsDictionary && contactsDictionary[singleLeadData?.uuid] && { hintMembershipStatus: contactsDictionary[singleLeadData?.uuid] }),
      customAttributes,
      additionalDataLoading: {
        tagLoading: true,
        consentLoading: true,
        chronicConditionLoading: true,
        careProgramLoading: true,
        careTeamLoading: true,
        customAttributeLoading: true,
        claimsLoading: true,
        membershipLoading: true,
        nextPCPAppointmentLoading: true,
        assigneeLoading: true,
        patientDiagnosisGapsLoading: true
      }
    });
  });
  return leadList;
};

export const mapContactWithSubscriptionData = (campaignMappingResult: any, contactsSubscriptionInfo: any) => {
  const customerDataListWithSubscriptionInfo = campaignMappingResult.map((contact: any) => {
      let subscriptionStatus: any = {};
      let subscriptionInfo: any;
      const isSubscriptionData = contactsSubscriptionInfo.some((subscription: any) => {
          if (subscription[0].customer === contact?.contactData?.contactAdditionalInfos?.stripeCustomerId) {
            subscriptionInfo = subscription
              return;
          }
      })
      if (subscriptionInfo) {
          const sortedArray = subscriptionInfo?.sort(
              (a: any, b: any) => {
                  return (
                      isMemberShipWithinDateRange(b) &&
                      b.plan?.metadata?.category == 'membership' &&
                      SUBSCRIPTION_SORT_ORDER.indexOf(a.status) -
                      SUBSCRIPTION_SORT_ORDER.indexOf(b.status)
                  );
              }
          );
          subscriptionStatus = {
              status: sortedArray[0].status.toUpperCase(),
              label: capitalizeText(sortedArray[0].status.replaceAll('_', ' ')),
              loading: false
          };
          return {
              ...contact,
              subscriptionStatus: subscriptionStatus
          }
      } else {
          return {
              ...contact,
              subscriptionStatus: {
                  status: 'NOT_MEMBER',
                  label: 'Not a member',
                  loading: false
              }
          }
      }
  })
  return customerDataListWithSubscriptionInfo
}

export const getEmailAddressFromPerson = (
  contactList: any,
  separator = ', '
): string => {
  let emailList: string[] = [];
  emailList = contactList.map((singleContactObj: any) => {
    return singleContactObj.value;
  });
  return emailList.join(separator);
};

export const getProgramTypeFromFilterCode = (filterCode: string): string => {
  const programType = filterCode?.split('_')[0] || '';
  if ([PROGRAM_FILTER_CODE.TCM, PROGRAM_FILTER_CODE.CCM].includes(programType)) {
    return programType;
  }
  return 'AWV';
};

export const getCareProgramAssigneeColumnHeaderTitle = (displayName?: string, filterCode?: string): string => {
  if (displayName) {
    return displayName;
  }
  const programType = getProgramTypeFromFilterCode(filterCode || '');
  return `${programType} Assignee`;
};

export const getActiveCareProgramAssignee = (contactCarePrograms: any[], activeStatusIds: string[]) => {
  const activeCareProgram = contactCarePrograms?.find(program => 
    activeStatusIds.includes(program?.statusId)
  );
  return activeCareProgram?.assignee?.user?.name || '-';
};

export const tagListData = (tagList: any) => {
  const tagLists: any = [];
  tagList?.labels?.map((tagItem: any) => {
    tagLists.push({
      tagName: tagItem.description,
      tagColor: tagItem.color,
      id: tagItem.id,
      __typename: tagItem.__typename,
      title: tagItem.title,
      showOnSidebar: tagItem.showOnSidebar,
    });
  });
  return tagLists;
};

export const handleSorting = (sorter: any, order_by: any) => {
  const obj = order_by;

  obj['updatedAt'] = 'desc';
  if (sorter?.field === 'nameData') {
    obj['name'] =
      sorter.order === 'descend'
        ? 'desc'
        : sorter.order === 'ascend'
        ? 'asc'
        : undefined;
    // obj['updatedAt'] = undefined;
    obj['phoneNumber'] = undefined;
    obj['email'] = undefined;
  }
  if (sorter?.field === 'email') {
    obj['email'] =
      sorter.order === 'descend'
        ? 'desc'
        : sorter.order === 'ascend'
        ? 'asc'
        : undefined;
    // obj['updatedAt'] = undefined;
    obj['phoneNumber'] = undefined;
    obj['name'] = undefined;
  }
  if (sorter?.field === 'mobileNumber') {
    obj['phoneNumber'] =
      sorter.order === 'descend'
        ? 'desc_nulls_last'
        : sorter.order === 'ascend'
        ? 'asc'
        : undefined;
    obj['email'] = undefined;
    // obj['updatedAt'] = undefined;
    obj['name'] = undefined;
  }
  return obj;
};

export const onPagination = (page: any, pageSize: any) => {
  const pageNumber = page;
  const pageLimit = pageSize;
  const offset = pageNumber * pageLimit - pageLimit;
  return offset;
};

export const onMultipleSelectedItem = (
  singleItem: any,
  items: any,
  callback: any
) => {
  const tempItems: any = items;
  const isItemPresent = tempItems.find((item: any) => {
    return singleItem?.id == item?.id;
  });
  if (isItemPresent) {
    const index = tempItems
      .map((item: any) => {
        return item.id;
      })
      .indexOf(isItemPresent.id);

    tempItems.splice(index, 1);
  } else {
    tempItems.push(singleItem);
  }
  callback(tempItems);
  return;
};

export const removeDuplicates = (originalArray: any, prop: any) => {
  const newArray: any = [];
  const lookupObject: any = {};

  for (const i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (const j in lookupObject) {
    newArray.push(lookupObject[j]);
  }
  return newArray;
};

export async function getPatientCareJourneys(args: {
  contactData: any;
  getAllPatientCareJourneys: LazyQueryExecFunction<any, OperationVariables>;
}) {
  const {contactData, getAllPatientCareJourneys} = args;
  const patientCareJourneyMap: any = {};
  const patientIdList = (contactData || []).map(
    (item: any) => item?.contactData?.patient?.patientUuid || item?.contactData?.patient?.patientId
  ).filter((patientId: string) => !!patientId);
  const careJourneyResponse = await getAllPatientCareJourneys({
    variables: {patientIdList},
  });
  const patientCareJourneys =
    careJourneyResponse.data?.patientCareJourneys || [];
  patientCareJourneys.forEach((item: any) => {
    const patientId = item.patientId;
    if (!patientCareJourneyMap[patientId]) {
      patientCareJourneyMap[patientId] = [];
    }
    patientCareJourneyMap[patientId].push(item);
  });
  const tempData = contactData;
  const result = tempData.map((item: any) => {
    const data = item;
    const patientId = data?.contactData?.patient?.patientUuid || data?.contactData?.patient?.patientId;
    const careJourney =
      patientCareJourneyMap[patientId] || [];
    return {
      ...data,
      contactData: {...item.contactData, careJourney: careJourney},
    };
  });
  return result;
}

export async function getContactCampaigns(contactData: any, result: any) {
  const contactIdList = contactData.map((item: any) => item.contactData?.id?.toString());
  const contactCampaignMap: any = {};
  const campaignResponse = await getCampaignByResourceIdList(contactIdList);
  const campaignList = campaignResponse?.campaignList || [];
  campaignList.forEach((item: any) => {
    const resourceId = item.resourceLevelLog.length > 0 ? item.resourceLevelLog[0].resourceId : "";
    if (!contactCampaignMap[resourceId]) {
      contactCampaignMap[resourceId] = [];
    }
    contactCampaignMap[resourceId].push(item);
  });
  const campaignMappingResult = result.map((item: any) => {
    const campaign = contactCampaignMap[item.contactData.id.toString()];
    return {
      ...item,
      contactData: {
        ...item.contactData,
        campaign: campaign,
      },
    };
  });
  return campaignMappingResult;
}

export const getMemberGroupMap = (
  memberGroups: IMultipleMemberGroupResponse[]
) => {
  const memberGroupMap = new Map<string, MemberGroupResponse[]>();
  memberGroups.forEach((item) => {
    const results = item.results.filter((item) => Boolean(item.name));
    memberGroupMap.set(item.contactId, results);
  });
  return memberGroupMap;
};


export const getFilteredDiagnosisGapsByStatus = (
  diagnosisGaps: IDeclinedPatientConditonInput[],
  status: string[]
) => {
  return diagnosisGaps?.filter(
    (diagnosisGap: IDeclinedPatientConditonInput) =>
      diagnosisGap?.conditionDetails?.length > 0 &&
      diagnosisGap?.conditionDetails[0]?.diagnosisGapStatus &&
      !status?.includes(diagnosisGap?.conditionDetails[0]?.diagnosisGapStatus)
  );
};
