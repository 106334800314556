import {Table} from 'antd';
import {Spinner} from 'native-base';
import {useContext, useState} from 'react';
import {Dimensions,View} from 'react-native';
import {useNavigate, useParams} from 'react-router-dom';
import {COMMON_ACTION_CODES} from '../../../../../../../constants/ActionConst';
import PageBodyContainer from '../../../../../../common/PageBodyContainer/PageBodyContainer';
import {TableWrapper} from '../../../../../../common/TableWrapper';
import {getQualifiedPatientList} from '../../../../HelperFiles/TablesColumnsView';
import {IQualityMeasuresPatientList} from '../../../../HelperFiles/interface';
import {MeasureCriteriaDetailDrawer} from '../MeasureCriteriaDetail/MeasureCriteriaDetailDrawer';
import {MIDDLE_CONTAINER_TABS} from '../../../../../../PersonOmniView/MiddleContainer/MiddleContainerConst';
import {ExecutionDate} from '../../../../ExecutionDateView';
import {useLazyQuery} from '@apollo/client';
import LeadQueries from '../../../../../../../services/Lead/LeadQueries';
import MessagingContactDetailsDrawer from '../../../../../TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {PERSON_TYPES} from '../../../../../../../constants';
import {IContactForQuickDrawer, IContactResponseForQuickDrawer} from '../../../../../TeamInbox/Conversations/MessagingContactDetails/interface';
import FeatureFlags from '../../../../../../../constants/FeatureFlags.enums';
import {getBooleanFeatureFlag} from '../../../../../../../utils/commonUtils';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';

const PatientList = (props: IQualityMeasuresPatientList) => {
  const {stateData, handlePageChange, data, patientType} = props;
  const [patientListState, setPatientListState] = useState({
    selectedItems: [] as any,
    selectedRowKey: [] as any,
    showMeasureCriteriaDetailDrawer: false,
    selectedMeasure: {} as any,
    contactData: {} as IContactForQuickDrawer,
    isLoadingContact: false 
  })
  const {height} = Dimensions.get('window');
  const {contractId, qmId} = useParams();
  const finalHeight = height - 320;
  const navigate = useNavigate();
  const commonData = useContext(CommonDataContext);
  const hidePayerColumnInContract = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.HIDE_PAYER_COLUMN_IN_CONTRACT);
  const [GET_CONTACT_DATA_FOR_QUICK_DRAWER] = useLazyQuery(LeadQueries.contactDataForQuickDrawer,{
    fetchPolicy: 'network-only'
  })
  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch(actionCode) {
      case COMMON_ACTION_CODES.SHOW_REASON:
        setPatientListState((prev) => {
          return{
            ...prev,
            showMeasureCriteriaDetailDrawer: true,
            selectedMeasure: actionData,
          }
        })
        break;
      case COMMON_ACTION_CODES.CLOSE:
        setPatientListState((prev) => {
          return{
            ...prev,
            showMeasureCriteriaDetailDrawer: false,
            selectedMeasure: {}
          }
        })
        break;
      case COMMON_ACTION_CODES.OPEN:
        handleOpenQuickDrawer(actionData?.contactData)
        // const selectedMiddleContainerTab = MIDDLE_CONTAINER_TABS.ALERTS;
        // window.open(`#/members/patient/${actionData?.contactData?.id}/${selectedMiddleContainerTab}`, '_blank', 'noopener,noreferrer');
        break;
      default:
        break;
    }
  }

  const handleOpenQuickDrawer = async (contactData: {uuid: string}) => {
    try {
      setPatientListState(prev => ({
        ...prev,
        isLoadingContact: true
      }));
  
      const response: IContactResponseForQuickDrawer = await GET_CONTACT_DATA_FOR_QUICK_DRAWER({
        variables: {
          uuid: contactData?.uuid
        }
      });
  
      setPatientListState((prev) => ({
        ...prev,
        contactData: response?.data?.contacts?.[0] || {} as IContactForQuickDrawer,
        isLoadingContact: false
      }));
    } catch  {
      setPatientListState((prev) => ({
        ...prev,
        contactData: {} as IContactForQuickDrawer,
        isLoadingContact: false
      }));
    }
  }
  return (
    <>
      <PageBodyContainer>
        <ExecutionDate date={stateData?.executionDate} loading={stateData.executionDateLoading}/>
        <TableWrapper
          wrapperStyle={{alignItems: 'center'}}
          minHeight={230}
          pagination={{
            current: stateData?.currentPage,
            pageSize: stateData?.pageSize || 10,
            total: stateData?.total,
            onChange: (currentPage, currentPageSize) => {
              handlePageChange?.(currentPage, currentPageSize);
            },
          }}
          hideOnSinglePage={false}
        >
          <Table
            scroll={{x: 1000, y: finalHeight}}
            rowClassName={(record, index) => {
              let className = index % 2 == 0 ? 'table-row-light' : ''
              className = className + ' quality-measures-report'
              return className
            }
            }
            className="lead-list-table-view patient-list-view"
            columns={getQualifiedPatientList({memberType: patientType,hidePayerColumnInContract},onActionPerformed)}
            dataSource={data}
            pagination={false}
            loading={{
              spinning: stateData.loading || patientListState.isLoadingContact,
              indicator: (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Spinner size={'lg'} />
                </View>
              ),
            }}
            rowSelection={undefined}
            rowKey={(record) => {
              return record.id;
            }}
          />
        </TableWrapper>
      </PageBodyContainer>
      {patientListState?.showMeasureCriteriaDetailDrawer && <MeasureCriteriaDetailDrawer
        selectedMeasureId={patientListState?.selectedMeasure?.id}
        isInverse={patientListState?.selectedMeasure?.inverse}
        onDrawerClose={onActionPerformed}
        criteriaType={patientType}
        qmMeasureEvidence={patientListState?.selectedMeasure?.qmMeasureEvidence}
      />}
      {patientListState?.contactData?.id && <MessagingContactDetailsDrawer
        contactData={patientListState?.contactData}
        isDrawerVisible={patientListState?.contactData ? true : false}
        contactType={PERSON_TYPES.CUSTOMER}
        onSideBarActionPerformed={() => {
          setPatientListState((prev) => {
            return {
              ...prev,
              contactData: {} as IContactForQuickDrawer
            }
          })
        }}
      />}
    </>
  );
};

export default PatientList;
